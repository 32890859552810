import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import ChatIcon from '@mui/icons-material/Chat';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import Divider from '@mui/material/Divider';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import OutlinedInput from '@mui/material/OutlinedInput';
import _ from 'lodash'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import timezones from '../../constants/timezones.json'
import Autocomplete from '@mui/lab/Autocomplete';
import Box from '@mui/material/Box';
import RoomIcon from '@mui/icons-material/Room';
import HistoryIcon from '@mui/icons-material/History';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GoogleAdSense from '../GoogleAdSense';
import Grid from '@mui/material/Grid';


registerPlugin(FilePondPluginImagePreview);

const styless = {
	bigAvatar:{
		margin: 10,
		width: 150,
		height: 150,
		alignSelf:'center',
		cursor: 'pointer'
	},
	title: {
		color: '#333333',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 24
	},
	subtitle: {
		color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 18
	},
	center:{
		textAlign:'center',
		color: '#3c4859'
	},

	small: {
	   	color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10
	   },

	   em: {
	   	 color: '#8e8e8e',
	   	 fontFamily: 'Gotham Rounded'
	   },

	   buttonPrimary: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded',
	   	backgroundColor: '#00b16a'
	   },

	   buttonDefault: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded'
	   }

}

class JobPublic extends Component{
	
	constructor(props){
		
		super(props);

		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		snackbar: false,
    		snackbarMessage: '',
    		applyDialog: false,
			job: [],
			proposedRate: 0,
			proposedRateError:false,
			coverLetter: '',
			coverLetterError: false,
			otherLinks: '',
			otherLinksError: false,
			hourlyRate: 0,
			fullTimeRate: 0,
			jobApplication: [],
			allApplications:[],
			preference: '',
			confirmDeleteJobDialog: false,
			declineReason: 'Skills are not suited for the job',
			jobApplicationID: '',
			declineApplicationDialog: false,
			interviewApplicationDialog: false,
			showDeclineReason: false,
			tabActive: "ALL",
			interviewMessage: '',
			showApplicationDialog: false,
			offerApplicationDialog: false,
			offerMessage: '',
			offerJobType: '',
			offerDuration: '',
			offerRate: '',
			offerHoursPerWeek: '',
			offerNotes: '',
			interviewDate: new Date(),
			interviewTime: new Date(),
			profileStatus: '',
			showContractDialog:false,
			timezone: timezones[6].text,
			showLimit: 2,
			unlockShowLimitDialog: false,
			showLimitPackage: 50,
			client: [],
			jobTitle : "",
			stats:[]
		};
	
	}	


	componentWillMount() {
		this.getJob();	
		this.getStat()
	}


	async getStat()
	{
		
		let that = this
		const querySnapshot = await this.props.getDocs(this.props.collection(this.props.db, "stats"));
        
        querySnapshot.forEach((doc) => {
            
           let data = doc.data()
           data.id = doc.id

           that.setState({stats:data}) 

        });

	}

	async getJob(){
		let that = this

		let jobsRef = this.props.doc(this.props.db, "jobs", this.props.id);
		let jobSnap = await this.props.getDoc(jobsRef);

		if (jobSnap.exists())
		{

			let data = jobSnap.data()
			data.id = jobSnap.id

			document.title = "Freelancertogo - " + data.jobPostTitle

			if(data.jobType==="Project Based")
				that.setState({proposedRate: 0})
			else if(data.jobType==="Full Time")
				that.setState({proposedRate: that.state.fullTimeRate})
			else if(data.jobType==="Part Time")
				that.setState({proposedRate: that.state.hourlyRate})
			
			that.setState({
				jobTitle: data.jobPostTitle,
				id: that.props.id,
				job: data,
				offerJobType: data.jobType,
				offerDuration: data.estProjectDuration,
				offerRate: data.budget,
				offerHoursPerWeek: data.hoursPerWeek,
				isLoaded: true,
				showLimit: _.has(data,'showLimit') ? data.showLimit : 25
			}, async ()=>{	

			})

		}else{

			jobsRef = this.props.collection(this.props.db, "jobs");
	  		const q = this.props.query(jobsRef, this.props.where("permalink", "==", that.props.id) );
	  		const querySnapshot = await this.props.getDocs(q);

	  		querySnapshot.forEach((doc) => {
			  
			  	let data = doc.data()
			  	data.id = doc.id

				document.title = "Freelancertogo - " + data.jobPostTitle

				if(data.jobType==="Project Based")
					that.setState({proposedRate: 0})
				else if(data.jobType==="Full Time")
					that.setState({proposedRate: that.state.fullTimeRate})
				else if(data.jobType==="Part Time")
					that.setState({proposedRate: that.state.hourlyRate})

				that.setState({
					jobTitle: data.jobPostTitle,
					id: that.props.id,
					job: data,
					offerJobType: data.jobType,
					offerDuration: data.estProjectDuration,
					offerRate: data.budget,
					offerHoursPerWeek: data.hoursPerWeek,
					isLoaded: true,
					showLimit: _.has(data,'showLimit') ? data.showLimit : 25
				}, async ()=>{	

				})

			});

		}

	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	jsUcfirst = (string) =>
	{
	    let str = string.charAt(0).toUpperCase() + string.slice(1);
	    return str
	}
	
	render(){
		
		if (typeof window !== 'undefined')
		{
			let jobApplicationsList  = ''
			let activeApplicationCount = 0
			let that = this

			if( this.state.allApplications.length > 0 )
			{
				jobApplicationsList = this.state.allApplications.map(function(item, i){
					


					if(item.status===that.state.tabActive || that.state.tabActive==="ALL" && _.has(item,'user') ){
						activeApplicationCount++
						return (
						<div key={i}>
						<ListItem>
							<ListItemAvatar>
					          <Avatar alt={ that.jsUcfirst(item.user.fname)+" "+that.jsUcfirst(item.user.lname) } src={item.user.avatar} />
					        </ListItemAvatar>
							<ListItemText secondary={item.coverLetter.substring(0,70) + "" + ( item.coverLetter.length > 70 ? "..." : "" ) }>
								<a rel="noopener noreferrer" target="_blank" href={'/freelancer/'+item.uid} style={{fontFamily:"Gotham Rounded"}}>{that.jsUcfirst(item.user.fname) } {that.jsUcfirst(item.user.lname)}</a>
							 		
							</ListItemText>
							<ListItemSecondaryAction>
							
							   { ( item.status !=="DECLINED" && item.status !=="INTERVIEW" && item.status !=="HIRE" && item.status !=="INVITATION" ) &&
			                   <Tooltip title="Set For Interview" aria-label="Add to Shortlist">
				                    <IconButton edge="end" aria-label="shortlist" onClick={ ()=>  that.setState({interviewApplicationDialog:true, jobApplicationID: item}) }>
				                      <FormatListNumberedIcon />
				                    </IconButton>
			                    </Tooltip>
			                	}
			                   
			                   { ( item.status !=="HIRE" && item.status !=="OFFER" && item.status !=="INVITATION" ) && <Tooltip title="Send Job Offer" aria-label="hire">
				                    <IconButton edge="end" aria-label="hire" onClick={ ()=>that.setState({offerApplicationDialog:true, jobApplicationID: item}) }>
				                      <CheckIcon />
				                    </IconButton>
			                    </Tooltip>
			                	}

			                    { ( item.status !=="DECLINED" && item.status !=="HIRE" && item.status !=="INVITATION" ) &&
			                    <Tooltip title="Decline" aria-label="decline">
				                    <IconButton onClick={ () => that.setState({declineApplicationDialog:true, jobApplicationID: item}) } edge="end" aria-label="decline">
				                      <CloseIcon />
				                    </IconButton>
			                    </Tooltip>
			                	}

			                    <Tooltip title="Message" aria-label="Message">
									<IconButton edge="end" aria-label="chat" onClick = { () => that.addContact(item) } >
				                      <ChatIcon />
				                    </IconButton>
			                    </Tooltip>

			                    { item.status !=="INVITATION" &&
				                    <Tooltip title="View Job Application" aria-label="View Job Application">
										<IconButton edge="end" aria-label="chat" onClick = { () => that.setState({showApplicationDialog:true, jobApplicationID: item}) } >
					                      <ArrowForwardIcon />
					                    </IconButton>
				                    </Tooltip>
			                	}

			                    {
			                    	item.status === "HIRE" &&
			                    	<Tooltip title={ _.has(item,'end_contract_at') ? "Contract Ended" : "View Contract"} aria-label={ _.has(item,'end_contract_at') ? "Contract Ended" : "View Contract"}>
									
									
									<IconButton style={{color:  _.has(item,'end_contract_at') ? 'red' : '' }} edge="end" aria-label="chat" onClick = { () => that.setState({showContractDialog:true, jobApplicationID: item}) } >
					                    <DescriptionIcon />
					                </IconButton>
				                	
			                    </Tooltip>
			                    }
			                </ListItemSecondaryAction>
			            </ListItem>	
			            <Divider variant="inset" component="li" />
			            </div>
						)
					}else{return null}

				})
			}

			let est = this.state.job.estProjectDuration
			
			if( est === '1' ){
				est = 'Less than a week'
			}else if( est ==='2' ){
				est = 'Less than a month'
			}else if( est ==='3' ){
				est = 'Less than 3 months'
			}else if( est ==='4' ){
				est = 'Less than 6 months'
			}else if( est ==='5' ){
				est = 'Less than a year'
			}else if( est ==='6' ){
				est = 'More than a year'
			}


			let offer_est = this.state.jobApplicationID.offer_duration
			
			if( offer_est === '1' ){
				offer_est = 'Less than a week'
			}else if( offer_est ==='2' ){
				offer_est = 'Less than a month'
			}else if( offer_est ==='3' ){
				offer_est = 'Less than 3 months'
			}else if( offer_est ==='4' ){
				offer_est = 'Less than 6 months'
			}else if( offer_est ==='5' ){
				offer_est = 'Less than a year'
			}else if( offer_est ==='6' ){
				offer_est = 'More than a year'
			}

			let date = _.has( this.state.job,'created_at' )  ?  moment(this.state.job.created_at.toDate()).fromNow() : ''
		
			return (

				<div>
				
				<header>
				  { this.state.isLoaded === true &&
			          <div style={{marginTop: 100}}>
				          
				        
				          	<Grid container>
				          		
				     			
				          		<Grid item xs={12} md={12}>

				          		 

				          		 <h1 className="App-title">{ this.state.job.jobPostTitle }
				          		 	<b>Posted {date}  </b>
				          		 </h1>
				          		 
				          		 <br/>

				          		 <div style={{textAlign:'center'}}>
				          		 	<Link target="_blank" to={ROUTES.SIGN_UP}>Signup</Link> or <Link target="_blank" to={ROUTES.SIGN_IN}>Login</Link> to Apply to this Job
				          		 </div>
				          		 
			          		 	<Grid container>
			          		 	    <Grid item md={2}></Grid>
			          		 		<Grid item md={8}>
			          		 			<h2><br/></h2>
			          		 			{ _.has(this.state.stats,'enable_ads') && this.state.stats.enable_ads == true &&
								            <div>
												<GoogleAdSense location="horizontal"></GoogleAdSense>
											
											</div>
										}
			          		 			<h2><br/></h2>
			          		 			<div className="App-panel">
						         				<b>Job Description: </b> <br/><br/>
						         				<div dangerouslySetInnerHTML={{ __html: this.state.job.jobDescription }} />
						         				<hr/>
						         				<b>Job Category: </b> { this.state.job.jobCategory }
						         				<hr/>
						         				<b>Expertise: </b> { this.state.job.expertise.charAt(0).toUpperCase() + this.state.job.expertise.slice(1) }
						         				<hr/>
						         				<b>Job Type: </b> { this.state.job.jobType }
						         				<hr/>
						         				<b>Estimate Project Duration: </b> {est}
						         				<hr/>
						         				<b>{ this.state.job.jobType ==="Project Based" ? "Budget" : "Rate Per Hour" }: </b> ${this.state.job.budget}
						         				<hr/>
						         				<b>Hours per week: </b> { this.state.job.hoursPerWeek }
						         				{ this.state.job.howToApply!=='' && <hr/> }
						         				{ this.state.job.howToApply!=='' && <b>How to Apply: </b> }
						         				{ this.state.job.howToApply!=='' && <div dangerouslySetInnerHTML={{ __html: this.state.job.howToApply }} /> }

						         				{ this.state.job.notes!=='' && <hr/> }
						         				{ this.state.job.notes!=='' && <b>Additional Note: </b> }
						         				{ this.state.job.notes!=='' && <div dangerouslySetInnerHTML={{ __html: this.state.job.notes }} /> }
						         				<hr/>
						         				<Grid container>
								          			<Grid item xs={12} md={12}>
								          				{ this.state.jobApplication.length === 0 && this.state.preference === "jobs" && this.state.profileStatus === "APPROVED" &&
								          					<Button style={styless.buttonPrimary} onClick={ () => this.sendProposal() } variant='contained' color='primary' fullWidth={true}>
								          						 { this.state.isSaving === true ? <CircularProgress size={28} color={'#ffffff'} /> : 'Send a Proposal' }
								          					</Button>
								          				}

								          				
								          				{ this.state.preference === "jobs" && this.state.profileStatus!=="" && this.state.profileStatus!=="APPROVED" &&
								          					<div className="alert alert-warning">
								          		 				<Grid container>
								          		 					<Grid item xs={12} md={12}>
								          		 						Sorry you cannot submit a proposal at this moment because your profile status is not active. Please complete your profile and submit again for review.
								          		 					</Grid>
								          		 				</Grid>
								          		 			</div>
								          		 		}

								          				

								          				{ this.state.jobApplication.length > 0 && this.state.preference === "jobs" &&
								          					<center>
								          						<img alt="applied.png" src="/applied.png" />
								          						<Typography>You already applied to this job</Typography>
								          					</center>
								          				}
								          			</Grid>
								          		</Grid> 
						          		 </div>

			          		 		</Grid>
			          		 		<Grid md={2}></Grid>
			          		 	</Grid>
				          		
						         
				          		 <br/>

				          		 	
				          		 { this.props.user.preference === "freelancers" &&
				          		 	<div>
					          		    <h1 className="App-title">Job Applications</h1>
				          		  		<div>
					          		 		
					          		 		<Tabs variant="scrollable" scrollButtons="auto" TabIndicatorProps={{style:{background: '#00B16A'}}} value={this.state.tabActive} onChange={ (event, newValue) => { this.setState({tabActive:newValue, rowLimit: that.state.rowLimitDefault}) } }>
									          <Tab value="ALL" label="All" />
									          <Tab value="INVITATION" label="Invitation" />
									          <Tab value="APPLIED" label="Applied" />
									          <Tab value="INTERVIEW" label="Interview" />
									          <Tab value="OFFER" label="Offer" />
									          <Tab value="DECLINED_OFFER" label="Declined Offer" />
									          <Tab value="HIRE" label="Hires" />
									          <Tab value="DECLINED" label="Declined" />
									        </Tabs>


					          		 		<List>
						          		 		{this.state.allApplications.length > 0 && jobApplicationsList}

						          		 		{activeApplicationCount === 0 && 
						          		 			<center>
						          		 				<br/><br/>
							          		 			<img alt="resume.png" src="/resume.png" />
							          		 			<br/><br/>
							          		 			<Typography>Sorry, no applicant to show on this tab.</Typography>
							          		 			<br/>
							          		 		</center>
						          		 		}
						          		 	</List>
					          		 	</div>

					          		 	<br/>

					          		 	{
					          		 		this.state.showLimit!==-1 &&
					          		 		<Alert severity="info" 
					          		 		action={
										          <Button color="inherit" size="small" onClick={()=>{this.setState({unlockShowLimitDialog:true})}}>
										            UNLOCK
										          </Button>
										        }
					          		 	>

					          		 			We are showing {this.state.showLimit} job proposals to this job. Use credit to unlock all proposals.
					 						</Alert>  
					          		 	}

					          		 	
					          		 	
				          		 	</div>


				          		 }

				          		</Grid>
				          		
				          	</Grid>
				       
				      </div>
			  	  }

			      { this.state.isLoaded === false &&
	                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
	                        <CircularProgress style={{color:'#212121', alignSelf:'center'}} />
	                    </div>
	              }
		        </header>
		        
		        <Snackbar
			          anchorOrigin={{
			            vertical: 'bottom',
			            horizontal: 'center',
			          }}
	          		open={this.state.snackbar}
	          		autoHideDuration={5000}
	          		onClose={this.snackbarClose}
	          		ContentProps={{
	            		'aria-describedby': 'message-id',
	          		}}
	          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
			          action={[
			            <IconButton
			              key="close"
			              aria-label="Close"
			              color="inherit"
			              onClick={this.snackbarClose}
			            >
			              <CloseIcon />
			            </IconButton>,
			          ]} />
		    </div>
				)

		}else{
			return (<div></div>)
		}
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		doc: state.doc,
		db: state.db,
		collection: state.collection,
		getDoc: state.getDoc,
		getDocs: state.getDocs,
		where: state.where,
		query: state.query
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(JobPublic);





