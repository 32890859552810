import React, {Component} from 'react'

import * as ROUTES from '../../constants/routes';
import { connect } from 'react-redux'
import { BrowserRouter, Route , Routes   } from 'react-router-dom';
import Navigation from '../Navigation';
import Landing from '../Landing';
import SignUp from '../SignUp';
import SignIn from '../SignIn';
import SignOut from '../SignOut';
import Profile from '../Profile';
import EmpProfile from '../EmpProfile';
import Jobs from '../Jobs';
import Job from '../Job';
import JobPublic from '../JobPublic';
import USER from '../User';
import USERMAIN from '../UserMain';
import Work from '../Work';
import Freelancer from '../Freelancer';
import MyApplications from '../MyApplications';
import MyJobs from '../MyJobs';
import Message from '../Message';
import Cookie from '../Cookie';
import Privacy from '../Privacy';
import TermsOfService from '../TermsOfService'
import ComingSoon from '../ComingSoon';
import Maintenance from '../Maintenance';
import UnderConstruction from '../UnderConstruction';
import About from '../About';
import Support from '../Support';
import Admin from '../AdminSettings';
import EndContract from '../EndContract';
import EndContractFreelancer from '../EndContractFreelancer';
import BuyCredits from '../BuyCredits/'
import Blank from '../Blank/'
import ReportProblem from '../ReportProblem/'
import Premium from '../Premium';
import UserPublic from '../UserPublic';
//admin routes
import UsersList from '../UsersList';
import AllJobList from '../AllJobList';
import PayoutList from '../PayoutList';
import BlogAdmin from '../BlogAdmin';

import Preview from '../PremiumTemplates/Preview'


import Transactions from '../Transactions';

import WithParams from '../WithParams';
import Blogs from '../Blogs';
import Blog from '../Blog';

import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import _ from 'lodash'

import USER_SOCIAL from '../PremiumTemplates/Template1';
import Grid from '@mui/material/Grid';

import { Helmet } from 'react-helmet';

const styles = {
   brand:{
        color: '#00b16a',
        fontFamily: 'Gotham Rounded',
        fontSize: 24,
        fontWeight: 'bold'
    }
}



class ProtectedRoute extends Component {

    constructor(props){
        super(props);

        this.state = {
            preference: null,
            isLoaded: false,
            notification_limit: 5,
            isMaintenance: false
           
        }
    }





    componentWillMount(){
        
        let that = this
        this.getStats()

        this.props.onAuthStateChanged(this.props.auth, (user) => {
          
        
          if(_.has(user,'emailVerified') && user.emailVerified === true)
          {
          
             this.getUser(user);

          } else {
             that.setState({isLoaded:true})
          }
        });
        
    

    }

    async getUser(user)
    {
        let that = this
        
        const userRef = this.props.doc(this.props.db, "users", user.uid);
        const userSnap = await this.props.getDoc(userRef);

        if (userSnap.exists())
        {
           
            var data = userSnap.data();
            data.uid = user.uid
            that.setState({preference: data.preference} )

            
            if(_.has(data,'newmessages')){
              that.props.setMessageCount(data.newmessages.length)
            }

            that.props.setAuth(data, true ); 

            let sessionExpired = localStorage.getItem("sessionExpired")

            if(sessionExpired)
            {
                let oldDateObj = new Date();
                let newDateObj = new Date(sessionExpired)
                let difference = oldDateObj.getTime()-newDateObj.getTime(); // This will give difference in milliseconds
                let resultInMinutes = Math.round(difference / 60000);

                if(resultInMinutes > 0)
                {
                    localStorage.clear()

                    that.props.signOut(that.props.auth).then(() => {
                        
                        that.props.logout(null)
                        window.location = '/'

                    }).catch((error) => {
                      // An error happened.
                    });
                }

            }else{
                let oldDateObj = new Date();
                let newDateObj = new Date();
                newDateObj.setTime(oldDateObj.getTime() + (280 * 60 * 1000));
                localStorage.setItem('sessionExpired',newDateObj)
            }

        }

    }

    async getStats(){

        const querySnapshot = await this.props.getDocs(this.props.collection(this.props.db, "stats"));
        
        querySnapshot.forEach((doc) => {
            
            let stat = doc.data();

            if( _.has(stat,"maintenance") )
            {
                this.props.setMaintenance( stat.maintenance ); 
                this.props.setFunding( stat.funding ); 
            }

        });

    }

    /*
    async getNotifications(){
        let that = this
        
        this.props.firebase.firestore().collection('notifications').where( "to","==", that.props.user.uid ).orderBy("created_at","desc").limit(that.state.notification_limit)
        .onSnapshot((querySnapshot) => {

            let notifications = []
            
            querySnapshot.forEach((doc) => {
                 let notification = doc.data();
                 notification.id = doc.id
                 notifications.push(notification)
            });

           
             that.props.setNotifications(notifications)
        })
        
    
    }

    */

    render(){

        let route
        let that = this

        if (typeof window !== 'undefined')
        {

            if( this.props.maintenance === true )
            {

                if( this.props.isLoggedIn === true )
                {
                     if(this.state.preference === 'admin')
                     {
                         route = <Routes>
                                     <Route path={ROUTES.LANDING} element={ <Maintenance/> } />

                                 </Routes>
                    }else{
                         route = <Routes>
                                     <Route path={ROUTES.LANDING} element={ <Maintenance/> } />
                                 </Routes>
                    }

                }else{
                    route = <Routes>
                                <Route path={ROUTES.LANDING} element={ <Maintenance/> } />
                            </Routes>
                }
               
               
            }
            else if( this.props.isLoggedIn === true )
            {

                if( this.state.preference === 'jobs' )
                {
                    route = <Routes>
                                <Route path={ROUTES.BLOG+':id'} element={<WithParams>{(id) => <Blog id={id} />}</WithParams>} />
                                <Route path={ROUTES.BLOGS} element={ <Blogs /> } />
                                <Route path={ROUTES.LANDING} element={ <Landing /> } />
                                <Route path={ROUTES.SIGN_IN} element={ <Profile /> } /> 
                                <Route path={ROUTES.SIGN_UP} element={ <Profile /> } /> 
                                <Route path={ROUTES.PROFILE} element={ <Profile /> } /> 
                                <Route path={ROUTES.SIGN_OUT} element={ <SignOut /> } /> 
                                <Route path={ROUTES.FINDWORK} element={ <Work /> } /> 
                                <Route path={ROUTES.JOBS+':id'} element={<WithParams>{(id) => <Job id={id} />}</WithParams>} />
                                <Route path={ROUTES.MESSAGE} element={ <Message /> } /> 
                                <Route path={ROUTES.MYAPPLICATIONS} element={ <MyApplications /> } /> 
                                <Route path={ROUTES.TRANSACTIONS} element={ <Transactions /> } />
                                <Route path={ROUTES.USER+':id'} element={<WithParams>{(id) => <USER id={id} />}</WithParams>} />
                                <Route path={ROUTES.ENDCONTRACT+':id'} element={<WithParams>{(id) => <EndContract id={id} />}</WithParams>} />
                                <Route path="*" element={ <Blank /> } /> 
                            </Routes>

                }else if(this.state.preference === 'freelancers')
                {   
                    route = <Routes>    
                                <Route path={ROUTES.BLOG+':id'} element={<WithParams>{(id) => <Blog id={id} />}</WithParams>} />
                                <Route path={ROUTES.BLOGS} element={ <Blogs /> } />
                                <Route path={ROUTES.LANDING} element={ <Landing /> } />
                                <Route path={ROUTES.SIGN_OUT} element={ <SignOut /> } />
                                <Route path={ROUTES.PROFILE} element={ <EmpProfile /> } /> 
                                <Route path={ROUTES.SIGN_IN} element={ <EmpProfile /> } /> 
                                <Route path={ROUTES.SIGN_UP} element={ <EmpProfile /> } /> 
                                <Route path={ROUTES.JOBS} element={ <Jobs /> } /> 
                                <Route path={ROUTES.JOBS+':id'} element={<WithParams>{(id) => <Job id={id} />}</WithParams>} />
                                <Route path={ROUTES.MESSAGE} element={ <Message /> } /> 
                                <Route path={ROUTES.TRANSACTIONS} element={ <Transactions /> } />
                                <Route path={ROUTES.FINDFREELANCER} element={ <Freelancer /> } />
                                <Route path={ROUTES.USER+':id'} element={<WithParams>{(id) => <USER id={id} />}</WithParams>} />
                                <Route path={ROUTES.ENDCONTRACT+':id'} element={<WithParams>{(id) => <EndContractFreelancer id={id} />}</WithParams>} />
                                <Route path="*" element={ <Blank /> } /> 
                            </Routes>

                }else if(this.state.preference === 'admin')
                {

                   route = <Routes>
                                <Route path={ROUTES.BLOG+':id'} element={<WithParams>{(id) => <Blog id={id} />}</WithParams>} />
                                <Route path={ROUTES.BLOGS} element={ <Blogs /> } />
                                <Route path={ROUTES.LANDING} element={ <Landing /> } />
                                <Route path={ROUTES.SIGN_OUT} element={ <SignOut /> } />
                                <Route path={ROUTES.USER+':id'} element={<WithParams>{(id) => <USER id={id} />}</WithParams>} />
                                <Route path={ROUTES.SIGN_IN} element={ <UsersList /> } />  
                                <Route path={ ROUTES.USERS_LIST} element={ <UsersList /> } />
                                <Route path={ ROUTES.JOBS_LIST} element={ <AllJobList /> } />
                                <Route path={ ROUTES.ADMIN_SETTINGS} element={ <Admin /> } />
                                <Route path={ROUTES.PAYOUT_LIST} element={ <PayoutList /> } />
                                <Route path={ROUTES.BLOG_ADMIN} element={ <BlogAdmin /> } />
                                <Route path="*" element={ <Blank /> } /> 
                            </Routes>

                }
                

            }else if( this.props.isLoggedIn === false )
            {
               
                route = <Routes>
                            <Route path={ROUTES.BLOG+':id'} element={<WithParams>{(id) => <Blog id={id} />}</WithParams>} />
                            <Route path={ROUTES.BLOGS} element={ <Blogs /> } />
                            <Route path={ROUTES.FINDWORK} element={ <Work /> } /> 
                            <Route path={ROUTES.LANDING} element={ <Landing /> } />
                            <Route path={ROUTES.SIGN_UP} element={ <SignUp />} />
                            <Route path={ROUTES.SIGN_IN} element={ <SignIn /> } />
                            <Route path={ROUTES.USER+':id'} element={<WithParams>{(id) => <UserPublic id={id} />}</WithParams>} />
                            <Route path={ROUTES.JOBS+':id'} element={<WithParams>{(id) => <JobPublic id={id} />}</WithParams>} />
                            <Route path="/allpermalinks" element={ <Blank /> } /> 
                            <Route path="*" element={ <Blank /> } /> 
                        </Routes>
            }

            return (
           
                <div>
                   <Helmet>
                      <title>Freelancertogo - Connect with Freelancers and Find Online jobs on the GO</title>
                      <meta name="description" content="Freelancertogo is a new online work market place for freelancers. We help freelancers to get online job posted by clients all over the world." />
                      <meta name="robots" content="index,follow" />
                      <meta property="og:title" content="Freelancertogo" />
                      <meta property="og:description" content="Discover the perfect freelancer for your projects. Your vision, their expertise – a winning combination." />
                      <meta property="og:image" content="https://freelancertogo.com/cropped.png" />
                      <meta property="og:url" content="https://freelancertogo.com" />
                  </Helmet>
                  <BrowserRouter>
                      <Navigation />
                       {route}

                       <br/><br/><br/><br/>
                  </BrowserRouter>


                
                  <div className="footer">
                      <Grid>
                          <Grid container >
                              <Grid item md={3}>
                                  <span style={styles.brand}>Freelancertogo</span> <br/><br/>
                                  &copy; 2024 Freelancertogo
                              </Grid>
                              <Grid item md={3}>

                                  <ul>
                                      <li>
                                          <b>About</b>
                                      </li>
                                      <li>
                                          <b><a target="_blank"  href='https://knowledgebase.freelancertogo.com'>How it Works</a></b>
                                      </li>
                                      <li>
                                          <b><a target="_blank"  href='https://freelancertogo.com/blogs'>Blogs</a></b>
                                      </li>
                                  </ul>
                                  
                              </Grid>
                              <Grid item md={3}>

                                  <ul>
                                      <li>
                                          <b>Social</b>
                                      </li>
                                      <li>
                                          <b><a rel="noopener noreferrer nofollow" target='_blank' href='https://www.facebook.com/freelancertogo'>Facebook</a></b>
                                      </li>
                                      <li>
                                          <b><a rel="noopener noreferrer nofollow" target='_blank' href='https://medium.com/@freelancertogo'>Medium</a></b>
                                      </li>
                                      <li>
                                           <b><a rel="noopener noreferrer nofollow" href="https://www.buymeacoffee.com/freelancertogo" target="_blank">Buy Me a Coffee</a></b>
                                     
                                      </li>
                                  </ul>

                              </Grid>
                              <Grid item md={3}>
                                  <ul>
                                      <li>
                                          <b>More</b>
                                      </li>
                                     
                                      <li>
                                          <b>
                                          <a rel="noopener noreferrer nofollow" href="https://knowledgebase.freelancertogo.com/freelancertogo-knowledgebase/get-started/privacy-policy" target="_blank">Privacy Policy</a>
                                          </b>
                                      </li>
                                      <li>
                                          <b><a rel="noopener noreferrer nofollow" href="https://knowledgebase.freelancertogo.com/freelancertogo-knowledgebase/get-started/terms-of-service" target="_blank">Terms of Service</a></b>
                                      </li>
                                      <li>
                                           <b><a rel="noopener noreferrer nofollow" href="https://knowledgebase.freelancertogo.com/freelancertogo-knowledgebase/get-started/cookie-policy" target="_blank">Cookie Policy</a></b>
                                     
                                      </li>
                                      
                                  </ul>
                              </Grid>
                          </Grid>
                      </Grid>
                  </div>  
                      
                </div>

            )
        }else
        {
          return (
          <div>
            <Helmet>
                  <title>Freelancertogo - Your Source for Freelancers and Online Jobs</title>
                  <meta name="description" content="Discover endless opportunities on Freelancertogo, the global marketplace for freelancers. Get hired for online jobs from clients worldwide." />
                  <meta name="robots" content="index,follow" />
                  <meta itemprop="name" content="Freelancertogo" />
                  <meta itemprop="description" content="Discover endless opportunities on Freelancertogo, the global marketplace for freelancers. Get hired for online jobs from clients worldwide." />
                  <meta itemprop="image" content="https://freelancertogo.com/cropped.png" />
                  <meta property="og:url" content="https://freelancertogo.com/signup" />
                  <meta property="og:type" content="website" />
                  <meta property="og:title" content="Freelancertogo" />
                  <meta property="og:description" content="Discover endless opportunities on Freelancertogo, the global marketplace for freelancers. Get hired for online jobs from clients worldwide." />
                  <meta property="og:image" content="https://freelancertogo.com/cropped.png" />
                  <meta name="twitter:card" content="summary_large_image" />
                  <meta name="twitter:title" content="Freelancertogo" />
                  <meta name="twitter:description" content="Discover endless opportunities on Freelancertogo, the global marketplace for freelancers. Get hired for online jobs from clients worldwide." />
                  <meta name="twitter:image" content="https://freelancertogo.com/cropped.png" />
              </Helmet>
          </div>)
        }

    }
}

const mapStateToProps = (state) => { 
    return {
        doc:state.doc,
        auth: state.auth,
        onAuthStateChanged: state.onAuthStateChanged,
        isLoggedIn: state.isLoggedIn,
        user: state.user,
        db: state.db,
        collection: state.collection,
        getDocs:state.getDocs,
        getDoc:state.getDoc,
        messaging: state.messaging,
        maintenance: state.maintenance,
        funding: state.funding,
        templateUsed: state.templateUsed,
        auth: state.auth,
        firestore: state.firestore,
        query:state.query,
        where:state.where,
        signOut: state.signOut
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMessageCount: (newMessageCount) => dispatch({type: "SET_MESSAGE_COUNT", payload: { newMessageCount: newMessageCount }}),
        setFunding: (funding) => dispatch({type: "SET_FUNDING", payload: { funding: funding }}),
        setMaintenance: (maintenance) => dispatch({type: "SET_MAINTENANCE", payload: { maintenance: maintenance }}),
        setAuth: (user, isLoggedIn) => dispatch({type: "SET_AUTH", payload: { user: user, isLoggedIn: isLoggedIn }}),
        setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
        setNotifications: (notifications) => dispatch({type: "SET_NOTIFICATIONS", payload: { notifications: notifications }}),
        logout: (firebase) => dispatch({type: "SET_LOGOUT", payload: {firebase:firebase} })
    };
}
export default connect(mapStateToProps,mapDispatchToProps)(ProtectedRoute);  

