import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import { connect } from 'react-redux'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Snackbar from '@mui/material/Snackbar';
import FormGroup from '@mui/material/FormGroup';
//import { withStyles } from '@mui/material/styles';
//import AsyncStorage from '@callstack/async-storage';
import _ from 'lodash'
import Alert from '@mui/lab/Alert';
//import SwipeableViews from 'react-swipeable-views';
import Box from '@mui/material/Box';
import * as FIREBASECONFIG from '../../constants/firebase';
import * as MESSAGES from '../../constants/messages';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';


const styles = {
   small: {
   	color: '#8e8e8e',
   	fontFamily: 'Gotham Rounded'
   },

   em: {
   	 color: '#8e8e8e',
   	 fontFamily: 'Gotham Rounded'
   },

   buttonPrimary: {
   	padding: 18,
   	fontFamily: 'Gotham Rounded',
   	backgroundColor: '#00b16a'
   }
}

var intervalVar


/*function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other} >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
*/




class SignIn extends Component{
	
	constructor(props){
		super(props);

		this.state = {
			email: '',
			password: '',
			loginErrorMessage: '',
			notVerified: false,
			snackbar: false,
			snackbarMessage: '',
			resetPassword: false,
			resetpasswordemail: '',
			rememberme: false,
			newPassword:'',
			resetPasswordError: '',
			isResetPasswordSuccessful: false,
			isEmailVerify: false,
			isEnterNewPassword:false,
			isLogin: true,
			login_next:0,
			loginConfirmationNumber:0,
			loginConfirmationNumber: 0,
			confirmationMatch: true,
			c1: "",
			c2: "",
			c3: "",
			c4: "",
			c5: "",
			confirmationMatchMessage: '',
			hasGenerateLoginCode: false,
			resendTimer: 0
		};
	
	}	





	componentDidMount() {

		let that = this

		const keysToRetrieve = ["email", "password", "rememberme"];
		const retrievedValues = this.multiGet(keysToRetrieve);

		if(_.has(retrievedValues,"email"))
			that.setState({email: retrievedValues.email});
		if(_.has(retrievedValues,"password"))
			that.setState({email: retrievedValues.email});
		if(_.has(retrievedValues,"rememberme"))
			that.setState({email: retrievedValues.email});

	    this.intervalResend()       
        this.handleVerifyEmail()
       
	}

	multiGet(keys) {
		  const values = {};

		  keys.forEach((key) => {
		    const value = localStorage.getItem(key);
		    if (value !== null) {
		      values[key] = value;
		    }
		  });

		  return values;
	}

	intervalResend()
	{
		let that = this
		this.setState({resendTimer:179})

		intervalVar = setInterval(function () {

			let timeLeft =  that.state.resendTimer - 1

			if(timeLeft > 0){
				that.setState({resendTimer: timeLeft })
			}else{
				clearInterval(intervalVar)
				that.setState({resendTimer: timeLeft })
			}

		}, 1000);

	}

	handleVerifyEmail(){

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const mode = urlParams.get('mode')
		const oobCode = urlParams.get('oobCode')
		let that = this

		if(mode==="verifyEmail")
		{	

			that.props.applyActionCode(that.props.auth, oobCode).then((resp) => {
		   		that.setState({isEmailVerify:true})
			}).catch((error) => {
				console.log("error",error);
			});

		}else if(mode==="resetPassword")
		{
			this.setState({isEnterNewPassword: true, isLogin:false})
		}
		
	}

	sendEmailVerification (){
		
		let user_ = this.props.firebase.auth().currentUser;
		let that = this
		
		user_.sendEmailVerification().then(function() {
		 
		  that.setState({snackbar: true, snackbarMessage: 'A verification link is sent to your email.'})
		  that.props.setLoading(false)

		  setTimeout(function(){ window.location = 'signin' }, 1000);
		  
		}).catch(function(error) {
		  
		  that.props.setLoading(false);
		  that.setState({loginErrorMessage: error.message});
		  
		});
	}
	hideSnackbar(){
		this.setState({snackbar:false})
	}

	resetNewPassword(){

		this.props.setLoading(true)
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const oobCode = urlParams.get('oobCode')
		let that = this

		that.props.verifyPasswordResetCode(that.props.auth, oobCode).then((email) => {
		   
	        that.props.confirmPasswordReset(that.props.auth, oobCode, that.state.newPassword).then((resp) => {
	        	that.setState({isResetPasswordSuccessful:true,isEnterNewPassword:false, resetPassword:false, isEnterNewPassword:false})
	        	this.props.setLoading(false)
		    }).catch((error) => {
		    	that.setState({resetPasswordError: error.message})
		    	this.props.setLoading(false)
		    });

	  }).catch((error) => {
	    console.log("resetNewPassword error",error);
	    this.props.setLoading(false)
	  });
	}
	resetPassword()
	{

		this.props.setLoading(true)
		let that = this

		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	
		if ( !re.test(this.state.resetpasswordemail) ){
			this.setState({snackbarMessage:"Please enter correct email",snackbar:true})
			this.props.setLoading(false)
		}else{
			this.setState({resetPasswordError:false})

			that.props.sendPasswordResetEmail(that.props.auth, that.state.resetpasswordemail).then(() => {
		      
		      	that.props.setLoading(false)
		        that.setState({isLogin: true, isEnterNewPassword: false, resetPassword: false, isResetPasswordSuccessful: false, snackbar: true, snackbarMessage: 'Please check your email. If you didnt receive it in your inbox, you can also check in your spam folder.'})

		    }).catch((error) => {
		      	that.props.setLoading(false);
				that.setState({loginErrorMessage: error.message});
				console.log('error: ',error)
		    });
		}
	}

	rememberMe = () => {
		let that = this
		
		this.setState({rememberme: this.state.rememberme === true ? false : true}, ()=>{
		})
		
			

	}


	verifyConfirmation()
	{

		let that = this
  		this.props.setLoading(true)
  	
  		let inputConfirmation = this.state.c1+""+this.state.c2+""+this.state.c3+""+this.state.c4+""+this.state.c5
 
	  	if( Number(inputConfirmation) === this.state.loginConfirmationNumber )
	  	{
	  		clearInterval(intervalVar)
	  		this.props.setLoading(true);
			
			that.props.signInWithEmailAndPassword(that.props.auth, this.state.email, this.state.password)
			  .then((userCredential) => {
			    const user = userCredential.user;
			
			    if(user.emailVerified===false)
				{
					that.props.setLoading(false);
					that.setState({notVerified: true,login_next:0});
				}else{

					that.props.setLoading(false);
					that.props.setAuth(user, that)
			
				}
			    // ...
			  })
			  .catch((error) => {
			  
			    that.props.setLoading(false);
				that.setState({loginErrorMessage: error.message});
				console.log('error: ',error);
				that.setState({confirmationMatch:false, confirmationMatchMessage:error.message})
			  });

	  	}else
	  	{
	  		that.props.setLoading(false);
	  		this.setState({confirmationMatch: false, confirmationMatchMessage: "Confirmation code is incorrect."})
	  	}
	 }

	async generateConfirmationCode(){

  		let that = this
  		var min = 10000;
		var max = 99999;
		var num = Math.floor(Math.random() * (max - min + 1)) + min;
		
		
		if(this.state.hasGenerateLoginCode===false)
		{
		
			that.props.setLoading(true)

			let message = MESSAGES.LOGIN_VERIFICATION
			message = message.replace("{code}", num )

			fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
			{
			    headers: {
			      'Accept': 'application/json',
			      'Content-Type': 'application/json'
			    },
			    method: "POST",
			    body: JSON.stringify({ title: "Login Confirmation Code", name: that.state.email, to: that.state.email, message: message })
			})
			.then(function(res){ return res.json() })
			.then(function(data){

				that.props.setLoading(false)
				that.setState({ hasGenerateLoginCode:true, loginConfirmationNumber: num, login_next: 1})
			})
			.catch(function(res){ 
				console.log('error message',res)
				that.props.setLoading(false)
				that.setState({ snackbar:true, snackbarMessage: "Failed to send verification code. Please try again in few seconds."})
			})

		}else{
			this.setState({ login_next: 1})
		}
		

    }

	handleSubmit (){
		let that = this
		this.setState({hasSubmitted:true});
		this.generateConfirmationCode();
	}

	
	render(){

		if (typeof window !== 'undefined')
		{
			let that = this
			return (

				<div>

					<Snackbar
	                      anchorOrigin={{
	                        vertical: 'top',
	                        horizontal: 'center',
	                      }}
	                    open={that.state.snackbar}
	                    autoHideDuration={5000}
	                    onClose={() => this.hideSnackbar()}
	                    ContentProps={{
	                        'aria-describedby': 'message-id',
	                    }}
	                    message={<span id="message-id">{ this.state.snackbarMessage }</span>}
	                      action={[
	                        <IconButton
	                          key="close"
	                          aria-label="Close"
	                          color="inherit"
	                          onClick={ () => this.hideSnackbar()}
	                        >
	                          <CloseIcon />
	                        </IconButton>,
	                      ]} />  

					<div className="page-container">
			        <header>
			          <div> 
			          	<Grid container spacing={2}  >
			          		<Grid item  xs={0} md={4}></Grid >
			          		<Grid item  xs={12} md={4}>

			          			<h1 className="App-title">
			          				
						          	{ this.state.isLogin === true && 'Sign In' }
						          	{ this.state.isEnterNewPassword === true && 'Reset Password' }
						          	{ this.state.resetPassword === true && 'Reset Password' }
						          	{ this.state.isResetPasswordSuccessful === true && 'Reset Password' }
						          	
						        </h1>
						        { this.state.isEmailVerify === true &&
						        	<Alert severity="success">Your email is now verified. You can now sign in with your new account.</Alert>
	          		 			}
	          		 			<br/>

	          		 			{ this.state.login_next == 0 &&
		          		 			<div  className="App-panel">
				          				{ this.state.isLogin===true &&
				          				<div onKeyDown={(event)=>{ event.keyCode ===13 && this.handleSubmit()  }} >
							          		<Grid container spacing={2}>
							          			<Grid item xs={12} md={12}>
							          				<Typography style={styles.small}>Email</Typography>
							          				<TextField
							          					error={ this.state.hasSubmitted === true && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email) ? true : false }
												      	name='email'
												      	variant="outlined"
												      	margin="normal" value={this.state.email} fullWidth={true} onChange={ (event) => this.setState({email: event.target.value }) } />
							          			</Grid>
							          		</Grid>
							          		<Grid container spacing={2}>
							          			<Grid item xs={12} md={12}>
							          					<Typography style={styles.small}>Password</Typography>
							          					<TextField
							          					error={ this.state.hasSubmitted === true && this.state.password === '' ? true : false }
												      	name='password'
												      	variant="outlined"
												      	margin="normal" type='password' value={this.state.password} fullWidth={true} onChange={ (event) => this.setState({password: event.target.value }) } />
							          			</Grid>
							          		</Grid>
						          			<br/>
							          		{ this.state.loginErrorMessage!=='' && <Typography style={styles.em}>{ this.state.loginErrorMessage }</Typography> }
							          		<br/>
							          		{ this.state.notVerified===true && <Typography style={styles.em}>Your email is not verified. Click the <Link href="#" onClick={(e)=>{ 
							          			e.preventDefault();
							          			that.props.setLoading(true)
							          			this.sendEmailVerification();
							          		} }> link</Link> so we can send new email verification.</Typography> }
							          		
							          		{/*
							         		<FormGroup row>
							         			
							         			<FormControlLabel
											        control={
											          <GreenCheckbox  checked={this.state.rememberme} onChange={ ()=> this.rememberMe() } />
											        }
											        label="Remember Me"
											      />

										    </FormGroup>

							         		<br/>
							         		 */}

							         		<center><p><Link href="#" onClick={()=>{
							         			this.setState({resetPassword:true, isLogin:false})
							         		}}>Forgot Password?</Link></p></center>
							         		
							          		<Grid container spacing={2}>
							          			<Grid item xs={12} md={12}>
							          				<Button disabled={ this.props.isLoading === true ? true :false } onClick={ ()=> this.handleSubmit() } style={styles.buttonPrimary} type='submit' variant='contained' color='primary' fullWidth={true}> { this.props.isLoading === true ? <CircularProgress style={{color:"#fff"}} size={28} /> : 'Sign In' } </Button>
							          			</Grid>
							          		</Grid>
							          		<br/>
							          	</div>
						          		}

						          		{
		 									this.state.resetPassword===true &&
		 									<div>
			 									<Grid container spacing={2}>
								          			<Grid item xs={12} md={12}>
								          				<IconButton onClick={ () => this.setState({resetPassword: false, isLogin: true}) }><KeyboardArrowLeft /></IconButton>
								          				<br/><br/>
								          				<Typography style={styles.small}>Please Enter your Email</Typography>
								          				<TextField
								          					error={ this.state.hasSubmitted === true && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.resetpasswordemail) ? true : false }
													      	name='email'
													      	variant="outlined"
													      	margin="normal" value={this.state.resetpasswordemail} fullWidth={true} onChange={ (event) => this.setState({resetpasswordemail: event.target.value }) } />
								          			</Grid>
							          			</Grid>
							          			{ this.state.loginErrorMessage!=='' && <Typography style={[{marginBottom: 20, textAlign:'center'}, styles.em]}>{ this.state.loginErrorMessage }</Typography> }
							          			<br/>
							          			<Grid container spacing={2}>
								          			<Grid item xs={12} md={12}>
								          				<Button disabled={this.props.isLoading===true?true:false} onClick={ ()=> this.resetPassword() } style={styles.buttonPrimary} type='submit' variant='contained' color='primary' fullWidth={true}>
								          					{ this.props.isLoading === true ? <CircularProgress style={{color:"#fff"}} size={28} /> : 'Reset Password' }
								          				</Button>
								          			</Grid>
								          		</Grid>
						          			</div>
						          		}	

						          		{
		 									this.state.isEnterNewPassword===true &&
		 									<div>
			 									<Grid container spacing={2}>
								          			<Grid item xs={12} md={12}>
								          				<IconButton onClick={ () => this.setState({isEnterNewPassword: false, isLogin:true}) }><KeyboardArrowLeft /></IconButton>
								          				<br/><br/>
								          				<Typography style={styles.small}>Please Enter your new Password</Typography>
								          				<TextField
								          					error={ this.state.hasSubmitted === true && !/\s/i.test(this.state.newPassword) ? true : false }
													      	name='email'
													      	variant="outlined"
													      	margin="normal" value={this.state.newPassword} fullWidth={true} onChange={ (event) => this.setState({newPassword: event.target.value }) } />
								          			</Grid>
							          			</Grid>
							          			{ this.state.resetPasswordError!=='' && <Typography>{ this.state.resetPasswordError }</Typography> }
							          			<br/>
							          			<Grid container spacing={2}>
								          			<Grid item xs={12} md={12}>
								          				<Button disabled={this.props.isLoading===true?true:false} onClick={ ()=> this.resetNewPassword() } style={styles.buttonPrimary} type='submit' variant='contained' color='primary' fullWidth={true}>
								          					{ this.props.isLoading === true ? <CircularProgress style={{color:"#fff"}} size={28} /> : 'Reset Password' }
								          			</Button>
								          			</Grid>
								          		</Grid>
						          			</div>
						          		}	

						          		{
						          			this.state.isResetPasswordSuccessful===true &&

							        		<center>
							        			<img alt="verified" src="/verified.png" />
							        			<Typography>Password Reset Successful. </Typography>
							        			<br/>
							        			<Button onClick={ ()=> this.setState( { isLogin: true, isEnterNewPassword: false, resetPassword: false, isResetPasswordSuccessful: false }) } style={styles.buttonPrimary} type='submit' variant='contained' color='primary' fullWidth={true}>Back to Signin</Button>
							        		</center>
							        	
						          		}
				          		
				          			</div>
			          			}

			          			{ this.state.login_next == 1 &&
				          			<div  className="App-panel">
						        	 			<IconButton onClick={ ()=>that.setState({login_next:0, confirmationMatchMessage:"",confirmationMatch:true}) } style={{alignSelf:'center', color:"#00b16a"}} aria-label="delete"  color="primary">
									           		<ArrowBackIcon />
									         	</IconButton>
						        	 			<Box textAlign='center'>
						        	 			<Typography><b>Email Authentication</b><br/>Enter the 5 digit code that was sent to your email to continue.</Typography>
						        	 			<br/>
						        	 			<div style={{flexDirection: 'row'}}>
									      			<br/>
									      			<TextField onKeyPress={(event)=>{ if (!/[0-9.]/.test(event.key)) {
									          event.preventDefault();
									        } }} onChange={(e)=>{ 
									      				this.setState({c1: e.target.value })
									      				
									      				const nextfield = document.querySelector(
												          `input[name=c2]`
												        );

												        if (nextfield !== null) {
												          nextfield.focus();
												          nextfield.select()
												        }
									      				

									      			}
									      			} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ selectTextOnFocus: true, maxLength: 1, min: 0, style: { textAlign: 'center' }}} />
									      			<TextField onKeyPress={(event)=>{ if (!/[0-9.]/.test(event.key)) {
									          event.preventDefault();
									        } }} name="c2" selectTextOnFocus={true} onChange={(e)=>{
									      				this.setState({c2: e.target.value })

									      				const nextfield = document.querySelector(
												          `input[name=c3]`
												        );

												        if (nextfield !== null) {
												          nextfield.focus();
												          nextfield.select()
												        }

									      			}
									      			}
									      				 variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
									      			<TextField onKeyPress={(event)=>{ if (!/[0-9.]/.test(event.key)) {
									          event.preventDefault();
									        } }} name="c3" onChange={(e)=>{
									      				this.setState({c3: e.target.value })

									      				const nextfield = document.querySelector(
												          `input[name=c4]`
												        );

												        if (nextfield !== null) {
												          nextfield.focus();
												          nextfield.select()
												        }
									      			}
									      			} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
									      			<TextField onKeyPress={(event)=>{ if (!/[0-9.]/.test(event.key)) {
									          event.preventDefault();
									        } }} name="c4" onChange={(e)=>{
									      				this.setState({c4: e.target.value })

									      				const nextfield = document.querySelector(
												          `input[name=c5]`
												        );

												        if (nextfield !== null) {
												          nextfield.focus();
												          nextfield.select()
												        }
									      			}
									      			} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
									      			<TextField onKeyPress={(event)=>{ if (!/[0-9.]/.test(event.key)) {
									          event.preventDefault();
									        } }} name="c5" onChange={(e)=>{
									        	this.setState({c5: e.target.value },()=>{
									        		that.verifyConfirmation()
									        	})

									        	}
									        	} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
									      			<br/><br/>
									      		</div>

									      		{ this.state.confirmationMatch === false && 
									      			<div>
										      			<Alert severity="error">{this.state.confirmationMatchMessage}</Alert>
										      			<br/>
										      		</div>
									      		}

									      		
									      		{ this.state.resendTimer > 0 && 
									      			<Typography>If you didn't get the email authentication, you can check spam folder or resend in { Number( this.state.resendTimer / 60 ).toFixed(0) }:{  ( Number( this.state.resendTimer)%60 < 10 ? "0" :"" ) +Number( this.state.resendTimer)%60 } minutes</Typography>
									      		}

									      		{ this.state.resendTimer <= 0 &&
									      			<Button onClick={()=>{
									      				this.setState({hasGenerateLoginCode:false},()=>{
									      					that.intervalResend()
									      					that.generateConfirmationCode()
									      				})
									      			} } variant="text">Resend Code</Button>
									      		}

									      		<br/><br/>
									      		<Button disabled={ this.props.isLoading === true ? true :false } style={styles.buttonPrimary} variant='contained' color='primary' onClick={ ()=>{
									      			that.verifyConfirmation()
									      			} }>
									 				{ this.props.isLoading === true ? <CircularProgress style={{color:"#fff"}} size={28} /> : 'Next' }
											 	</Button>
											 	</Box>
								    </div>
								}
	          		 			

			          		</Grid >
			          		<Grid item  xs={0} md={4}></Grid >
			          	</Grid >
				      </div>
			        </header>
		        	</div>
		        </div>
			)

		}else{
			return (<div></div>)
		}
		
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		isLoggedIn: state.isLoggedIn,
		sendPasswordResetEmail:state.sendPasswordResetEmail,
		auth: state.auth,
		applyActionCode: state.applyActionCode,
		verifyPasswordResetCode: state.verifyPasswordResetCode,
		confirmPasswordReset: state.confirmPasswordReset,
		signInWithEmailAndPassword: state.signInWithEmailAndPassword,
		isLoading: state.isLoading,
		getUserByEmail: state.getUserByEmail
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAuth: (user, state) => dispatch( {type: "SET_AUTH", payload: { user: user, route: state }} ),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}



export default connect(mapStateToProps, mapDispatchToProps)(SignIn);





