import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import CircularProgress from '@mui/material/CircularProgress';
import * as ROUTES from '../../constants/routes';
import Avatar from '@mui/material/Avatar';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Autocomplete from '@mui/lab/Autocomplete';
import _ from 'lodash'
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import PaypalButton from '../../constants/paypalButton';
import Alert from '@mui/lab/Alert';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Tooltip from '@mui/material/Tooltip';
import * as FIREBASECONFIG from '../../constants/firebase';
import * as MESSAGES from '../../constants/messages';
import countries from '../../constants/countries.json'
import industries from '../../constants/industries.json'
import Grid from '@mui/material/Grid';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);

const styless = {
	bigAvatar:{
		margin: 10,
		width: 150,
		height: 150,
		alignSelf:'center',
		cursor: 'pointer'
	},
	title: {
		color: '#333333',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 24
	},
	subtitle: {
		color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 18
	},
	center:{
		textAlign:'center',
		color: '#3c4859'
	},

	small: {
	   	color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10
	   },

	   em: {
	   	 color: '#8e8e8e',
	   	 fontFamily: 'Gotham Rounded'
	   },

	   buttonPrimary: {
	   	padding: 18,
	   	fontFamily: 'Gotham Rounded',
	   	backgroundColor: '#00b16a'
	   },

	   buttonDefault: {
	   	padding: 12,
	   	fontFamily: 'Gotham Rounded',
	   	borderColor: '#00b16a',
	   	color: '#00b16a',
	   }
}


class EmpProfile extends Component{
	
	constructor(props){
		
		super(props);

		this.state = {
			deletemyaccount:'',
			fullWidth: true,
    		maxWidth: 'sm',
    		profile: [],
    		db: [],
    		user: [],
    		snackbar: false,
    		snackbarMessage: '',
    		company: '',
			isSaving: false,
			companyError: false,
			avatar: '',
			isAvatarUpload: false,
			industryError: false,

			fname: '',
			lname: '',
			overview: '',
			address: '',
			phone: '',
			isLoaded:false,
			submenu: 0,
			jpc: 0,
			country: '',
			showPaypal:false,
			selectedPackage: 0,
			creditPackages: [
			{value: 0, amount: 5, credits: 15},
			{value: 1, amount: 10, credits: 35},
			{value: 2, amount: 15, credits: 50}
			],
			identityVerified: false,
			identityVerifiedDialog:false,
			industry:''
		};
	
	}	

	 handleChange = (event, value) => {
	    this.setState({ submenu: value });
	  };

	componentDidMount() {
		this.getProfile()

	}

	async deleteMyAccount()
	{
		
		let that = this
		let uid = this.props.user.uid;
		that.props.setLoading(true);

		if(that.state.deletemyaccount=="delete")
		{
			
			this.props.deleteUser(this.props.auth.currentUser).then( async () =>
			{
				await that.props.deleteDoc( that.props.doc( that.props.db, "users", uid));
				localStorage.clear();
				that.props.setLoading(false);
				window.location = '/';
			}).catch((error) => {
			  console.log("error delete account",error)
			});

		}else{
			that.props.setLoading(false);
			this.setState({snackbar:true, snackbarMessage:"To proceed with the deletion, simply type 'delete' in the text field and press the 'Delete My Account' button."})
		
		}
		
	}

	async getAdminNotifyVerification(profile){

		let that = this;

		let message = MESSAGES.NEW_IDENTITY_VERIFICATION
		message = message.replace("{name}", profile.fname+" "+profile.lname )
		message = message.replace("{email}", profile.email)
		message = message.replace("{date}", new Date())
		
		fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
		{
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    method: "POST",
		    body: JSON.stringify({ title: "Identity Verification Request", name: that.props.adminName , to: that.props.adminEmail, message: message })
		})
		.then(function(res){
			 return res.json() })
		.then(function(data)
		{
		
		})
		.catch(function(res)
		{ 
			
		})

	}
	submitVerification(){

		this.props.setLoading(true);
		let id = this.pond3.getFiles();
		let selfie = this.pond4.getFiles();
		let uploads = [];
		let verificationimages = []
		let that = this
		 let i = 0;

		id.map(file => { if(file.fileSize<1000000){uploads.push(file.file)} } )
		selfie.map(file => { if(file.fileSize<1000000){uploads.push(file.file)} } )

		if(uploads.length<2)
		{
			this.setState({snackbar:true, snackbarMessage:'Please upload required images.'})
			this.props.setLoading(false);
			return
		}

		let fileRefs = []
		const uploadPromises = [];
		const storageRef = this.props.storageRef(this.props.storage, 'uassets/'+that.props.user.uid+"/");

		uploads.map( async file => {
      
	      const fileRef = that.props.storageRef(storageRef, Math.random().toString(36).substring(7)+file.name);

	      try {
	          const uploadTask = that.props.uploadBytes(fileRef, file.file);
	          fileRefs.push(fileRef)
	          uploadPromises.push(uploadTask);
	        
	        } catch (error) {
	          console.error(`Error uploading "${file.file.name}":`, error);
	        }

	    } )

	     Promise.all(uploadPromises).then( ()=>{
       
		       let files_ = []
		       let fileCount = 0

		       const userRef = this.props.doc(this.props.db, "users", this.props.user.uid);

		       fileRefs.map(async fr=>{
		         const downloadURL = await that.props.getDownloadURL(fr);
		         files_.push(downloadURL);

		         fileCount++;

		         if(fileCount==fileRefs.length)
		         {
		             

		             if(fileCount==1)
		             {
		             	 that.props.updateDoc(userRef, {
					        verification_1: downloadURL
					     });

		             }else if(fileCount==2)
		             {
		             	that.props.updateDoc(userRef, {
					        verification_1: downloadURL
					     });
		             }

		             that.props.setLoading(false);
					 that.setState({snackbar:true,snackbarMessage:'Thank you! We received your request to verify your account. We will send an email when the verification is complete.',identityVerifiedDialog:false})
					
					 let profile = that.props.user
					 that.getAdminNotifyVerification(profile)

		         }

		       })
	    } )

		
	}

	async getProfile()
	{
		const that = this
		const state = this
		const userRef = this.props.doc(this.props.db, "users", this.props.user.uid );
		const userSnap = await this.props.getDoc(userRef);
		
		if (userSnap.exists())
		{
		  	var data = userSnap.data();

		  	that.setState(
			{
				fname: data.fname, 
				lname: data.lname,
				isLoaded:true,
				jpc: data.jpc,
				country: data.country,
				identityVerified: _.has(data, 'verification_review') ? data.verification_review : ''
			});

			if(data.profile){

				that.setState(
				{
					about: _.has(data.profile,"about") ? data.profile.about : "",
					company: data.profile.company, 
					overview: data.profile.overview,
					position: data.profile.position,
					address: data.profile.address,
					phone: data.profile.phone,
					avatar: data.profile.avatar,
					industry:  _.has(data.profile,"industry") ? data.profile.industry : ""
					
				});

			}
		}

	}


	async saveProfile(){
		let that = this;
		this.props.setLoading(true)

		const userRef = this.props.doc(this.props.db, "users", this.props.user.uid);
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
			var data = userSnap.data();

			let profile ={
				company: that.state.company,
				overview: that.state.overview,
				address: that.state.address,
				phone: that.state.phone,
				avatar: that.state.avatar,
				about: that.state.about,
				industry: that.state.industry
			}

			data.profile = profile
			data.country = that.state.country

			that.props.setDoc(userRef, data )
			.then( ()=>{

				that.setState({
					isAvatarUpload: false,
					isSaving: false,
					snackbar: true,
					snackbarMessage: "Your Profile was updated"
				})

				that.props.setLoading(false);
			} )
		}
	
	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	onTagsChange = (event, values) => {
		    this.setState({
		      country: values
		    })
    }

    onTagsChange2 = (event, values) => {
		    this.setState({
		      industry: values
		    })
    }
	
	profileBasic(){

		let that = this
		if(this.state.isSaving===true)
			return

		let error = 0;

		if(this.state.fname===''){
			this.setState({fnameError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({fnameError:false})
		}

		if(this.state.lname===''){
			this.setState({lnameError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({lnameError:false})
		}

		if(this.state.company===''){
			this.setState({companyError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({companyError:false})
		}

		if(this.state.overview===''){
			this.setState({overviewError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({overviewError:false})
		}

		if(this.state.about===''){
			this.setState({aboutError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({aboutError:false})
		}

		if(this.state.address===''){
			this.setState({addressError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({addressError:false})
		}

		if(this.state.industry===''){
			this.setState({industryError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({industryError:false})
		}

		

		if(error===0)
		{	

			this.setState({isSaving:true})
		
			if(this.state.isAvatarUpload===false)
			{
				this.saveProfile();
				return 0
			}

			let files = this.pond2.getFiles();
			const storageRef = this.props.storageRef(this.props.storage, 'uassets/'+that.props.user.uid+"/"); 
			let avatarUpload = []

			files.map( async file =>
			{
			
				const fileRef = that.props.storageRef(storageRef, Math.random().toString(36).substring(7)+file.file.name);

				try {
			      await that.props.uploadBytes(fileRef, file.file);
			      const downloadURL = await that.props.getDownloadURL(fileRef);

			      that.setState({ isSaving: false, avatar : downloadURL, saveWarning:false }, ()=>{
			      	that.saveProfile();
			      } )
				  
			    } catch (error) {
			      console.error(`Error uploading "${file.file.name}":`, error);
			    }

		 	} )

		
		}else{

			this.setState({
				isAvatarUpload: false,
				isSaving: false,
				snackbar: true,
				snackbarMessage: "Please provide needed information before saving."
			})

		}
		



	}

	updateJPC=(jpc)=>{

		this.setState({jpc:jpc})
	}

	handlepondInit2(){
	}
	handlepondInit(){
	}

	getCountries(){
		let that = this

	
		that.setState({countries:countries},()=>{

			 fetch('https://extreme-ip-lookup.com/json/')
			   .then( res => res.json())
			   .then(response => {
			  
			    that.setState({country: _.has(response,'country') ? response.country : countries[0].name })
			  })
			  .catch((data, status) => {
			       that.setState({country:  countries[0].name })
			 
			  });
		 
		})
	}

	
	render(){

		return (

			<div>
			
			<header>
	          { this.state.isLoaded === true &&
	          <div style={{marginTop: 50}}>
		          
		          <Grid container spacing={2}>
		          		<Grid item xs={0} md={3}>
		          		</Grid>
		          		<Grid xs={12} md={6}>

		          		 <h1 className="App-title">My Profile</h1>
		          		 
		          		 <br/>

		          		 { this.props.isLoggedIn === true &&
				                    <Tabs TabIndicatorProps={{ style: { backgroundColor: "#00b16a"  }}} value={this.state.submenu} onChange={this.handleChange} variant="scrollable" scrollButtons="on" indicatorColor="primary" textColor="primary">
				                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Profile" />
				                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Credits" />
				                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Settings" />
				                    </Tabs>
				         }
		          		 

				         <div className="App-panel">
				         { this.state.submenu === 0 && 
		          		 	<form onSubmit={ this.saveProfile }>
		          		
				          		<Grid container>
				          			<Grid item xs={12} md={12}>

				          				<Typography style={styless.em}>Your Avatar</Typography>
				        				<br/>
				        				{ this.state.isAvatarUpload === false &&
					        				<div style={{alignItems:'center', justifyContent:'center', display:'flex'}} >
					        				{
							          			this.state.avatar ? <Avatar onClick={()=>this.setState({isAvatarUpload:true})} alt={this.state.title} src={this.state.avatar} style={styless.bigAvatar} /> : <Avatar onClick={()=>this.setState({isAvatarUpload:true})} alt={this.state.title} src={ROUTES.AVATAR} style={styless.bigAvatar} />
							          		}
							          		</div>
						          		}
						          		{ this.state.isAvatarUpload &&
					        				<FilePond 
					        					allowMultiple={false}
					        					maxFiles={1}
					        					maxFileSize="1MB"
					        					acceptedFileTypes={"image/*"}
					        					ref = { ref2 => this.pond2 = ref2 }
					        					oninit={ () => this.handlepondInit2() } >
					        				</FilePond>
				        				}

				        				<br/>

				        				<center>

				        				{ this.state.identityVerified !=="APPROVED" && 
				        					<Tooltip title="Your identity is not verified. Earn this badge when you verify your identity with us.">
						        				<IconButton onClick={()=>{ this.setState({identityVerifiedDialog:true}) } } color="primary">
										         <VerifiedUserIcon fontSize="medium" style={{color:'#333333'}} />
										        </IconButton>
										     </Tooltip>
								    	}

								    	{ this.state.identityVerified ==="APPROVED" && 
								    		<Tooltip title="Identity Verified">
						        				<IconButton  size="large" color="primary" style={{cursor:'initial'}}>
										          <VerifiedUserIcon fontSize="medium" style={{color:'#00b16a'}} />
										        </IconButton>
									         </Tooltip>
								    	}

								    	</center>

				          				
				          			</Grid>
				          		</Grid>
				          		<Grid container>
				          			<Grid item xs={12} md={12}>
				          					<Typography style={styless.small}>First Name</Typography>
				          					<TextField
				          					multiline
				          					row="5"
				          					error={this.state.fnameError}
				          					name='overview'
									      	variant="outlined"
									      	margin="normal" value={this.state.fname} fullWidth={true} onChange={ (event) => this.setState({fname: event.target.value }) } />
				          					<br/><br/>
				          			</Grid>
				          		</Grid>
				          		<Grid container>
				          			<Grid item xs={12} md={12}>
				          					<Typography style={styless.small}>Last Name</Typography>
				          					<TextField
				          					multiline
				          					row="5"
				          					error={this.state.lnameError}
				          					name='overview'
									      	variant="outlined"
									      	margin="normal" value={this.state.lname} fullWidth={true} onChange={ (event) => this.setState({lname: event.target.value }) } />
				          					<br/><br/>
				          			</Grid>
				          		</Grid>
				          		<Grid container>
				          			<Grid item xs={12} md={12}>
				          				<Typography style={styless.small}>Country</Typography>
							          				
				          				  <Autocomplete
										        onChange={this.onTagsChange}
										        options={countries.map((option) => _.has(option,'name') ? option.name : '')}
										        renderInput={(params) => (
										          <TextField style={{width:'100%'}} {...params} label={this.state.country} margin="normal" variant="outlined" />
										        )}
										      />
						          		<br/>
				          			</Grid>
				          		</Grid>
				          		<Grid container>
				          			<Grid item xs={12} md={12}>
				          					<Typography style={styless.small}>Company Name</Typography>
				          					<TextField
				          					multiline
				          					row="5"
				          					error={this.state.companyError}
				          					name='overview'
									      	variant="outlined"
									      	margin="normal" value={this.state.company} fullWidth={true} onChange={ (event) => this.setState({company: event.target.value }) } />
				          					<br/><br/>
				          			</Grid>
				          		</Grid>
				          		<Grid container>
				          			<Grid item xs={12} md={12}>
				          				<Typography style={styless.small}>Position in the Company</Typography>
				          				<TextField
				          					error={this.state.overviewError}
				          					name='title'
									      	variant="outlined"
									      	margin="normal" value={this.state.overview} fullWidth={true} onChange={ (event) => this.setState({overview: event.target.value }) } />
				          				<br/><br/>
				          			</Grid>
				          		</Grid>
				          		<Grid container>
				          			<Grid item xs={12} md={12}>
				          				<Typography style={styless.small}>About the Company</Typography>
				          				<TextField
				          					error={this.state.aboutError}
				          					name='title'
									      	variant="outlined"
									      	multiline
				          					rows="5"
									      	margin="normal" value={this.state.about} fullWidth={true} onChange={ (event) => this.setState({about: event.target.value }) } />
				          				<br/><br/>
				          			</Grid>
				          		</Grid>
				          		<Grid container>
				          			<Grid item xs={12} md={12}>
				          				<Typography style={styless.small}>Industry</Typography>
				          				  <Autocomplete

										        onChange={this.onTagsChange2}
										        options={industries.map((option) => _.has(option,'longname') ? option.longname : '')}
										        renderInput={(params) => (
										          <TextField error={ this.state.industryError } style={{width:'100%'}} {...params} label={ this.state.industry } margin="normal" variant="outlined" />
										        )}
										      />
						          		<br/>
				          			</Grid>
				          		</Grid>
				          		<Grid container>
				          			<Grid item xs={12} md={12}>
				          					<Typography style={styless.small}>Company Address</Typography>
				          					<TextField
				          					error={ this.state.addressError }
				          					name='hourly-rate'
									      	variant="outlined"
									      	
									      	margin="normal" value={this.state.address} fullWidth={true} onChange={ (event) => { 
									      		   this.setState({address: event.target.value })
									      	 } } />
				          					<br/><br/>
				          			</Grid>
				          		</Grid>
				          		
				          		<br/>
				         
				          		<Grid container>
				          			<Grid item xs={12} md={12}>
				          				<Button style={styless.buttonPrimary} onClick={ () => this.profileBasic() } variant='contained' color='primary' fullWidth={true}>
				          					 { this.props.isLoading === true ? <CircularProgress size={28} style={{color:'#fff'}} /> : 'Save' }
				          				</Button>
				          			</Grid>
				          		</Grid>
				          		<br/>
		          		
		          			</form>
		          		 }

		          		 { this.state.submenu === 1 &&
			          		 	<div>
			          		 		<center>
			          		 			<h1>You have { this.state.jpc > 0 ? this.state.jpc : 0 } Credits</h1>
			          		 		</center>
			          		 		<br/>
			          		 		<Button onClick={ () => this.setState({showPaypal:true}) } style={styless.buttonPrimary} variant='contained' color='primary' fullWidth={true}>
				          					 Buy Credits
				          			</Button>
			          		 	</div>

			          	  }

			          	  {
			          	  	this.state.submenu === 2 &&
			          	  	 <div>
			          		 		<center>
			          		 			<h1>Settings</h1>
			          		 		</center>
			          		 		<Typography>
			          		 			<b>Account Deletion</b><br/>
			          		 			Deleting your account will result in the permanent removal of all your data from our database, and this action cannot be undone. To proceed with the deletion, simply type 'delete' in the text field and press the "Delete My Account" button.
			          		 		</Typography>
			          		 		<br/>
			          		 		<div style={{justifyContent:'center', textAlign:'center'}}>
			          		 			 <TextField variant="outlined" margin="normal" value={this.state.deletemyaccount} fullWidth={true} onChange={ (event) => this.setState({deletemyaccount: event.target.value }) } />
					          			 <br/>
			          		 			 <Button style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=>this.deleteMyAccount() } >
								           Delete My Account
								         </Button>
			          		 		</div>
			          		 	</div>
			          	  }
		          		 </div>

		          		</Grid>
		          		<Grid item xs={0} md={3}></Grid>
		          	</Grid>
		      </div>
		  	  }

		  	  { this.state.isLoaded === false &&
                 <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                     <CircularProgress style={{color:'#00b16a', alignSelf:'center'}} />
                 </div>
              }
	        </header>


	        <Dialog
		        open={this.state.identityVerifiedDialog}
		        onClose={()=>{ 
		        	this.setState({identityVerifiedDialog:false})
		        	this.props.setLoading(false)
		        	 } }
		        fullWidth={true}
		        maxWidth="md"
		        scroll="body"
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		      >
		        <DialogContent>
	 				
	 				<h1 style={{textAlign:'center', fontSize: '28px'}}>
	 					Identity Verification
	 				</h1>

	 				<Typography>
	 					We would like to verify your identity to give freelancers a better feeling that they are not communicating with fake accounts. This identity verification process will ask you to upload 
	 					a scanned copy or a taken picture of any of your government issued ID and a selfie of you holding the same ID.

	 					<br/><br/>

	 					For your privacy, you can blur out any sensitive information from your id like id number, contact numbers and address. We will only looking at your full name and your id picture.

	 					<br/><br/>

	 					We will also delete the uploaded id and picture from our system after the verification process.
	 				</Typography>

	 				<br/><br/>
	 				<Grid container>
	 					<Grid item md={6} sm={12}>

	 						<div className="App-panel">
		 						<Typography style={{textAlign:'center',fontSize: '16px'}}><i>Upload scanned or picture of your government issued id.</i></Typography>
		 						
		 						<FilePond 
		        					allowMultiple={false}
		        					maxFiles={1}
		        					maxFileSize="1MB"
		        					acceptedFileTypes={"image/*"}
		        					ref = { ref3 => this.pond3 = ref3 } >
		        				</FilePond>
	        				</div>
	 					</Grid>
	 					<Grid item md={6} sm={12}>

	 						<div className="App-panel">
		 						<Typography style={{textAlign:'center',fontSize: '16px'}}><i>Upload a selfie of you holding the id. Make sure that your id picture and name is clear.</i></Typography>

		 						<FilePond 
		        					allowMultiple={false}
		        					maxFiles={1}
		        					maxFileSize="1MB"
		        					acceptedFileTypes={"image/*"}
		        					ref = { ref4 => this.pond4 = ref4 } >
		        				</FilePond>
	        				</div>
	 					</Grid>
	 				</Grid>
					
	 			</DialogContent>
		        <DialogActions>
		          <Button style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=>this.submitVerification() } >
		            { this.props.isLoading === true ? <CircularProgress style={{color:"#fff"}} size={28} /> : 'Submit' }
		          </Button>
		          <Button style={styless.buttonDefault} variant='outlined' color='primary' onClick={()=>this.setState({identityVerifiedDialog:false}) } >
		           Close
		          </Button>
		         </DialogActions>
		      </Dialog>


	        <Dialog
		        open={this.state.showPaypal}
		        onClose={()=>this.setState({showPaypal:false})}
		        fullWidth={true}
		        maxWidth="sm"
		        scroll="body"
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		      >
		        <DialogTitle id="alert-dialog-title">Buy Credits</DialogTitle>
		        <DialogContent>
	 				
				  <PaypalButton updateJPC = { this.updateJPC } jpc={this.state.jpc}  user={this.props.user} db={this.props.db} doc={this.props.doc} updateDoc={this.props.updateDoc} />

	 			</DialogContent>
		        <DialogActions>
		          <Button style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=>this.setState({showPaypal:false}) } >
		           Close
		          </Button>
		         </DialogActions>
		      </Dialog>
      		

	        
	        <Snackbar
		          anchorOrigin={{
		            vertical: 'bottom',
		            horizontal: 'center',
		          }}
          		open={this.state.snackbar}
          		autoHideDuration={3000}
          		onClose={this.snackbarClose}
          		ContentProps={{
            		'aria-describedby': 'message-id',
          		}}
          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
		          action={[
		            <IconButton
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              onClick={this.snackbarClose}
		            >
		              <CloseIcon />
		            </IconButton>,
		          ]} />
	    </div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		countries: state.countries,
		doc:state.doc,
		db: state.db,
		getDoc: state.getDoc,
		setDoc: state.setDoc,
		storageRef : state.storageRef,
		storage: state.storage,
		uploadBytes: state.uploadBytes,
		getDownloadURL: state.getDownloadURL,
		updateDoc: state.updateDoc,
		isLoading: state.isLoading,
		adminEmail: state.adminEmail,
		adminName: state.adminName,
		updateDoc: state.updateDoc,
		deleteUser: state.deleteUser,
		auth: state.auth,
		deleteDoc: state.deleteDoc
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		setCountries: (countries) => dispatch({type: "SET_COUNTRIES", payload: {countries:countries}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EmpProfile);





