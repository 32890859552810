import React, { Component, useRef } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import ChatIcon from '@mui/icons-material/Chat';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Badge from '@mui/material/Badge';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import Divider from '@mui/material/Divider';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpIcon from '@mui/icons-material/Help';
import CheckIcon from '@mui/icons-material/Check';
import OutlinedInput from '@mui/material/OutlinedInput';
import _ from 'lodash'
import AttachMoneyIcon from '@mui/icons-material/MonetizationOn';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import timezones from '../../constants/timezones.json'
import Autocomplete from '@mui/lab/Autocomplete';
import Box from '@mui/material/Box';
import RoomIcon from '@mui/icons-material/Room';
import HistoryIcon from '@mui/icons-material/History';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ReportIcon from '@mui/icons-material/Report';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { green } from '@mui/material/colors';
//import { withStyles } from '@mui/material/styles';
//import SwipeableViews from 'react-swipeable-views';
import GoogleAdSense from '../GoogleAdSense';
import Grid from '@mui/material/Grid';

registerPlugin(FilePondPluginImagePreview);

const styless = {
	bigAvatar:{
		margin: 10,
		width: 150,
		height: 150,
		alignSelf:'center',
		cursor: 'pointer'
	},
	title: {
		color: '#333333',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 24
	},
	subtitle: {
		color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 18
	},
	center:{
		textAlign:'center',
		color: '#3c4859'
	},

	small: {
	   	color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10
	   },

	   em: {
	   	 color: '#8e8e8e',
	   	 fontFamily: 'Gotham Rounded'
	   },

	   buttonPrimary: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded',
	   	backgroundColor: '#00b16a'
	   },

	   buttonDefault: {
	   	padding: 12,
	   	fontFamily: 'Gotham Rounded',
	   	borderColor: '#00b16a',
	   	color: '#00b16a',
	   },

	    buttonDanger: {
	   	padding: 12,
	   	fontFamily: 'Gotham Rounded',
	   	borderColor: '#d32f2f',
	   	color: '#d32f2f',
	   }

}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


class Job extends Component{
	
	constructor(props){
		
		super(props);

		let c2

		this.state = {
			isLoaded:false,
			confirmationMatch: true,
			releaseConfirmationNumber: 0,
			releaseAmountNote: "",
			releaseFundSuccess: 0,
			last_transaction_id: '',
			releaseFundJob:'',
			releaseFundUser:[],
			releaseAmountErrorMessage:'',
			releaseAmountError:false,
			releaseAmount: 0,
			balanceDisplay:0,
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		snackbar: false,
    		snackbarMessage: '',
    		applyDialog: false,
			job: [],
			proposedRate: 0,
			proposedRateError:false,
			coverLetter: '',
			coverLetterError: false,
			otherLinks: '',
			otherLinksError: false,
			hourlyRate: 0,
			fullTimeRate: 0,
			jobApplication: [],
			allApplications:[],
			preference: '',
			confirmDeleteJobDialog: false,
			declineReason: 'Skills are not suited for the job',
			jobApplicationID: '',
			declineApplicationDialog: false,
			interviewApplicationDialog: false,
			showDeclineReason: false,
			tabActive: "APPLIED",
			interviewMessage: '',
			showApplicationDialog: false,
			offerApplicationDialog: false,
			offerMessage: '',
			offerJobType: '',
			offerDuration: '',
			offerRate: '',
			offerHoursPerWeek: '',
			offerNotes: '',
			interviewDate: new Date(),
			interviewTime: new Date(),
			profileStatus: '',
			showContractDialog:false,
			timezone: timezones[6].text,
			showLimit: 2,
			unlockShowLimitDialog: false,
			showLimitPackage: 50,
			client: [],
			reportDialog: false,
			report: -1,
			reports: [],
			showReportDialog:false,
			funded: 0,
			fundpanel:0,
			c1: 0,
			c2: 0,
			c3: 0,
			c4: 0,
			c5: 0,
			applicants: 0,
			jobExpired:false,
			stats:[],
			id:'',
			reportDetails:''
		};
	
	}	

	 handleChange = (event, value) => {
	    this.setState({ submenu: value });
	  };

	handleGoBack = () => {
		console.log("handleGoBack");
    	window.history.back();
  	};

	componentWillMount() {

		this.getStat()
		this.getProfile()
		this.getJob()
		this.checkApplication()
		this.getFunded()
		
	}


	onTagsChange = (event, values) => {
    this.setState({
      timezone: values
    }, () => {
     
       // console.log(this.state.timezone);
    });
  }

  async getStat()
  {
  		
		let that = this
		const querySnapshot = await this.props.getDocs(this.props.collection(this.props.db, "stats"));
        
        querySnapshot.forEach((doc) => {
            
           let data = doc.data()
           data.id = doc.id

           that.setState({stats:data}) 

        });

  }

  async useMax(){

  	let balance = 0;
	let last_transaction_id = ''
	let that = this

  	const transactionsRef = this.props.collection(this.props.db, "transactions");
  	const q = this.props.query(transactionsRef, this.props.where("job", "==", this.props.id ), this.props.orderBy("created_at",'desc'), this.props.limit(1) );

  	const querySnapshot = await this.props.getDocs(q);

	querySnapshot.forEach((doc) => {
	  
	  	last_transaction_id = doc.id
		let transaction = doc.data();
		balance=Number(transaction.amount + transaction.last_balance)

	});

	this.setState({releaseAmount:balance})

  }

  async verifyConfirmation(){
  	
  	this.props.setLoading(true)
  	let balance = 0;
	let last_transaction_id = ''
	let that = this

  	let inputConfirmation = this.state.c1+""+this.state.c2+""+this.state.c3+""+this.state.c4+""+this.state.c5
 
  	if( Number(inputConfirmation) === this.state.releaseConfirmationNumber )
  	{
  		
  		let transactionsRef = this.props.collection(this.props.db, "transactions");
  		const q = this.props.query(transactionsRef, this.props.where("job", "==", this.props.id ), this.props.orderBy("created_at",'desc'), this.props.limit(1) );

  		const querySnapshot = await this.props.getDocs(q);

		querySnapshot.forEach((doc) => {
		  
		  	last_transaction_id = doc.id
			let transaction = doc.data();
			balance=Number(transaction.amount + transaction.last_balance)

		});

		transactionsRef = await this.props.addDoc(this.props.collection(this.props.db, "transactions"), {
		  user: that.props.user.uid,
		  last_transaction_id: last_transaction_id,
		  last_balance:balance,
		  job: that.state.releaseFundJob,
		  type: 'FUNDING_ADD',
		  amount : Number(that.state.releaseAmount)*-1, 
		  created_at : new Date(), 
		  note: that.state.releaseAmountNote
		}).then(async function(){

				const qq = that.props.query(transactionsRef, that.props.where("freelancer_id", "==", that.state.releaseFundUser.uid ), that.props.orderBy("created_at",'desc'), that.props.limit(1) );
				const qqSnapShot = await that.props.getDocs(qq);

				let f_last_transaction_id =''
				let f_balance = 0

				qqSnapShot.forEach((doc) => {
			  
				  	f_last_transaction_id = doc.id
					let f_trx = doc.data();
					f_balance=Number(f_trx.amount + f_trx.last_balance)

				});


				await that.props.addDoc(that.props.collection(that.props.db, "transactions"), {
				  job: that.state.job, 
				  user: that.props.user.uid,
				  freelancer: that.state.releaseFundUser.user,
				  freelancer_id: that.state.releaseFundUser.uid,
				  job_transfer_from_transaction_id: transactionsRef.id, 
				  last_transaction_id: f_last_transaction_id,
				  last_balance:f_balance,
				  job_transfer_from: that.state.releaseFundJob,
				  type: 'FUNDING_TRANSFER', 
				  amount : Number(that.state.releaseAmount), 
				  created_at : new Date()
				}).then( async function(){

						that.props.setLoading(false);
						that.setState({releaseFundSuccess: 1})

						const qqq = that.props.query(transactionsRef, that.props.where("job", "==", that.props.id ), that.props.orderBy("created_at",'desc'), that.props.limit(1) );
						const qqqSnapShot = await that.props.getDocs(qqq);

						let balance = 0;
						let last_transaction_id =''

						qqqSnapShot.forEach((doc) => {
				  
						  	last_transaction_id = doc.id
							let transaction = doc.data();
							balance=Number(transaction.amount + transaction.last_balance)

						});

						that.props.setLoading(false)
						that.setState({ confirmationMatch: true, fundpanel: 2, balanceDisplay: Number(balance).toLocaleString({undefined, minimumFractionDigits: 2}) }, ()=>{
							
						that.setState({ snackbar:true, snackbarMessage:"Fund release success. User has received the fund and ready to be claimed.", releaseAmount:0,releaseAmountNote:'', funded:balance})

						let message = MESSAGES.RELEASE_FUND_NOTIF
						message = message.replace("{jobPostTitle}", that.state.job.jobPostTitle )
						message = message.replace("{amount}", that.state.releaseAmount )
						message = message.replace("{note}", that.state.releaseAmountNote )
						
						fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
						{
						    headers: {
						      'Accept': 'application/json',
						      'Content-Type': 'application/json'
						    },
						    method: "POST",
						    body: JSON.stringify({ title: "Fund released", name: that.state.jobApplicationID.user.fname+" "+that.state.jobApplicationID.user.lname, to: that.state.jobApplicationID.user.email, message: message })
						})
						.then(function(res){ return res.json() })
						.then(function(data){
							//console.log('message sent successfully',data)

						})
						.catch(function(res){ 
							console.log('error message',res)
						})


				})

			});

		});	    

  	}else
  	{
  		this.setState({confirmationMatch: false})
  	}

  	
  }

  async releaseFund(){

  	let balance = 0;
	let last_transaction_id = ''
	let that = this

  	const transactionsRef = this.props.collection(this.props.db, "transactions");
  	const q = this.props.query(transactionsRef, this.props.where("job", "==", this.props.id), this.props.orderBy('created_at',"desc"), this.props.limit(1) );
  	const querySnapshot = await this.props.getDocs(q);

  	querySnapshot.forEach((doc) => {
		  
	  	last_transaction_id = doc.id
		let transaction = doc.data();
		balance=Number(transaction.amount + transaction.last_balance)

	});
  	
	if(this.state.releaseAmount>balance){
		this.setState({releaseAmountError:true, releaseAmountErrorMessage: "You dont have enough fund"})
	}else if(this.state.releaseAmount==0){
		this.setState({releaseAmountError:true, releaseAmountErrorMessage: "Release amount must be greater than 0"})
	}else{
		this.setState({releaseAmountError:false, releaseAmountErrorMessage: "", fundpanel:1})


		this.generateConfirmationCode()

	}

  }

  async generateConfirmationCode(){

  		let that = this
  		var min = 10000;
		var max = 99999;
		var num = Math.floor(Math.random() * (max - min + 1)) + min;

	
		if(this.state.releaseConfirmationNumber===0){
			this.setState({releaseConfirmationNumber: num})
		}

	
		let message = MESSAGES.SEND_CONFIRMATION_CODE
		message = message.replace("{code}", num )
		message = message.replace("{jobPostTitle}", that.state.job.jobPostTitle )
		message = message.replace("{amount}", that.state.releaseAmount )
		message = message.replace("{freelancer}", that.state.jobApplicationID.user.fname+" "+that.state.jobApplicationID.user.lname )
		
		fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
		{
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    method: "POST",
		    body: JSON.stringify({ title: "Release Fund Verification Code", name: that.state.jobApplicationID.user.fname+" "+that.state.jobApplicationID.user.lname, to: that.props.user.email, message: message })
		})
		.then(function(res){ return res.json() })
		.then(function(data){
		//	console.log('message sent successfully',data)
		})
		.catch(function(res){ 
			console.log('error message',res)
		})

  }
  async showBalance(item)
  {

  	let balance = 0;
	let last_transaction_id =''

  	const transactionsRef = this.props.collection(this.props.db, "transactions");
  	const q = this.props.query(transactionsRef, this.props.where("job", "==", this.props.id), this.props.orderBy('created_at',"desc"), this.props.limit(1) );

  	const querySnapshot = await this.props.getDocs(q);
	querySnapshot.forEach((doc) => {
	  
	  	last_transaction_id = doc.id
		let transaction = doc.data();
		balance=Number(transaction.amount + transaction.last_balance)

	});

	this.setState({ releaseConfirmationNumber: 0,fundpanel: 0, funded: balance,  releaseFundSuccess: 0, jobApplicationID: item, last_transaction_id: last_transaction_id, releaseFundJob: item.jobID, releaseFundUser: item, showReleaseFundDialog:true, last_balance: balance, balanceDisplay: Number(balance).toLocaleString({undefined, minimumFractionDigits: 2}) })

 }

  localStorageMultiRemove()
  {
		let keys  = ['APPLIED','INVITATION','OFFER','DECLINED_OFFER','HIRE','DECLINED']

		for(let i = 0 ; i <keys.length ; i++)
		{
			localStorage.removeItem( 'jobApplications_'+keys[i]+'_'+this.props.id);
		}	
  }

	async getApplications(){

		let that = this
		let applications = []
		let applications_ = []
		let q = []
		this.props.setLoading(true)
		let status = ""

		const jobApplicationsRef = this.props.collection(this.props.db, "jobApplications");
	  	
	  	if(this.state.showLimit>0)
		{

			
			q = this.props.query(jobApplicationsRef, this.props.where("jobID", "==", this.props.id ), this.props.where("status","==",this.state.tabActive), this.props.limit(this.state.showLimit) );
				
			const querySnapshot = await this.props.getDocs(q);

		  	querySnapshot.forEach((doc) => {
			
			    let ja = doc.data()
				ja.id = doc.id
				
				applications_.push(ja)
			});

			this.setState({ allApplications : applications_ } , ()=>{})

			localStorage.setItem('jobApplications_'+this.state.tabActive+'_'+this.props.id, JSON.stringify(applications_) );
			this.props.setLoading(false)

			

		}else{

			
			q = this.props.query(jobApplicationsRef, this.props.where("jobID", "==", this.props.id ), this.props.where("status","==",this.state.tabActive) );
				
			const querySnapshot = await this.props.getDocs(q);

		  	querySnapshot.forEach((doc) => {
			
			    let ja = doc.data()
				ja.id = doc.id
				
				applications_.push(ja)
			});

			this.setState({ allApplications : applications_ } , ()=>{})

			localStorage.setItem('jobApplications_'+this.state.tabActive+'_'+this.props.id, JSON.stringify(applications_) );
			this.props.setLoading(false)

		}
		
		
		
	}

	async checkApplication(){

		const jobApplications = []
		const jobApplicationsRef = this.props.collection(this.props.db, "jobApplications");
		const q = this.props.query(jobApplicationsRef, this.props.where("jobID", "==", this.props.id ), this.props.where("uid", "==", this.props.user.uid )  );

		const querySnapshot = await this.props.getDocs(q);
		querySnapshot.forEach((doc) => {
		  	
		  	const jobApplication = doc.data()
		  	jobApplication.id = doc.id
		  	jobApplications.push(jobApplication)

		});

		this.setState({ jobApplication : jobApplications })
		
	}

	async getFunded(){
	
		let that = this

		const transactionsRef = this.props.collection(this.props.db, "transactions");
		const q = this.props.query(transactionsRef, this.props.where("type", "==", "FUNDING_ADD" ), this.props.where("job", "==", this.props.id ), this.props.orderBy('created_at','desc'), this.props.limit(1)  );

		const querySnapshot = await this.props.getDocs(q);
		querySnapshot.forEach((doc) => {
		  	
		  	const funded = doc.data()
		  	funded.id = doc.id
		  	that.setState({'funded': Number(funded.amount)+Number(funded.last_balance) })

		});
	}

	async getJob(){
		let that = this
		let jobRef = this.props.doc(this.props.db, "jobs",  this.props.id );
		const jobSnap = await this.props.getDoc(jobRef);

		if (jobSnap.exists())
		{
		  
		  	var data = jobSnap.data();
		  	data.id = jobSnap.id

		  	if(data.status=="ACTIVE")
			{

				document.title = "Freelancertogo - " + data.jobPostTitle

				if( _.has(data,'reports') )
					that.setState({reports: data.reports})


				if(data.jobType==="Project Based")
					that.setState({proposedRate: data.budget})
				else if(data.jobType==="Full Time")
					that.setState({proposedRate: that.state.fullTimeRate})
				else if(data.jobType==="Part Time")
					that.setState({proposedRate: that.state.hourlyRate})
			
				that.setState({
					id: that.props.id,
					job: data,
					offerJobType: data.jobType,
					offerDuration: data.estProjectDuration,
					offerRate: data.budget,
					jobType: data.jobType,
					offerHoursPerWeek: data.hoursPerWeek,
					isLoaded: true,
					showLimit: _.has(data,'showLimit') ? data.showLimit : 25,
					applicants: _.has(data,'applicants') ? data.applicants : 0
				}, async ()=>{

					if( that.props.user.preference === "freelancers"){
						localStorage.removeItem('jobApplications_'+that.props.id)
						that.getApplications()
					}


					if(that.state.showLimit===25)
						that.setState({showLimitPackage: 50})

					if(that.state.showLimit===50)
						that.setState({showLimitPackage: 100})

					if(that.state.showLimit===100)
						that.setState({showLimitPackage: 150})

					if(that.state.showLimit===150)
						that.setState({showLimitPackage: -1})

					const userRef = this.props.doc(this.props.db, "users", that.state.job.user );
					const userSnap = await this.props.getDoc(userRef);
					let userdata = userSnap.data();
					
					that.setState({client:userdata})
		

				})


			}else{
				that.setState({jobExpired:true})
			}


	
		}else{
			
			jobRef = this.props.collection(this.props.db, "jobs");
	  		const q = this.props.query(jobRef, this.props.where("permalink", "==", that.props.id) );
	  		const querySnapshot = await this.props.getDocs(q);

	  		querySnapshot.forEach((doc) => {
			  
			  	let data = doc.data()
			  	data.id = doc.id

				document.title = "Freelancertogo - " + data.jobPostTitle

				if(data.jobType==="Project Based")
					that.setState({proposedRate: 0})
				else if(data.jobType==="Full Time")
					that.setState({proposedRate: that.state.fullTimeRate})
				else if(data.jobType==="Part Time")
					that.setState({proposedRate: that.state.hourlyRate})

				that.setState({
					jobTitle: data.jobPostTitle,
					id: that.props.id,
					job: data,
					offerJobType: data.jobType,
					offerDuration: data.estProjectDuration,
					offerRate: data.budget,
					offerHoursPerWeek: data.hoursPerWeek,
					isLoaded: true,
					showLimit: _.has(data,'showLimit') ? data.showLimit : 25
				}, async ()=>{	

				})

			});
		}


	}
	async getProfile(){

		let data = this.props.user

		if(data.profile)
		{
			this.setState({
				hourlyRate: data.profile.hourlyRate,
				fullTimeRate: data.profile.fullTimeRate});

		}

		this.setState({
				preference: data.preference,
				profileStatus: data.status});

	}
	snackbarClose = () => {
		this.setState({snackbar: false})
	}



	async sendProposal(){

		const userRef = this.props.doc(this.props.db, "users", this.props.user.uid );
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
			let userdata = userSnap.data()
			let jpc = _.has(userdata,'jpc') ? userdata.jpc : 0

			if(jpc>0)
				this.setState({applyDialog: true, jpc: jpc})
			else
				this.setState({snackbar: true, snackbarMessage: 'Sorry but you dont have enough credits. You can buy credits to start applying again.'})
		}
	}

	jsUcfirst = (string) =>
	{
	    let str = string.charAt(0).toUpperCase() + string.slice(1);
	    return str
	}

	async offerApplication(){
		let that = this
		this.props.setLoading(true);
		

		const jobApplicationsRef = this.props.doc(this.props.db, "jobApplications", this.state.jobApplicationID.id );
		const jaSnap = await this.props.getDoc(jobApplicationsRef);

		if (jaSnap.exists())
		{
			this.props.updateDoc(jobApplicationsRef, {
		        status: "OFFER",
		        reason: that.state.offerMessage,
		        offer_at: new Date(),
		        offer_job_type: that.state.offerJobType, 
		        offer_duration: that.state.offerDuration,
		        offer_rate: that.state.offerRate,
		        offer_hours_per_week: that.state.offerHoursPerWeek, 
		        offer_notes: that.state.offerNotes
		    }).then( function(){
		        that.props.setLoading(false);
			 	that.setState({ offerApplicationDialog: false, jobApplicationID:'',offerMessage: '', snackbar: true, snackbarMessage: 'Job offer sent to the applicant.'}, ()=>{ }) 
				localStorage.removeItem('jobApplications_'+that.props.id)
				that.localStorageMultiRemove()
				that.getApplications()
		    });
		}
	
		let message = MESSAGES.JOB_APPLICATION_OFFER
		message = message.replace("{jobPostTitle}", that.state.job.jobPostTitle )
		message = message.replace("{jobPostID}", that.props.id )
		message = message.replace("{reason}", that.state.offerNotes )
		
		fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
		{
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    method: "POST",
		    body: JSON.stringify({ title: "Job Offer", name: that.state.jobApplicationID.user.fname+" "+that.state.jobApplicationID.user.lname, to: that.state.jobApplicationID.user.email, message: message })
		})
		.then(function(res){ return res.json() })
		.then(function(data){
			//console.log('message sent successfully',data)
		})
		.catch(function(res){ 
			console.log('error message',res)
		})
	}

	async interviewApplication(){
		let that = this
		this.props.setLoading(true);
		
		const jaRef = this.props.doc(this.props.db, "jobApplications", this.state.jobApplicationID.id );
		const jaSnap = await this.props.getDoc(jaRef);

		this.props.updateDoc(jaRef, {
	        status:"INTERVIEW",
	        interview_reason: that.state.interviewMessage,
	        interview_at: new Date(),
	        interview_date: new Date(that.state.interviewDate),
	        interview_time: new Date(that.state.interviewTime)
	    }).then( function(){
	        that.props.setLoading(false);
		 	that.setState({ interviewApplicationDialog: false, jobApplicationID:'',interviewMessage: '', snackbar: true, snackbarMessage: 'Application set for Interview'}, ()=>{ }) 
	    });

		let message = MESSAGES.JOB_APPLICATION_INTERVIEW
		message = message.replace("{jobPostTitle}", that.state.job.jobPostTitle )
		message = message.replace("{reason}", that.state.interviewMessage )
		message = message.replace("{date}", that.state.interviewDate )
		
		fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
		{
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    method: "POST",
		    body: JSON.stringify({ title: "Job Interview", name: that.state.jobApplicationID.user.fname+" "+that.state.jobApplicationID.user.lname, to: that.state.jobApplicationID.user.email, message: message })
		})
		.then(function(res){ return res.json() })
		.then(function(data){
			//console.log('message sent successfully',data)
		})
		.catch(function(res){ 
			console.log('error message',res)
		})
	}
	
	async declineApplication(){
		let that = this
		this.props.setLoading(true);

		const jobApplicationsRef = this.props.doc(this.props.db, "jobApplications", this.state.jobApplicationID.id );
		const jaSnap = await this.props.getDoc(jobApplicationsRef);

		if (jaSnap.exists())
		{
			this.props.updateDoc(jobApplicationsRef, {
		        status:"DECLINED",
		        declined_reason: that.state.declineReason,
		        declined_at: new Date()
		    }).then( function(){
		        that.props.setLoading(false);
			 	that.setState({ declineApplicationDialog: false, jobApplicationID:[],declineReason: '', snackbar: true, snackbarMessage: 'Application declined'}, ()=>{ }) 
				localStorage.removeItem('jobApplications_'+that.props.id)
				that.localStorageMultiRemove()
				that.getApplications()
		    });
		}
		
		let message = MESSAGES.JOB_APPLICATION_DECLINED
		message = message.replace("{jobPostTitle}", that.state.job.jobPostTitle )
		message = message.replace("{reason}", that.state.declineReason )
		
		fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
		{
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    method: "POST",
		    body: JSON.stringify({ title: "Job Application Declined", name: that.state.jobApplicationID.user.fname+" "+that.state.jobApplicationID.user.lname, to: that.state.jobApplicationID.user.email, message: message })
		})
		.then(function(res){ return res.json() })
		.then(function(data){
			//console.log('message sent successfully',data)
		})
		.catch(function(res){ 
			console.log('error message',res)
		})
	}

	async consumeJPC(){

		const userRef = this.props.doc(this.props.db, "users", this.props.user.uid);
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
			let data = userSnap.data()
			this.props.updateDoc(userRef, {
		        jpc: data.jpc - 1
		    }).then( function(){
		       
		    });
		}
	}

	async notifyClient()
	{
		
		let that = this

		const userRef = this.props.doc(this.props.db, "users", this.state.job.user);
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
			let user = userSnap.data()
			let message = MESSAGES.NEW_JOB_APPLICATION
				message = message.replace("{jobPostTitle}", that.state.job.jobPostTitle )
				message = message.replace("{date}", new Date())
				
				fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
				{
				    headers: {
				      'Accept': 'application/json',
				      'Content-Type': 'application/json'
				    },
				    method: "POST",
				    body: JSON.stringify({ title: "New Job Proposal", name: user.fname+" "+user.lname, to: user.email, message: message })
				})
				.then(function(res){ return res.json() })
				.then(function(data){
					//console.log('notifyClient',data)
				})
				.catch(function(res){ 
					console.log('error',res)
				})
		}

		
	}

	 niceBytes(x){

	  let l = 0, n = parseInt(x, 10) || 0;

	  while(n >= 1024 && ++l){
	      n = n/1024;
	  }
	 
	  return n.toFixed(n < 10 && l > 0 ? 1 : 0);
	}

	submitReportJob(){

		let that = this
		this.props.setLoading(true);

		const jobsRef = this.props.doc(this.props.db, "jobs", this.props.id);
		
		this.props.updateDoc(jobsRef, {
	        reports: this.props.arrayUnion({ created_at: new Date(),  fname: that.props.user.fname, lname: that.props.user.lname,  report: that.state.report, detail: that.state.reportDetails})
	    }).then( function(){
	       that.props.setLoading(false);
		   that.setState({ reportDetails: '',report: -1, reportDialog:false, snackbar: true, snackbarMessage: 'Your report matters. This will help in keeping our platform safe for our freelancers. Our team will investigate and do the necessary action on this job post.'}, ()=>{ }) 
		
	    });
	}

	createNotification = (type,to,job_id) => {

		let message  =''
		let link = ''
		let iconname = ''
		if(type==="NEW_JOB_APPLICATION")
		{
			message = "You received a job application"
			link = "/jobs/"+job_id
			iconname = "work"
		}

		/*
		this.props.firebase.firestore().collection("notifications").doc().set({
						to: to,
						icon: iconname,
						type: type,
						unread: true,
						link: link,
						message: message,
						created_at: new Date()
					})
					.then(function(){
						
					})
					.catch(function(error){
						console.log('error: ',error);
					});
		*/
				

	}

	async submitProposal(){

	
		this.props.setLoading(true);
		let files = this.pond.getFiles();
		let savedFiles = []
		let uploadFiles = []
		let that = this
		let formerror  = 0;

		if(this.state.coverLetter===''){ 
			formerror++
			this.setState({coverLetterError:true, snackbar: true, snackbarMessage: 'Cover Letter is required'}, ()=>{ }) 
			this.props.setLoading(false);
			return
		}

		if(Number(this.state.proposedRate)===0){ 
			formerror++
			this.setState({proposedRateError:true, snackbar: true, snackbarMessage: 'Please make sure you have the correct proposed rate.'}, ()=>{ }) 
			this.props.setLoading(false);
			return
		
		}

		if(formerror===0)
			this.setState({proposedRateError: false, coverLetterError:false })
		else
			return 

		
		files.map(file => uploadFiles.push(file.file) )


		if(files.length>0)
		{

			const storageRef = this.props.storageRef(this.props.storage, 'uassets/'+that.props.user.uid+"/"); 
			let filesize = files[0].fileSize/(1024*1024 )

			if(filesize>3){
				this.setState({snackbar:true, snackbarMessage: 'Your attachment exceeds the allowed filesize.'})
				return
			}


			files.map( async file => {
			
				const fileRef = that.props.storageRef(storageRef, Math.random().toString(36).substring(7)+file.file.name);

				try {
			      await that.props.uploadBytes(fileRef, file.file);
			      const downloadURL = await that.props.getDownloadURL(fileRef);

			      const jobsRef = this.props.doc(this.props.db, "jobs", this.props.id);
				  const jobSnap = await this.props.getDoc(jobsRef);

				  if (jobSnap.exists())
				  {
				  		let jobdata = jobSnap.data();
				  		let applicants = _.has(jobdata,'applicants') ? jobdata.applicants : 0
				  		this.props.updateDoc(jobsRef, {
					        applicants:  Number(applicants) + 1
					    }).then( function(){
					      
					    });

					    const jobApplicationsRef = await that.props.addDoc(this.props.collection(that.props.db, "jobApplications"), {
						    uid: that.props.user.uid,
							user: { fname: that.props.user.fname, lname: that.props.user.lname, email: that.props.user.email, avatar: that.props.user.profile.avatar },
							jobID: that.state.id,
							jobPostTitle: that.state.job.jobPostTitle,
							coverLetter: that.state.coverLetter,
							proposedRate: that.state.proposedRate,
							attachments: savedFiles,
							otherLinks: that.state.otherLinks,
							applied_at: new Date(),
							status: "APPLIED"
						}).then(function(){

							that.consumeJPC()
							that.notifyClient()

						    that.props.setLoading(false);
								that.setState({ applyDialog:false, snackbarMessage:"Your job application was submitted.", snackbar: true})
								that.setState({ coverLetter: '', 
									coverLetterError: false, 
									proposedRateError: false, 
									})

							//that.createNotification("NEW_JOB_APPLICATION",that.state.job.user,that.state.job.id)
						    that.checkApplication()

						}).catch(function(error) {
	
					    that.setState({snackbar:true,snackbarMessage:error})
						});

				  }

			      
			    } catch (error) {
			      console.error(`Error uploading "${file.file.name}":`, error);
			    }

			 } )

		

		}else{

			const jobsRef = this.props.doc(this.props.db, "jobs", this.props.id);
			const jobSnap = await this.props.getDoc(jobsRef);

			if (jobSnap.exists())
			{
		  		this.props.updateDoc(jobsRef, {
			        applicants:  Number(this.state.applicants) + 1
			    }).then( function(){
			      
			    });

			    const jobApplicationsRef = await that.props.addDoc(this.props.collection(that.props.db, "jobApplications"), {
				    uid: that.props.user.uid,
					user: { fname: that.props.user.fname, lname: that.props.user.lname, email: that.props.user.email, avatar: that.props.user.profile.avatar },
					jobID: that.state.id,
					jobPostTitle: that.state.job.jobPostTitle,
					coverLetter: that.state.coverLetter,
					proposedRate: that.state.proposedRate,
					attachments: savedFiles,
					otherLinks: that.state.otherLinks,
					applied_at: new Date(),
					status: "APPLIED"
				}).then(function(){

					that.consumeJPC()
					that.notifyClient()

				    that.props.setLoading(false);
						that.setState({ applyDialog:false, snackbarMessage:"Your job application was submitted.", snackbar: true})
						that.setState({ coverLetter: '', 
							coverLetterError: false, 
							proposedRateError: false, 
							})

					//that.createNotification("NEW_JOB_APPLICATION",that.state.job.user,that.state.job.id)
				    that.checkApplication()

				}).catch(function(error) {

			    that.setState({snackbar:true,snackbarMessage:error})
				});
			}

			
		}
		

		
	
	}

	handlepondInit(){
		
	}

	

	async unlockLimit(){

		this.props.setLoading(true);

		const userRef = this.props.doc(this.props.db, "users", this.props.user.uid);
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
			let userdata = userSnap.data()
			let jpc = _.has(userdata,'jpc') ? userdata.jpc : 0
			let limit = 5
			let that = this

			if(this.state.showLimitPackage===50)
				limit = 5;
			else if(this.state.showLimitPackage===100)
				limit = 10;
			else if(this.state.showLimitPackage===150)
				limit = 15
			else if(this.state.showLimitPackage===-1)
				limit = 25


			if(jpc>limit ){
				
				const jobsRef = this.props.doc(this.props.db, "jobs", this.props.id);
				
				this.props.updateDoc(jobsRef, {
			        showLimit: that.state.showLimitPackage
			    }).then( function(){
			        that.props.setLoading(false);
				 	that.setState({ showLimitPackage: that.state.showLimitPackage, unlockShowLimitDialog: false, snackbar: true, snackbarMessage: 'Applications limit unlock'}, ()=>{ }) 
					that.localStorageMultiRemove()
					that.getJob()
			    });

			    this.props.updateDoc(userRef, {
			        jpc: jpc - limit
			    }).then( function(){
			       
			    });				
			}
			else{
				this.setState({snackbar: true, snackbarMessage: 'Sorry but you dont have enough credits. You can buy credits to unlock proposal limit of this job.'})
				this.props.setLoading(false);
			}
		}

	}

	renderReportItem(i){

		if(i===1)
			return 'Fake Job'
		else if(i===2)
			return 'Client is asking for free work'
		else if(i===3)
			return 'Client is abusive'
		else if(i===4)
			return 'Spam'
		else if(i==='other')
			return 'Other'
	}

	async addContact(item){
	
		this.props.setLoading(true)
		let that = this

		const roomsRef = this.props.collection(this.props.db, "rows");
		const qleft = this.props.query(roomsRef, this.props.where("from", "==", this.props.user.uid), this.props.where("to", "==", item.uid ) );
		const contactleft = await this.props.getDocs(qleft);

		const qright = this.props.query(roomsRef, this.props.where("from", "==", item.uid), this.props.where("to", "==", this.props.user.uid ) );
		const contactright = await this.props.getDocs(qright);

		if(contactleft.size===0 && contactright.size ===0)
		{

			const roomRef = await this.props.addDoc(this.props.collection(this.props.db, "rooms"), {
			  	from: that.props.user.uid,
				to: item.uid,
				history: [],
				created_at: new Date()
			}).then(function(docRef)
			{	
				that.props.setLoading(false)
				//that.props.history.push('/message/'+docRef.id);
				window.location.replace('/message/'+docRef.id)
			})
			
		}else{

		
			that.props.setLoading(false)
		
			if(contactleft.size!==0){
				contactleft.docs.map( doc =>window.location.replace('/message/'+doc.id))
			}
			else if(contactright.size!==0){
				contactright.docs.map( doc =>window.location.replace('/message/'+doc.id) )
				
			}

			
		}
	}

	
	render(){
		

		if (typeof window !== 'undefined')
		{
			let jobApplicationsList  = ''
			let activeApplicationCount = 0
			let that = this

			if( this.state.allApplications.length > 0 )
			{
				jobApplicationsList = this.state.allApplications.map(function(item, i){
					
				
					if(item.status===that.state.tabActive || that.state.tabActive==="ALL" && _.has(item,'user') ){
						activeApplicationCount++
						return (
						<div key={i}>
						<ListItem>
							<ListItemAvatar>
					          <Avatar alt={ that.jsUcfirst(item.user.fname)+" "+that.jsUcfirst(item.user.lname) } src={item.user.avatar} />
					        </ListItemAvatar>
							<ListItemText secondary={ moment( new Date( item.applied_at.seconds * 1000 ) ).fromNow() +" \u2022 "+ item.coverLetter.substring(0,70) + "" + ( item.coverLetter.length > 70 ? "..." : "" ) }>
								<a rel="noopener noreferrer" target="_blank" href={'/freelancer/'+item.uid} style={{fontFamily:"Gotham Rounded"}}>{that.jsUcfirst(item.user.fname) } {that.jsUcfirst(item.user.lname)}</a>
							 		
							</ListItemText>
							<ListItemSecondaryAction>
							 
			                    {
			                      (that.state.funded > 0 && item.status !=="HIRE" && item.status !=="OFFER" && item.status !=="INVITATION") && 
			                      <Tooltip title="Send Job Offer" aria-label="hire">
				                    <IconButton edge="end" aria-label="hire" onClick={ ()=>that.setState({offerApplicationDialog:true, jobApplicationID: item}) }>
				                      <CheckIcon />
				                    </IconButton>
			                      </Tooltip>
			                	}

			                	{
			                      (that.state.funded == 0 && item.status !=="HIRE" && item.status !=="OFFER" && item.status !=="INVITATION") && 
			                      <Tooltip title="Job offer is disabled for non funded projects" aria-label="hire">
				                    <IconButton edge="end" aria-label="hire">
				                      <CheckIcon />
				                    </IconButton>
			                      </Tooltip>
			                	}

			                    { ( item.status !=="DECLINED" && item.status !=="HIRE" && item.status !=="INVITATION" ) &&
			                    <Tooltip title="Decline" aria-label="decline">
				                    <IconButton onClick={ () => that.setState({declineApplicationDialog:true, jobApplicationID: item}) } edge="end" aria-label="decline">
				                      <CloseIcon />
				                    </IconButton>
			                    </Tooltip>
			                	}

			                    <Tooltip title="Message" aria-label="Message">
									<IconButton edge="end" aria-label="chat" onClick = { () => that.addContact(item) } >
				                      <ChatIcon />
				                    </IconButton>
			                    </Tooltip>

			                    { item.status !=="INVITATION" &&
				                    <Tooltip title="View Job Application" aria-label="View Job Application">
										<IconButton edge="end" aria-label="chat" onClick = { () => that.setState({showApplicationDialog:true, jobApplicationID: item}) } >
					                      <VisibilityIcon />
					                    </IconButton>
				                    </Tooltip>
			                	}

			                    {
			                    	item.status === "HIRE" &&
			                    <Tooltip title={ _.has(item,'end_contract_at') ? "Contract Ended" : "View Contract"} aria-label={ _.has(item,'end_contract_at') ? "Contract Ended" : "View Contract"}>
									
									
									<IconButton style={{color:  _.has(item,'end_contract_at') ? 'red' : '' }} edge="end" aria-label="chat" onClick = { () => that.setState({showContractDialog:true, jobApplicationID: item}) } >
					                    <DescriptionIcon />
					                </IconButton>
				                	
			                    </Tooltip>
			                    }

			                    {
			                    	item.status === "HIRE" &&
			                    	<Tooltip title="Release Fund">
			                    		<IconButton edge="end" aria-label="chat" onClick = { ()=> that.showBalance(item) } >
					                      <AttachMoneyIcon />
					                    </IconButton>
			                    	</Tooltip>
			                    }
			                </ListItemSecondaryAction>
			            </ListItem>	
			            <Divider variant="inset" component="li" />
			            </div>
						)
					}else{return null}

				})
			}

			let est = this.state.job.estProjectDuration
			
			if( est === '1' ){
				est = 'Less than a week'
			}else if( est ==='2' ){
				est = 'Less than a month'
			}else if( est ==='3' ){
				est = 'Less than 3 months'
			}else if( est ==='4' ){
				est = 'Less than 6 months'
			}else if( est ==='5' ){
				est = 'Less than a year'
			}else if( est ==='6' ){
				est = 'More than a year'
			}


			let offer_est = this.state.jobApplicationID.offer_duration
			
			if( offer_est === '1' ){
				offer_est = 'Less than a week'
			}else if( offer_est ==='2' ){
				offer_est = 'Less than a month'
			}else if( offer_est ==='3' ){
				offer_est = 'Less than 3 months'
			}else if( offer_est ==='4' ){
				offer_est = 'Less than 6 months'
			}else if( offer_est ==='5' ){
				offer_est = 'Less than a year'
			}else if( offer_est ==='6' ){
				offer_est = 'More than a year'
			}

			let date = _.has( this.state.job,'created_at' )  ?  moment(this.state.job.created_at.toDate()).fromNow() : ''
		
			return (

				<div>
				
			
				<header>
				  { this.state.isLoaded === true && this.state.id !="" &&
			          <div style={{marginTop: 100}}>
				          
				       
			          	<Grid container spacing={2}>
			          		
			     			
			          		<Grid item xs={12} md={12}>

			          		 <Grid container spacing={2}>
			          		 	<Grid item md={1}></Grid>
			          		 	<Grid item md={10}>
			          		 		{/*<Tooltip title="Back">
				          		 	 	<IconButton onClick={this.handleGoBack} style={{alignSelf:'center', color:"#00b16a"}} aria-label="delete">
							           		<ArrowBackIcon />
							         	</IconButton>
							        </Tooltip>*/}
			          		 	</Grid>
			          		 	<Grid item md={1}></Grid>
						      </Grid>

			          		 <h1 className="App-title">{ this.state.job.jobPostTitle }
			          		 	<b>Posted {date}  </b>
			          		 </h1>
			          		 
			          		 <br/>
			          		 
			          		<Grid container spacing={2}>
			          			<Grid item md={1}></Grid>
		          		 		<Grid item md={7}>

		          		 			<h2><br/></h2>
		          		 			
		          		 			<div className="App-panel">
					         				<b>Job Description: </b> <br/><br/> 

					         				<div dangerouslySetInnerHTML={{ __html: this.state.job.jobDescription }} />
										          		 				
					         				<hr/>
					         				<b>Job Category: </b> { this.state.job.jobCategory }
					         				<hr/>
					         				<b>Expertise: </b> { this.state.job.expertise.charAt(0).toUpperCase() + this.state.job.expertise.slice(1) }
					         				<hr/>
					         				<b>Job Type: </b> { this.state.job.jobType }
					         				<hr/>
					         				<b>Estimate Project Duration: </b> {est}
					         				<hr/>
					         				<b>{ this.state.job.jobType ==="Project Based" ? "Budget" : "Rate Per Hour" }: </b> ${this.state.job.budget}
					         				
					         				<hr/>

					         				<b>Funded: </b> ${ this.state.funded } 

										    <Tooltip title={ this.state.funded > 0 ? "Fund available for this project" : "This project is not funded. Apply at your own risk." }>
												  <IconButton>
												    <HelpIcon />
												  </IconButton>
												</Tooltip>
					         				<hr/>
					         				<b>Hours per week: </b> { this.state.job.hoursPerWeek }
					         				{ this.state.job.howToApply!=='' && <hr/> }
					         				{ this.state.job.howToApply!=='' && <b>How to Apply: </b> }
					         				{ this.state.job.howToApply!=='' && <div dangerouslySetInnerHTML={{ __html: this.state.job.howToApply.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} /> }

					         				{ this.state.job.notes!=='' && <hr/> }
					         				{ this.state.job.notes!=='' && <b>Additional Note: </b> }
					         				{ this.state.job.notes!=='' && <div dangerouslySetInnerHTML={{ __html: this.state.job.notes.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} /> }
					         				<hr/>
					         				<b>Number of Applicants: </b> { this.state.applicants }
					         				<hr/>
					         				<Grid container>
							          			<Grid item xs={12} md={12}>
							          				{ this.state.jobApplication.length === 0 && this.state.preference === "jobs" && this.state.profileStatus === "APPROVED" &&
							          					<Button style={styless.buttonPrimary} onClick={ () => this.sendProposal() } variant='contained' fullWidth={true}>
							          						 { this.state.isSaving === true ? <CircularProgress size={28} color={'#ffffff'} /> : 'Send a Proposal' }
							          					</Button>
							          				}

							          				
							          				{ this.state.preference === "jobs" && this.state.profileStatus!=="" && this.state.profileStatus!=="APPROVED" &&
							          					
							          		 			<Alert severity="info">
							          		 				Sorry you cannot submit a proposal at this moment because your profile status is not active. Please complete your profile and submit again for review.
							          		 			</Alert>
							          		 		}

							          				

							          				{ this.state.jobApplication.length > 0 && this.state.preference === "jobs" &&
							          					<Alert severity="success">You already applied to this job</Alert>
							          				}
							          			</Grid>
							          		</Grid> 
					          		 </div>

		          		 		</Grid>
		          		 		<Grid item md={3}>
		          		 			<h2>
								       About this client
								    </h2>

								    <div className="App-panel">
				          				 <RoomIcon /> { _.has( this.state.client,'country' ) ? this.state.client.country : '' } <br/>
				          				 <HistoryIcon /> Member since { _.has( this.state.client,'created_at' )  ?  moment( this.state.client.created_at.toDate() ).format('MMM d, YYYY') : '' } <br/>
				          				{ _.has( this.state.client,'verification_review' ) && this.state.client.verification_review ==="APPROVED" ? <VerifiedUserIcon style={{color:'#00b16a'}} /> : <VerifiedUserIcon /> }  { _.has( this.state.client,'verification_review' ) && this.state.client.verification_review ==="APPROVED" ? 'Identity Verified' : 'Identity Not Verified' } <br/>
				          			</div>

				          			<br/>

				          			{ this.state.reports.length > 0 &&
				          			<div style={{ cursor: 'pointer' }} onClick={ () => {this.setState({ showReportDialog: true }) } }>
					          			<Alert severity="error">
					          				{this.state.reports.length} Report{ this.state.reports.length > 1 ? 's' : '' } on this job
	 									</Alert>    
	 									<br/>  
										</div>
										}

										

							        <Button style={styless.buttonDanger} variant="outlined" color="error" fullWidth onClick={ () => this.setState({reportDialog:true}) }>
						              <ReportIcon />  Report this Job
						            </Button>

						            
						            <br/><br/>
						            { _.has(this.state.stats,'enable_ads') && this.state.stats.enable_ads == true &&
						            <div>
											<GoogleAdSense location="side"></GoogleAdSense>
										</div>
										}
		          		 		</Grid>
		          		 		<Grid item md={1}></Grid>
		          		 	</Grid>
					         
			          		 <br/>

			          		 	
			          		 { this.props.user.preference === "freelancers" &&
			          		 	<Grid container spacing={2}>
			          		 		<Grid item md={1} ></Grid>
					          		 	<Grid item md={10} >
					          		 	<div>
						          		    <h1 className="App-title">Job Applications</h1>
					          		  		<div>
						          		 		
						          		 		<Tabs variant="scrollable" scrollButtons="auto" TabIndicatorProps={{style:{background: '#00B16A'}}} value={this.state.tabActive} onChange={ (event, newValue) => { 
						          		 			this.setState({tabActive:newValue, rowLimit: that.state.rowLimitDefault}, ()=>{
						          		 				this.getApplications()
						          		 			})
						          		 			
						          		 		} }>
										          <Tab style={{color:"#666666"}} value="APPLIED" label="Applied" />
										          <Tab style={{color:"#666666"}} value="INVITATION" label="Invitation" />
										          <Tab style={{color:"#666666"}} value="OFFER" label="Offer" />
										          <Tab style={{color:"#666666"}}length value="DECLINED_OFFER" label="Declined Offer" />
										          <Tab style={{color:"#666666"}} value="HIRE" label="Hires" />
										          <Tab style={{color:"#666666"}} value="DECLINED" label="Declined" />
										        </Tabs>


						          		 		<List>
							          		 		{this.state.allApplications.length > 0 && jobApplicationsList}

							          		 		{activeApplicationCount === 0 && 
							          		 			<center>
							          		 				<br/><br/>
								          		 			<img alt="resume.png" src="/resume.png" />
								          		 			<br/><br/>
								          		 			<Typography>Sorry, no applicant to show on this tab.</Typography>
								          		 			<br/>
								          		 		</center>
							          		 		}
							          		 	</List>
						          		 	</div>

						          		 	<br/>

						          		 	{
						          		 		this.state.showLimit!==-1 &&
						          		 		<Alert severity="info" 
						          		 		action={
											          <Button size="small" onClick={()=>{this.setState({unlockShowLimitDialog:true})}}>
											            UNLOCK
											          </Button>
											        }
						          		 	>

						          		 			We are showing {this.state.showLimit} job proposals to this job. Use credit to unlock all proposals.
						 						</Alert>  
						          		 	}

						          		 	
						          		 	
					          		 	</div>
					          		 	</Grid>
					          		<Grid item md={1} ></Grid>
			          		 	</Grid>


			          		 }

			          		</Grid>
			          		
			          	</Grid>
				         
				      </div>
			  	  }

			  	  {
			  	  	this.state.isLoaded===true && this.state.id==="" &&
			  	  		<div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
			  	  		   <h1 style={{fontSize:'100px',margin:'10px'}}>404</h1>
	                       <Typography>This job is no longer available.</Typography>
	                       <Tooltip title="Back">
		          		 	 	<IconButton onClick={this.handleGoBack} style={{alignSelf:'center', color:"#00b16a"}} aria-label="delete">
					           		<ArrowBackIcon />
					         	</IconButton>
					        </Tooltip>
	                    </div>
			  	  }
			      { this.state.isLoaded === false &&
	                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
	                        <CircularProgress style={{color:'#00b16a', alignSelf:'center'}} />
	                    </div>
	              }

	              {
	              	this.state.jobExpired===true &&              	

	              	    
							<Grid container>	
					          	<Grid item xs={12} md={12}>
					          		<Tooltip title="Back">
					          		 	<IconButton onClick={ ()=>that.props.history.goBack() } style={{alignSelf:'left', color:"#00b16a"}} aria-label="delete">
							           		<ArrowBackIcon /> Back
							         	</IconButton>
							        </Tooltip>

							        <br/><br/>
					          		<Alert severity="error">This job is no longer available.</Alert>
					          	</Grid>
					        </Grid>
	                   
	              }
		        </header>


		          <Dialog
		            maxWidth={'md'}
		            fullWidth={true}
		        	open={this.state.reportDialog}
		        	onClose={ () => this.setState({reportDialog:false}) } >
		        		<DialogTitle><Typography style={styless.title}>Report this Job</Typography> </DialogTitle>
		        		<DialogContent>
		        			<Typography>Please tell us the reason why you are reporting this job?</Typography>

		        			<RadioGroup aria-label="gender" name="report" value={this.state.report} onChange={(event)=>{ that.setState({report:event.target.value}) } }>
						        <FormControlLabel value="1" control={<Radio style={{ color: '#00b16a' }} />} label="Fake Job" />
						        <FormControlLabel value="2" control={<Radio style={{ color: '#00b16a' }} />} label="Client is asking for free work" />
						        <FormControlLabel value="3" control={<Radio style={{ color: '#00b16a' }} />} label="Client is abusive" />
						        <FormControlLabel value="4" control={<Radio style={{ color: '#00b16a' }} />} label="Spam" />
						        <FormControlLabel value="other" control={<Radio style={{ color: '#00b16a' }} />} label="Other" />
						    </RadioGroup>

						    <br/>
						    <Typography>Please describe the issue.</Typography>
						     <TextField
							    	multiline
							    	rows="5"
							   	name='coverLetter'
							      	variant='outlined'
							      	margin="normal" value={this.state.reportDetails} fullWidth={true} onChange={ (event) => this.setState({reportDetails: event.target.value }) } />
							
		        		</DialogContent>
	        			<DialogActions style={{marginTop: 20}}>
	        					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({reportDialog:false}) } autoFocus>
					              Close
					            </Button>
					            <Button style={styless.buttonPrimary} variant='contained'  onClick={ () => this.submitReportJob() }>
					              { this.props.isLoading === true ? <CircularProgress size={28} style={{color:'#ffffff'}} /> : 'Submit' }
					            </Button>
				        </DialogActions>
		        		
		        </Dialog>


		         <Dialog
		            maxWidth={'md'}
		            fullWidth={true}
		        	open={this.state.showReportDialog}
		        	onClose={ () => this.setState({showReportDialog:false}) } >
		        		<DialogTitle><Typography style={styless.title}>Reports on this job</Typography> </DialogTitle>
		        		<DialogContent>
		        			<List>
		        				{
		        					this.state.reports.map(function(item,i){
		        						let name = _.has(item,'fname') ? item.fname.charAt(0)+". "+item.lname : ''
		        						name = Array.from(name);

		        					
		        						let newname = "";
		        						for(var i = 0 ; i <name.length ; i++){
		        							if(i===0 || i===name.length-1)
		        								newname += name[i]
		        							else
		        								newname += '*'
		        						}

		        						return (
		        						<div key={i}>
			        						<ListItem>
			        							<ListItemText primary={"Reported by: "+newname} secondary={that.renderReportItem(item.report)+" - "+item.detail} />
			        						</ListItem>
			        						<Divider />
		        						</div>
		        						)
		        					})
		        				}
		        			</List>
		        		</DialogContent>
	        			<DialogActions style={{marginTop: 20}}>
	        					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({showReportDialog:false}) } autoFocus>
					              Close
					            </Button>
					            
				        </DialogActions>
		        		
		        </Dialog>


		         <Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.unlockShowLimitDialog}
		        	onClose={ () => this.setState({unlockShowLimitDialog:false}) } >
		 			<DialogTitle><Typography style={styless.title}>Unlock Proposals Limit</Typography></DialogTitle>   
		 			<DialogContent>
		 			    <Typography style={styless.em}>Select options below to unlock proposals limit.</Typography>
					    <br/>
					    <Select
				          value={this.state.showLimitPackage}
				          onChange={(event)=>{ this.setState({showLimitPackage: event.target.value})  }}
				          displayEmpty
				          fullWidth={true}
				        
				        >
				          { this.state.showLimit < 50 && this.state.showLimit > 0 &&
				         	<MenuItem value={50}><em>Unlock up to 50 proposals for 5 credits</em></MenuItem>
				          }
				          
				          { this.state.showLimit < 100 && this.state.showLimit > 0 &&
				          	<MenuItem value={100}><em>Unlock up to 100 proposals for 10 credits</em></MenuItem>
				          }

				          { this.state.showLimit < 150 && this.state.showLimit > 0 &&
				          	<MenuItem value={150}><em>Unlock up to 150 proposals for 15 credits</em></MenuItem>
				          }

				          <MenuItem value={-1}><em>Remove limit for 25 credits</em></MenuItem>
				        </Select>

					</DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
	    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({unlockShowLimitDialog:false}) } autoFocus>
				              No
				            </Button>
				            <Button style={styless.buttonPrimary} variant='contained'  onClick={ () => this.unlockLimit() }>
				              { this.props.isLoading === true ? <CircularProgress size={28} style={{color:'#ffffff'}} /> : 'Submit' }
				            </Button>
			        </DialogActions>
		        </Dialog>

		        <Dialog
		            maxWidth={'sm'}
		            fullWidth={true}
		        	open={this.state.applyDialog}
		        	onClose={ () => this.setState({applyDialog:false}) } >
		        		<DialogTitle><Typography style={styless.title}>Submit Proposal</Typography> </DialogTitle>
		        		<DialogContent>

		        			<Typography style={styless.em}>Cover Letter</Typography>
							    <TextField
							    	multiline
							    	rows="5"
							    	error={this.state.coverLetterError}
		          					name='coverLetter'
							      	variant='outlined'
							      	margin="normal" value={this.state.coverLetter} fullWidth={true} onChange={ (event) => this.setState({coverLetter: event.target.value }) } />
							    
							    <br/>
							    <Typography style={styless.em}>Proposed Rate</Typography>
							    { this.state.job.jobType === "Full Time" &&
							    	<small>Your Full Time Rate: ${this.state.fullTimeRate} </small>
								}

								{ this.state.job.jobType === "Part Time" &&
							    	<small>Your Hourly Rate: ${this.state.hourlyRate} </small>
								}

							    <TextField
		          					error={ this.state.proposedRateError }
		          					name='rate'
							      	variant="outlined"
							      	InputProps={{
							          startAdornment: <InputAdornment position="start">$</InputAdornment>
							        }}
							      	margin="normal" value={this.state.proposedRate} fullWidth={true} onChange={ (event) => { 
							      		const re = /^[0-9\b]+$/

							      		if (event.target.value === '' || re.test(event.target.value)) {
									       this.setState({proposedRate: event.target.value })
									    }
							      		
							      	 } } />
							    <br/>
							    <Typography style={styless.em}>Attachment</Typography>
							    <small>You may attach a file (1mb) that will support your proposal. </small>
							    <br/><br/>
							    <FilePond 
		        					allowMultiple={false}
		        					maxFiles={1}
		        					maxFileSize="1MB"
		        					ref = { ref => this.pond = ref }
		        					oninit={ () => this.handlepondInit() } >
		        				</FilePond>
		        				<br/>
							    <Typography style={styless.em}>Other Links</Typography>
							    <small>You can use this field to attach links of online documents, projects or portfolio. </small>
							    <br/>
							    <TextField
							    	multiline
							    	rows="5"
							    	error={this.state.otherLinksError}
		          					name='otherLinks'
							      	variant='outlined'
							      	margin="normal" value={this.state.otherLinks} fullWidth={true} onChange={ (event) => this.setState({otherLinks: event.target.value }) } />
							    
		        			
		        			</DialogContent>
		        			<DialogActions style={{marginTop: 20}}>
		        					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({applyDialog:false}) } autoFocus>
						              Close
						            </Button>
						            <Button disabled={ this.props.isLoading===true?true:false } style={styless.buttonPrimary} variant='contained'  onClick={ () => this.submitProposal(this) }>
						              { this.props.isLoading === true ? <CircularProgress size={28} style={{color:'#ffffff'}} /> : 'Submit' }
						            </Button>
					        </DialogActions>
		        		
		        </Dialog>

		        <Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.interviewApplicationDialog}
		        	onClose={ () => this.setState({interviewApplicationDialog:false}) } >
		 			<DialogTitle><Typography style={styless.title}>Set for Interview</Typography></DialogTitle>   
		 			<DialogContent>
		 				
		 			
		 				Additional Message to Applicant
		 				<br/>
		 				
		 				<TextField
	      					multiline
	      					row="5"
	      					name='overview'
					      	variant="outlined"
					      	margin="normal" value={this.state.interviewMessage} fullWidth={true} onChange={ (event) => this.setState({interviewMessage: event.target.value }) } />
		 				
					    
		 			</DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
	    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({interviewApplicationDialog:false}) } autoFocus>
				              No
				            </Button>
				            <Button style={styless.buttonPrimary} variant='contained'  onClick={ () => this.interviewApplication() }>
				              Submit
				            </Button>
			        </DialogActions>
		        </Dialog>

		        <Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.offerApplicationDialog}
		        	onClose={ () => this.setState({offerApplicationDialog:false}) } >
		 			<DialogTitle><Typography style={styless.title}>Send Job Offer</Typography></DialogTitle>   
		 			<DialogContent>
		 			    <Typography style={styless.em}>Job Type</Typography>
					    <br/>
					    <FormControl variant="outlined" style={{width:'100%'}}>
						    <Select
	          				  	onChange={ (event) => this.setState({offerJobType: event.target.value }) }
								error = { this.state.offerJobTypeError }
								value={this.state.offerJobType}
							    inputProps={{
							    name: 'preference'
							    }} fullWidth={true}
							    input={<OutlinedInput />} >
							     >

							   {/* <MenuItem value="Full Time">
							   						      <em>Full Time</em>
							   						    </MenuItem>
							   						    <MenuItem value="Part Time">
							   						    	<em>Part Time</em>
							   						    </MenuItem>*/}
							    <MenuItem value="Project Based">
							    	<em>Project Based</em>
							    </MenuItem>
						  	</Select>
					  	</FormControl>
					  	<br/><br/>
					  	<Typography style={styless.em}>Estimated Project Duration</Typography>
				    	<br/>
					    <FormControl variant="outlined" style={{width:'100%'}}>
						    <Select
	          				  	onChange={ (event) => this.setState({offerDuration: event.target.value }) }
								value={this.state.offerDuration}
								error={this.state.offerDurationError}
							    inputProps={{
							    name: 'preference',
							    }} fullWidth={true}
							    input={<OutlinedInput />} > >

							    <MenuItem value="1">
							      <em>Less than a week</em>
							    </MenuItem>
							    <MenuItem value="2">
							    	<em>Less than a month</em>
							    </MenuItem>
							    <MenuItem value="3">
							    	<em>Less than 3 months </em>
							    </MenuItem>
							    <MenuItem value="4">
							    	<em>Less than 6 months </em>
							    </MenuItem>
							    <MenuItem value="5">
							    	<em>Less than a year </em>
							    </MenuItem>
							    <MenuItem value="6">
							    	<em>More than a year</em>
							    </MenuItem>


						  </Select>
					  	</FormControl>

					  	<br/>
					  	<br/>

					 	<Typography style={styless.em}> { this.state.jobType==="Project Based" ? 'Budget' : 'Rate Per Hour' }</Typography>
	     				<TextField
	      					error={ this.state.offerRateError }
	      					name='hourly-rate'
					      	variant="outlined"
					      	InputProps={{
					          startAdornment: <InputAdornment position="start">$</InputAdornment>
					        }}
					      	margin="normal" value={this.state.offerRate} fullWidth={true} onChange={ (event) => { 
					      		const re = /^[0-9\b]+$/

					      		if (event.target.value === '' || re.test(event.target.value)) {
							       this.setState({offerRate: event.target.value })
							    }
					      		
					      	 } } />
		 				<br/>
		 				<br/>
		 				{/*<Typography style={styless.em}>Hours per week</Typography>
			     		<TextField
		  					error={ this.state.offerHoursPerWeekError }
		  					name='hourly-rate'
					      	variant="outlined"
					      	
					      	margin="normal" value={this.state.offerHoursPerWeek} fullWidth={true} onChange={ (event) => { 
					      		const re = /^[0-9\b]+$/

					      		if (event.target.value === '' || re.test(event.target.value)) {
							       this.setState({offerHoursPerWeek: event.target.value })
							    }
					      		
					      	 } } />

					   	    
						<br/><br/> */}
					    <Typography style={styless.em}>Additional Note</Typography>
						<TextField
						    	multiline
						    	rows="5"
						    	name='jobDescription'
						      	variant='outlined'
						      	margin="normal" value={this.state.offerNotes} fullWidth={true} onChange={ (event) => this.setState({offerNotes: event.target.value }) } />

						</DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
	    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({offerApplicationDialog:false}) } autoFocus>
				              No
				            </Button>
				            <Button disabled={this.props.isLoading===true?true:false} style={styless.buttonPrimary} variant='contained'  onClick={ () => this.offerApplication() }>
				               { this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : 'Submit' }
				            </Button>
			        </DialogActions>
		        </Dialog>

		        <Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.declineApplicationDialog}
		        	onClose={ () => this.setState({declineApplicationDialog:false}) } >
		 			<DialogTitle><Typography style={styless.title}>Decline Application</Typography></DialogTitle>   
		 			<DialogContent>
		 				You can state your reason for declining this application.
		 				<br/><br/>
		 				<FormControl variant="outlined" style={{width:'100%'}}>
						    <Select
	          					value={this.state.declineReason}
								onChange={(event)=>{ this.setState({declineReason: event.target.value, showDeclineReason: event.target.value==="Other" ? true : false }) }}
							    fullWidth={true}>
							     
							   	<MenuItem value={'Skills are not suited for the job'}>Skills are not suited for the job</MenuItem>
						        <MenuItem value={'Bid exceeds my budget'}>Bid exceeds my budget</MenuItem>
						        <MenuItem value={'Other'}>Other</MenuItem>

						  	</Select>
					  	</FormControl>
					    { this.state.showDeclineReason === true && 
		 				<TextField
	      					multiline
	      					row="5"
	      					name='overview'
					      	variant="outlined"
					      	margin="normal" value={this.state.declineReason} fullWidth={true} onChange={ (event) => this.setState({declineReason: event.target.value }) } />
		 				}
		 			</DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
	    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({declineApplicationDialog:false}) } autoFocus>
				              No
				            </Button>
				            <Button disabled = { this.props.isLoading === true ? true : false } style={styless.buttonPrimary} variant='contained'  onClick={ () => this.declineApplication() }>
				              
				               { this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : 'Decline' }
				            </Button>
			        </DialogActions>
		        </Dialog>

		        <Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.showApplicationDialog}
		        	onClose={ () => this.setState({showApplicationDialog:false}) } >
		 			<DialogTitle><Typography style={styless.title}>{this.state.job.jobPostTitle}</Typography></DialogTitle>   
		 			<DialogContent>
		 				<b>Cover Letter:</b> <br/>
		 				<div dangerouslySetInnerHTML={{ __html: _.has(this.state.jobApplicationID,'coverLetter') ?  this.state.jobApplicationID.coverLetter.replace(/(?:\r\n|\r|\n)/g, '<br/>') : "" }} />
		 				<br/><br/>

		 				<b>Submitted On:</b> <br/>
		 				<Typography>{  _.has(this.state.jobApplicationID,'applied_at') ? moment( new Date( this.state.jobApplicationID.applied_at.seconds * 1000 ) ).fromNow() : "" }</Typography>
		 				<br/><br/>
		 				<b>Proposed Rate:</b> <br/>
		 				<Typography>${Number(this.state.jobApplicationID.proposedRate).toLocaleString()}</Typography>
		 				{  _.has(this.state.jobApplicationID,'otherLinks') &&
			 				<div>
			 					<br/><br/>
				 				<b>Other Links:</b> <br/>
				 				<Typography>{this.state.jobApplicationID.otherLinks}</Typography>
			 				</div>
		 				}
		 				{  _.has(this.state.jobApplicationID,'attachments') && this.state.jobApplicationID.attachments.length > 0 &&
			 				<div>
			 					<br/><br/>
				 				<b>Attachment:</b> <br/>
				 				<Typography><a target="_blank" href={this.state.jobApplicationID.attachments[0]}>Download attachment</a></Typography>
			 				</div>
		 				}
		 			</DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
	    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({showApplicationDialog:false}) } autoFocus>
				              Close
				            </Button>
			        </DialogActions>
		        </Dialog>

		        <Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.showReleaseFundDialog}
		        	onClose={ () => this.setState({showReleaseFundDialog:false}) } >
		 			<DialogTitle><Typography style={styless.title}>Release Fund</Typography></DialogTitle>   
		 			<DialogContent>

		 				{ this.state.fundpanel ===0 &&
		 					<div>
					      		<Typography align='center' variant='h5'>${ Number( this.state.balanceDisplay ).toLocaleString(undefined, {minimumFractionDigits: 2}) }</Typography>
		        				<Typography align='center' variant='subtitle2'>Available Fund</Typography>
		        				<br/>
				        		
				        			<div>
						        		<TextField style={{width:'100%'}} value={this.state.releaseAmount} helperText={ this.state.releaseAmountErrorMessage } error={that.state.releaseAmountError} id="amount" label="Enter amount" variant="outlined" fullWidth onKeyPress={(event)=>{ if (!/[0-9]/.test(event.key)) {
								          event.preventDefault();
								        } }} onChange={ (e)=>
								            {
								              
								              this.setState({releaseAmount:e.target.value } )
								            }
								          }

								          InputProps={{
								          	endAdornment: <InputAdornment position="end" onClick={ ()=> this.useMax()  } style={{cursor:'pointer'}}>Use Max</InputAdornment>
								          }}

								        />
								    </div>
						       
						        <br/><br/>
						        <TextField value={this.state.releaseAmountNote} label="Note" variant="outlined" multiline={true} rows={5} fullWidth onChange={ (e)=>
						            {
						              
						              this.setState({releaseAmountNote:e.target.value } )
						            }
						          } />

				        		<br/><br/>
				        		<Box textAlign='center'>
				        			{ this.state.releaseFundSuccess===0 &&
				        				<div>
					        				<Typography>You are about to transfer ${Number(this.state.releaseAmount).toLocaleString(undefined, {minimumFractionDigits: 2})} to { _.has(this.state.releaseFundUser,'user') ? this.state.releaseFundUser.user.fname : '' } { _.has(this.state.releaseFundUser,'user') ? this.state.releaseFundUser.user.lname : ''  }. Please double check the amount before proceeding. This action cannot be undone. </Typography>
				        					<br/><br/>
			        					</div>
				        			}

				        			

				        			<Button style={styless.buttonPrimary} variant='contained' onClick={ ()=>that.releaseFund()  }>
							 			 Next
									</Button>
				        			
								</Box>
						    </div>
		 				}

		 				{ this.state.fundpanel ===1 &&
		 					 <div>
						      	<Tooltip title="Back">
				          		 	<IconButton onClick={ ()=>this.setState({fundpanel: 0}) } style={{alignSelf:'center', color:"#00b16a"}} aria-label="delete">
						           		<ArrowBackIcon />
						         	</IconButton>
						        </Tooltip>

								<br/><br/>

						      	<Box textAlign='center'>


						      		<Typography>Enter the 5 digit code that was sent to your email.</Typography>
						      		<div style={{flexDirection: 'row'}}>
						      			<br/>
						      			<TextField onChange={(e)=>{ 
						      				this.setState({c1: e.target.value })
						      				
						      				const nextfield = document.querySelector(
									          `input[name=c2]`
									        );

									        if (nextfield !== null) {
									          nextfield.focus();
									          nextfield.select()
									        }
						      				

						      			}
						      			} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ selectTextOnFocus: true, maxLength: 1, min: 0, style: { textAlign: 'center' }}} />
						      			<TextField name="c2" selectTextOnFocus={true} onChange={(e)=>{
						      				this.setState({c2: e.target.value })

						      				const nextfield = document.querySelector(
									          `input[name=c3]`
									        );

									        if (nextfield !== null) {
									          nextfield.focus();
									          nextfield.select()
									        }

						      			}
						      			}
						      				 variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
						      			<TextField name="c3" onChange={(e)=>{
						      				this.setState({c3: e.target.value })

						      				const nextfield = document.querySelector(
									          `input[name=c4]`
									        );

									        if (nextfield !== null) {
									          nextfield.focus();
									          nextfield.select()
									        }
						      			}
						      			} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
						      			<TextField name="c4" onChange={(e)=>{
						      				this.setState({c4: e.target.value })

						      				const nextfield = document.querySelector(
									          `input[name=c5]`
									        );

									        if (nextfield !== null) {
									          nextfield.focus();
									          nextfield.select()
									        }
						      			}
						      			} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
						      			<TextField name="c5" onChange={(e)=>this.setState({c5: e.target.value })} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
						      			<br/><br/>
						      		</div>

						      		{ this.state.confirmationMatch === false && 
						      			<div>
							      			<Alert severity="error">Confirmation code is incorrect.</Alert>
							      			<br/>
							      		</div>
						      		}

							      	<Button disabled={this.props.isLoading===true?true:false} style={styless.buttonPrimary} variant='contained' onClick={()=>this.verifyConfirmation()} startIcon={<AttachMoneyIcon />}>
									   { this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : ' Release Fund' }
									</Button>
								</Box>

						      </div>
		 				}

		 				{ this.state.fundpanel ===2 &&
		 					<div>
						      	<center>
					              <p>
					                <img alt="verified" src="/verified.png" /> <br/>
					               	<div>
				        				<Typography>Fund release success. User has received the fund and ready to be claimed. You can check <a href="/transactions" target="_blank">transactions</a> here.</Typography>
			        					<br/><br/>
		        					</div>
					              </p>
					            </center>
						    </div>
		 				}
		 				
		 					
		 			</DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
	    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({showReleaseFundDialog:false}) } autoFocus>
				              Close
				            </Button>
			        </DialogActions>
		        </Dialog>

		        <Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.showContractDialog}
		        	onClose={ () => this.setState({showContractDialog:false}) } >
		 			<DialogTitle>
		 				<Typography style={styless.title}>{this.state.job.jobPostTitle} </Typography>
		 			</DialogTitle>   
		 			<DialogContent>

		 				
		 				<b>Contract Start Date:</b> <br/>
		 				<Typography>{  _.has(this.state.jobApplicationID,'hire_at') ? moment( new Date( this.state.jobApplicationID.hire_at.seconds * 1000 ) ).format('MM/DD/YYYY') : "" }</Typography>
		 				<br/>
		 				<b>Job Type :</b> <br/>
		 				<Typography>{ this.state.jobApplicationID.offer_job_type }</Typography>
		 				<br/>
		 				<b>Rate :</b> <br/>
		 				<Typography>${ Number(this.state.jobApplicationID.offer_rate).toLocaleString() }</Typography>
		 				<br/>
		 				<b>Duration :</b> <br/>
		 				<Typography>{ offer_est }</Typography>
		 				<br/>
		 				<b>Hours Per Week :</b> <br/>
		 				<Typography>{ this.state.jobApplicationID.offer_hours_per_week }</Typography>
		 				
		 				
		 			</DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
        				<Link to={ROUTES.ENDCONTRACT+this.state.jobApplicationID.id}>
		        	        
			        	        {  !_.has(this.state.jobApplicationID,'end_contract_at') &&
			        	        	<Button style={styless.buttonPrimary} variant='contained' autoFocus>
					              		End Contract
					              	</Button>
					            }

					            {  _.has(this.state.jobApplicationID,'end_contract_at') &&
					            	<Button style={styless.buttonPrimary} variant='contained' autoFocus>
					              		View Contract
					              	</Button>
					            }
				            
			            </Link>
			        </DialogActions>
		        </Dialog>

		        <Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.confirmDeleteJobDialog}
		        	onClose={ () => this.setState({confirmDeleteJobDialog:false}) } >
		 			<DialogTitle><Typography style={styless.title}>Delete</Typography></DialogTitle>   
		 			<DialogContent>Are you sure you want to remove job post?</DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
	    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({confirmDeleteJobDialog:false}) } autoFocus>
				              No
				            </Button>
				            <Button style={styless.buttonPrimary} variant='contained'  onClick={ () => this.confirmDeleteJob() }>
				              Yes
				            </Button>
			        </DialogActions>
		        </Dialog>
		        
		        <Snackbar
			          anchorOrigin={{
			            vertical: 'bottom',
			            horizontal: 'center',
			          }}
	          		open={this.state.snackbar}
	          		autoHideDuration={5000}
	          		onClose={this.snackbarClose}
	          		ContentProps={{
	            		'aria-describedby': 'message-id',
	          		}}
	          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
			          action={[
			            <IconButton
			              key="close"
			              aria-label="Close"
			              color="inherit"
			              onClick={this.snackbarClose}
			            >
			              <CloseIcon />
			            </IconButton>,
			          ]} />
		    </div>
				)
		}else
		{
			return (

				<div></div>

			)
		}

		
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		getDocs: state.getDocs,
		db: state.db,
		collection: state.collection,
		doc: state.doc,
		getDoc: state.getDoc,
		where: state.where,
		limit: state.limit,
		query: state.query,
		arrayUnion: state.arrayUnion,
		updateDoc: state.updateDoc,
		isLoading: state.isLoading,
		orderBy : state.orderBy,
		storageRef: state.storageRef,
		storage: state.storage,
		uploadBytes: state.uploadBytes,
		getDownloadURL: state.getDownloadURL,
		addDoc: state.addDoc,
		isLoading: state.isLoading
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Job);





