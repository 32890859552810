import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import ChatIcon from '@mui/icons-material/Chat';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import Divider from '@mui/material/Divider';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import OutlinedInput from '@mui/material/OutlinedInput';
import _ from 'lodash'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Alert from '@mui/lab/Alert';
import premiumProfiles from '../../constants/premiumProfiles.json'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Grid from '@mui/material/Grid';

registerPlugin(FilePondPluginImagePreview);

const styles = {
    bigAvatar:{
        margin: 10,
        width: 150,
        height: 150,
        alignSelf:'center',
        cursor: 'pointer'
    },
    title: {
        color: '#333333',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10,
        fontSize: 24
    },
    subtitle: {
        color: '#8e8e8e',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10,
        fontSize: 18
    },
    center:{
        textAlign:'center',
        color: '#3c4859'
    },

    activeTemplate:{
      backgroundColor:"#00b16a"
    },

    small: {
        color: '#8e8e8e',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10
       },

       em: {
         color: '#8e8e8e',
         fontFamily: 'Gotham Rounded'
       },

       buttonPrimary: {
        padding: 10,
        fontFamily: 'Gotham Rounded',
        backgroundColor: '#00b16a'
       },

       buttonDefault: {
        padding: 10,
        fontFamily: 'Gotham Rounded'
       }

}

class Premium extends Component{
    
    constructor(props){
        
        super(props);

        this.state = {
            fullWidth: true,
            maxWidth: 'sm',
            db: [],
            snackbar: false,
            snackbarMessage: '',
            purchaseDialog: false,
            jpc:0,
            selectedTemplate: {},
            cost: 0,
            profile: []
        };
    
    }   

     handleChange = (event, value) => {
        this.setState({ submenu: value });
      };

    componentDidMount() {
        this.props.setLoading(true)
        this.setState({isLoaded:true})
        this.getProfile()

    }

    async getProfile(){

        let profile = await this.props.firebase.firestore().collection('users').doc( this.props.user.uid ).get()
        profile = profile.data()

        this.setState({profile:profile})
        this.props.setLoading(false)

    }

    snackbarClose = () => {
      this.setState({snackbar: false})
    }
    
    async buyNow(){

        let that = this
        if(this.state.jpc===0){
          this.setState({snackbar:true, snackbarMessage:"Sorry but you dont have enough credit to buy this template. Purchase credits and try again"})
        }else{
          this.props.firebase.firestore().collection('users').doc( this.props.user.uid ).update({ jpc: this.state.jpc - this.state.cost, 'socialTemplate': this.state.selectedTemplate }).then(()=>{
                that.props.setLoading(false)
                that.setState({purchaseDialog:false, snackbar:true, snackbarMessage:'Successfully purchased '+that.state.selectedTemplate.name+' template'})
                that.getProfile()
          })
        
        }
    }

    async useTemplate(alias)
    {
      let that = this
      this.props.firebase.firestore().collection('users').doc( this.props.user.uid ).update({ usePremiumTemplate: alias }).then(function(){
           that.props.setLoading(false)
           that.setState({snackbar:true, snackbarMessage:'Template is set to active.'}, ()=>{
            that.getProfile()
          })
      })
     
        
    }
    async buyTemplate(item)
    {
      item.skillProfiencies = {}

      let profile = await this.props.firebase.firestore().collection('users').doc( this.props.user.uid ).get()
      profile = profile.data()

      if(_.has(profile, 'socialTemplate'))
      {
         this.setState({snackbar:true, snackbarMessage:"You already purchased this template."})
      }else{
         let jpc = profile.jpc
         this.setState({cost: item.cost, jpc:jpc, purchaseDialog:true, selectedTemplate: item})
      }
     
    }
  
    setPreview(preview){

      if(preview==="")
        window.open("/fd/"+this.props.user.uid,"_blank")
      else if(preview==="socialTemplate")
        window.open("/fsocial/"+this.props.user.uid,"_blank")

    }

    render(){
    
        let that = this
       
       return (

            <div>
            
            <header>
              { this.state.isLoaded === true &&
                  <div style={{marginTop: 100}}>
                      
                      <div>
                        <Grid container>
                            
                            <Grid item xs={2} md={2}></Grid>
                            <Grid item xs={8} md={8}>

                             <h1 className="App-title">Premium Profile</h1>
                             
                             <br/>
                             
                            </Grid>
                            <Grid item xs={2} md={2}></Grid>
                            
                        </Grid>

                        <Grid container>
                          {
                              premiumProfiles.map( function(item,i){
                              
                                return (  
                                  <Grid item xs={12} md={3}>
                                    
                                    <Card sx={{ maxWidth: 345 }}>
                                      <CardMedia
                                        component="img"
                                        height="140"
                                        image={item.thumbnail}
                                        alt={item.name}
                                      />
                                      <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                          {item.name}  <i style={{fontSize:16,color:'#00b16a'}}>{ _.has(that.state.profile, 'usePremiumTemplate') && that.state.profile.usePremiumTemplate === item.alias ? 'Active': '' }</i>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                         { item.description }
                                        </Typography>
                                      </CardContent>
                                      <CardActions style={{justifyContent:'center'}}>
                                        <Tooltip title="Preview Template">
                                          <IconButton aria-label="Preview"  onClick={()=>{ that.setPreview(item.alias) } }>
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Tooltip>

                                        {  !_.has( that.state.profile, item.alias) && item.cost > 0 &&
                                           <Tooltip title="Buy template using credits">
                                              <IconButton aria-label="Buy Template"  onClick={()=>that.buyTemplate(item) }>
                                                <MonetizationOnIcon />
                                              </IconButton>
                                           </Tooltip>
                                        }
                                       
                                       { ( _.has(that.state.profile, item.alias ) || item.id ===0 ) &&
                                        <Tooltip title="Use Template">
                                          <IconButton aria-label="Use Template"  onClick={()=>that.useTemplate(item.alias) }>
                                            <CheckIcon  />
                                          </IconButton>
                                        </Tooltip>
                                       }
                                     
                                      </CardActions>
                                    </Card>

                                  </Grid>
                                )

                              })
                          }
                        </Grid>
                      </div>
                  </div>
              }

              { this.state.isLoaded === false &&
                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                        <CircularProgress style={{color:'#212121', alignSelf:'center'}} />
                    </div>
              }
            </header>

            
            <Dialog
            open={this.state.purchaseDialog}
            onClose={()=>this.setState({purchaseDialog:false})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Buy Social Template
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You are about to buy Social Template for { this.state.cost } credits. Click buy to continue.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>this.setState({purchaseDialog:false}) }>Cancel</Button>
              <Button onClick={()=> this.buyNow() }>Buy</Button>
      
            </DialogActions>
          </Dialog>

            <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                open={this.state.snackbar}
                autoHideDuration={5000}
                onClose={this.snackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{ this.state.snackbarMessage }</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={this.snackbarClose}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]} />
        </div>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        firebase: state.firebase,
        user: state.user,
        isLoggedIn: state.isLoggedIn
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
        setPreview: (preview) => dispatch({type: "SET_PREVIEW_TEMPLATE", payload: {previewTemplate:preview}})
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Premium);





