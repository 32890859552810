import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StarsIcon from '@mui/icons-material/Stars';
import WorkIcon from '@mui/icons-material/Work';
import { connect } from 'react-redux'
import _ from 'lodash'
import CircularProgress from '@mui/material/CircularProgress';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import CheckIcon from '@mui/icons-material/Check'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Alert from '@mui/lab/Alert';
import Grid from '@mui/material/Grid';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

registerPlugin(FilePondPluginImagePreview);

const styless = {
	bigAvatar:{
		margin: 10,
		width: 150,
		height: 150,
		alignSelf:'center',
		cursor: 'pointer'
	},
	title: {
		color: '#333333',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 24
	},
	subtitle: {
		color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 18
	},
	center:{
		textAlign:'center',
		color: '#3c4859'
	},

	small: {
	   	color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10
	   },

	   em: {
	   	 color: '#8e8e8e',
	   	 fontFamily: 'Gotham Rounded'
	   },

	   buttonPrimary: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded',
	   	backgroundColor: '#00b16a'
	   },

	   buttonDefault: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded'
	   }

}


class MyApplications extends Component{
	
	constructor(props){
		
		super(props);



		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		user: [],
    		snackbar: false,
    		snackbarMessage: '',
    		isSaving: false,
			jobs: [],
			addDialog: false,
			jobPostTitle:'',
			jobPostTitleError: false,
			jobDescription: '',
			jobDescriptionError: false,
			jobCategory: '',
			jobCategoryError: false,
			jobType: '',
			jobTypeError: false,
			estProjectDuration:'',
			estProjectDurationError: false,
			budget:  0,
			budgetError: false,
			hoursPerWeek: 0,
			hoursPerWeekError: false,
			howToApply: '',
			howToApplyError: false,
			notes: '',
			notesError: false,
			confirmDeleteJobDialog:false,
			deleteId: '',
			isEdit: false,
			jobStatus: "PENDING",
			isLoaded:false,
			jobCategories: [],
			expertise: '',
			expertiseError:false,
			closeJobReason: '',
			newJobID: '',
			jobApplications: [],
			tabActive: 'HIRE',
			rowLimit: 10,
			rowIncrement: 10,
			rowLimitDefault: 10,
			jobModal:false,
			jobOfferModal: false,
			jobDetails: [],
			declineJobOfferModal: false,
			proposalModal: false,
			jobApplicationID: [],
			fullTimeRate: 0,
			coverLetter: '',
			coverLetterError: false,
			proposedRate: '',
			proposedRateError: false,
			otherLinks: '',
			declineReason: 'Budget too low',
			selectJobStatus: "HIRE"

		};
	
	}	

	 handleChange = (event, value) => {
	    this.setState({ submenu: value });
	  };

	componentWillMount() {
		this.getJobApplication()	
	}

	changeTab(event, newValue,){

		let that = this
		this.setState({tabActive:newValue, rowLimit: this.state.rowLimitDefault},()=>{
			that.getJobApplication()
		})

	}

	localStorageMultiRemove(){
		let keys  = ['OFFER','DECLINED','ACCEPTED_OFFER','HIRE','INVITATION','APPLIED']

		for(let i = 0 ; i <keys.length ; i++)
		{
			localStorage.removeItem(keys[i]);
		}
		
	}

	async getJobApplication(){

		this.props.setLoading(true)
		
		if(localStorage.getItem(this.state.tabActive))
		{
			let ja = localStorage.getItem(this.state.tabActive)
			ja = JSON.parse(ja);
			this.setState({  jobApplications: ja, isLoaded:true })	
			this.props.setLoading(false)
		}else{
			
			let query
			if( this.state.tabActive==="APPLIED" )
				 query = this.props.query(this.props.collection( this.props.db, "jobApplications"), this.props.where("uid", "==", this.props.user.uid), this.props.where('status','==',this.state.tabActive),this.props.orderBy('applied_at','desc'));
			else if( this.state.tabActive==="OFFER" )
				 query = this.props.query(this.props.collection( this.props.db, "jobApplications"), this.props.where("uid", "==", this.props.user.uid), this.props.where('status','==',this.state.tabActive),this.props.orderBy('offer_at','desc'));
			else if( this.state.tabActive==="INVITATION" )
				 query = this.props.query(this.props.collection( this.props.db, "jobApplications"), this.props.where("uid", "==", this.props.user.uid), this.props.where('status','==',this.state.tabActive),this.props.orderBy('invite_at','desc'));
			else if( this.state.tabActive==="DECLINED" )
				 query = this.props.query(this.props.collection( this.props.db, "jobApplications"), this.props.where("uid", "==", this.props.user.uid), this.props.where('status','==',this.state.tabActive),this.props.orderBy('applied_at','desc'));
			else if( this.state.tabActive==="HIRE" )
				 query = this.props.query(this.props.collection( this.props.db, "jobApplications"), this.props.where("uid", "==", this.props.user.uid), this.props.where('status','==',this.state.tabActive),this.props.orderBy('hire_at','desc'));

			let that = this
			let ja = []

			const querySnapshot = await this.props.getDocs(query);

			querySnapshot.forEach((doc) => {
			  	let d = doc.data() 
				d.id = doc.id
				ja.push(d)	
			});

			localStorage.setItem(this.state.tabActive, JSON.stringify(ja))
			this.props.setLoading(false)
			that.setState({  jobApplications: ja, isLoaded:true })			
	
	
		}
		

		
		

	}

	
	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	async acceptOffer(){
		let that = this
		this.props.setLoading(true);

		const jaRef = this.props.doc(this.props.db, "jobApplications", this.state.jobApplicationID.id);

		this.props.updateDoc(jaRef, {
	        status: "HIRE",
	        hire_at: new Date()
	    }).then( function(){
	       		
	       	that.notifyClient('accept')
		 	that.props.setLoading(false);
		 	that.setState({  jobOfferModal: false,declineReason: '', snackbar: true, snackbarMessage: 'Job Offer Accepted'}, ()=>{
		 		that.localStorageMultiRemove()
		 		that.getJobApplication()
		 	 }) 

	    });

		
	}


	async notifyClient(type)
	{

		const jobRef = this.props.doc(this.props.db, "jobs", this.state.jobApplicationID.jobID );
		const jobSnap = await this.props.getDoc(jobRef);
		let job 
		if (jobSnap.exists())
		{
			job = jobSnap.data()
		}

		const clientRef = this.props.doc(this.props.db, "users", job.user );
		const clientSnap = await this.props.getDoc(clientRef);
		let client 
		if (clientSnap.exists())
		{
			client = clientSnap.data();
		}


		const freelancerRef = this.props.doc(this.props.db, "users", this.state.jobApplicationID.uid );
		const freelancerSnap = await this.props.getDoc(freelancerRef);
		let freelancer 
		if (freelancerSnap.exists())
		{
			freelancer = freelancerSnap.data()
		}

		let subject = ''
		let message = ''
		if(type==="accept")
		{
			message = MESSAGES.JOB_OFFER_ACCEPTED
			message = message.replace("{jobPostTitle}", this.state.jobApplicationID.jobPostTitle )
			message = message.replace("{freelancer}",  freelancer.fname+" "+freelancer.lname )
			subject = 'Job Offer Accepted'
		}else if(type==="decline")
		{
			message = MESSAGES.JOB_OFFER_DECLINE

			message = message.replace("{jobPostTitle}", this.state.jobApplicationID.jobPostTitle )
			message = message.replace("{freelancer}",  freelancer.fname+" "+freelancer.lname )
			message = message.replace("{reason}",  this.state.declineReason )
			subject = 'Job Offer Declined'
		}else if(type==='acceptinvite')
		{
			message = MESSAGES.NEW_JOB_APPLICATION

			message = message.replace("{date}", new Date())
			message = message.replace("{jobPostTitle}", this.state.jobApplicationID.jobPostTitle )
			
			subject = 'New Job Proposal'
		}
		
		fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
		{
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    method: "POST",
		    body: JSON.stringify({ title: subject, name: client.fname+" "+client.lname, to: client.email, message: message, reason: this.state.declineReason })
		})
		.then(function(res){ return res.json() })
		.then(function(data){
		})
		.catch(function(res){ 
			console.log('error',res)
		})
		
	}

	async declineOffer()
	{
		let that = this
		this.props.setLoading(true);

		const jaRef = this.props.doc(this.props.db, "jobApplications", this.state.jobApplicationID.id);

		this.props.updateDoc(jaRef, {
			status: "DECLINED",
	        DECLINED_OFFER: "HIRE",
	        declined_offer_reason: this.state.declineReason,
	        declined_offer_at: new Date()
	    }).then( function(){
	       		
	       that.notifyClient('decline')
		 	that.props.setLoading(false);
		 	that.setState({ declineJobOfferModal: false, snackbar: true, snackbarMessage: 'Application declined'}, ()=>{ }) 
		 	that.localStorageMultiRemove()
		 	that.getJobApplication()

	    });
			
	}

	async showJobDetails(application){

		this.props.setLoading(true)

		const jobRef = this.props.doc(this.props.db, "jobs", application.jobID );
		const jobSnap = await this.props.getDoc(jobRef);
		let job

		if (jobSnap.exists())
		{
			job = jobSnap.data()
			application.job = job

			application.job.owner = _.startCase( application.user.fname ) + " " + _.startCase( application.user.lname )
		
			if(application.job.estProjectDuration === 1)
				application.job.estProjectDuration = "Less than a week"
			else if(application.job.estProjectDuration ===2)
				application.job.estProjectDuration = "Less than a month"
			else if(application.job.estProjectDuration === 3)
				application.job.estProjectDuration = "Less than 3 months"
			else if(application.job.estProjectDuration === 4)
				application.job.estProjectDuration = "Less than 6 months"
			else if(application.job.estProjectDuration === 5)
				application.job.estProjectDuration = "More than a year"

			this.setState({jobDetails:application.job,jobModal:true, jobApplicationID: application})
			this.props.setLoading(false)
		}

	}

	async addContact(uid){

		let that = this

		const jobRef = this.props.doc(this.props.db, "jobs", uid.jobID );
		const jobSnap = await this.props.getDoc(jobRef);
		let job

		if (jobSnap.exists())
		{
			job = jobSnap.data()
			this.props.setLoading(true)

			const contactleftRef = this.props.collection(this.props.db, "rooms");
			const q = this.props.query(contactleftRef, this.props.where("from", "==", this.props.user.uid ), this.props.where("to", "==", job.user ), this.props.orderBy('created_at',"desc"), this.props.limit(1) );
			const contactleft = await this.props.getDocs(q);

			const contactRightRef = this.props.collection(this.props.db, "rooms");
			const qq = this.props.query(contactRightRef, this.props.where("to", "==", this.props.user.uid ), this.props.where("from", "==", job.user ), this.props.orderBy('created_at',"desc"), this.props.limit(1) );
			const contactright = await this.props.getDocs(qq);
		
			if(contactleft.size===0 && contactright.size===0 ){

				const docRef = await this.props.addDoc(this.props.collection(this.props.db, "rooms"), 
				{
					from: uid,
					to: that.props.user.uid,
					history: [],
					created_at: new Date()
				}).then(function(docRef){
					
					that.props.setLoading(false)
					//that.props.history.push('/message/'+docRef.id);
					window.location.replace('/message/'+docRef.id)
				})

				
			}else{
				
				that.props.setLoading(false)

				if(contactleft.size!==0){
					contactleft.docs.map( doc =>window.location.replace('/message/'+doc.id) )
				}
				else if(contactright.size!==0){
					contactright.docs.map( doc =>window.location.replace('/message/'+doc.id) )
					
				}
				
			}
		}

	}

	async sendProposal(job){
		this.setState({proposalModal:true})
	}

	submitProposal(){

		
		this.props.setLoading(true);
		let files = this.pond.getFiles();
		let savedFiles = []
		let uploadFiles = []
		let that = this
		let formerror  = 0;

		if(this.state.coverLetter===''){ 
			formerror++
			this.setState({coverLetterError:true, snackbar: true, snackbarMessage: 'Cover Letter is required'}, ()=>{ }) 
			this.props.setLoading(false);
			return
		}else{
			this.setState({coverLetterError:false})
		}

		if(Number(this.state.proposedRate)===0){ 
			formerror++
			this.setState({proposedRateError:true, snackbar: true, snackbarMessage: 'Please make sure you have the correct proposed rate.'}, ()=>{ }) 
			this.props.setLoading(false);
			return
		
		}else{
			this.setState({proposedRateError:false})
		}

		if(formerror===0)
			this.setState({proposedRateError: false, coverLetterError:false })
		else
			return 

		
		files.map(file => uploadFiles.push(file.file) )


		if(files.length>0)
		{

			let fileRefs = []
			const uploadPromises = [];
			const storageRef = this.props.storageRef(this.props.storage, 'uassets/'+that.props.user.uid+"/"); 

			files.map( async file => {
			
				const fileRef = that.props.storageRef(storageRef, Math.random().toString(36).substring(7)+file.file.name);

				try {
			      const uploadTask = that.props.uploadBytes(fileRef, file.file);
			      fileRefs.push(fileRef)
			      uploadPromises.push(uploadTask);
				  
			    } catch (error) {
			      console.error(`Error uploading "${file.file.name}":`, error);
			    }

			})

			Promise.all(uploadPromises).then( ()=>{
			 
				 let files_ = []
				 let fileCount = 0
				 fileRefs.map(async fr=>{
				 	 const downloadURL = await that.props.getDownloadURL(fr);
				 	 files_.push(downloadURL);

				 	 fileCount++;

				 	 if(fileCount==fileRefs.length)
				 	 {
				 	 	  const jaRef = this.props.doc(this.props.db, "jobApplications", this.state.jobApplicationID.id);
	  		
					  		this.props.updateDoc(jaRef, {
						        uid: that.props.user.uid,
								jobID: that.state.jobApplicationID.jobID,
								coverLetter: that.state.coverLetter,
								proposedRate: that.state.proposedRate,
								attachments: files_,
								otherLinks: that.state.otherLinks,
								applied_at: new Date(),
								status: "APPLIED"
						    }).then( function(){
						       	
						       	that.props.setLoading(false);
								that.setState({ proposalModal: false, applyDialog:false, snackbarMessage:"Your job application was submitted.", snackbar: true})
								that.setState({ coverLetter: '', coverLetterError: false, proposedRateError: false, })
								that.notifyClient('acceptinvite')
								that.localStorageMultiRemove()
								that.getJobApplication()	

						    }).catch(function(error) {
							    that.setState({snackbar:true,snackbarMessage:error})
							});
				 	 }

				 })
			} )

		}else{

			const jaRef = this.props.doc(this.props.db, "jobApplications", this.state.jobApplicationID.id);
	  		
	  		this.props.updateDoc(jaRef, {
		        uid: that.props.user.uid,
				jobID: that.state.jobApplicationID.jobID,
				coverLetter: that.state.coverLetter,
				proposedRate: that.state.proposedRate,
				attachments: savedFiles,
				otherLinks: that.state.otherLinks,
				applied_at: new Date(),
				status: "APPLIED"
		    }).then( function(){
		       	
		       	that.props.setLoading(false);
				that.setState({ proposalModal: false, applyDialog:false, snackbarMessage:"Your job application was submitted.", snackbar: true})
				that.setState({ coverLetter: '', coverLetterError: false, proposedRateError: false, })
				that.notifyClient('acceptinvite')
				that.localStorageMultiRemove()
				that.getJobApplication()	

		    }).catch(function(error) {
			    that.setState({snackbar:true,snackbarMessage:error})
			});
		}
	}

	async reviewOffer(jobApplication){

		const jobRef = this.props.doc(this.props.db, "jobs", jobApplication.jobID );
		const jobSnap = await this.props.getDoc(jobRef);
		let job

		if (jobSnap.exists())
		{
			job = jobSnap.data()
			job.owner = _.startCase( jobApplication.user.fname ) + " " + _.startCase( jobApplication.user.lname )

			if(job.estProjectDuration === 1)
				job.estProjectDuration = "Less than a week"
			else if(job.estProjectDuration ===2)
				job.estProjectDuration = "Less than a month"
			else if(job.estProjectDuration === 3)
				job.estProjectDuration = "Less than 3 months"
			else if(job.estProjectDuration === 4)
				job.estProjectDuration = "Less than 6 months"
			else if(job.estProjectDuration === 5)
				job.estProjectDuration = "More than a year"

			this.setState({jobDetails:job,jobOfferModal:true})
		}
	}


	getDuration(value)
	{
		let duration
		if(value === '1')
			duration = "Less than a week"
		else if(value ==='2')
			duration = "Less than a month"
		else if(value === '3')
			duration = "Less than 3 months"
		else if(value === '4')
			duration = "Less than 6 months"
		else if(value === '5')
			duration = "More than a year"

		return duration
	}
	
	
	render(){
		let applicationList
		let that = this
		let displayCount = 0;
		let statusCount = 0;

		let oduration = ''
    	
    	if( _.has(this.state.jobApplicationID,"offer_duration") && this.state.jobApplicationID.offer_duration === '1')
			oduration = "Less than a week"
		else if( _.has(this.state.jobApplicationID,"offer_duration") && this.state.jobApplicationID.offer_duration ==='2')
			oduration = "Less than a month"
		else if( _.has(this.state.jobApplicationID,"offer_duration") && this.state.jobApplicationID.offer_duration === '3')
			oduration = "Less than 3 months"
		else if( _.has(this.state.jobApplicationID,"offer_duration") && this.state.jobApplicationID.offer_duration === '4')
			oduration = "Less than 6 months"
		else if( _.has(this.state.jobApplicationID,"offer_duration") && this.state.jobApplicationID.offer_duration === '5')
			oduration = "More than a year"

		
		if(this.state.jobApplications.length > 0)
		{		

				if(that.state.tabActive==="ALL")
				{
					statusCount = _.filter(this.state.jobApplications, function(o){
						if( o.status === that.state.tabActive ){
							return o
						}
					} );
				}else{
					statusCount = _.filter(this.state.jobApplications, function(o){
						if( o.status === that.state.tabActive ){
							return o
						}
					} );
				}

				applicationList = this.state.jobApplications.map(function(item, i){
				
			
				let status = ''
				let actions
				let ago
				let tooltip
		
				if( item.status ==="APPLIED" ){
					status="APPLIED"
					ago = moment( new Date(item.applied_at.seconds * 1000) ).fromNow();

				}else if( item.status ==="OFFER" || item.status==="DECLINED_OFFER" || item.status ==="ACCEPTED_OFFER" )
				{
					status="OFFER"
					ago = moment(  new Date(item.offer_at.seconds * 1000) ).fromNow();

					if(item.status==="DECLINED_OFFER")
					{
						actions = <div className="status-danger">Offer Declined</div>
					} else if(item.status==="ACCEPTED_OFFER"){
						actions = <div className="status-success">Offer Accepted</div>
					}
					else{
						actions = <div>
								<Tooltip title={ "Accept Job Offer" }>
									<IconButton edge="end" aria-label="hire" onClick={ ()=> {
										that.reviewOffer(item)
										that.setState({jobApplicationID:item})
										}
										 }><CheckIcon /></IconButton>
							  	</Tooltip>
							  	<Tooltip title={ "Decline Job Offer" }>
									<IconButton edge="end" aria-label="hire" onClick={ ()=>that.setState({declineJobOfferModal:true, jobApplicationID: item}) }><CloseIcon /></IconButton>
							  	</Tooltip>
							  </div>
					}

					
				}else if( item.status ==="INTERVIEW" ){
					status="INTERVIEW"
					ago = moment( new Date( item.interview_at.seconds * 1000) ).fromNow();
				}else if( item.status ==="DECLINED" ){
					status="DECLINED"
					ago = moment( new Date( item.declined_at.seconds * 1000) ).fromNow();
				}else if( item.status ==="HIRE" ){
					status="HIRE"
					ago = moment(  new Date( item.hire_at.seconds * 1000) ).fromNow();
					tooltip = 'View Contract'
					if( _.has(item,'end_contract_at') ){
						tooltip = 'View Contract'
					}

					if(  !_.has(item,'end_contract_at')  )
					{
						tooltip = 'End Contract'
					}

					actions = <div>
								<Tooltip title={ tooltip }>
									<Link to={ROUTES.ENDCONTRACT+item.id}>
										<IconButton edge="end" aria-label="hire" onClick={ ()=> {
											that.sendProposal(item.job)
											that.setState({jobApplicationID:item})
											}
											 }><StarsIcon /></IconButton>
									</Link>
							  	</Tooltip>
							  </div>
				}else if( item.status ==="INVITATION" ){
					status="INVITATION"
					ago = moment( new Date( item.invite_at.seconds * 1000) ).fromNow();

					actions = <div>
								<Tooltip title={ "Submit Proposal" }>
									<IconButton edge="end" aria-label="hire" onClick={ ()=> {
										that.sendProposal(item.job)
										that.setState({jobApplicationID:item})
										}
										 }><WorkIcon /></IconButton>
							  	</Tooltip>
							  </div>
				}else{
					displayCount=-2
				}
			
				if(that.state.tabActive===status&&displayCount<that.state.rowLimit)
				{
					displayCount++

					return (
						<ListItem key={i}>
							<ListItemText secondary={ ago }>

								<Link to={"/jobs/"+item.jobID}>
				        	       {item.jobPostTitle}
					            </Link>
								</ListItemText>
		                  	<ListItemSecondaryAction>
		                  		{ actions }
		                  </ListItemSecondaryAction>

						</ListItem>
					)
				}else if(that.state.tabActive==="ALL"&&displayCount<that.state.rowLimit){
					displayCount++

					if(displayCount>that.state.rowLimit){
						return (false)
					}

					return (
						<ListItem key={i}>
							<ListItemText secondary={ ago }>

								<Link to={"/jobs/"+item.jobID}>
				        	       {item.jobPostTitle}
					            </Link>
								</ListItemText>
		                  	<ListItemSecondaryAction>
		                  		{ actions }
		                  </ListItemSecondaryAction>

						</ListItem>
					)
				}


					

				return true
			

			})
				
		}

		
		

		
		return (

			<div>
			
			<header>
			  { this.state.isLoaded === true && 
	          <div style={{marginTop: 100}}>
		          <Grid container spacing={2}>
			          	<Grid item xs={0} md={2}>
			          	</Grid>
			          	<Grid item xs={10} md={8}>
			          		<Grid>
					          	<Grid container>
					          		
					          		<Grid item xs={12} md={12}>

					          		 <h1 className="App-title">My Job Applications</h1>
					          		 
					          		 <br/>

					          		 <Grid>
					          		 		<Grid container>
					          		 			<Grid item md={12}>
						          		 			<FormControl fullWidth>
						          		 			  <InputLabel> Job Status</InputLabel>
													  <Select
													    defaultValue={this.state.tabActive}
													    value={this.state.tabActive}
													    label="Job Status"
													    onChange={ (event, newValue)=>{

												    		that.setState({tabActive: event.target.value }, ()=>{
													    		//that.getJobs()
													    		that.getJobApplication()
													    	})

													    } }
													  >
													    <MenuItem  value={"APPLIED"}>Applied</MenuItem >
													    <MenuItem  value={"OFFER"}>Offer</MenuItem >
													    <MenuItem  value={"INVITATION"}>Invite</MenuItem >
													    <MenuItem  value={"DECLINED"}>Declined</MenuItem >
													    <MenuItem  value={"HIRE"}>My Jobs</MenuItem >
													  </Select>
													</FormControl>
					          		 			</Grid>
					          		 		</Grid>
					          		 	
					          		 </Grid>

					          		 <br/>

					          		  <List>
					          		 	{ !_.isEmpty(applicationList) &&
					          		 		applicationList
					          		 	}

					          		 	{  this.state.jobApplications.length===0 &&
					          		 		<center>
					          		 			<img alt="Online job" src="/online-job.png" />
					          		 			<Typography>No job listed on this tab.</Typography>
					          		 		</center>
					          		 	}

					          		 	{ this.state.rowLimit<statusCount.length&&
					          		 	 <div style={{flex:1,justifyContent:'center', aligItems:'center', textAlign:'center'}}>
						          		 	<IconButton onClick={()=>{this.setState({rowLimit: this.state.rowLimit+this.state.rowIncrement})}} style={{alignSelf:'center', color:"#999999"}} aria-label="delete" size="large" color="primary">
								           		<MoreHorizIcon />
								         	</IconButton>
								         </div>
								        }

					          		 </List>

					          		
					          		</Grid>
					          		
					          	</Grid>
			         		</Grid>
			          	</Grid>
			          	<Grid item xs={0} md={2}>
			          	</Grid>
			        </Grid>
		      </div>
		      }

		      { this.state.isLoaded === false &&
                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                        <CircularProgress style={{color:'#00b16a', alignSelf:'center'}} />
                    </div>
              }
	        </header>

	        <Dialog
		        open={this.state.declineJobOfferModal}
		        onClose={()=>this.setState({declineJobOfferModal:false})}
		        fullWidth={true}
		        maxWidth="sm"
		        scroll="body"
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		      >
		        <DialogTitle id="alert-dialog-title">Decline Job Offer</DialogTitle>
		        <DialogContent>
	 				You can state your reason for declining this job offer.
	 				<br/><br/>
	 				<FormControl style={{width:'100%'}}>
					        <InputLabel htmlFor="declinereason">Select Reason</InputLabel>
					        <Select
					          labelId=""
					          id="declinereason"
					          value={this.state.declineReason}
					          label="Select Reason"
					          onChange={(event)=>{ this.setState({declineReason: event.target.value, showDeclineReason: event.target.value==="Other" ? true : false }) }}
					        >
					          <MenuItem value={'Budget too low'}>Budget too low</MenuItem>
					          <MenuItem value={'My skills is not fit for the job'}>My skills is not fit for the job</MenuItem>
					          <MenuItem value={'Already busy with other projects'}>Already busy with other projects</MenuItem>
					          <MenuItem value={'Other'}>Other</MenuItem>
					        </Select>
				      </FormControl>
				    { this.state.showDeclineReason === true && 
	 				<TextField
      					multiline
      					row="5"
      					name='overview'
				      	variant="outlined"
				      	margin="normal" value={this.state.declineReason} fullWidth={true} onChange={ (event) => this.setState({declineReason: event.target.value }) } />
	 				}
	 			</DialogContent>
		        <DialogActions>
		          <Button disabled={this.props.isLoading===true?true:false} style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=>this.declineOffer() } >
		           
		             { this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : ' Decline Job Offer' }
		          </Button>
		         </DialogActions>
		      </Dialog>
      		
      		<Dialog
		        open={this.state.proposalModal}
		        onClose={()=>this.setState({proposalModal:false})}
		        fullWidth={true}
		        maxWidth="sm"
		        scroll="body"
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		      >
		        <DialogTitle id="alert-dialog-title">Submit Proposal</DialogTitle>
		        <DialogContent>
	 				
	 				<Typography style={styless.em}>Cover Letter</Typography>
					<TextField
						    	multiline
						    	rows="5"
						    	error={this.state.coverLetterError}
	          					name='coverLetter'
						      	variant='outlined'
						      	margin="normal" value={this.state.coverLetter} fullWidth={true} onChange={ (event) => this.setState({coverLetter: event.target.value }) } />
						    
					<br/>
					<Typography style={styless.em}>Proposed Rate</Typography>
				    { _.has(this.state.jobApplicationID,'jobType') && this.state.jobApplicationID.jobType === "Full Time" &&
				    	<small>Your Full Time Rate: ${this.state.fullTimeRate} </small>
					}

					{ this.state.jobApplicationID.jobType === "Part Time" &&
				    	<small>Your Hourly Rate: ${this.state.hourlyRate} </small>
					}

				    <TextField
      					error={ this.state.proposedRateError }
      					name='rate'
				      	variant="outlined"
				      	InputProps={{
				          startAdornment: <InputAdornment position="start">$</InputAdornment>
				        }}
				      	margin="normal" value={this.state.proposedRate} fullWidth={true} onChange={ (event) => { 
				      		const re = /^[0-9\b]+$/

				      		if (event.target.value === '' || re.test(event.target.value)) {
						       this.setState({proposedRate: event.target.value })
						    }
				      		
				      	 } } />
				    <br/>
				    <Typography style={styless.em}>Attachment</Typography>
				    <small>You may attach a file (1mb) that will support your proposal. </small>
				    <br/><br/>
				    <FilePond 
    					allowMultiple={false}
    					maxFiles={1}
    					maxFileSize="1MB"
    					ref = { ref => this.pond = ref } >
    				</FilePond>
    				<br/>
				    <Typography style={styless.em}>Other Links</Typography>
				    <small>You can use this field to attach links of online documents, projects or portfolio. </small>
				    <br/>
				    <TextField
				    	multiline
				    	rows="5"
				    	error={this.state.otherLinksError}
      					name='otherLinks'
				      	variant='outlined'
				      	margin="normal" value={this.state.otherLinks} fullWidth={true} onChange={ (event) => this.setState({otherLinks: event.target.value }) } />
	 			</DialogContent>
		        <DialogActions>
		          <Button disabled={ this.props.isLoading===true ? true : false } style={styless.buttonPrimary} variant='contained' onClick={()=>this.submitProposal() } >
		             { this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : 'Send Proposal' }
		          </Button>
		         </DialogActions>
		      </Dialog>
      

	        <Dialog
		        open={this.state.jobOfferModal}
		        onClose={()=>this.setState({jobOfferModal:false})}
		        fullWidth={true}
		        maxWidth="sm"
		        scroll="body"
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		      >
		        <DialogTitle id="alert-dialog-title">Job Detail</DialogTitle>
		        <DialogContent>
		          <DialogContentText id="alert-dialog-description">
		            <b>Job Title:</b> <br/>
		            { _.has(this.state.jobDetails,"jobPostTitle") ? this.state.jobDetails.jobPostTitle: '' }
		            <br/><br/>
		            
		            <b>Job Description:</b> <br/>
		            { _.has(this.state.jobDetails,"jobDescription") ? this.state.jobDetails.jobDescription: '' }
		            <br/><br/>
		            <b>Category: </b> <br/>
		            { _.has(this.state.jobDetails,"jobCategory") ? this.state.jobDetails.jobCategory: '' }
		            <br/><br/>
		            <b>Budget: </b> <br/>
		            { _.has(this.state.jobDetails,"budget") ? "$"+this.state.jobDetails.budget: '' }
		            <br/><br/>
		            <b>Job Type: </b> <br/>
		            { _.has(this.state.jobDetails,"jobType") ? this.state.jobDetails.jobType: '' }
		            <br/><br/>
		            <b>Project Duration: </b> <br/>
		           
		            { _.has( this.state.jobDetails,'estProjectDuration' ) ? this.getDuration(  this.state.jobDetails.estProjectDuration ) : '' }
		        
		            <br/><br/>
		            <b>Needed Experience: </b> <br/>
		            { _.has(this.state.jobDetails,"expertise") ? _.startCase( this.state.jobDetails.expertise ): '' }
		            <br/><br/>
		            <b>Hours Per Week: </b> <br/>
		            { _.has(this.state.jobDetails,"hoursPerWeek") ? this.state.jobDetails.hoursPerWeek: '' }
		            <br/><br/>
		            <b>Additional Note: </b> <br/>
		            { _.has(this.state.jobDetails,"notes") ? this.state.jobDetails.notes: '' }
		         
		            <hr/>
		            <b>Job Offered by: </b> <br/>
		            { _.has(this.state.jobDetails,"owner") ? this.state.jobDetails.owner: '' }
		            <br/><br/> 
		            <b>Job Offered on: </b> <br/>
		            { _.has(this.state.jobApplicationID,"offer_at") ? moment( new Date( this.state.jobApplicationID.offer_at.seconds * 1000) ).fromNow(): '' }
		            <br/><br/> 
		            <b>Job Type Offer: </b> <br/>
		            { _.has(this.state.jobApplicationID,"offer_job_type") ? this.state.jobApplicationID.offer_job_type: '' }
		            <br/><br/>
		            <b>Estimated Project Duration Offer: </b> <br/>
		            { oduration }
		            <br/><br/>
		            <b>Rate Offer: </b> <br/>
		            { _.has(this.state.jobApplicationID,"offer_rate") ? "$"+ this.state.jobApplicationID.offer_rate: '' } { _.has(this.state.jobApplicationID,"offer_job_type") &&  this.state.jobApplicationID.offer_job_type !== "Project Based" ? ' per hour ': '' }
		           	<br/><br/>
		            <b>Offer Notes: </b> <br/>
		            { _.has(this.state.jobApplicationID,"offer_notes") ?  this.state.jobApplicationID.offer_notes: '' }

		          </DialogContentText>
		        </DialogContent>
		        <DialogActions>
		          { _.has( this.state.jobApplicationID,'status' ) && this.state.jobApplicationID.status==="OFFER" &&
		          	<Button style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=> this.addContact(this.state.jobApplicationID) } >
		            	Send Message
		          	</Button>
		          }
		          <Button disabled={this.props.isLoading===true?true:false} style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=>this.acceptOffer() } >
		             { this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : 'Accept Offer' }
		          </Button>
		         </DialogActions>
		      </Dialog>
      
	        <Dialog
		        open={this.state.jobModal}
		        onClose={()=>this.setState({jobModal:false})}
		        fullWidth={true}
		        maxWidth="sm"
		        scroll="body"
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		      >
		        <DialogTitle id="alert-dialog-title">Job Detail</DialogTitle>
		        <DialogContent>
		          <DialogContentText id="alert-dialog-description">
		            <b>Job Title:</b> <br/>
		            { _.has(this.state.jobDetails,"jobPostTitle") ? this.state.jobDetails.jobPostTitle: '' }
		            <br/><br/>
		            
		            <b>Job Description:</b> <br/>
		            { _.has(this.state.jobDetails,"jobDescription") ? this.state.jobDetails.jobDescription: '' }
		            <br/><br/>
		            <b>Category: </b> <br/>
		            { _.has(this.state.jobDetails,"jobCategory") ? this.state.jobDetails.jobCategory: '' }
		            <br/><br/>
		            <b>Budget: </b> <br/>
		            { _.has(this.state.jobDetails,"budget") ? "$"+this.state.jobDetails.budget: '' }
		            <br/><br/>
		            <b>Job Type: </b> <br/>
		            { _.has(this.state.jobDetails,"jobType") ? this.state.jobDetails.jobType: '' }
		            <br/><br/>
		            <b>Project Duration: </b> <br/>
		            { _.has(this.state.jobDetails,"estProjectDuration") ? this.getDuration( this.state.jobDetails.estProjectDuration ): '' }
		            <br/><br/>
		            <b>Needed Experience: </b> <br/>
		            { _.has(this.state.jobDetails,"expertise") ? _.startCase( this.state.jobDetails.expertise ): '' }
		            <br/><br/>
		            <b>Hours Per Week: </b> <br/>
		            { _.has(this.state.jobDetails,"hoursPerWeek") ? this.state.jobDetails.hoursPerWeek: '' }
		            <br/><br/>
		            <b>Additional Note: </b> <br/>
		            { _.has(this.state.jobDetails,"notes") ? this.state.jobDetails.notes: '' }
		            <br/><br/>
		            <b>Posted by: </b> <br/>
		            { _.has(this.state.jobDetails,"owner") ? this.state.jobDetails.owner: '' }
		            <br/><br/>
		            <b>Posted On: </b> <br/>
		            { _.has(this.state.jobDetails,"created_at") ? moment( new Date( this.state.jobDetails.created_at.seconds * 1000) ).format("LL"): '' }

		            { _.has( this.state.jobApplicationID,'status' ) && this.state.jobApplicationID.status==="INTERVIEW" &&
		          		<div>
		          			<br/>
		            		<b>Interview Date: </b> <br/>
		            		{ _.has(this.state.jobApplicationID,"interview_date") ? moment( new Date( this.state.jobApplicationID.interview_date.seconds * 1000)  ).format('LL')+" "+moment( new Date( this.state.jobApplicationID.interview_date.seconds * 1000) ).format('hh:mm:ss A'): '' }
		            		<br/><br/>
		            		<b>Interview Message: </b> <br/>
		            		{ _.has(this.state.jobApplicationID,"interview_reason") ? this.state.jobApplicationID.interview_reason : "" }
		            		
		            	</div>
		            }

		            { _.has( this.state.jobApplicationID,'status' ) && this.state.jobApplicationID.status==="DECLINED" &&
		          		<div>
		          			<br/>
		            		<b>Declined Date: </b> <br/>
		            		{ _.has(this.state.jobApplicationID,"declined_at") ? moment( new Date( this.state.jobApplicationID.declined_at.seconds * 1000) ).format('LL') : '' }
		            		<br/><br/>
		            		<b>Declined Reason: </b> <br/>
		            		{ _.has(this.state.jobApplicationID,"declined_reason") ? this.state.jobApplicationID.declined_reason : "" }
		            		
		            	</div>
		            }

		            { _.has( this.state.jobApplicationID,'status' ) && ( this.state.jobApplicationID.status==="OFFER" || this.state.jobApplicationID.status==="DECLINED_OFFER"  ) &&
		          		<div>
		          			<br/>
		            		<b>Offer Date: </b> <br/>
		            		{ _.has(this.state.jobApplicationID,"offer_at") ? moment( new Date( this.state.jobApplicationID.offer_at.seconds * 1000)  ).format('LL') : '' }
		            		<br/><br/>

		            		{ _.has(this.state.jobApplicationID,'offer_duration')  && <span><b>Offer Duration:</b><br/></span> }
		            		{ _.has(this.state.jobApplicationID,"offer_duration") && this.state.jobApplicationID.offer_duration === '1' && "Less than a week" }
		            		{ _.has(this.state.jobApplicationID,"offer_duration") && this.state.jobApplicationID.offer_duration === '2' && "Less than a month" }
		            		{ _.has(this.state.jobApplicationID,"offer_duration") && this.state.jobApplicationID.offer_duration === '3' && "Less than 3 months" }
		            		{ _.has(this.state.jobApplicationID,"offer_duration") && this.state.jobApplicationID.offer_duration === '4' && "Less than 6 months" }
		            		{ _.has(this.state.jobApplicationID,"offer_duration") && this.state.jobApplicationID.offer_duration === '1' && "More than a year" }
							
							<br/><br/>
							<b>Offer Hours per Week:</b> <br/>
							{ _.has(this.state.jobApplicationID,"offer_hours_per_week") ? this.state.jobApplicationID.offer_hours_per_week+" hour" : '' }
		            		<br/><br/>

		            		<b>Offer Job Type:</b> <br/>
							{ _.has(this.state.jobApplicationID,"offer_job_type") ? this.state.jobApplicationID.offer_job_type : '' }
		            		<br/><br/>

		            		<b>Offer Rate:</b> <br/>
							{ _.has(this.state.jobApplicationID,"offer_rate") ? '$'+this.state.jobApplicationID.offer_rate + ' '+ (this.state.jobApplicationID.offer_job_type === "Project Based" ? '' : 'per hour') : '' }
		            		<br/><br/>

		            		{ _.has(this.state.jobApplicationID,'status') && this.state.jobApplicationID.status==="DECLINED_OFFER" &&
		            			<div>
		            				<b>Offer Declined on:</b> <br/>
		            				{ _.has(this.state.jobApplicationID,"declined_at") ? moment( new Date( this.state.jobApplicationID.declined_at.seconds * 1000) ).format("LL")  : '' }
		            				<br/><br/>
		            				<b>Offer Declined Reason:</b> <br/>
		            				{ _.has(this.state.jobApplicationID,"declined_offer_reason") ? this.state.jobApplicationID.declined_offer_reason  : '' }
		            		
		            			</div>
		            		}

		            	</div>
		            }

		            { _.has( this.state.jobApplicationID,'status' ) && ( this.state.jobApplicationID.status==="HIRE"  ) &&
		          		<div>
		          			<br/>
		            		<b>Hired Date: </b> <br/>
		            		{ _.has(this.state.jobApplicationID,"hire_at") ? moment( new Date( this.state.jobApplicationID.hire_at.seconds * 1000)  ).format('LL') : '' }
		            	</div>
		            }

		            <br/><br/>

		           
		          </DialogContentText>
		        </DialogContent>
		        <DialogActions>
		          { _.has( this.state.jobApplicationID,'status' ) && this.state.jobApplicationID.status==="INTERVIEW" &&
		          	<Button style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=> this.addContact(this.state.jobApplicationID.job.user) } >
		            	Send Message
		          	</Button>
		          }
		          { _.has( this.state.jobApplicationID,'status' ) && this.state.jobApplicationID.status==="HIRE" &&
		          	
		          	<Link to={ROUTES.ENDCONTRACT+this.state.jobApplicationID.id}>
	        	        <Button style={styless.buttonPrimary} variant='contained' color="primary" autoFocus>
			              

			              { _.has(this.state.jobApplicationID,'end_contract_at') && 
			 					'View Contract'
			 			  }

			 			  { !_.has(this.state.jobApplicationID,'end_contract_at') && 
			 					'End Contract'
			 			  }

			            </Button>
		            </Link>
		          }
		          <Button style={styless.buttonDefault} variant='contained' onClick={()=>this.setState({jobModal:false})} >
		            Close
		          </Button>
		         </DialogActions>
		      </Dialog>
      
	        <Snackbar
		          anchorOrigin={{
		            vertical: 'bottom',
		            horizontal: 'right',
		          }}
          		open={this.state.snackbar}
          		autoHideDuration={3000}
          		onClose={this.snackbarClose}
          		ContentProps={{
            		'aria-describedby': 'message-id',
          		}}
          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
		          action={[
		            <IconButton
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              onClick={this.snackbarClose}
		            >
		              <CloseIcon />
		            </IconButton>,
		          ]} />
	    </div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		collection: state.collection,
		db: state.db,
		where: state.where,
		orderBy: state.orderBy,
		query: state.query,
		getDocs: state.getDocs,
		storageRef: state.storageRef,
		storage: state.storage,
		uploadBytes: state.uploadBytes,
		getDownloadURL: state.getDownloadURL,
		doc: state.doc,
		updateDoc: state.updateDoc,
		getDoc: state.getDoc,
		limit: state.limit,
		addDoc: state.addDoc,
		isLoading: state.isLoading
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MyApplications);





