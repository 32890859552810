import React, { Component } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Paper from '@mui/material/Paper';
import { connect } from 'react-redux'
import _ from 'lodash'
import CircularProgress from '@mui/material/CircularProgress';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import Avatar from '@mui/material/Avatar';
import PeopleIcon from '@mui/icons-material/People'
import FaceIcon from '@mui/icons-material/Face'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import { forwardRef } from 'react';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import Warning from '@mui/icons-material/Warning';
import ViewColumn from '@mui/icons-material/ViewColumn';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Alert from '@mui/lab/Alert';
import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import OutlinedInput from '@mui/material/OutlinedInput';


const rlimit = 1
var unsubscribe = null
var renderUsers = []

class UsersList extends Component{

	
	constructor(props){
		
		super(props);

		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		snackbar: false,
    		snackbarMessage: '',
    		isLoaded: true,
			users: [],
			openActionMenu: false,
			clientsCount: 0,
			freelancerCount: 0,
			dialogReason: false,
			identityVerifiedDialog: false,
			actionStatus:'',
			actionID: [],
			reasonText: '',
			notificationTitle: '',
			pendings: [],
			selectedStat : "PENDING",
			clientCount: 0,
			searchstring: '',
			identityverificationstatus: 'PENDING',
			selectedType: ""
		};
	
	}	

	handleChange = (event, value) => {
	    this.setState({ submenu: value });
	};

	
	componentDidMount() {
		
		renderUsers = []
		this.changeDisplay(this.state.selectedStat);
		this.getStats()
	}

	submitAction(){
		let that = this
		this.setState({dialogReason:false})

		const userRef = this.props.doc(this.props.db, "users", this.state.actionID.id);

		this.props.updateDoc(userRef, {
	        statusReason: that.state.reasonText,
	        status: that.state.actionStatus
	    }).then( function(){});

	}

	async submitActionNotify(){

		let that = this
		that.props.setLoading(true)
		this.setState({dialogReason:false})
		

		if( _.has(that.state.actionID,'referer') && that.state.actionID.referer!==null && that.state.actionID.referer!==""&&this.state.actionID.status==="PENDING")
		{

		
			const refererRef = this.props.doc(this.props.db, "users", that.state.actionID.referer );
			const refererSnap = await this.props.getDoc(refererRef);

			if (refererSnap.exists())
			{
				let refererdata = refererSnap.data()
				let jpc = refererdata.jpc
				let refererid = that.state.actionID.referer
				jpc = jpc + 50

				this.props.updateDoc(refererRef, {
			        jpc: jpc
			    }).then( function()
			    {
			    	
			    	fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
					{
					    headers: {
					      'Accept': 'application/json',
					      'Content-Type': 'application/json'
					    },
					    method: "POST",
					    body: JSON.stringify({title: "Signup Link Reward", name:refererdata.fname+" "+refererdata.lname, to: refererdata.email, message:MESSAGES.REFERER})
					})
					.then(function(res){ return res.json() })
					.then(function(data){
						that.setState({snackbar:true,snackbarMessage:'Signup Notification sent!'})
					})
					.catch(function(res){ 
						console.log('error',res)
						that.setState({snackbar:true,snackbarMessage:'Found error while sending notification.'})
						that.props.setLoading(false)
					})

			    });
			}
         					
			
		}

		const userRef = this.props.doc(this.props.db, "users", that.state.actionID.id );
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
			let userdata = userSnap.data()
			let userjpc = Number(userdata.jpc) + 50

			this.props.updateDoc(userRef, {
		        'jpc': userjpc, 
		        'statusReason': this.state.reasonText,
		        'status':this.state.actionStatus
		    }).then( function(){
		        
		        fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
				{
				    headers: {
				      'Accept': 'application/json',
				      'Content-Type': 'application/json'
				    },
				    method: "POST",
				    body: JSON.stringify({title: that.state.notificationTitle, name: that.state.actionID.fname, to: that.state.actionID.email, message: that.state.reasonText})
				})
				.then(function(res){ return res.json() })
				.then(function(data){
					that.setState({snackbar:true,snackbarMessage:'Notification sent!'})

					let type;

					if(that.state.actionStatus==="APPROVED")
						type="PROFILE_APPROVE"
					else if(that.state.actionStatus==="DISAPPROVED")
						type ="PROFILE_DISAPPROVE"
					else if(that.state.actionStatus==="SUSPEND")
						type = "PROFILE_SUSPEND"

					//that.createNotification(type, that.state.actionID.id)

					that.props.setLoading(false)
				})
				.catch(function(res){ 
					console.log('error',res)
					that.setState({snackbar:true,snackbarMessage:'Found error while sending notification.'})
					that.props.setLoading(false)
				})

		    });
		}
		
		
	}

	handleReasonText = event => {
		this.setState({reasonText: event.target.value})
	}


	async getStats(){

		let that = this

		const querySnapshot = await this.props.getDocs(this.props.collection(this.props.db, "stats"));
        
        querySnapshot.forEach((doc) => {
          
            let stat = doc.data()
            that.props.setClientCount(stat.client_count)
            that.props.setFreelancerCount(stat.freelancer_count)
		
            that.setState({isLoaded:true, freelancerCount: stat.freelancer_count, clientCount: stat.client_count })

        });

	}

	async changeType(type)
	{
		let that = this
		renderUsers = []

		const q = this.props.query(this.props.collection( this.props.db, "users"), this.props.where("preference", "==",type), this.props.orderBy('created_at','desc'), this.props.limit(rlimit));

		const querySnapshot = await this.props.getDocs(q);
		querySnapshot.forEach((doc) => {
		  
		  	let id = doc.id
			let user = doc.data()
			user.id = id
			let status = user.status
			let type = 'Freelancer'

			if(user.preference==="admin")
				type = "Admin"
			else if(user.preference=="jobs")
				type = "Freelancer"
			else if(user.preference=="freelancers")
				type = "Client"
			
			renderUsers.push(user)
		  	
		});

		that.setState({isLoaded: true, users: renderUsers, selectedType:type})
	}

	async changeDisplay(stat){

		let that = this
		let users = []
		renderUsers = []
		const usersRef = this.props.collection(this.props.db, "users");
		let q = null

		if(stat==="NEW")
		{
			q = this.props.query(usersRef, this.props.where("status", "not-in", ['PENDING','APPROVED','SUSPEND','DISAPPROVED'] ), this.props.orderBy('status','desc'), this.props.orderBy('created_at','desc'), this.props.limit(rlimit) );
			
			if(unsubscribe!=null)
				unsubscribe()

			unsubscribe = this.props.onSnapshot(q,(querySnapshot) => {
			  
			  renderUsers = []

			  querySnapshot.forEach((doc) => {
			    	
			    	let id = doc.id
					let user = doc.data()
					user.id = id
					let status = user.status
					let type = 'Freelancer'

					if(user.preference==="admin")
						type = "Admin"
					else if(user.preference=="jobs")
						type = "Freelancer"
					else if(user.preference=="freelancers")
						type = "Client"
					
					renderUsers.push(user)
			 });

			  that.setState({isLoaded: true, users: renderUsers})

			}, (error) => {
			  console.error("Error getting documents: ", error);
			});
	
		}else if(stat==="IDENTITYVERIFY")
		{

			q = this.props.query(usersRef, this.props.where("verification_review", "in", ['PENDING'] ), this.props.orderBy('status','desc'), this.props.orderBy('created_at','desc'), this.props.limit(rlimit) );
			
			if(unsubscribe!=null)
				unsubscribe()

			unsubscribe = this.props.onSnapshot(q,(querySnapshot) => {
			  
			  renderUsers = []

			  querySnapshot.forEach((doc) => {
			    	
			    let id = doc.id
				let user = doc.data()
				user.id = id
				let status = user.status
				let type = 'Freelancer'

				if(user.preference==="admin")
					type = "Admin"
				else if(user.preference=="jobs")
					type = "Freelancer"
				else if(user.preference=="freelancers")
					type = "Client"
				
				renderUsers.push(user)

			  });

			  that.setState({isLoaded: true, users: renderUsers})

			}, (error) => {
			  console.error("Error getting documents: ", error);
			});


		}else{

			q = this.props.query(usersRef, this.props.where("status", "==", stat ), this.props.limit(rlimit) );
			
			
			if(unsubscribe!=null)
				unsubscribe()

			unsubscribe = this.props.onSnapshot(q,(querySnapshot) => {
			  
			  renderUsers = []

			  querySnapshot.forEach((doc) => {
			    	
			   		let id = doc.id
					let user = doc.data()
					user.id = id
					let status = user.status
					let type = 'Freelancer'

					if(user.preference==="admin")
						type = "Admin"
					else if(user.preference=="jobs")
						type = "Freelancer"
					else if(user.preference=="freelancers")
						type = "Client"
	
					renderUsers.push(user)

			  });

			  that.setState({isLoaded: true, users: renderUsers})

			}, (error) => {
			  console.error("Error getting documents: ", error);
			});
			
		}
		

		
	}

	async searchUser(){

		let renderUsers = []
		let that = this

		const q = this.props.query(this.props.collection( this.props.db, "users"), this.props.where("fname", "==", this.state.searchstring) );

		const querySnapshot = await this.props.getDocs(q);
		querySnapshot.forEach((doc) => {
		  
		  	let id = doc.id
			let user = doc.data()
			user.id = id
			let status = user.status
			let type = 'Freelancer'

			if(user.preference==="admin")
				type = "Admin"
			else if(user.preference=="jobs")
				type = "Freelancer"
			else if(user.preference=="freelancers")
				type = "Client"
			
			renderUsers.push(user)
  	
		});

		
		that.setState({isLoaded: true, users: renderUsers})
		
	}

	/*

	//create notification add this later

	createNotification = (type,to) => {

		let message  =''
		let link = ''
		let iconname = ''
		if(type==="IDENTITYVERIFY_APPROVED")
		{
			message = "Identity verification was approved"
			link = "/profile"
			iconname = "security"
		}else if(type==="IDENTITYVERIFY_DECLINED")
		{
			message = "Identity verification was declined"
			link = "/profile"
			iconname = "security"
		}else if(type==="PROFILE_APPROVE")
		{
			message = "Your profile was approved"
			link = "/profile"
			iconname = "face"
		}else if(type==="PROFILE_DISAPPROVE")
		{
			message = "Your profile was declined"
			link = "/profile"
			iconname = "face"
		}else if(type==="PROFILE_SUSPEND")
		{
			message = "Your profile was suspended"
			link = "/profile"
			iconname = "security"
		}

		this.props.firebase.firestore().collection("notifications").doc().set({
						to: to,
						icon: iconname,
						type: type,
						unread: true,
						link: link,
						message: message,
						created_at: new Date()
					})
					.then(function(){
						
					})
					.catch(function(error){
						console.log('error: ',error);
					});
				

	} */

	async submitIdentityVerification(){

		
		let that = this
		that.props.setLoading(true)
		this.setState({identityVerifiedDialog:false})

		const userRef = this.props.doc(this.props.db, "users", this.state.actionID.id);
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
			this.props.updateDoc(userRef, {
		        verification_review:  that.state.identityverificationstatus
		    }).then( function(){
		        
		    	fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
				{
				    headers: {
				      'Accept': 'application/json',
				      'Content-Type': 'application/json'
				    },
				    method: "POST",
				    body: JSON.stringify({title: 'Identity Verification', name: that.state.actionID.fname, to: that.state.actionID.email, message: that.state.reasonText})
				})
				.then(function(res){ return res.json() })
				.then(function(data){
					that.setState({snackbar:true,snackbarMessage:'Notification sent!'})
					that.props.setLoading(false)

					if(that.state.identityverificationstatus==="APPROVED")
					{
						let imageRef = that.props.firebase.storage().refFromURL(that.state.actionID.verification_1)
				
						imageRef.delete().then(() => {
						    console.log("Deleted")
						}).catch(err => console.log(err))

						imageRef = that.props.firebase.storage().refFromURL(that.state.actionID.verification_2)
							imageRef.delete().then(() => {
						    console.log("Deleted")
						}).catch(err => console.log(err))
					}
					

				})
				.catch(function(res){ 
					console.log('error',res)
					that.setState({snackbar:true,snackbarMessage:'Found error while sending notification.'})
					that.props.setLoading(false)
				})

		    });
		}

	}

	identitystatus = (event) =>{
	
		if(event.target.value==="APPROVED")
		{
			this.setState({reasonText: MESSAGES.IDENTITY_VERIFICATION_APPROVED})
		}else{
			this.setState({reasonText: MESSAGES.IDENTITY_VERIFICATION_DECLINED})
		}
		this.setState({identityverificationstatus: event.target.value})
	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	jsUcfirst = (string) =>
	{
	    let str = string.charAt(0).toUpperCase() + string.slice(1);
	    return str
	}

	handleChangePage = (event, newPage) => {
    }

    handleChangeRowsPerPage = event => {

    }
	

	render(){
	
		
		let that = this
		
		return (

			<div>
			
				<header>
				  { this.state.isLoaded === true &&
			          <div style={{marginTop: 100}}>
				          <Grid container spacing={2} >
				          		<Grid item  xs={12} md={2}></Grid>
				          		<Grid item  xs={12} md={8}>

				          		 <h1 className="App-title">Users</h1>
				          		 
				          		 <br/>
				          		 

						         <div>
						         		
					         			<Grid container spacing={2} >
					         				<Grid item  xs={4} md={4} lg={4}><Paper style={{textAlign:'center',padding: 3}}><PeopleIcon fontSize="large" /><h2 style={{textAlign:'center'}}>All: {this.props.freelancerCount + this.props.clientCount }</h2></Paper></Grid >
					         				<Grid item  xs={4} md={4} lg={4}><Paper style={{textAlign:'center',padding: 3}}><FaceIcon fontSize="large" /><h2 style={{textAlign:'center'}}>Freelancer: { this.props.freelancerCount }</h2></Paper></Grid >
					         				<Grid item  xs={4} md={4} lg={4}><Paper style={{textAlign:'center',padding: 3}}><PermContactCalendarIcon fontSize="large" /><h2 style={{textAlign:'center'}}>Client: { this.props.clientCount }</h2></Paper></Grid >
					         			</Grid >
						         		
						         		
						         		<br/>
						         		<Grid container spacing={2} >
					         				<Grid item md={4}  xs={4}>
					         					<Typography>Status</Typography>
					         					<br/>
					         					<Select
										          value={this.state.selectedStat}
										          onChange={(event)=> { 
										          	this.setState({selectedStat: event.target.value})
										          	this.changeDisplay(event.target.value) } }
										          style={{width: '100%'}}
										        >
										          <MenuItem value="PENDING">PENDING</MenuItem>
										          <MenuItem value="APPROVED">APPROVED</MenuItem>
										          <MenuItem value="SUSPEND">SUSPEND</MenuItem>
										          <MenuItem value="DISAPPROVED">DISAPPROVED</MenuItem>
										          <MenuItem value="IDENTITYVERIFY">IDENTITY VERIFICATION</MenuItem>

										        </Select>
					         				</Grid >
					         				<Grid item md={4}  xs={4}>
					         					<Typography>Search By First Name</Typography>
					         					
					         					<FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
          										<OutlinedInput
           											style={{width:'100%', marginTop:'14px'}}
					         					 	type={'text'}
									            	value={this.state.searchstring}
									            	onChange={ (event)=> this.setState({searchstring:event.target.value}) }
									            	endAdornment={
										              <InputAdornment position="end">
										                <IconButton
										                  aria-label="toggle password visibility"
										                  onClick={ ()=> this.searchUser() }
										                  edge="end"
										                >
										                  <SearchIcon />
										                </IconButton>
										              </InputAdornment>
									            	}	/>
									            </FormControl>
					         				</Grid >
					         				<Grid item md={4} xs={4}>
					         					<Typography>Search By Type</Typography>
					         					<br/>
					         					<Select
										          value={this.state.selectedType}
										          onChange={(event)=> this.changeType(event.target.value) }
										          style={{width: '100%'}}
										        >
										          <MenuItem value="freelancers">Client</MenuItem>
										          <MenuItem value="jobs">Freelancer</MenuItem>
										          <MenuItem value="admin">Admin</MenuItem>
										        </Select>
					         				</Grid >
						         		</Grid >
						         		<br/>
						         		
						         		<TableContainer component={Paper}>
						         			<Table>
						         				<TableHead>
						         					<TableRow>
											            <TableCell>Status</TableCell>
											            <TableCell align="center"> </TableCell>
											            <TableCell align="center">Name</TableCell>
											            <TableCell align="center">Email</TableCell>
											            <TableCell align="center">Type</TableCell>
											            <TableCell align="center">Actions</TableCell>
											         </TableRow>
						         				</TableHead>
						         				 <TableBody>
										         	{
										         		this.state.users.map((row)=>(
										         			<TableRow
													              key={row.id}
													              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
													            >
													             <TableCell component="th" scope="row" >{row.status}</TableCell>
													             <TableCell align="right"><Avatar alt="{row.fname} {row.lname}" src={ _.has(row,"profile") ? row.profile.avatar : "" } /></TableCell>
													             <TableCell align="center">{row.fname} {row.lname}</TableCell>
													             <TableCell align="center">{row.email}</TableCell>
													             <TableCell align="center">{row.preference==="jobs" ? "Freelancer" : "Client" }</TableCell>
													             <TableCell align="center">

													             <Tooltip title="Identity Verification">
														             <IconButton onClick={()=>{
														             	
														             	that.setState({ notificationTitle: 'Identity Verification', identityVerifiedDialog:true, actionID:row})
						         					
														             }}>
																		  <VerifiedUserIcon />
																	 </IconButton>
																 </Tooltip>

													             <Tooltip title="Approve Profile">
														             <IconButton onClick={()=>{
														             	
														             	if(that.state.dialogReason===false)
						         											that.setState({ reasonText: MESSAGES.PROFILE_APPROVE, notificationTitle: 'Profile Approved', dialogReason:true, actionStatus: 'APPROVED', actionID:row})
						         						
														             }}>
																		  <Check />
																	 </IconButton>
																 </Tooltip>

													             <Tooltip title="Disapprove Profile">
														             <IconButton onClick={()=>{
														             	
														             	if(that.state.dialogReason===false)
						         											that.setState({ reasonText: MESSAGES.PROFILE_DISAPPROVE,notificationTitle: 'Profile Declined', dialogReason:true, actionStatus: 'DISAPPROVED', actionID:row})
						         						
														             }}>
																		  <Remove />
																	 </IconButton>
																 </Tooltip>

													             <Tooltip title="Suspend Profile">
														             <IconButton onClick={()=>{
														             	
														             if(that.state.dialogReason===false)
						         										that.setState({ reasonText: MESSAGES.PROFILE_SUSPEND, notificationTitle: 'Profile Suspended', dialogReason:true, actionStatus: 'SUSPEND', actionID:row})
						         					

														             }}>
																		  <Warning />
																	 </IconButton>
																 </Tooltip>

													             <Tooltip title="View Profile">
														             <IconButton onClick={()=>{
														             	if(row.preference==="jobs")
									         								window.open("/freelancer/"+row.id, "_blank")
									         							else
									         								window.open("/client/"+row.id, "_blank")
														             }}>
																		  <PeopleIcon />
																	 </IconButton>
																 </Tooltip>
													             </TableCell>
													       	</TableRow>
										         		))
										         	}
										         </TableBody>
						         			</Table>
						         		</TableContainer>

				          		 </div>
				          		
				          		
				          		</Grid >
				          		<Grid item  xs={12} md={2}></Grid>
				          		
				          	</Grid >
				      </div>
			  	  }

			      { this.state.isLoaded === false &&
	                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
	                        <CircularProgress style={{color:'#212121', alignSelf:'center'}} />
	                    </div>
	              }
		        </header>

		        <Snackbar
			        anchorOrigin={{
			          vertical: 'bottom',
			          horizontal: 'right',
			        }}
			        open={this.state.snackbar}
			        autoHideDuration={1000}
			        onClose={ ()=>this.setState({snackbar:false}) }
			        message={this.state.snackbarMessage} />

			    
		        <Dialog
			        open={this.state.dialogReason}
			        onClose={ ()=>this.setState({dialogReason:false})}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			        fullWidth={true}
			      >
			        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
			        <DialogContent>
			          	
		          		<TextField
		          		fullWidth={true}
      					variant="outlined"
				      	margin="normal" value={this.state.reasonText} 
				      	onChange={ (event)=>this.handleReasonText(event) } />

			        </DialogContent>
			        <DialogActions>
			          <Button onClick={ ()=> this.submitAction() } color="primary">
			            Submit
			          </Button>
			          <Button onClick={ (event)=> this.submitActionNotify(event) } color="primary" autoFocus>
			            Submit and Notify User
			          </Button>
			        </DialogActions>
			      </Dialog>


			    <Dialog
			        open={this.state.identityVerifiedDialog}
			        onClose={ ()=>this.setState({identityVerifiedDialog:false})}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			        fullWidth={true}
			      >
			        <DialogTitle id="alert-dialog-title">{"Identity Verification"}</DialogTitle>
			        <DialogContent>

			        	{ _.has( this.state.actionID,'verification_1' ) && this.state.actionID.verification_review != 'APPROVED' &&
			        		<img style={{width: '49%', height: 'auto'}} src={ this.state.actionID.verification_1 } />
			        	}

			        	{ _.has( this.state.actionID,'verification_2' ) && this.state.actionID.verification_review != 'APPROVED' &&
			        		<img style={{width: '49%', height: 'auto'}} src={ this.state.actionID.verification_2 } />
			        	}

			        	<br/><br/>

			        	{ this.state.actionID.verification_review != 'APPROVED' &&
				        	<FormControl component="fieldset">
						      <FormLabel component="legend">Identity Verification Status</FormLabel>
						      <br/><br/>
						      <RadioGroup aria-label="gender" name="identitystatus" value={this.state.identityverificationstatus} onChange={this.identitystatus}>
						        <FormControlLabel value="PENDING" control={<Radio />} label="PENDING" />
						        <FormControlLabel value="APPROVED" control={<Radio />} label="APPROVED" />
						      </RadioGroup>
						    </FormControl>
						}

						{  this.state.actionID.verification_review === 'APPROVED' &&
							<Alert severity="success">
          		 				<Grid container >
          		 					<Grid item  xs={12} md={12}>
          		 						Identity verification is APPROVED
          		 					</Grid >
          		 				</Grid >
          		 			</Alert>
						}
					    <br/><br/>
			          	{ this.state.actionID.verification_review != 'APPROVED' &&
			          		<div>

			          			<Typography>Message</Typography>
				          		<TextField
				          		fullWidth={true}
		      					variant="outlined"
						      	margin="normal" value={this.state.reasonText} 
						      	onChange={ (event)=>this.handleReasonText(event) } />
						    </div>
					     }

			        </DialogContent>
			        <DialogActions>
			        	{ this.state.actionID.verification_review != 'APPROVED' &&
				          <Button  onClick={ ()=> this.submitIdentityVerification() } color="primary">
				            Submit
				          </Button>
				        }

				        { this.state.actionID.verification_review === 'APPROVED' &&
				          <Button  onClick={ ()=> this.setState({identityVerifiedDialog:false}) } color="primary">
				            Close
				          </Button>
				        }
			        </DialogActions>
			      </Dialog>
   
	    	</div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		clientCount: state.clientCount,
		freelancerCount: state.freelancerCount,
		collection: state.collection,
		db: state.db,
		where: state.where,
		limit: state.limit,
		query: state.query,
		onSnapshot: state.onSnapshot,
		orderBy: state.orderBy,
		getDocs: state.getDocs,
		doc: state.doc,
		updateDoc: state.updateDoc,
		getDoc: state.getDoc
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setClientCount: (count) => dispatch({type: "SET_CLIENT_COUNT", payload: {clientCount: count}}),
		setFreelancerCount: (count) => dispatch({type: "SET_FREELANCER_COUNT", payload: {freelancerCount: count}}),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(UsersList);





