import React from "react";
import ReactDOM from "react-dom";
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

import * as firebaseconfig from './firebase'; 
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const CLIENT_ID =firebaseconfig.PAYPAL.credits
//create button here
let PayPalButton = null;

// next create the class and Bind React and ReactDom to window
//as we will be needing them later

 const paypalOptions = {
    'client-id': CLIENT_ID, // Replace with your PayPal Client ID
    currency: 'USD', // Specify the currency
  };

class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paypalloading:false,
      showButtons: false,
      loading: true,
      paid: false,
      processing:false,
      selectedPackage: 0,
      processing: false,
      creditPackages: [
      {value: 0, amount: 5, credits: 15},
      {value: 1, amount: 10, credits: 35},
      {value: 2, amount: 15, credits: 60}
      ],
      jpc: 0,
      creditPackagesFreelancer: [
      {value: 0, amount: 5, credits: 50},
      {value: 1, amount: 10, credits: 110},
      {value: 2, amount: 15, credits: 170}
      ]
  
    };

  }
  
  componentDidMount()
  {
       this.setState({ loading: false, showButtons: true, jpc: this.props.user.jpc, user: this.props.user.uid });
  }

   handlePayPalButtonLoad = () => {
    // This function will be called when the PayPal button is fully loaded.
    // You can perform any actions you need here.

    this.setState({paypalloading:false})
    console.log('PayPal button is fully loaded.');
  }

  createOrder = (data, actions) => 
  {
      let that = this
      
      return actions.order.create(
      {
        purchase_units: [
          {
            description: +""+that.state.creditPackages[that.state.selectedPackage].credits+" Credits",
            amount: {
              currency_code: "USD",
              value: this.computeCheckoutAmount(that.state.creditPackages[that.state.selectedPackage].amount)
            }
          }
        ]
      });
  };


   onApprove =  (data, actions) => {

    let that = this
    this.setState({processing: true})

    actions.order.capture().then(details => {
      const paymentData = {
        payerID: data.payerID,
        orderID: data.orderID
      };
      
      let jpc = this.props.jpc < 0 ? 0 : this.props.jpc
      let user = this.props.user.uid
      let newjpc
      let addjpc

      if( this.props.user.preference=="freelancers" )
          addjpc = this.state.creditPackages[this.state.selectedPackage].credits
      else
          addjpc = this.state.creditPackagesFreelancer[this.state.selectedPackage].credits

      newjpc = jpc + addjpc
    
      this.props.updateJPC(newjpc)
     
      const usersRef = this.props.doc(this.props.db, "users", user);

      this.props.updateDoc(usersRef, {
        jpc: newjpc
      }).then( function(){
        that.setState({ processing:false, showButtons: false, paid: true });
      } );

      
    });
  };

  computePaypalFee(){
      let amount =  Number(this.state.creditPackages[this.state.selectedPackage].amount)
      let paypal_fee = Math.ceil( ( amount *  0.0349 ) + 0.49 )

      return Number( Number(paypal_fee).toFixed(2) )
  }

  computeCheckoutAmount()
  {  
     let amount =  Number(this.state.creditPackages[this.state.selectedPackage].amount)
     return Number( Number( amount + this.computePaypalFee() ).toFixed(2) );
  }

 render() {
    const { showButtons, loading, paid, processing } = this.state;

    return (
      <div className="main">
       
        {loading && <center><CircularProgress style={{color:"#00b16a"}} /></center>}
        {processing && <center>
                          <CircularProgress style={{color:"#00b16a"}} />
                          <Typography>Please wait while we are processing your order.</Typography>
                          <br/><br/>
                       </center>}
        {showButtons && (
          <div>
            { this.props.user.preference == "freelancers" && 
              <Typography>Buy credits to start posting a job. Select from our list of credit packages.</Typography>
            }

            { this.props.user.preference == "jobs" && 
              <Typography>Buy credits to start applying to job posts. Select from our list of credit packages.</Typography>
            }
            <br/><br/>

            { this.props.user.preference=="freelancers" &&
              <Select
                      onChange={ (event) => this.setState({selectedPackage: event.target.value }) }
                      value={this.state.selectedPackage}
                      inputProps={{
                         name: 'preference',
                      }} fullWidth={true} >

                      { 
                        this.state.creditPackages.map(function(item,i){
                         return <MenuItem value={item.value} key={i}>
                              <em>{item.credits} Credits for ${item.amount} </em>
                           </MenuItem>
                      })

                    }
              </Select>
            }

            { this.props.user.preference=="jobs" &&
              <Select
                      onChange={ (event) => this.setState({selectedPackage: event.target.value }) }
                      value={this.state.selectedPackage}
                      inputProps={{
                         name: 'preference',
                      }} fullWidth={true} >

                      { 
                        this.state.creditPackagesFreelancer.map(function(item,i){
                         return <MenuItem value={item.value} key={i}>
                              <em>{item.credits} Credits for ${item.amount} </em>
                           </MenuItem>
                      })

                    }
              </Select>
            }

            <br/><br/>


            <div>
              { this.props.user.preference=="freelancers" &&
                 <p>Items: { this.state.creditPackages[this.state.selectedPackage].credits } Credits</p>
              }

              { this.props.user.preference=="jobs" &&
                 <p>Items: { this.state.creditPackagesFreelancer[this.state.selectedPackage].credits } Credits</p>
              }

              <p>Paypal Fee: ${ Number( this.computePaypalFee() ).toLocaleString(undefined, {minimumFractionDigits: 2}) }</p>

              { this.props.user.preference=="freelancers" &&
                <p>Total checkout Amount: ${ Number( this.computeCheckoutAmount() ).toLocaleString(undefined, {minimumFractionDigits: 2}) }</p>
              }

              { this.props.user.preference=="jobs" &&
                 <p>Total checkout Amount: ${ Number( this.computeCheckoutAmount() ).toLocaleString(undefined, {minimumFractionDigits: 2}) }</p>
              }
            </div>

            <br/><br/>

             <PayPalScriptProvider options={paypalOptions}>
                <PayPalButtons
                  style={{ layout: 'horizontal' }} // Adjust the button layout as needed
                  createOrder={ this.createOrder }
                  onApprove={ this.onApprove }
                  onClose={ ()=>{} }
                  onInit={ ()=>this.setState({paypalloading:true}) }
                   />
             
              </PayPalScriptProvider>

              { this.state.paypalloading===false &&
                  <center>
                    <small>
                      <Typography style={{fontSize:'14px'}}>Loading Paypal...</Typography>
                    </small>
                  </center>
              }

          </div>
        )}

        {paid && (
          <div className="main">
            <center>
              <p>
                <img alt="verified" src="/verified.png" /> <br/>
                Thank you for your purchase. Additional credits are already added to your account.     
              </p>
            </center>
          </div>
        )}
      </div>
    );
  }
 }
 
export default PaypalButton