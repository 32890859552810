import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import { connect } from 'react-redux'
import _ from 'lodash'
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import TimerIcon from '@mui/icons-material/Timer';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import SearchIcon from '@mui/icons-material/Search';
import PeopleIcon from '@mui/icons-material/People'
import { forwardRef } from 'react';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import Warning from '@mui/icons-material/Warning';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment'

import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import OutlinedInput from '@mui/material/OutlinedInput';
import Avatar from '@mui/material/Avatar';


const rlimit = 1
var unsubscribe = null

class AllJobList extends Component{

	
	constructor(props){
		
		super(props);

		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		snackbar: false,
    		snackbarMessage: '',
    		isLoaded: true,
			jobs: [],
			openActionMenu: false,
			clientsCount: 0,
			freelancerCount: 0,
			dialogReason: false,
			actionStatus:'',
			actionID: [],
			reasonText: '',
			activeCount: 0,
			inactiveCount: 0,
			pendingCount: 0,
			details: false,
			users: [],
			notificationTitle: '',
			selectedStat:"PENDING",
			searchstring: "",
			jobs_count: 0
		};
	
	}	

	handleChange = (event, value) => {
	    this.setState({ submenu: value });
	};

	componentDidMount() {
		
		this.changeDisplay(this.state.selectedStat)
		this.getStats()
		
	}

	async getStats(){

		let that = this

		const querySnapshot = await this.props.getDocs(this.props.collection(this.props.db, "stats"));
        
        querySnapshot.forEach((doc) => {
          
            let stat = doc.data()
            that.setState({jobs_count: that.state.jobs_count})

        });

	}

	async submitAction(){

		this.setState({dialogReason:false})
		const jobsRef = this.props.doc(this.props.db, "jobs", this.state.actionID.id);
		const jobsSnap = await this.props.getDoc(jobsRef);

		if (jobsSnap.exists())
		{
			this.props.updateDoc(jobsRef, {
		        statusReason: this.state.reasonText,
		        status:this.state.actionStatus
		    }).then( function(){
		       
		    });
		}
					         					
	}

	async submitActionNotify(){

		this.props.setLoading(true);
		let that = this
		this.setState({dialogReason:false})

		const userRef = this.props.doc(this.props.db, "users", this.state.actionID.user);
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{

			let id = userSnap.id
			let username = userSnap.data()
			let jpc = username.jpc
			let jpcconsume = that.state.selectedStat === "UPDATE" ? 0 : 3

		
			if(jpc>3)
			{	

				this.props.updateDoc(userRef, {
			        jpc: jpc-jpcconsume
			    }).then( function(){
			      
			    });

			    const jobRef = this.props.doc(this.props.db, "jobs", this.state.actionID.id);

				this.props.updateDoc(jobRef, {
			        statusReason: this.state.reasonText,
			        status:this.state.actionStatus
			    }).then( function(){
			      	
			    	fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
					{
					    headers: {
					      'Accept': 'application/json',
					      'Content-Type': 'application/json'
					    },
					    method: "POST",
					    body: JSON.stringify({ title: that.state.notificationTitle, name: username.fname, to: username.email, message: that.state.reasonText})
					})
					.then(function(res){ return res.json() })
					.then(function(data){
					
						that.setState({snackbar:true,snackbarMessage:'Notification sent!'})
						that.props.setLoading(false);
					})
					.catch(function(res){ 
						that.setState({snackbar:true,snackbarMessage: "Found error while sending notification." })
						that.props.setLoading(false);
						console.log('error',res)
					 })

			    });

			}else{
				that.setState({snackbarMessage:"Not enough credits",snackbar:true})
				this.props.setLoading(false);
			}
		}

		
	}

	handleReasonText = event => {
		this.setState({reasonText: event.target.value})
	}

 
	async getPendingCount(){

		let that = this

		const jobsRef = this.props.collection(this.props.db, "jobs");
		const q = this.props.query(jobsRef, this.props.where("status", "==", "PENDING"));
		const querySnapshot = await this.props.getDocs(q);

		that.props.setPendingCount(querySnapshot.size)
		that.setState({ isLoaded: true })

	}

	async getInactiveCount(){

		let that = this

		const jobsRef = this.props.collection(this.props.db, "jobs");
		const q = this.props.query(jobsRef, this.props.where("status", "==", "INACTIVE"));
		const querySnapshot = await this.props.getDocs(q);

		that.props.setPendingCount(querySnapshot.size)
		that.setState({ isLoaded: true })

	}

	async searchJob(){

		let that = this
		let renderJobs = []

		if(this.state.searchstring!=="")
		{

			const jobsRef = this.props.doc(this.props.db, "jobs", this.state.searchstring );
			const jobsSnap = await this.props.getDoc(jobsRef);

			if (jobsSnap.exists())
			{
				let id = jobsSnap.id
				let job = jobsSnap.data()
				job.id = id
				
				renderJobs.push(job)
				that.setState({ isLoaded: true, jobs : renderJobs })
			}

		}else{
			that.setState({snackbarMessage:"Search ID is empty", snackbar: true})
		}
		
	}


	
	async changeDisplay(stat){

		let that = this
		const jobsRef = this.props.collection(this.props.db, "jobs");
		let q = null
		let renderJobs = []


		if(stat==="OLDEST")
		{
			q = this.props.query(jobsRef, this.props.where("status", "!=", "EXPIRED" ), this.props.orderBy('status','asc'), this.props.orderBy('created_at','desc'), this.props.limit(rlimit) );
			
			if(unsubscribe!=null)
				unsubscribe()

			unsubscribe = this.props.onSnapshot(q,(querySnapshot) => {
			  
			  renderJobs = []

			  querySnapshot.forEach((doc) => {
			    	
			    let id = doc.id
				let job = doc.data()
				job.id = id
				
				renderJobs.push(job)

			 });

			 that.setState({ isLoaded: true, jobs : renderJobs, selectedStat: stat })

			}, (error) => {
			  console.error("Error getting documents: ", error);
			});

		}else{

			q = this.props.query(jobsRef, this.props.where("status", "==", stat ), this.props.limit(rlimit) );
			
			if(unsubscribe!=null)
				unsubscribe()

			unsubscribe = this.props.onSnapshot(q,(querySnapshot) => {
			  
				  renderJobs = []

				  querySnapshot.forEach((doc) => {
				    	
				    let id = doc.id
					let job = doc.data()
					job.id = id
					
					renderJobs.push(job)

				 });

				 that.setState({ isLoaded: true, jobs : renderJobs, selectedStat: stat })

			}, (error) => {
			  console.error("Error getting documents: ", error);
			});


		}
		

		
	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	jsUcfirst = (string) =>
	{
	    let str = string.charAt(0).toUpperCase() + string.slice(1);
	    return str
	}

	handleChangePage = (event, newPage) => {
    }

    handleChangeRowsPerPage = event => {

    }

    renderExpire(expires_at){

    	let now = moment()
		let expires = moment( new Date(expires_at.seconds * 1000) )
		let expires_in = expires.diff(now,'days')

		let output = expires_in > 0 ? "Expires in "+expires_in+" days" : "Expired"
    	return  output
    }


    async setExpired(rowData){

    	let that = this
    	let now = moment()
		let expires = moment( new Date(rowData.expires_at.seconds * 1000) )
		let expires_in = expires.diff(now,'days')

		this.props.setLoading(true)
		if(expires_in>0)
		{
			this.setState({snackbar:true, snackbarMessage:"Job has not reached its expiration."})
			that.props.setLoading(false)
		}else{

			const jobsRef = this.props.doc(this.props.db, "jobs",  rowData.id );
			const jobsSnap = await this.props.getDoc(jobsRef);

			this.props.updateDoc(jobsRef, {
		        status: "EXPIRED"
		    }).then( function(){
		        that.setState({snackbar:true, snackbarMessage:"Job set as expired."})
				that.props.setLoading(false)
		    });
		}

    }
	
	render(){
	
		
		let that = this
		

		return (

			<div>
			
				<header>
				  { this.state.isLoaded === true &&
			          <div style={{marginTop: 100}}>
				          
				          <Grid container spacing={2}>
				            <Grid item xs={12} md={2}></Grid>
				          		<Grid item xs={12} md={8}>

				          		 <h1 className="App-title">Jobs</h1>
				          		 
				          		 <br/>
				          		 

						         <div>
						         		<Grid container spacing={2}>
						         				<Grid item xs={3} md={3} lg={3}><Paper style={{textAlign:'center',padding: 3}}><WorkOutlineIcon fontSize="large" /><h2 style={{textAlign:'center'}}>All: { this.state.jobs_count }</h2></Paper></Grid>
						         		</Grid>
						         		<br/>

						         		<br/>
						         		<Grid container>
					         				<Grid item xs={6}>
					         					<Typography>Status</Typography>
					         					<br/>
					         					<Select
										          value={this.state.selectedStat}
										          onChange={(event)=> this.changeDisplay(event.target.value) }
										          style={{width: '100%'}}
										        >
										          <MenuItem value="ACTIVE">ACTIVE</MenuItem>
										          <MenuItem value="INACTIVE">INACTIVE</MenuItem>
										          <MenuItem value="PENDING">PENDING</MenuItem>
										          <MenuItem value="OLDEST">OLDEST</MenuItem>
										          <MenuItem value="UPDATE">UPDATE</MenuItem>
										          <MenuItem value="EXPIRED">EXPIRED</MenuItem>
										        </Select>
					         				</Grid>
					         				<Grid item xs={6}>
					         					<Typography>Search By ID</Typography>
					         					
					         					<FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
	          										<OutlinedInput
	           											style={{width:'100%',  marginTop:'14px'}}
											            type={'text'}
											            value={this.state.searchstring}
											            onChange={ (event)=> this.setState({searchstring:event.target.value}) }
											            endAdornment={
											              <InputAdornment position="end">
											                <IconButton
											                  aria-label="toggle password visibility"
											                  onClick={ ()=> this.searchJob() }
											                  edge="end"
											                >
											                  <SearchIcon />
											                </IconButton>
											              </InputAdornment>
										            	}	/>
									            </FormControl>

					         				</Grid>
						         		</Grid>
						         		<br/>

						         		<TableContainer component={Paper}>
						         			<Table>
						         				<TableHead>
						         					<TableRow>
											            <TableCell>Job Title</TableCell>
											            <TableCell align="center">Status</TableCell>
											            <TableCell align="center">Job Type</TableCell>
											            <TableCell align="center">Budget</TableCell>
											            <TableCell align="center">Expires</TableCell>
											            <TableCell align="center">Actions</TableCell>
											         </TableRow>
						         				</TableHead>
						         				<TableBody>
						         					{
						         						this.state.jobs.map((row)=>(
						         							<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
													             <TableCell component="th" scope="row" >{row.jobPostTitle}</TableCell>
													             <TableCell align="center">{ row.status }</TableCell>
													             <TableCell align="center">{row.jobType}</TableCell>
													             <TableCell align="center">{row.budget}</TableCell>
													             <TableCell align="center">{  that.renderExpire( row.expires_at ) }</TableCell>
													             <TableCell align="center">
														             <Tooltip title="Approve Profile">
															             <IconButton onClick={()=>{
															             	
															             	that.setState({ reasonText: MESSAGES.JOB_APPROVE.replace("{jobtitle}",row.jobPostTitle), notificationTitle: "Job Post was Accepted", dialogReason:true, actionStatus: 'ACTIVE', actionID:row})
															             }}>
																			  <Check />
																		 </IconButton>
																	 </Tooltip>

														             <Tooltip title="Disapprove Profile">
															             <IconButton onClick={()=>{
															             	
															             	that.setState({ reasonText: MESSAGES.JOB_DISAPPROVE.replace("{jobtitle}",row.jobPostTitle) ,notificationTitle: "Job Post was Declined", dialogReason:true, actionStatus: 'INACTIVE', actionID:row})
						         					
															             }}>
																			  <Remove />
																		 </IconButton>
																	 </Tooltip>

														             <Tooltip title="Set Expired">
															             <IconButton onClick={()=>{
															             	
															             	that.setExpired(row)

															             }}>
																			  <Warning />
																		 </IconButton>
																	 </Tooltip>

														             <Tooltip title="View Job">
															             <IconButton onClick={()=>{
															             		that.setState({details:true, jobDetails:row})
															             }}>
																			  <PeopleIcon />
																		 </IconButton>
																	 </Tooltip>
													             </TableCell>
													       	</TableRow>
						         						))
						         					}
						         				</TableBody>
						         			</Table>
						         		</TableContainer>
				          		 </div>
				          		
				          		
				          		</Grid>
			          		<Grid item xs={12} md={2}></Grid>
				          </Grid>

				      </div>
			  	  }

			      { this.state.isLoaded === false &&
	                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
	                        <CircularProgress style={{gridor:'#212121', alignSelf:'center'}} />
	                    </div>
	              }
		        </header>

		        <Snackbar
			        anchorOrigin={{
			          vertical: 'bottom',
			          horizontal: 'right',
			        }}
			        open={this.state.snackbar}
			        autoHideDuration={1000}
			        onClose={ ()=>this.setState({snackbar:false}) }
			        message={this.state.snackbarMessage} />

		        <Dialog
		        	size={"md"}
			        open={this.state.details}
			        onClose={ ()=>this.setState({details:false})}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			        fullWidth={true}
			      >
			        <DialogTitle id="alert-dialog-title">{"Details"}</DialogTitle>
			        <DialogContent>
			          	
				          { _.has(this.state.jobDetails,'jobPostTitle')
				          	&& 
				          	<div> 
				          	
				          		Job Title: 
				          		<Typography>{this.state.jobDetails.id}</Typography>
				          		<br/>
				          		Job Description
				          		<Typography>{this.state.jobDetails.jobDescription}</Typography>
				          		<br/>
				          		Job Type
				          		<Typography>{this.state.jobDetails.jobType}</Typography>
				          		<br/>
				          		Budget
				          		<Typography>${this.state.jobDetails.budget}</Typography>
				          		<br/>
				          		Duration
				          		<Typography>{this.state.jobDetails.estProjectDuration}</Typography>
				          		<br/>
				          		Expertise
				          		<Typography>{this.state.jobDetails.expertise}</Typography>
				          		<br/>
				          		Hours per Week
				          		<Typography>{this.state.jobDetails.hoursPerWeek}</Typography>
				          		<br/>
				          		How to Apply
				          		<Typography>{this.state.jobDetails.howToApply}</Typography>
				          		<br/>
				          		Job Category
				          		<Typography>{this.state.jobDetails.jobCategory}</Typography>
				          		<br/>
				          		Notes
				          		<Typography>{this.state.jobDetails.jobDescription}</Typography>
				          		<br/>
				          		Status
				          		<Typography>{this.state.jobDetails.status}</Typography>
				          		
				          	</div>
			          }

			        </DialogContent>
			        <DialogActions>
			          <Button onClick={ ()=> this.setState({details:false}) } gridor="primary">
			            Close
			          </Button>
			       
			        </DialogActions>
			      </Dialog>

		        <Dialog
			        open={this.state.dialogReason}
			        onClose={ ()=>this.setState({dialogReason:false})}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			        fullWidth={true}
			      >
			        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
			        <DialogContent>
			          	
			          	<TextField
				          label=""
				          multiline
				          rowsMax="4"
				          value={this.state.reasonText}
				          onChange={ (event)=>this.handleReasonText(event) }
				          variant="outlined"
				          fullWidth={true}
				        />

			        </DialogContent>
			        <DialogActions>
			          <Button onClick={ ()=> this.submitAction() } gridor="primary">
			            Submit
			          </Button>
			          <Button onClick={ (event)=> this.submitActionNotify(event) } gridor="primary" autoFocus>
			            Submit and Notify User
			          </Button>
			        </DialogActions>
			      </Dialog>
   
	    	</div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		activeCount: state.activeCount,
		inactiveCount: state.inactiveCount,
		pendingCount: state.pendingCount,
		collection: state.collection,
		db: state.db,
		where: state.where,
		limit: state.limit,
		query: state.query,
		onSnapshot: state.onSnapshot,
		getDocs: state.getDocs,
		doc: state.doc,
		getDoc: state.getDoc,
		updateDoc: state.updateDoc,
		orderBy: state.orderBy,
		doc: state.doc
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		setActiveCount: (count) => dispatch({type: "SET_ACTIVE_COUNT", payload: {activeCount:count}}),
		setInactiveCount: (count) => dispatch({type: "SET_INACTIVE_COUNT", payload: {inactiveCount:count}}),
		setPendingCount: (count) => dispatch({type: "SET_PENDING_COUNT", payload: {pendingCount:count}})
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(AllJobList);





