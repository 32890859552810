import React, { Component } from 'react'
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux'
import _ from 'lodash'
import * as ROUTES from '../../../constants/routes';
import moment from 'moment'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Rating from '@mui/lab/Rating';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/lab/Skeleton';
import Grid from '@mui/material/Grid';

import { useNavigate  } from "react-router-dom";

const styles = {
   small: {
   	color: '#8e8e8e',
   	fontFamily: 'Gotham Rounded'
   },

   em: {
   	 color: '#8e8e8e',
   	 fontFamily: 'Gotham Rounded'
   },

   buttonPrimary: {
   	padding: 18,
   	fontFamily: 'Gotham Rounded',
   	backgroundColor: '#00b16a'
   },

   bigAvatar: {
   	margin: 10,
   	width: 150,
   	height: 150,
   	alignSelf: 'center'
   }
}

class Preview extends Component{
	
	constructor(props){
		super(props);

		this.state = {
			id: '',
			user: [],
			profile: [],
			skills: [],
			projects: [],
			employment: [],
			educations: [],
			portfolioDialog: false,
			selectedPortfolio:[],
			scroll: 'body',
			feedbacks : [],
			identityVerified: '',
			usePremiumTemplate: '',
			redirect: ''
		};
	
	}	

	componentDidMount() {
		
		if(this.props.previewTemplate==='')
			this.setState({redirect:'/fd/'})
		else if(this.props.previewTemplate==='socialTemplate')
			this.setState({redirect:'/fsocial/'})

	}




	render(){

		/*const navigate = useNavigate();

		if( this.state.redirect !== '' )
			navigate(  this.state.redirect+this.props.match.params.id );*/

		return (
				<div>
			        <header>      
				       <div className="public-section">
				       	
				       </div>
			        </header>

			      
		    	</div>
			)
		
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		templateUsed: state.templateUsed,
		previewTemplate: state.previewTemplate
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAuth: (user, state) => dispatch({type: "SET_AUTH", payload: { user: user, route: state }}),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		setTemplate: ( template ) => dispatch({type: "SET_TEMPLATE", payload: {template:template}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Preview);





