import React, { Component } from 'react'
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux'
import _ from 'lodash'
import * as ROUTES from '../../constants/routes';
import moment from 'moment'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Rating from '@mui/lab/Rating';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/lab/Skeleton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Grid from '@mui/material/Grid';


const styles = {
   small: {
   	color: '#8e8e8e',
   	fontFamily: 'Gotham Rounded'
   },

   em: {
   	 color: '#8e8e8e',
   	 fontFamily: 'Gotham Rounded'
   },

   buttonPrimary: {
   	padding: 18,
   	fontFamily: 'Gotham Rounded',
   	backgroundColor: '#00b16a'
   },

   bigAvatar: {
   	margin: 10,
   	width: 150,
   	height: 150,
   	alignSelf: 'center'
   }
}


class User extends Component{
	
	constructor(props){
		super(props);

		this.state = {
			id: '',
			user: [],
			profile: [],
			skills: [],
			projects: [],
			employment: [],
			educations: [],
			portfolioDialog: false,
			selectedPortfolio:[],
			scroll: 'body',
			feedbacks : [],
			identityVerified: '',
			usePremiumTemplate: ''
		};
	
	}	

	async componentDidMount() {
		
		let that = this

		const userRef = this.props.doc(this.props.db, "users", this.props.id );
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
			let data = userSnap.data()
					
			that.setState({
				id: that.props.id,
				user: data,
				profile: data.profile,
				skills: data.skills,
				projects: data.projects,
				employment: data.employment,
				educations: data.educations,
				profilecompleteness: null,
				identityVerified: _.has(data,'verification_review') ? data.verification_review : '',
				usePremiumTemplate: _.has(data,'usePremiumTemplate') ? data.usePremiumTemplate : ''
			}, ()=>{
				

				if(that.state.usePremiumTemplate==='')
					that.setState({redirect:'/fd/'})
				else if(that.state.usePremiumTemplate==='socialTemplate')
					that.setState({redirect:'/fsocial/'})
				
			})
		}else{

			const usersRef = this.props.collection(this.props.db, "users");
	  		const q = this.props.query(usersRef, this.props.where("mypermalink", "==", that.props.id) );
	  		const querySnapshot = await this.props.getDocs(q);

	  		querySnapshot.forEach((doc) => {
			  
			  	let data = doc.data();


				that.setState({
					id: that.props.id,
					user: data,
					profile: data.profile,
					skills: data.skills,
					projects: data.projects,
					employment: data.employment,
					educations: data.educations,
					profilecompleteness: null,
					identityVerified: _.has(data,'verification_review') ? data.verification_review : '',
					usePremiumTemplate: _.has(data,'usePremiumTemplate') ? data.usePremiumTemplate : ''
				}, ()=>{
					

					if(that.state.usePremiumTemplate==='')
						that.setState({redirect:'/fd/'})
					else if(that.state.usePremiumTemplate==='socialTemplate')
						that.setState({redirect:'/fsocial/'})
					
				})
				
			  	
			});

		}
	
		
	}
	async getRating(){
		const that = this

		let rating = await this.props.firebase.firestore().collection('jobApplications').where("uid","==",this.props.match.params.id).where("end_contract_client_at","!=","").get()
		let ja = []
		
		rating.docs.map( doc => {
			ja.push(doc.data())
		} )

		that.setState({ feedbacks:ja })
	}

	profileCompleteness(data){

		let percentage=0;
		//profile
		if( _.has(data.profile,'avatar') && data.profile.avatar!=='')
			percentage+=4
		if( _.has(data.profile,'title') && data.profile.title!=='')
			percentage+=4
		if( _.has(data.profile,'overview') && data.profile.overview!=='')
			percentage+=4
		if( _.has(data.profile,'hourlyRate') && data.profile.hourlyRate!=='')
			percentage+=4
		if( _.has(data.profile,'fullTimeRate') && data.profile.fullTimeRate!=='')
			percentage+=4

		//skills
		if( !_.isEmpty(data.skills) && data.skills.length>0)
			percentage+=20

		//educations
		if(!_.isEmpty(data.educations) && data.educations.length>0)
			percentage+=20

		//employment
		if(!_.isEmpty(data.employment) && data.employment.length>0)
			percentage+=20

		//projects
		if(!_.isEmpty(data.projects) && data.projects.length>0)
			percentage+=20

		

		this.setState({profilecompleteness:percentage})


	}


	
	render(){

		let that = this
		let feedbacks
		let educations
		let employments
		let projects
		let skills 

		if(  _.has(this.state.skills,'length') && this.state.skills.length >0 )
		{
			skills = this.state.skills.map(function(item,i){
      		 			return <Chip style={{margin: 5}} label={item} variant="outlined" />
					})
		}

		if ( _.has(this.state.projects,'length') && this.state.projects.length > 0)
		{

			projects = this.state.projects.map(function(item,i){
          		 			return <Grid item  xs={12} sm={12} md={6}>
          		 						
          		 						<div className="public-portfolio-container">
          		 								<a style={{cursor:'pointer'}} onClick={ ()=>that.setState({selectedPortfolio: item, portfolioDialog: true}) }>
				          		 					<div className="public-portfolio-body" style={{backgroundSize: 'cover',backgroundImage: `url(${item.projectfiles.length > 0 ? item.projectfiles[0] : "/cropped.png"})`}}>
				          		 						<div dangerouslySetInnerHTML={{ __html: item.description.replace(/(?:\r\n|\r|\n)/g, '<br/>').substring(0, 50)+".." }} />
				          		 					</div>
			          		 					</a>
			          		 					<div className="public-portfolio-footer">
			          		 						<p>{item.title}</p>
			          		 						{ moment( item.started ).isValid() &&  moment( item.ended ).isValid()  &&
			          		 							<small>{moment( item.started ).format('ll') } to {moment(item.ended).format('ll') }</small>
			          		 						}
			          		 					</div>
			          		 				</div>
		          		 				
		          		 				<br/>
          		 					</Grid >
						})
		}

						          		 		

		if( _.has( this.state.employment,'length') && this.state.employment.length > 0 ){

			employments = this.state.employment.map(function(item,i)
						{
							          		 			
          		 			return ( <div>
	          		 				   <h3>{item.jobTitle} | {item.company}</h3>
	          		 				   { moment( item.jobStarted ).isValid() &&  moment( item.jobEnded ).isValid()  &&
	          		 				   		<small>{moment(item.jobStarted).format('ll') } - { _.has(item,'jobEndedPresent') && item.jobEndedPresent===true ? "Present" : moment( item.jobEnded).format('ll') }</small>
	          		 				   }
	          		 				   <p dangerouslySetInnerHTML={{ __html: item.jobDescription.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
	          		 				   <hr/>
          		 				   </div> )
          		 				  
						})

		}


		if(  this.state.feedbacks.length > 0 ){

			feedbacks = this.state.feedbacks.map(function(item,i){
			
				return (
						<ListItem >
				         	<ListItemText primary={item.jobPostTitle}
				         	secondary={

				         		<React.Fragment>
				         			<Typography>
				         				<Rating
						                	  disabled={ true }
						                	  name="totalScore"
						                	  value={item.end_contract_totalscore}
									        /> <small> { Number( !isNaN(item.end_contract_totalscore) ? item.end_contract_totalscore : 0 ).toLocaleString(undefined, {minimumFractionDigits: 2}) } {  moment(item.hire_at.toDate()).format('MMM DD, YYYY') } -  { moment(item.end_contract_at.toDate()).format('MMM DD, YYYY') } </small>
				         			</Typography>
				         			<br/>
				         			<Typography>
				         				"{item.end_contract_feedback}"
				         			</Typography>
				         		</React.Fragment>

				         	}
				         	 />
				         	
				        </ListItem>
					)
			})
		}

		if( _.has(this.state.educations,'length') && this.state.educations.length > 0)
		{
			educations = this.state.educations.map(function(item,i){
							          		 			
          		 		return (
	          		 				<div>
		  		 						<h3>{item.schoolCourse} | {item.school}</h3>
		  		 						{ moment( item.schoolStarted ).isValid() &&  moment( item.schoolEnded ).isValid()  &&
		  		 							<small>{ moment(item.schoolStarted).format('ll') } - { _.has(item,'schoolEndedPresent') && item.schoolEndedPresent===true ? "Present" : moment(item.schoolEnded).format('ll') }</small>
		  		 						}
		  		 						<p>{item.schoolDescription}</p>
		  		 						<hr/>
	  		 				  		</div>
  		 				  		)
  		 				  	
						})
		}
		
		let name = 	this.state.user.fname && this.state.user.lname ? <h1 style={{color:'#00B16A', margin:5}}>{ this.state.user.fname.charAt(0).toUpperCase() + this.state.user.fname.slice(1) } { this.state.user.lname.charAt(0).toUpperCase() +"." }</h1> : ''
		
		return (
				<div>
			        <header>      
				       <div className="public-section">
				       		
				       	  <Grid>
				          	<Grid container >
				          		<Grid item  md={2} lg={2}></Grid >
				          			
				          		<Grid item  xs={12} md={8} lg={8}>
				          			<div className="App-panel">

				          					<div style={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
							          		{
							          			_.has(this.state.profile,'avatar') ? <Avatar alt={this.state.profile.fname} src={this.state.profile.avatar} style={styles.bigAvatar} /> : <Avatar alt={name} src={ROUTES.AVATAR} style={styles.bigAvatar} />
							          		}	
							          		</div>

							          		<div style={{ textAlign:'center'}}>
								          		{ name }	
								          	</div>

								          	<div style={{textAlign:'center'}}>
							          			{
							          			 _.has(this.state.profile,'title')  ? <h2 style={{margin:0}}>{ this.state.profile.title }  </h2> : ''
							          			}
							          		</div>
							          		<br/>
							          		{ this.state.identityVerified === "APPROVED" &&
								          		<div style={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
								          			<Tooltip title="Freelancer identity verified">
								          				<VerifiedUserIcon  fontSize="small" style={{color:'#00b16a'}} />
								          			</Tooltip>
								          		</div>
							          		}
							          		<br/>

							          		{ _.has(this.state.user,'country') &&
							          			<div style={{textAlign:'center'}}>
							          				<Typography>{ this.state.user.country }</Typography>
							          			</div>
							          	    }

							          		<br />

							          		<div>
							          		{
							          			_.has(this.state.profile,'overview')? <p style={{textAlign:'justify'}} dangerouslySetInnerHTML={{ __html: this.state.profile.overview.replace(/(?:\r\n|\r|\n)/g, '<br/>') }}  /> : ''
							          		}
							          		</div>
							          		
							          		<Grid>
							          			<br/>
							          			<hr/>
							          			<Grid container >
							          				<Grid item  md={6} sm={12} xs={12}>
							          					<h2 style={{textAlign:'center', color:'#00B16A'}}>${ _.has(this.state.profile,'hourlyRate') ? this.state.profile.hourlyRate  : 0 }</h2>
							          					<p className="center">Hourly Rate</p>
							          				</Grid >
							          				<Grid item  md={6} sm={12} xs={12}>
							          					<h2 style={{textAlign:'center', color:'#00B16A'}}>${ _.has(this.state.profile,'fullTimeRate') ? this.state.profile.fullTimeRate : 0 }</h2>
							          					<p className="center">Full Time Rate</p>
							          				</Grid >
							          			</Grid >
							          		</Grid>
							          		

							          		{ 	 !_.isEmpty( this.state.skills ) &&
							          				<div>
							          					<br/>
							          					<hr/>
							          					<h1>Skills</h1>
							          					{ skills }
													</div>
						          		 	}

						          		 	
						          		 	{ !_.isEmpty(this.state.projects) &&
						          		 		<div>
						          		 			<br/>
						          		 			<hr/>
						          		 			<h1>Portfolio</h1>
						          		 			<Grid>
								          		 		<Grid container >
									          		 		{
									          		 			projects
									          		 		}
								          		 		</Grid >
								          		 	</Grid>
						          		 		</div>
						          		 	}
						          		 	
				          		 			
									        { !_.isEmpty(this.state.employment) &&
									         	<div>
									         		<br/>
						          		 			<hr/>
										        	<h1>Employment History</h1>
										       		{ employments }
												</div>
						          		 	}

						          		 	{
						          		 		!_.isEmpty( this.state.educations ) &&
						          		 		<div>
						          		 			
						          		 			<h1>Education</h1>
						          		 			{ educations }
						          		 			
						          		 		</div>
						          		 	}

						          		 	
						          		 	{
						          		 		!_.isEmpty( this.state.feedbacks ) &&
						          		 		<div>
						          		 			<h1>Ratings and Feebacks</h1>
						          		 			<List>
						          		 			{ feedbacks }
						          		 			</List>
						          		 		</div>
						          		 	}
						          		 	
						          
				          			</div>
				          		</Grid >
				          		
				          		<Grid item  md={2} lg={2}></Grid >
				          	</Grid >
				          	
				          </Grid>
				       </div>
			        </header>

			        <Dialog
				        open={this.state.portfolioDialog}
				        onClose={ ()=>this.setState({portfolioDialog:false}) }
				        aria-labelledby="scroll-dialog-title"
				        aria-describedby="scroll-dialog-description"
				        fullWidth={true}
				        maxWidth={'md'}
				        scroll={this.state.scroll}
				      >
				      <DialogTitle id="scroll-dialog-title">{ _.has(this.state.selectedPortfolio, "title") ? this.state.selectedPortfolio.title : "Project Title" }</DialogTitle>
				      <DialogContent>
				      		{ _.has(this.state.selectedPortfolio, "description") ? this.state.selectedPortfolio.description : "Project Description" }
				      		<br/><br/>
				      		
				      		{ _.has(this.state.selectedPortfolio,"projectfiles") ?
				      		<ImageList rowHeight={'300'} cols={2} justifyContent="center">
						        {this.state.selectedPortfolio.projectfiles.map((file,i) => (
						          <ImageListItem key={file}>
						            <img src={file} style={{width:'auto',height:'100%'}} />
						          </ImageListItem>
						        ))}
						    </ImageList>
						    : ""
							}
				      		{ _.has(this.state.selectedPortfolio, "projectfiles") && this.state.selectedPortfolio.projectfiles.length === 0 ? <img alt='avatar' src={"/cropped.png"} style={{width:'100%'}} /> : "" }


				      </DialogContent>
				    </Dialog>
		    	</div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		templateUsed: state.templateUsed,
		doc: state.doc,
		db: state.db,
		getDocs: state.getDocs,
		getDoc: state.getDoc,
		collection: state.collection,
		where: state.where,
		query: state.query
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAuth: (user, state) => dispatch({type: "SET_AUTH", payload: { user: user, route: state }}),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		setTemplate: ( template ) => dispatch({type: "SET_TEMPLATE", payload: {template:template}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(User);





