import React from 'react';
import { useParams } from 'react-router-dom';

const WithParams = ({ children }) => {
  // Use the useParams hook to extract route parameters
  const { id } = useParams();

  // Render the wrapped component with the extracted parameters as children
  return children(id);
};

export default WithParams;
