import React from 'react';
import logo from './logo.svg';
import './App.css';
import './style.css';

import ProtectedRoute from '../components/ProtectedRoute';
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { reducer } from '../actions/';

import MetaTags from '../components/MetaTags';

import MyComponent from "../components/MyComponent";

const store = createStore(reducer)

const App = () => (

  <Provider store={store}>
      <ProtectedRoute />
  </Provider>

)

export default App;