import React, { Component } from 'react'
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux'
import _ from 'lodash'
import * as ROUTES from '../../constants/routes';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Tooltip from '@mui/material/Tooltip';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import GoogleAdSense from '../GoogleAdSense';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';

const styles = {
   small: {
   	color: '#8e8e8e',
   	fontFamily: 'Gotham Rounded'
   },

   em: {
   	 color: '#8e8e8e',
   	 fontFamily: 'Gotham Rounded'
   },

   buttonPrimary: {
   	padding: 18,
   	fontFamily: 'Gotham Rounded',
   	backgroundColor: '#00b16a'
   },

   bigAvatar: {
   	margin: 10,
   	width: 150,
   	height: 150,
   	alignSelf: 'center'
   }
}


class User extends Component{
	
	constructor(props){
    
		super(props);		
		this.state = {
			id: '',
			user: [],
			profile: [],
			skills: [],
			projects: [],
			employment: [],
			educations: [],
			portfolioDialog: false,
			selectedPortfolio:[],
			scroll: 'body',
			feedbacks : [],
			identityVerified: '',
			usePremiumTemplate: ''
		};
	
	}	

	componentDidMount() {
		
		this.getUser()
		this.getStat();
	}

	async getUser()
	{
		let that = this
		
		let userRef = this.props.doc(this.props.db, "users",  this.props.id );
		let userSnap = await this.props.getDoc(userRef);
		if (userSnap.exists())
		{
		  	var data = userSnap.data();
		  	that.setState({
				allowpublic: data.allowpublic,
				id: that.props.id,
				user: data,
				profile: data.profile,
				skills: data.skills,
				projects: data.projects,
				employment: data.employment,
				educations: data.educations,
				profilecompleteness: null,
				identityVerified: _.has(data,'verification_review') ? data.verification_review : '',
				usePremiumTemplate: _.has(data,'usePremiumTemplate') ? data.usePremiumTemplate : ''
			}, ()=>{
			
			})
		}else{

			userRef = this.props.collection(this.props.db, "users");
	  		const q = this.props.query(userRef, this.props.where("mypermalink", "==", that.props.id) );
	  		const querySnapshot = await this.props.getDocs(q);

	  		querySnapshot.forEach((doc) => {
			  
			  	let data = doc.data();

				that.setState({
					allowpublic: data.allowpublic,
					id: that.props.id,
					user: data,
					profile: data.profile,
					skills: data.skills,
					projects: data.projects,
					employment: data.employment,
					educations: data.educations,
					profilecompleteness: null,
					identityVerified: _.has(data,'verification_review') ? data.verification_review : '',
					usePremiumTemplate: _.has(data,'usePremiumTemplate') ? data.usePremiumTemplate : ''
				}, ()=>{
										
				})
			});

		}
	}
	async getStat()
	{
			
			let that = this

			const querySnapshot = await this.props.getDocs(this.props.collection(this.props.db, "stats"));
        
	        querySnapshot.forEach((doc) => {
	            
	            let stat = doc.data();
	            stat.id = doc.id
				that.props.setLoading(false)
				that.setState({stats: stat})

	  
	        });

	}


	capitalizeFirstLetter(string) {
	    return string.charAt(0).toUpperCase() + string.slice(1);
	}
	
	render(){

		if (typeof window !== 'undefined')
		{
			let that = this
			let name = 	this.state.user.fname && this.state.user.lname ? <h2 style={{fontSize: '36px',color:'#00B16A', margin:5}}>{ this.state.user.fname.charAt(0).toUpperCase() + this.state.user.fname.slice(1) } { this.state.user.lname.charAt(0).toUpperCase() +"." }</h2> : ''
			
			return (
				<div>
				    <header>      
				       <div className="public-section">
				       		
				       	  <Grid>
				          	<Grid container >
				          		<Grid item  md={2} lg={2}></Grid >
				          			
				          		<Grid item  xs={12} md={8} lg={8}>

				          			
				          			{ this.state.allowpublic==true &&
				          				
				          				<div>

				          				<div style={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
						          		{
						          			_.has(this.state.profile,'avatar') ? <Avatar alt={this.state.profile.fname} src={this.state.profile.avatar} style={styles.bigAvatar} /> : <Avatar alt={name} src={ROUTES.AVATAR} style={styles.bigAvatar} />
						          		}	
						          		</div>

						          		<div style={{textAlign:'center'}}>
						          			{
						          			 _.has(this.state.profile,'title')  ? <h1 style={{margin:0, fontSize:'28px'}}>{ this.capitalizeFirstLetter(this.state.profile.expertise) } { this.state.profile.title }  </h1> : ''
						          			}
						          		</div>

						          		<div style={{ textAlign:'center'}}>
							          		{ name }	
							          	</div>

							          	
						          		<br/>

						          		{ this.state.identityVerified === "APPROVED" &&
							          		<div style={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
							          			<Tooltip title="Freelancer identity verified">
							          				<VerifiedUserIcon  fontSize="small" style={{color:'#00b16a'}} />
							          			</Tooltip>
							          		</div>
						          		}
						          		<br/>

						          		{ _.has(this.state.user,'country') &&
						          			<div style={{textAlign:'center'}}>
						          				<Typography>{ this.state.user.country }</Typography>
						          			</div>
						          	    }

						          		<br />

								        <Typography  style={{textAlign:'center'}}>
						          			<a target="_blank" href="/signin">Login</a> or <a target="_blank" href="/signup">Create an account</a> to hire this freelancer.
						          		</Typography>

						          		<br />	

						          		{ _.has(this.state.stats,'enable_ads') && this.state.stats.enable_ads == true &&
								            <div>
												<GoogleAdSense location="horizontal"></GoogleAdSense>
											
											</div>
										}

										<br />	

					          			<div className="App-panel">
								          		<div>
								          		{
								          			_.has(this.state.profile,'overview')? <p style={{textAlign:'justify'}} dangerouslySetInnerHTML={{ __html: this.state.profile.overview.replace(/(?:\r\n|\r|\n)/g, '<br/>') }}  /> : ''
								          		}
								          		</div>
					          			</div>

					          			</div>
					          		
					          		}

					          		{ this.state.allowpublic==false &&
					          			<div className="App-panel">
					          				<Typography>
					          					This profile is private. <a target="_blank" href="/signin">Login</a> or <a target="_blank" href="/signup">Create an account</a> to view this profile.
					          				</Typography>
					          			</div>
					          		}
				          		</Grid >
				          		
				          		<Grid item  md={2} lg={2}></Grid >
				          	</Grid >
				          	
				          </Grid>
				       </div>
			        </header>

			      
		    	</div>
			)

		}else{

			return (<div></div>)
		}
		
		
	
	}
}

const mapStateToProps = (state) => {
	return {
		collection: state.collection,
		getDocs: state.getDocs,
		getDoc: state.getDoc,
		db: state.db,
		doc: state.doc,
		where: state.where,
		query: state.query
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAuth: (user, state) => dispatch({type: "SET_AUTH", payload: { user: user, route: state }}),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		setTemplate: ( template ) => dispatch({type: "SET_TEMPLATE", payload: {template:template}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(User);






