import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import { connect } from 'react-redux'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Autocomplete from '@mui/lab/Autocomplete';
import countries from '../../constants/countries.json'
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

const styles = {
   small: {
   	color: '#8e8e8e',
   	fontFamily: 'Gotham Rounded'
   },

   em: {
   	 color: '#8e8e8e',
   	 fontFamily: 'Gotham Rounded'
   },

   buttonPrimary: {
   	padding: 18,
   	fontFamily: 'Gotham Rounded',
   	backgroundColor: '#00b16a'
   }
}

class SignUp extends Component{
	
	constructor(props){
		super(props);

		this.state = {
			fname: '',
			lname: '',
			email: '',
			password: '',
			preference: 'jobs',
			subscribe: true,
			hasSubmitted: false,
			completeSignup: false,
			signupError: false,
			signupErrorMessage: '',
			countries: [],
			country: '',
			ref: ''
		};

	
	
	}	

	componentDidMount() {

		 const query = new URLSearchParams(window.location.search);
		 const ref = query.get('ref')
		 this.setState({ref:ref})
	}

	snackbarClose(){
		this.setState({snackbar: false, snackbarMessage: ''})
	}

	regComplete()
	{

		this.setState({completeSignup: true})
	  	this.props.setLoading(false);
	  	this.props.signOut(this.props.auth).then(() => {
		}).catch((error) => {
		});

		this.props.sendEmailVerification(this.props.auth.currentUser)
		  .then(() => {
		  });

	}

	async notify(){

		let that = this;

		let message = MESSAGES.NEW_SIGNUP
		message = message.replace("{name}", this.state.fname+" "+this.state.lname )
		message = message.replace("{email}", this.state.email)
		message = message.replace("{date}", new Date())
		message = message.replace("{type}", this.state.preference === "freelancers" ? "Client" : "Freelancer")
		
		fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
		{
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    method: "POST",
		    body: JSON.stringify({ title: "New User Sign Up", name: that.props.adminName , to: that.props.adminEmail, message: message })
		})
		.then(function(res){
			 return res.json() })
		.then(function(data)
		{
			
			that.regComplete()

		})
		.catch(function(res)
		{ 
			that.regComplete()
		})
		

	}

	async registerUser(id)
	{

		let that = this;
		const userRef = this.props.doc(this.props.db, "users", id); 

		const userData = {
			fname: this.state.fname,
			lname: this.state.lname,
			subscribe: this.state.subscribe,
			preference: this.state.preference,
			country: this.state.country,
			created_at: new Date(),
			email: this.state.email,
			jpc: this.state.preference === "freelancers" ? 25 : 0,
			status: "NEW",
			referer: this.state.ref
		};

		this.props.setDoc(userRef, userData)
		  .then(() => {
		    that.notify();
		  })
		  .catch((error) => {
		    console.error("Error writing document: ", error);
		  });

		
	}
	async updateStat()
	{
		let that = this;
		
		const querySnapshot = await that.props.getDocs(that.props.collection(that.props.db, "stats"));
            
		querySnapshot.forEach((doc) => {
            
            let stat = doc.data();
            let id = doc.id;

            
            if(that.state.preference==="freelancers")
            	stat.client_count = stat.client_count + 1
            else
            	stat.freelancer_count = stat.freelancer_count + 1

            let client_count = stat.client_count;
            let freelancer_count = stat.freelancer_count;

            const statRef = that.props.doc(that.props.db, "stats", id);

            that.props.updateDoc(statRef, {
			  client_count: client_count,
			  freelancer_count: freelancer_count
			});

            
        });
	}

	handleSubmit = (e) => {
		this.setState({hasSubmitted:true});
		e.preventDefault();

		let error = 0;
		
		if(this.state.fname==='')
			error++;
		if(this.state.lname==='')
			error++;
		if( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( this.state.email ) )
			error++;
		if(this.state.password==='')
			error++;

		if(error===0){

			let state = this
			state.props.setLoading(true);


			this.props.createUserWithEmailAndPassword(this.props.auth, this.state.email, this.state.password)
			  .then((userCredential) => {

			    let user_ = userCredential.user;
			   
			   state.props.updateProfile(state.props.auth.currentUser, {
				  displayName: state.state.fname
				}).then(() => {
						
					 state.updateStat();
					 state.registerUser(user_.uid);

				}).catch((error) => {
					state.setState({snackbar:true,snackbarMessage:"There was a problem completing your request. Please try again or contact us to report this error"})
					state.props.setLoading(false)
				});

			  })
			  .catch((error) => {
			   		state.setState({snackbar:true,snackbarMessage:"Email address already in use"})
			   		state.props.setLoading(false)
			  });
		}

	}

	onTagsChange = (event, values) => {
    this.setState({
      country: values
    }, () => {
    
    });
  }

	
	render(){

		if (typeof window !== 'undefined')
		{
			return (
			<div>
		        <header className="App-header">
	          	  	<Grid container  >
	          	  		<Grid item xs={12} md={4}></Grid >
		          		<Grid item  xs={12} md={4}>
		          			<br/><br/><br/><br/>
		          			<h1 className="App-title">
					          	{ this.state.completeSignup === false ? 'Create Your Account' : 'Thank You' }
					        </h1>
					        <Grid container spacing={2} className="App-panel">
					        	{ this.state.completeSignup === true &&
					        		<center>
					        			<img alt="verified" src="/verified.png" />
					        			<Typography>Thank you for signing up. An email verification link is sent to your email. Please verify email to start using freelancertogo.</Typography>
					        			<br/>
					        		</center>
					        	}
					          	{ this.state.completeSignup === false &&
						          	<form onSubmit={ this.handleSubmit }>
						          		<Grid container spacing={2}>
						          			<Grid item  xs={12} md={6}>

						          				<Typography style={styles.small}>First Name</Typography>
						          				<TextField
						          					style={{marginBottom: 15}}
						          					error={ this.state.hasSubmitted === true && this.state.fname === '' ? true : false }
						          					variant="outlined"
											      	margin="normal" value={this.state.fname} fullWidth={true} onChange={ (event) => this.setState({fname: event.target.value }) } />
				      	          			</Grid >

						          			<Grid item  xs={12} md={6}>
						          				<Typography style={styles.small}>Last Name</Typography>
						          				<TextField
						          					style={{marginBottom: 15}}
						          					error={ this.state.hasSubmitted === true && this.state.lname === '' ? true : false }
											      	variant="outlined"
											      	name='lname'
											      	margin="normal" value={this.state.lname} fullWidth={true} onChange={ (event) => this.setState({lname: event.target.value }) } />
						          			</Grid >
						          		</Grid >
						          		<Grid container  >
						          			<Grid item  xs={12} md={12}>
						          				  <Typography style={styles.small}>Country</Typography>
						          				
						          				  <Autocomplete
												        onChange={this.onTagsChange}
												        options={countries.map((option) => option.name)}
												        renderInput={(params) => (
												          <TextField style={{width:'100%'}} {...params} label={this.state.country} margin="normal" variant="outlined" />
												        )}
												      />
						          				 
						          			</Grid >
						          		</Grid >
						          		<Grid container  >
						          			<Grid item  xs={12} md={12}>
						          				<Typography style={styles.small}>Email</Typography>
						          				<TextField
						          					style={{marginBottom: 15}}
						          					error={ this.state.hasSubmitted === true && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email) ? true : false }
											      	name='email'
											      	variant="outlined"
											      	margin="normal" value={this.state.email} fullWidth={true} onChange={ (event) => this.setState({email: event.target.value }) } />
						          				{ this.state.signupError === true && <Typography>{ this.state.signupErrorMessage }</Typography>  }
						          			</Grid >
						          		</Grid >
						          		<Grid container  >
						          			<Grid item  xs={12} md={12}>
						          					<Typography style={styles.small}>Password</Typography>
						          					<TextField
						          					style={{marginBottom: 15}}
						          					error={ this.state.hasSubmitted === true && this.state.password === '' ? true : false }
											      	name='password'
											      	variant="outlined"
											      	
											      	margin="normal" type='password' value={this.state.password} fullWidth={true} onChange={ (event) => this.setState({password: event.target.value }) } />
						          			</Grid >
						          		</Grid >
						          		<br/>
						          		<Grid container  >
						          			<Grid item  xs={12} md={12}>
						          				  <Typography style={styles.small}>Preference</Typography>
						          				  <br/>
						          					
						          				  <Select
						          				  	onChange={ (event) => this.setState({preference: event.target.value }) }
													value={this.state.preference}
												    inputProps={{
												    name: 'preference',
												    }} fullWidth={true} >

												    <MenuItem value="jobs">
												      <em style={styles.em}>Im Looking for Jobs</em>
												    </MenuItem>
												    <MenuItem value="freelancers"><em style={styles.em}>Im Looking for Freelancers</em></MenuItem>

												  </Select>

						          			</Grid >
						          		</Grid >
						          		<br/>
						          		<Grid container  >
						          			<Grid item  xs={12} md={12}>
						          				<Button disabled={ this.props.isLoading === true ? true :false } style={styles.buttonPrimary} type='submit' variant='contained' color='primary' fullWidth={true}>{ this.props.isLoading === true ? <CircularProgress style={{color:"#fff"}} size={28} /> : 'Create Account' }</Button>
						          			</Grid >
						          		</Grid >
						          		<br/>
						          		<Grid container  >
						          			<Grid item  xs={12} md={12}>
						          				<Typography style={styles.em}>By creating an account you agree to Freelancertogo Terms of Use and Privacy Policy</Typography>
						          			</Grid >
						          		</Grid >
						          	</form>
					          	}
					        </Grid>
		          		</Grid >
		          		<Grid item  xs={12} md={4}></Grid >
	          	  	</Grid >
		        </header>

		        <Snackbar
			          anchorOrigin={{
			            vertical: 'bottom',
			            horizontal: 'center',
			          }}
	          		open={this.state.snackbar}
	          		autoHideDuration={3000}
	          		onClose={ () => this.snackbarClose() }
	          		ContentProps={{
	            		'aria-describedby': 'message-id',
	          		}}
	          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
			          action={[
			            <IconButton
			              key="close"
			              aria-label="Close"
			              color="inherit"
			              onClick={()=>this.snackbarClose()}
			            >
			              <CloseIcon />
			            </IconButton>,
			          ]} />
	    	</div>
			)
		}else{
			return (<div></div>)
		}
		
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		createUserWithEmailAndPassword: state.createUserWithEmailAndPassword,
		updateProfile:state.updateProfile,
		updateDoc:state.updateDoc,
		collection:state.collection,
		db:state.db,
		getDocs:state.getDocs,
		doc:state.doc,
		addDoc:state.addDoc,
		setDoc:state.setDoc,
		adminEmail: state.adminEmail,
		adminName: state.adminName,
		signOut : state.signOut,
		sendEmailVerification: state.sendEmailVerification,
		isLoading: state.isLoading
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAuth: (user) => dispatch({type: "SET_AUTH", payload: { user: user }}),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		logout: (firebase) => dispatch({type: "SET_LOGOUT", payload: {firebase:firebase} })
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);





