import React, { Component } from 'react'
import { connect } from 'react-redux'
import Landing from '../Landing'

class SignOut extends Component{
	
	constructor(props){
		super(props);

		this.state = {
		};
	
	}	

	componentWillMount() {

		let that = this
		this.props.signOut(this.props.auth).then(() => {
		 	
		 	that.props.logout(null);
		 	localStorage.clear();

		}).catch((error) => {
		  // An error happened.
		});


		
		
	}

	componentDidMount(){
		window.localStorage.clear()
	}
	
	
	render(){

		if (typeof window !== 'undefined'){
			return (
				<Landing />
			)
		}else{
			return (
				<div></div>
			)
		}
		
	}
}

const mapStateToProps = (state) => {
	return {
		signOut: state.signOut,
		auth: state.auth
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		logout: (firebase) => dispatch({type: "SET_LOGOUT", payload: {firebase:firebase} }),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);





