export const PROFILE_APPROVE = 'Congratulations! Your profile has been accepted. We reviewed your profile and believe that your skills align with those required for the positions listed on our platform. In order for you to begin applying for our jobs.';
export const PROFILE_DISAPPROVE = 'Sorry, but we disapproved your profile for now. Please correct the information listed below and you may submit your profile again for review.';
export const PROFILE_SUSPEND = 'We have detected inappropriate activities in your account. To make our community safe for both clients and freelancers, we make sure that all users follow our safety guidelines. In view of this, we decided to suspend your account. If you have any questions, you may contact our support team.';
export const JOB_APPROVE = 'Congratulations! Your job posting {jobtitle} is now active. Freelancers may now apply to your job and we wish you good luck in finding the right freelancer for your project.';
export const JOB_DISAPPROVE = 'Sorry, but we disapproved your job posting. We find inappropriate content in your job posting {jobtitle}. To make our platform safe, we carefully review all job posts and it should follow our job guidelines. Please review and correct your post and submit again for review.';
export const NEW_SIGNUP = '{name} with email {email} has signup as {type} on {date}.';
export const NEW_JOB = 'A new job is posted named {jobPostTitle} with ID {jobPostID} by {user} on {date}';
export const NEW_PROFILE_REVIEW = '{name} with email {email} has submitted a request to review profile on {date}';
export const NEW_IDENTITY_VERIFICATION = '{name} with email {email} has submitted a request to verify identity profile on {date}';
export const NEW_JOB_APPLICATION = 'A new proposal has been submitted to your job post {jobPostTitle} on {date}. Login to your account to view the proposal.';
export const JOB_APPLICATION_DECLINED = 'Your application for the the job {jobPostTitle} was declined. <br/><br/><b>Client reason:</b><br/> {reason}';
export const JOB_APPLICATION_INTERVIEW = 'Congratulations! You are invited for an interview to the job post {jobPostTitle}. <br/><br/><b>Client\'s Message:</b><br/> {reason} <br/><br/><b>Date:</b> {date} <br/><br/>.';
export const JOB_APPLICATION_OFFER = 'Congratulations! You recieved an offer to the job <b>{jobPostTitle}</b>. <br/><br/><b>Client\'s Message:</b><br/> {reason}. <br/><br/>Login to your account to see the offer details.';
export const JOB_APPLICATION_INVITE = 'Congratulations! You recieved an invitation to the job <b>{jobPostTitle}</b>. <br/><br/><b>Client\'s Message:</b><br/> {reason}. <br/><br/>Login to your account to see the job invitation details.';
export const JOB_OFFER_DECLINE = 'Your Job offer to <b>{freelancer}</b> for the the job <b>{jobPostTitle}</b> was declined. <br/><br/><b>Freelancer reason:</b><br/> {reason}';
export const JOB_OFFER_ACCEPTED = 'Your Job offer to <b>{freelancer}</b> for the the job <b>{jobPostTitle}</b> was accepted. ';
export const RELEASE_FUND_NOTIF = 'You received the amount ${amount} from the project <b>{jobPostTitle}</b>. <br/><br/>Note:<br/>{note} <br/><br/>The amount will be available to your claimable fund immediately.';
export const SEND_CONFIRMATION_CODE = 'You requested to release fund to <b>{freelancer}</b> the amount of <b>${amount}</b> for the job <b>{jobPostTitle}.</b> Enter the confirmation code: <b>{code}</b> to continue.';
export const SEND_CONFIRMATION_CODE_WITHDRAWAL = 'You requested to withdraw your earnings of <b>${amount}</b>. Enter the confirmation code: <b>{code}</b> to continue.';
export const LOGIN_VERIFICATION = 'You are logging in to your account. Enter the confirmation code: <b>{code}</b> to continue.';
export const MONEY_SENT_PAYPAL = 'We just sent your money! Check details below: <br/><br/>Amount: ${receivable} <br/>Withdrawal Method: {method} <br/>Transaction ID: {transaction_id} <br/><br/>Thank you for trusting freelancertogo.  ';
export const IDENTITY_VERIFICATION_APPROVED = 'Congratulations! Your profile is now verified and you also earned the verified badge. This badge will show on your profile and other users will feel better communicating with you. <br/><br/>For your privacy, the uploaded government-issued id and your selfie will be deleted from our system.';
export const IDENTITY_VERIFICATION_DECLINED = 'Thank you for submitting your profile for identity verification. As we checked on your id and selfie, we think that you uploaded incorrect images for the identity verification process. Please upload the required images and you may submit your profile again for review.';
export const REFERER = 'A profile was approved using your signup link. We are sending you 50 credits as a reward.';