import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterList';
import { connect } from 'react-redux'
import _ from 'lodash'
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link } from 'react-router-dom';
import Alert from '@mui/lab/Alert';
import Tooltip from '@mui/material/Tooltip';
import jobCategories from '../../constants/jobCategories.json'
import projectDurations from '../../constants/projectDurations.json'
import jobTypes from '../../constants/jobTypes.json'
import algoliasearch from 'algoliasearch/lite';
import { Menu, RefinementList, Pagination, Stats, HitsPerPage , InstantSearch, SearchBox, connectSearchBox,connectHits,connectStateResults, Configure  } from 'react-instantsearch-dom';
import moment from 'moment'
import GoogleAdSense from '../GoogleAdSense';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/lab/Skeleton';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';


const searchClient = algoliasearch(
  'RVNFJDRY7K',
  'a8193b8b5c757eb10d9aed404982d07f'
);


const styless = {
	bigAvatar:{
		margin: 10,
		width: 150,
		height: 150,
		alignSelf:'center',
		cursor: 'pointer'
	},
	title: {
		color: '#333333',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 24
	},
	subtitle: {
		color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 18
	},
	center:{
		textAlign:'center',
		color: '#3c4859'
	},

	small: {
	   	color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10
	   },

	   em: {
	   	 color: '#8e8e8e',
	   	 fontFamily: 'Gotham Rounded'
	   },

	   buttonPrimary: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded',
	   	backgroundColor: '#00b16a'
	   },

	   buttonDefault: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded'
	   }

}




const SearchBoxCustom = ({ currentRefinement, isSearchStalled, refine }) => {

	
	/*let search_value = currentRefinement;

	if(initial_load==1)
		search_value = paramValue;*/

	
	return ( <TextField
		id='algosearch'
		name='search'
      	variant="outlined"
      	placeholder="Search Keyword"
      	InputProps={{
          startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
        }}
        onChange={event => { 
        	refine(event.currentTarget.value);
        } }
      	margin="normal" style={{width: '99.5%'}} fullWidth={false} value={currentRefinement} />
      	)

};

const CustomSearchBox = connectSearchBox(SearchBoxCustom);

function getDuration(hit){

	var duration = "";
	
	if(hit === '1')
		duration = "Less than a week";
	else if(hit === '2')
		duration = "Less than a month";
	if(hit === '3')
		duration = "Less than 3 months";
	if(hit === '4')
		duration = "Less than 6 months";
	if(hit === '5')
		duration = "Less than a year";
	if(hit === '6')
		duration = "More than a year";

	return duration;


}


const Results = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <Grid>
		<Grid container >
			<Grid item  xs={0} md={2}></Grid >
			<Grid item  xs={0} md={8}>
				<br/><br/>
				<center>
			 		<Stack spacing={1}>
			 		  <center>
				      <Skeleton variant="circular" width={40} height={40} /> <br/> 
				      <Skeleton variant="rectangular" width={210} height={60} /> <br/> 
				      <Skeleton variant="rounded" width={210} height={60} />
				      </center>
				    </Stack>
			 	</center>
			 	<br/><br/>
			</Grid >
			<Grid item  xs={0} md={2}></Grid >
 		</Grid >
 	</Grid>
    )
);

let adsenseShow = 0;
let rand = Number( Number( Math.random() * (5 - 1) + 1 ).toFixed(0) );

const CustomHits = connectHits(({hits, data}) => {

	 
	   adsenseShow++;

       return (
       		<div className="ais-Hits">
       			<ul className="ais-Hits-list">
       				{
       					hits.map( (hit,i)=>{

       						return ( <li className="ais-Hits-item">
								<Link target="_blank" to={'/jobs/'+hit.objectID}>
								   
									<div className="jobRow">
										 { i===rand &&
									    		<div>{data}</div>
									     }
										<b className="jobRowTitle">{hit.jobPostTitle}</b>
										<p>{ hit.jobDescription.replace(/<[^>]*>|&[^;]+;/g, '').substring(0, 300) }.. </p>
										<small><b>Project Type:</b> { hit.jobType } <b style={{marginLeft: 10}}>Project Duration: </b> { getDuration(hit.estProjectDuration) } <b style={{marginLeft: 10}}>Posted:</b> { _.has( hit,'created_at' )  ?  moment( new Date( hit.created_at._seconds * 1000).toUTCString() ).fromNow() : '' }</small>
									</div>
								</Link>
							</li> )

       					} )
       				}
       			</ul>
       		</div>
       	)
});


class Work extends Component{
	

	constructor(props){
		
		super(props);

		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		snackbar: false,
    		snackbarMessage: '',
    		applyDialog: false,
			job: [],
			isLoaded: false,
			jobs: [],
			jobsUnfiltered: [],
			jobCategories: [],
			jobCategoriesFilter:[],
			durations: [],
			durationsFilter:[],
			budgetMin:0,
			budgetMax: 100,
			jobTypes: [],
			jobTypesFilter:[],
			rowLimit: 10,
			lastVisible : 0,
			lastVisibleID : '',
			rowIncrement: 10,
			profileStatus: '',
			hasSubmitProfile: 0,
			profile: [],
			searchFilterDialog: false,
			userCategories: [],
			jobsLoaded:false,
			searchState: {},
			noResults:false,
			query:  "",
			searchState: {
		        query: '', // Initial search query
		        refinementList: { category: [] } // Initial filter values for RefinementList (empty array)
		      }

		};
	
	}	

	 handleChange = (event, value) => {
	    this.setState({ submenu: value });
	  };

	componentWillMount() {

		if (typeof window !== 'undefined') {
		    const urlParams = new URLSearchParams(window.location.search);
			let paramValue = urlParams.get('query');
			let searchState = this.state.searchState
			searchState.query = paramValue
			this.setState({searchState:searchState})
		}

		this.setState({ jobsLoaded:true, isLoaded: true})
		
	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	getDurations(item){
	
			let duration = ''

			if(item === '1')
				duration = "Less than a week"
			else if(item === '2')
				duration = "Less than a month"
			if(item === '3')
				duration = "Less than 3 months"
			if(item === '4')
				duration = "Less than 6 months"
			if(item === '5')
				duration = "Less than a year"
			if(item === '6')
				duration = "More than a year"

		return duration
	}

	render(){
	
		let that = this
		let d = new Date()
		let now = Math.floor(d.setDate(d.getDate()) / 1000)

		return (

			<div>
			
			<header>
			  { this.state.isLoaded === true &&
		          <div style={{marginTop: 100}}>
			          
			         <h1 className="App-title">
				          Search Jobs
				     </h1>

			          <InstantSearch searchState={ this.state.searchState } onSearchStateChange={ (searchState)=> { that.setState({searchState:searchState}) } } indexName="jobs" searchClient={searchClient} >
			          
			          <Grid container spacing={3} >
			          		<Grid item  xs={0} md={1}></Grid>
			          		<Grid item  xs={0} md={4}>

			          			<h2>
			          			 	  <br/><br/>
							          Job Category
							    </h2>

							    <div className="catPanel">
			          				<RefinementList defaultRefinement={that.state.userCategories} attribute="jobCategory" />
			          			</div>

			          			<h2>
							          Job Type
							    </h2>

							    <div className="catPanel">
			          				<RefinementList attribute="jobType" />
			          			</div>


			          			<h2>
							          Project Duration
							    </h2>

							    <div className="catPanel">
			          				<RefinementList attribute="estProjectDuration" 
			          					transformItems={
			          						items => items.map(item=>({
			          							...item,
			          							label: that.getDurations( item.label )
			          						}))
			          					}
			          				/>
			          			</div>

			          			<br/>
			          			<center>
			          				<img alt="resume" src="/search-by-algolia-light-background.svg" />
 								</center>

 								<br/><br/>

							   
			          		</Grid >
			          		<Grid item  xs={12} md={6}>
			          			
								<Configure
									   filters= { `status:ACTIVE AND expires_at._seconds >`+now }
								/> 
								
								

				          		<CustomSearchBox />										    
							    <br/><br/>
							    
							    <div className="jobPanel">
								    <Results>
								    	 {this.state.noResults ? (
								            <div>No results found for your search query.</div>
								          ) : (
								            <CustomHits data={<GoogleAdSense location="feed"></GoogleAdSense> } />
								          )}
								    	
								    </Results>
							    </div>
							    <div className="pagination">
									<Pagination showFirst={false} showPrevious={false} showNext={false} showLast={false}  totalPages={10} />
								</div>
			          		 
			          		</Grid >

			          		<Grid item  xs={0} md={1}></Grid>
			          		
			          		
			          </Grid >

			          </InstantSearch>
			      </div>
		  	  }

		      { this.state.isLoaded === false &&
                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                         <CircularProgress  style={{color:'#00b16a', alignSelf:'center'}} />
                    </div>
              }
	        </header>

	     
	        <Snackbar
		          anchorOrigin={{
		            vertical: 'bottom',
		            horizontal: 'right',
		          }}
          		open={this.state.snackbar}
          		autoHideDuration={3000}
          		onClose={this.snackbarClose}
          		ContentProps={{
            		'aria-describedby': 'message-id',
          		}}
          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
		          action={[
		            <IconButton
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              onClick={this.snackbarClose}
		            >
		              <CloseIcon />
		            </IconButton>,
		          ]} />
	    </div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		profile: state.profile,
		jobCategories: state.jobCategories,
		jobspost: state.jobspost,
		collection: state.collection,
		db: state.db,
		where: state.where,
		limit: state.limit,
		query: state.query,
		getDocs: state.getDocs
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		setProfile: (profile) => dispatch({type: "SET_PROFILE", payload: {profile:profile}}),
		setJobCategories: (jobCategories) => dispatch({type: "SET_JOB_CATEGORIES", payload: {jobCategories:jobCategories }}),
		setJobPosts: (jobspost) => dispatch({type: "SET_JOBS", payload: {jobspost:jobspost }})
		
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(Work);





