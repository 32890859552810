//prod
export const CREDENTIALS = {
      apiKey: "AIzaSyC_fPwyC4bJ0S_P6xvLHUotznIg1bpg_O0",
      authDomain: "https://freelancertogo-prod.firebaseapp.com",
      databaseURL: "https://freelancertogo-prod.firebaseio.com",
      projectId: "freelancertogo-prod",
      storageBucket: "freelancertogo-prod.appspot.com",
      messagingSenderId: "956707496472",
      appId: "1:956707496472:web:962cece0a9b825b06fd96d"
    };
    
//prod
export const clfunc = "https://us-central1-freelancertogo-prod.cloudfunctions.net";

export const PAYPAL = {
   credits:
     "ARDDI_oZAwoclqHnTR_YpjYiJ6PmoZZebwX_kQC82ck29dz2oAedXheD3W-2dhUWIOmpmbs-c7vVgb2j",
   funding:
     "AcxFVatXEkzxC6ZyzaliGClAfnHnG_GegR01xzVPozDyBKWwKCQIlgQBJwdrDzsd__qDWqqtMaLE6rVm"
 };

