import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InsertEmoticon from '@mui/icons-material/InsertEmoticon';
import Send from '@mui/icons-material/Send';
import AttachFile from '@mui/icons-material/AttachFile';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux'
import _ from 'lodash'
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import EmojiPicker from 'emoji-picker-react';
import AttachmentIcon from '@mui/icons-material/Attachment';
import * as FIREBASECONFIG from '../../constants/firebase';
import HistoryIcon from '@mui/icons-material/History';
import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle'; 
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

let gcontacts =[]
let contacts =[]	
let lastmessagecount = 5
let scrollTo = 'bottom'
let users = []
let unsubscribe
let isRealtime = 0
let chat_history_limit =5
let chat_history_limit_last_date = null
let contacts_limit = 5
let roomhistory = {}

class Message extends Component{
	
	constructor(props){
		
		super(props);

		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		snackbar: false,
    		snackbarMessage: '',
    		applyDialog: false,
			job: [],
			proposedRate: 0,
			proposedRateError:false,
			coverLetter: '',
			coverLetterError: false,
			otherLinks: '',
			otherLinksError: false,
			hourlyRate: 0,
			fullTimeRate: 0,
			jobApplication: [],
			allApplications:[],
			preference: '',
			confirmDeleteJobDialog: false,
			declineReason: '',
			jobApplicationID: '',
			declineApplicationDialog: false,
			isLoaded: false,
			contacts: [],
			messages: [],
			searchcontacts: '',
			message: '',
			currentRoom: [],
			currentContact: [],
			onEmoji:false,
			fileuploading: false,
			fileUploadingProgress: 0,
			imagefile: '',
			isLoadedContacts: false,
			allmessages: [],
			limitContact: 1,
			emailTo: [],
			initRoom: 0,
			messageAttachments: [],
			chosenEmoji: null
		};
	
	}	


	componentDidMount() {
		
	}

	componentWillMount(){
		this.getContacts()
	}

	componentWillUnmount(){
		if(unsubscribe)
			unsubscribe()
	}
	

	async getContacts(){


		
		this.props.setLoading(true)
		const that = this
		let contacts = []
		let query

		const roomsRef = this.props.collection(this.props.db, "rooms");

		if(that.props.user.preference==="freelancers")
			query = this.props.query(roomsRef, this.props.where("from", "==", that.props.user.uid), this.props.orderBy("created_at","desc"), this.props.limit(contacts_limit) );
		else
			query = this.props.query(roomsRef, this.props.where("to", "==", that.props.user.uid), this.props.orderBy("created_at","desc"), this.props.limit(contacts_limit) );

		const querySnapshot = await this.props.getDocs(query);

		
		querySnapshot.forEach(async (doc,i) =>
		{
	 	    let id = doc.id
			let room = doc.data()
			room.id = id		
			
		
			let u
			if(that.props.user.preference==="freelancers")
	       		u = room.to
	       	else
	    	    u = room.from
	    	

	  	    const userRef = this.props.doc(this.props.db, "users", u );
			const userSnap = await this.props.getDoc(userRef);
	    	
	    	if (userSnap.exists())
	    	{

	    		let id = userSnap.id
	    		let userinfo = userSnap.data()
	    		userinfo.id = id
		    	room.userinfo = userinfo

		    	if( _.has(that.props.user,"newmessages") && that.props.user.newmessages.includes(id) )
		    		room.unread = [id]
		    	else
		    		room.unread = []

		    	contacts.push(room);
		    	gcontacts.push(room)
		    	that.setState({contacts:contacts})
	    	}

		});

		this.props.setLoading(false)
		this.setState({isLoaded:true})

	}
	

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	async sendEmailMessage(message, room){

		let query
		let that = this

		that.props.setMessageSent(that.props.messageSent+1)

		let roomRef = {}
		

		if(that.props.user.preference==="freelancers")
        	roomRef = this.props.doc(this.props.db, "rooms", room.to );
    	else
    		roomRef = this.props.doc(this.props.db, "rooms", room.from );

    	const roomSnap = await this.props.getDoc(roomRef);

    	if (roomSnap.exists())
    	{
    		let contact = roomSnap.data()
			contact.id = roomSnap.id

			fetch(FIREBASECONFIG.clfunc+"/sendEmailMessage",
			{
			    headers: {
			      'Accept': 'application/json',
			      'Content-Type': 'application/json'
			    },
			    method: "POST",
			    body: JSON.stringify({from: that.props.user, to: contact, message: message})
			})
			.then(function(res){ return res.json() })
			.then(function(data){
			})
			.catch(function(res){ console.log('error',res) })
    	}
		
	}

	linkify(text) {
	   let urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
	    return text.replace(urlRegex, function(url) {
	        return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">🖇️</a>';
	    });
	}

	async sendMessage(type){
		let that = this

		if(_.isEmpty( this.state.currentRoom.id ) )
			return

		let text = this.state.message
		let messageAttachments = that.state.messageAttachments

		if(messageAttachments.length>0){
			for(let i = 0; i<messageAttachments.length; i++)
			{
				text += "\r\n"+messageAttachments[i].url
			}
		}

		that.setState({message:'',messageAttachments:[]})

		const roomRef = this.props.doc(this.props.db, "rooms", that.state.currentRoom.id );
		const roomSnap = await this.props.getDoc(roomRef);

		if (roomSnap.exists())
		{
		
			let newmessage = {}

			if(type==="text")
				 newmessage = { room_id: this.state.currentRoom.id , user: that.props.user.uid, created_at: new Date(), type:'text', message: that.linkify(text), read:false } 
			else if(type==="image")
				 newmessage = { room_id: this.state.currentRoom.id , user: that.props.user.uid, created_at: new Date(), type:'image', message: text ,attachment: that.state.imagefile, read:false }
			else if(type==="file")
				 newmessage = { room_id: this.state.currentRoom.id , user: that.props.user.uid, created_at: new Date(), type:'file', message: text ,attachment: that.state.imagefile, read: false }


			const chathistoryRef = this.props.doc(this.props.collection(this.props.db, "chat_history"));

			await this.props.setDoc(chathistoryRef, newmessage).then( function()
			{

				that.props.updateDoc(roomRef, {
			        created_at: new Date()
			    }).then( ()=>{
			     	
			    });

        		const userRef = that.props.doc(that.props.db, "users", that.props.user.uid===that.state.currentRoom.to ? that.state.currentRoom.from : that.state.currentRoom.to  );

			   	that.props.updateDoc(userRef, {
			        newmessages: that.props.arrayUnion( that.props.user.uid  )
			    }).then( ()=>{
			     	
			    });

			});
			
			
		}

	
		
	}

	capitalize = (s) => {
	  if (typeof s !== 'string') return ''
	  return s.charAt(0).toUpperCase() + s.slice(1)
	}

	enterSend = (e) => {
		if(e.keyCode === 13){
        	this.sendMessage('text')
        }
	}

	async setRoom(room)
	{

		this.props.setLoading(true)
		let that = this
		let id = room.id
		let history = []

		if(unsubscribe){
			unsubscribe()
		}

		let idkey = id+"";

		if (!roomhistory.hasOwnProperty(idkey)) {
		  roomhistory[idkey] = {
		    history: []
		  };
		}else{

		}
		
		const roomRef = this.props.collection(this.props.db, "chat_history");
		let q = null
		let limit = chat_history_limit

		//get first 5 history
		 q = this.props.query(roomRef, this.props.where("room_id", "==", id),  this.props.orderBy('created_at',"desc"), this.props.limit(limit) );

		 let querySnapshot = await this.props.getDocs(q);

		 querySnapshot.forEach((doc) => {
		  	
		  	let chat = doc.data()
		    chat.id = doc.id
		    history.push(chat)

		 });

		 history.shift();
		 roomhistory[idkey].history = history
		
		 //create snapshot to pull one message at a time
		 limit = 1
		 q = this.props.query(roomRef, this.props.where("room_id", "==", id),  this.props.orderBy('created_at',"desc"), this.props.limit(limit) );

		
		 unsubscribe = this.props.onSnapshot(q, (querySnapshot) => {
		  
		  history = roomhistory[room.id].history

		  querySnapshot.forEach((doc) => {
		  	
		    let chat = doc.data()
		    chat.id = doc.id
		    history.unshift(chat)
		    chat_history_limit_last_date = chat.created_at

		  });
 
		  roomhistory[idkey].history = history
		  history = _.orderBy(history, ['created_at.seconds', 'created_at.nanoseconds'], ['asc', 'asc']);
		 
		  let contacts = that.state.contacts
	      var index = _.findIndex(contacts, {id: id});

	      contacts[index].unread = []

		  that.setState({ allmessages: history, currentRoom: room,currentContact: contacts[index], messages: history, contacts: contacts }, function(){
	  				
				if(scrollTo==="bottom"){
					this.scrollToBottom();
					scrollTo = "bottom"
				}else if(scrollTo==="top"){
					this.scrollToTop();
					scrollTo = "bottom"
				}

				let newmessageto = null

		  		if(that.props.user.uid===room.to)
		  			newmessageto = room.from
		  		else if(that.props.user.uid===room.from)
		  			newmessageto = room.to

		  		if(newmessageto!==null)
		  		{
		  			 const userRef = that.props.doc(that.props.db, "users", that.props.user.uid );
		  	
		  		   	 that.props.updateDoc(userRef, {
				        newmessages: that.props.arrayRemove( that.props.user.uid === room.to ? room.from : room.to )
				     }).then( ()=>{

				     	if(that.props.newMessageCount>0)
				     		that.props.setMessageCount(that.props.newMessageCount-1)
				     } );
		  		}

		  		that.props.setLoading(false)
	  	  })

		 });

	}

	scrollToBottom = () => {
	 
	  var objDiv = document.getElementById("chat-body-inner");
	  objDiv.scrollTop = objDiv.scrollHeight;

	}

	scrollToTop = () => {
	 
	  var objDiv = document.getElementById("chat-body-inner");
	  objDiv.scrollTop = 0;

	}

	addEmoji = (emoji) => {
		this.setState({message: this.state.message+" "+emoji.emoji, onEmoji:false})
	}


	moreMessages(){
	
		if(chat_history_limit>=50)
		{
			this.setState({snackbar:true,snackbarMessage:"Due to limited resources, we can no longer retrieve more messages."})
		}else{
			chat_history_limit+=5
			scrollTo="top"
			this.setRoom(this.state.currentContact)
		}
		
	}

	componentDidUpdate() {
	}

	_fileupload = (e) => {
		this.refs.fileUploader.click()
	}

	onChangeFile = (e) => {
	
		var file = e.target.files[0];
	    var type = 'image'
		
		if( file.size > 1000000 ){
			this.setState({snackbar:true, snackbarMessage: "You can only upload less than 1mb of file."})
			return
		}else{

			if( ! file.name.match(/\.(jpg|jpeg|png|gif)$/) )
				type ='file'
		}
		
		this.putStorageItem(file, type)
		

	}

	putStorageItem(item, type) {
	  // the return value will be a Promise
	  let that = this
	  that.setState({fileUploadingProgress: 0, fileuploading:true})
	  this.scrollToBottom();

	  const storageRef = this.props.storageRef(this.props.storage, 'uassets/'+that.props.user.uid+"/");
	  const uploadTask = this.props.uploadBytesResumable(storageRef, item);

	  uploadTask.on('state_changed', 
	  (snapshot) => {
	    // Get the upload progress as a percentage
	    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
	    that.setState({fileUploadingProgress: progress})
	  },
	  (error) => {
	    // Handle errors during the upload
	    console.error('Error uploading:', error);
	  },
	  () => {
	    // Upload is complete, get the download URL
	    that.props.getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
	   
	      that.setState({fileUploadingProgress: 0, fileuploading:false, imagefile: downloadURL, message: that.state.message+"\r\n"+item.name }, function(){
  			//that.sendMessage(type)
	  			let attachments = that.state.messageAttachments;
	  			let obj = {}
	  			obj.url = downloadURL
	  			obj.name = item.name
	  			attachments.push(obj)
	  			that.setState({messageAttachments: attachments})

	  		})

	    });
	  }
	);

	
	}


	render(){
	
		let that = this
		let contact_

		
		let contacts__ = this.state.contacts.map( (contact,i) =>{


				return (

					<ListItem className="contact-list" key={ i } onClick={ ()=> this.setRoom( contact ) }>
						<ListItemAvatar>
						  <Avatar style={{backgroundColor: '#00b16a', color:'#fff'}} alt={ contact.userinfo.fname+" "+contact.userinfo.lname } src={ !_.isEmpty( contact.userinfo.profile ) ?contact.userinfo.profile.avatar : '' } > { contact.userinfo.fname.charAt(0).toUpperCase()+""+contact.userinfo.lname.charAt(0).toUpperCase() } </Avatar>     
			        	</ListItemAvatar>
			        	<ListItemText primary={ contact.userinfo.fname+" "+contact.userinfo.lname } />
				        <ListItemSecondaryAction>
				        	{ contact.unread.length > 0 && <div className="badge">{ contact.unread.length }</div> }
				        </ListItemSecondaryAction>
			  		 </ListItem>
					)
			}
		)

		let messages = this.state.messages.map((item, i) => {

			
			let date
			let time


			if(item.created_at instanceof Date)
			{	
				 date = "Just now"
				 time = ""
			}else{
				 date = item.created_at.toDate().toDateString()
				 time = item.created_at.toDate().toLocaleTimeString('en-US')
			}


			if(item.user === that.props.user.uid){
			 	 contact_ = that.props.user
			}else{

			 	if( this.props.user.preference === 'freelancers' )
			  	    contact_ = _.find(this.state.contacts, function(o) { return o.to === item.user })
			  	else
			  		contact_ = _.find(this.state.contacts, function(o) { return o.from === item.user })

			
			  	 contact_ = _.has(contact_,'userinfo') ? contact_.userinfo : null
			}
			
			  
			
			 if( !_.isEmpty(contact_) )
			 {
			      return (
			        <div>
			      	<ListItem key={i} className='messagesList'>
				        <ListItemAvatar>
							  <Avatar style={{backgroundColor: '#00b16a', color:'#fff'}} alt={ contact_.fname } src={ !_.isEmpty( contact_.profile ) ? contact_.profile.avatar : '' }>{ contact_.fname.charAt(0).toUpperCase()+""+contact_.lname.charAt(0).toUpperCase() }</Avatar>     
				        </ListItemAvatar>
				        { item.type ==="text" && 
				           
				        	<ListItemText secondary={date+" "+time} primary={ <div className={ item.user !== that.props.user.uid ? 'chat-bubble' : 'chat-bubble-invert' } dangerouslySetInnerHTML={{__html: item.message.replace(/(?:\r\n|\r|\n)/g, "<br />") }}></div> } />

				    	}

				    	{ item.type ==="image" && 
				        	<ListItemText primary={ <div className="chat-bubble"><a href={item.attachment} target="_blank" rel="noopener noreferrer"><img alt="chat" className="chat-image" src={ item.attachment } /><br/>{ item.message }</a></div> } />
				    	}


				    	{ item.type ==="file" && 
				        	<ListItemText primary={ <div className="chat-bubble"><a href={item.attachment} target="_blank" rel="noopener noreferrer"><AttachmentIcon style={{fontSize: 36}} /> <br/>{item.message}</a></div> } />
				    	}


			        </ListItem>
			        <Divider />
			        </div>
			      );
			 }else{
			 	return
			 }

			
		    });

		
		
		return (

			<div>
			
			<header>
			  { this.state.isLoaded === true &&
		          <div style={{marginTop: 100}}>
			        	
			        	<Grid container spacing={2}>    
			        		<Grid item xs={12} md={2}></Grid> 		
			          		<Grid item xs={12} md={8}> 
			          			<h1 className="App-title">Messages</h1>

			          			<div className="App-panel chat-panel">
			          				<Grid container>
				          					<Grid xs={12} md={4} style={{padding:0}}>
				          						<div style={{paddingLeft: 10, paddingRight: 10}}>
				          							<TextField
				          						    placeholder="Search Contact"
				          							id="search-messages"
						          					error={ false }
						          					name='search-messages'
											      	variant="outlined"
											      	InputProps={{
											          startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
											        }}
											      	margin="normal" value={this.state.searchcontacts} fullWidth={true} onChange={ (event) => { 
											      		
											      		that.setState({searchcontacts: event.target.value })

											      		if(event.target.value===''){
											      			that.setState({contacts: gcontacts })
											      			return
											      		}
											      			
											      		var scontacts =_.filter(gcontacts,function(item){
											      			let name = item.userinfo.fname+" "+item.userinfo.lname
											      			return _.includes(name.toLowerCase(), event.target.value.toLowerCase())
											      		});

											      		scontacts = _.uniqBy(scontacts, 'to');
													    that.setState({contacts: scontacts })

											      	 } } />
											    </div>

				          						{ this.state.contacts.length === 0 && this.state.isLoadedContacts === true &&
						          					<div style={{flex: 1, textAlign:'center'}}>
						          						<img alt="contacts" src="/contacts.png" style={{width: 50, height: 50}} />
						          						<Typography>No contacts to show</Typography>
						          					</div> 
						          				}

						          				{ this.state.contacts.length > 0 && 
						          					<List style={{maxHeight:'450px',overflow: 'auto'}}>
						          						{contacts__}
						          					</List>
						          				}

				          					</Grid>
				          					<Grid item xs={12} md={8} className="chat-body">
				          						<div className="chat-body-title">
				          							<List>
						          						<ListItem key={ 0 }>
				          									{ !_.isEmpty( this.state.currentContact.id ) ?
				          										<ListItemAvatar>
				          									  		<Avatar style={{backgroundColor: '#00b16a', color:'#fff'}} alt={ this.state.currentContact.userinfo.fname+" "+this.state.currentContact.userinfo.lname } src={ !_.isEmpty( this.state.currentContact.userinfo.profile ) ? this.state.currentContact.userinfo.profile.avatar : '' } > { this.state.currentContact.userinfo.fname.charAt(0).toUpperCase()+""+this.state.currentContact.userinfo.lname.charAt(0).toUpperCase() } </Avatar>     
													        	</ListItemAvatar>
													        	: <Typography></Typography>
													        }

													        {	!_.isEmpty( this.state.currentContact.id ) ?
													        		<ListItemText primary={ this.capitalize( this.state.currentContact.userinfo.fname )+" "+ this.capitalize( this.state.currentContact.userinfo.lname ) } />
													        	: <Typography>Conversation</Typography>
													    	}
														</ListItem>
						          					</List>
						          					
				          							
				          						</div>



						          				<div className="chat-body-inner" id="chat-body-inner" ref={ el => { this.messagesEndRef = el} } >

						          					{ this.state.messages.length === 0 && 
							          					<div style={{flex: 1, textAlign:'center'}}>
							          						<br/><br/><br/><br/>
							          						<img alt="conversation" src="/conversation.png" style={{width: 100, height: 100}} />
							          						<Typography>No messages to show</Typography>
							          					</div> 
							          				}

							          				{ this.state.messages.length > 0 &&
							          						<List>

							          							{ this.state.allmessages.length >= chat_history_limit &&
								          							<ListItem style={{textAlign:'center', flex: 1, justifyContent:'center'}}>
									          								<Tooltip title="Load More">
										          								<IconButton onClick={ ()=> { this.moreMessages() } }>
																		              <HistoryIcon />
																		        </IconButton>
																	        </Tooltip>
									          						</ListItem>
								          						}
							          							
								          					{ messages }
								          					{ this.state.fileuploading === true &&
								          						<LinearProgress variant="buffer" value={this.state.fileUploadingProgress} color="secondary" />
								          					}
								          					</List>
								          				
							          				}

						          				</div>
						          				<div style={{padding:'10px'}} className="chat-input">
						          				  <TextField
											          rows={4}
											          maxRows={4}
											          multiline
											          fullWidth={true}
											          id="outlined-start-adornment"
											          value={this.state.message}
											          onChange = { (event)=> this.setState({message: event.target.value}) }
											          
											          variant="outlined"
											        />

						          				</div>

						          				<List style={{paddingTop:0}}>
												      <ListItem style={{paddingTop:0}}>
												      		<Tooltip title="Send Message">
													      		<IconButton
												                  aria-label="toggle password visibility"
												                  onClick={ ()=>  this.sendMessage('text') }
												                  onMouseDown={ ()=> {}  }
												                >
												                 <Send />
												                </IconButton>
												            </Tooltip>
												            <Tooltip title="Attach Emoticon">
													       		<IconButton
												                  aria-label="toggle password visibility"
												                  onClick={ ()=>{ that.state.onEmoji===true ? that.setState({onEmoji:false}) : that.setState({onEmoji:true}) } }
												                  onMouseDown={ ()=>{} }
												                >
												                 <InsertEmoticon />
												                </IconButton>
											                 </Tooltip>

											                <Tooltip title="Attach File">
												                <IconButton
												                  aria-label="toggle password visibility"
												                  onClick={ ()=> { this._fileupload() } }
												                  onMouseDown={ ()=>{} } >
												                 <AttachFile />
												                 <input onChange={ (e)=> this.onChangeFile(e) } type="file" id="file" ref="fileUploader" style={{display: "none"}}/>
												                </IconButton>
											                 </Tooltip>
												      </ListItem>
												</List>

											   { this.state.onEmoji === true &&  
											   	<div className="emoji-container">
											   		<EmojiPicker onEmojiClick={ this.addEmoji} />
											   		{this.state.chosenEmoji && (
											          <div>Chosen Emoji: {this.state.chosenEmoji.emoji}</div>
											        )}
											    </div>
											   }
											      

				          					</Grid>
			          					</Grid>
			          			</div>

			          		</Grid>
			          		<Grid item xs={12} md={2}></Grid>
			          		
			          	</Grid>
			        
			      </div>
		  	  }

		      { this.state.isLoaded === false &&
                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                        <CircularProgress style={{color:'#00b16a', alignSelf:'center'}} />
                    </div>
              }
	        </header>

	        
	        <Snackbar
		          anchorOrigin={{
		            vertical: 'bottom',
		            horizontal: 'center',
		          }}
          		open={this.state.snackbar}
          		autoHideDuration={3000}
          		onClose={this.snackbarClose}
          		ContentProps={{
            		'aria-describedby': 'message-id',
          		}}
          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
		          action={[
		            <IconButton
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              onClick={this.snackbarClose}
		            >
		              <CloseIcon />
		            </IconButton>,
		          ]} />
	    </div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		newMessageCount: state.newMessageCount,
		messageSent: state.messageSent,
		contacts: state.contacts,
		getDoc: state.getDoc,
		collection: state.collection,
		db: state.db,
		where: state.where,
		query: state.query,
		getDocs: state.getDocs,
		uploadBytesResumable: state.uploadBytesResumable,
		storageRef: state.storageRef,
		storage: state.storage,
		getDownloadURL: state.getDownloadURL,
		doc: state.doc,
		updateDoc: state.updateDoc,
		onSnapshot: state.onSnapshot,
		orderBy: state.orderBy,
		limit: state.limit,
		arrayUnion: state.arrayUnion,
		arrayRemove: state.arrayRemove,
		setDoc: state.setDoc
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		setMessageCount: (newMessageCount) => dispatch({type: "SET_MESSAGE_COUNT", payload: {newMessageCount:newMessageCount}}),
		setMessageSent: (messageSent) => dispatch({type: "SET_MESSAGE_SENT", payload: {messageSent:messageSent}}),
		setContacts: (contacts) => dispatch({type: "SET_CONTACTS", payload: {contacts:contacts}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Message);





