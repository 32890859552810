import React, { Component } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import _ from "lodash";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import jobCategories from '../../constants/jobCategories.json'
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';

import Grid from '@mui/material/Grid';

const styles = {
   small: {
   	color: '#8e8e8e',
   	fontFamily: 'Gotham Rounded'
   },

   em: {
   	 color: '#8e8e8e',
   	 fontFamily: 'Gotham Rounded'
   },

   buttonPrimary: {
   	padding: 18,
   	fontFamily: 'Gotham Rounded',
   	backgroundColor: '#00b16a',
   	color: '#fff'
   }
}


class AdminSettings extends Component{

	
	constructor(props){
		
		super(props);

		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		snackbar: false,
    		snackbarMessage: '',
    		isLoaded: false,
			users: [],
			openActionMenu: false,
			clientsCount: 0,
			freelancerCount: 0,
			dialogReason: false,
			actionStatus:'',
			actionID: [],
			reasonText: '',
			notificationTitle: '',
			pendings: [],
			selectedStat : "PENDING",
			clientCount: 0,
			searchstring: '',
			newJobCategory: '',
			addJobCategoryDialog:false,
			categoryName: '',
			categoryNameError: false,
			categoryDescription: '',
			categoryReference: '',
			skillName: '',
			skillNameError: false,
			skillDescription: '',
			skillReference: '',
			searchJobCategory: '',
			renderJobCategories: [],
			renderSkills: [],
			searchJobCategoryDialog: false,
			searchSkillsDialog: false,
			addSkillDialog: false,
			maintenance: false,
			enableAds: false,
			funding: false,
			stat: [],
			stat_id: '',
			isLoading: false,
			min_funding: 0,
			max_funding: 0,
			min_withdrawal: 0,
			max_withdrawal: 0,
			jobCategory: '',
			numberOfJobs: 5,
			numberOfFreelancers: 50,
			alljobs: [],
			allfreelancers: [],
			addAffiliateLink:'',
			addAffiliateName: '',
			addAffiliateDescription: '',
			affiliateLinks: []
		};
	
	}	

	handleChange = (event, value) => {
	    this.setState({ submenu: value });
	};

	componentDidMount() {
		
		this.getStat()
	}

	async getStat(){

		let that = this

		const querySnapshot = await this.props.getDocs(this.props.collection(this.props.db, "stats"));
        
        querySnapshot.forEach((doc) => {  
        	let stat = doc.data()
			stat.id  = doc.id
			that.setState({ min_funding:stat.min_funding, max_funding: stat.max_funding, min_withdrawal: stat.min_withdrawal, max_withdrawal: stat.max_withdrawal , funding: stat.funding, maintenance:stat.maintenance,stat_id: stat.id, enableAds:stat.enable_ads})
        	
        	that.props.setLoading(false)
			that.setState({isLoaded: true, stat: stat})
        });

	}

	
	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	jsUcfirst = (string) =>
	{
	    let str = string.charAt(0).toUpperCase() + string.slice(1);
	    return str
	}

	handleChangePage = (event, newPage) => {
    }

    handleChangeRowsPerPage = event => {

    }
	
	async save()
	{	
		this.props.setLoading(true)
		let that = this
		const statsRef = that.props.doc(that.props.db, "stats",that.state.stat.id);
		const statsSnap = await that.props.getDoc(statsRef);

		that.props.updateDoc(statsRef, {
		        maintenance: that.state.maintenance,
		        funding: that.state.funding,
		        min_funding: that.state.min_funding,
		        max_funding: that.state.max_funding,
		        min_withdrawal: that.state.min_withdrawal,
		        max_withdrawal: that.state.max_withdrawal
		}).then( function(){
		 	that.props.setLoading(false)

		});
	}

	render(){
	
		
		let that = this

		let renderAffiliateLinks = this.state.affiliateLinks.map(function(item,i){
          			return (
          				<div>
          				<ListItem key={i} component="a" href={item.link} target="_blank">
			                  <ListItemText
			                    primary={item.name}
			                    secondary={item.description}
			                  />
			                  <ListItemSecondaryAction>
			                    <IconButton edge="end" aria-label="delete" onClick={ ()=>{
			                    	that.removeAffiliateLink(item.id)
			                    } }>
			                      <DeleteIcon />
			                    </IconButton>
			                  </ListItemSecondaryAction>
			             </ListItem>
			             <Divider variant="inset" component="li" />
			             </div>
          				)
          		});

		let renderJobCategories = this.state.renderJobCategories.map(function(item,i){
          			return (
          				<ListItem key={i}>
          					<ListItemText>{item.name}</ListItemText>
          					<ListItemSecondaryAction>
				              <IconButton onClick={ ()=> that.removeJobCategory(item) }>
				                <CloseIcon />
				              </IconButton>
				            </ListItemSecondaryAction>
          				</ListItem>
          				)
          		});

		
		

		return (

			<div>
			
				<header>
				  { this.state.isLoaded === true &&
			          <div style={{marginTop: 100}}>
				          
				          <Grid container spacing={2}>
				          		<Grid item xs={12} md={2}></Grid>
				          		<Grid item xs={12} md={8}>

					          		 <h1 className="App-title">Admin Utility</h1>
					          		 {
					          		 	this.state.isLoading===true &&
						          		 <Box sx={{ width: '100%' }}>
									      	<LinearProgress />
									   	 </Box>
								   	 }

					          		 <hr/>

							         <div>
						         		<Grid container spacing={2}>
					         				<Grid item xs={6} md={6}>
					         					 <FormControl component="fieldset" variant="standard">
											      <FormGroup>
											        <FormControlLabel
											          control={
											            <Switch  sx={{ "&.MuiSwitch-root .Mui-checked": {
   																				color: "#00b16a"
															  },
															  ".MuiSwitch-track": {
															    backgroundColor: "rgba(0, 177, 106, 0.5) !important"
															  } 
															}}checked={this.state.maintenance} onChange={ ()=> { this.setState({ maintenance: this.state.maintenance===true?false:true })   }  } name="maintenance" />
											          }
											          label="Enable/Disable Maintenance"
											        />
											       
											      </FormGroup>
											    </FormControl>
					         				</Grid>
					         				<Grid item xs={6} md={6}>
					         					 <FormControl component="fieldset" variant="standard">
											      <FormGroup>
											        <FormControlLabel
											          control={
											            <Switch  sx={{ "&.MuiSwitch-root .Mui-checked": {
   																				color: "#00b16a"
															  },
															  ".MuiSwitch-track": {
															    backgroundColor: "rgba(0, 177, 106, 0.5) !important"
															  } 
															}} checked={this.state.funding} onChange={ ()=>{ this.setState({ funding: this.state.funding===true?false:true }) } } name="funding" />
											          }
											          label="Enable/Disable Funding"
											        />
											       
											      </FormGroup>
											    </FormControl>
					         				</Grid>
					         			
					         				<Grid item xs={4} md={4}>
					         					<TextField label="Min Funding Amount" variant="outlined" fullWidth value={this.state.min_funding} onChange={(event)=> { this.setState({min_funding:event.target.value}) }  }  />
					         				</Grid>
					         				<Grid item xs={2} md={2}></Grid>
					         				<Grid item xs={4} md={4}>
					         					<TextField label="Max Funding Amount" variant="outlined" fullWidth value={this.state.max_funding} onChange={(event)=> { this.setState({max_funding:event.target.value}) } } />
					         				</Grid>
						         			
					         				<Grid item xs={4} md={4}>
					         					<TextField label="Min Withdrawal Amount" variant="outlined" fullWidth value={this.state.min_withdrawal} onChange={(event)=> { this.setState({min_withdrawal:event.target.value}) }  } />
					         				</Grid>
					         				<Grid item xs={2} md={2}></Grid>
					         				<Grid item xs={4} md={4}>
					         					<TextField label="Max Withdrawal Amount" variant="outlined" fullWidth value={this.state.max_withdrawal} onChange={(event)=> { this.setState({max_withdrawal:event.target.value}) }  } />
					         				</Grid>
						         		</Grid>
					          		 </div>
					          		 <br/>
					          		 <div>
					          		 	<Grid container spacing={2}>
					          		 		<Grid item xs={6} md={6}></Grid>
					          		 		<Grid item xs={6} md={6}>
					          		 			 <Button style={styles.buttonPrimary} variant="contained" onClick={ ()=>{ this.save() } }>Save</Button>
					          		 		</Grid>
					          		 	</Grid>
					          		 </div>
				          		
				          		
				          		</Grid>
				          		<Grid item xs={12} md={2}></Grid>
				          
				          </Grid>
				      </div>
			  	  }

			      { this.state.isLoaded === false &&
	                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
	                        <CircularProgress style={{color:'#00b16a', alignSelf:'center'}} />
	                    </div>
	              }
		        </header>

		        <Snackbar
			        anchorOrigin={{
			          vertical: 'bottom',
			          horizontal: 'right',
			        }}
			        open={this.state.snackbar}
			        autoHideDuration={1000}
			        onClose={ ()=>this.setState({snackbar:false}) }
			        message={this.state.snackbarMessage} />

	    	</div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		clientCount: state.clientCount,
		freelancerCount: state.freelancerCount,
		collection: state.collection,
		db: state.db,
		getDocs: state.getDocs,
		docs: state.docs,
		doc: state.doc,
		getDoc: state.getDoc,
		updateDoc: state.updateDoc,
		isLoading: state.isLoading
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setClientCount: (count) => dispatch({type: "SET_CLIENT_COUNT", payload: {clientCount: count}}),
		setFreelancerCount: (count) => dispatch({type: "SET_FREELANCER_COUNT", payload: {freelancerCount: count}}),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);





