import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterList';
import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { connect } from 'react-redux'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import _ from 'lodash'
import { registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import FormGroup from '@mui/material/FormGroup';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Slider from '@mui/material/Slider';
//import { createMuiTheme } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
//import SwipeableViews from 'react-swipeable-views';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import moment from 'moment'
import jobCategories from '../../constants/jobCategories.json'
import algoliasearch from 'algoliasearch/lite';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import {  ClearRefinements, Menu, Pagination, Stats, HitsPerPage , InstantSearch, SearchBox, connectRefinementList , connectSearchBox,connectHits,connectStateResults, Configure  } from 'react-instantsearch-dom';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const searchClient = algoliasearch(
  'RVNFJDRY7K',
  'a8193b8b5c757eb10d9aed404982d07f'
);


let freelancerObj

const SearchBoxCustom = ({ currentRefinement, isSearchStalled, refine }) => {

	
	return ( <TextField
		name='search'
      	variant="outlined"
      	placeholder="Search Keyword"
      	InputProps={{
          startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          endAdornment: <InputAdornment position="end"><Tooltip title="Advance Search Filter"><IconButton onClick={ () => { freelancerObj.setState({searchFilterDialog:true}) } }><FilterIcon /></IconButton></Tooltip></InputAdornment>,
        }}
        onChange={event => refine(event.currentTarget.value)}
      	margin="normal" style={{width: '99.5%'}} fullWidth={false} value={currentRefinement} />
      	)

};

const CustomSearchBox = connectSearchBox(SearchBoxCustom);



const Results = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <div>
		<br/><br/>        		 	    
	 	<center>
	 		<Stack spacing={1}>
	 		  <center>
		      <Skeleton variant="circular" width={40} height={40} /> <br/> 
		      <Skeleton variant="rectangular" width={210} height={60} /> <br/> 
		      <Skeleton variant="rounded" width={210} height={60} />
		      </center>
		    </Stack>

 		</center>
 		<br/>
 	</div>
    )
);


const RefinementList   = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
}) => (

	<ul>
   
    {/*items.map(item => (

      <li key={item.label}>
      <FormControlLabel key={item.objectID}
		        control={
		          <GreenCheckbox onClick={event => {
            event.preventDefault();
            refine(item.value);
          }}  checked={item.isRefined } value={item.value} data-value={item.value} />
		        }
	        label={item.label.charAt(0).toUpperCase() + item.label.slice(1)} />
      </li>
    ))*/}
  </ul>
	

)


const CustomHits = connectHits(({hits, data}) => {
   return (
   		hits.map( (hit,i)=>{

   						return (

   								<ListItem key={hit.objectID} style={{padding:20}}>
									<Card key={hit.objectID}>
								      <CardContent style={{width: 200, height: 200}}>
								      	<center>
								      	{  _.has(hit,'avatar') && hit.avatar !=="" ? <Avatar alt={ "Hire "+ hit.fname +" "+hit.lname } style={styless.bigAvatar} src={hit.avatar} /> : <Avatar style={styless.bigAvatar}>{hit.fname.charAt(0).toUpperCase()+hit.lname.charAt(0).toUpperCase()}</Avatar>}
								       	</center>
								       	<center>
								       		<b>{ _.startCase(hit.fname)+" "+_.startCase(hit.lname.charAt(0))+"." }</b>
								       		<Tooltip title={ _.has(hit,'title') ? hit.title : "Freelancer" }>
								       			<Typography>{ _.has(hit,'title') ? ( hit.title.length > 30 ? hit.title.substring(0, 30)+".." : hit.title.substring(0, 25) ) : '' }</Typography>
											</Tooltip>
								       		<Tooltip title='Hourly Rate'><b style={{color:'rgb(0, 177, 106)'}}>{ _.has(hit,'hourlyRate') ? '$'+Number(hit.hourlyRate).toLocaleString()+'hr - ' : '' }</b></Tooltip>
								       		<Tooltip title='Full Time Rate'><b style={{color:'rgb(0, 177, 106)'}}>{ _.has(hit,'fullTimeRate') ? '$'+Number(hit.fullTimeRate).toLocaleString()+'mo ' : '' }</b></Tooltip>
								       	</center>
								      </CardContent>
								      <CardActions style={{justifyContent:'center'}}>
							      			{ /*<Tooltip title='Add to Favorites'>
							                    <IconButton
											        aria-label="Favorites"
											        aria-controls="long-menu"
											        aria-haspopup="true"
											       
											      >
											       <FavoriteBorderIcon />  
											    </IconButton>
										    </Tooltip>*/ }
										    <Tooltip title='View Profile'>
							                    <IconButton
											        aria-label="Profile"
											        aria-controls="long-menu"
											        aria-haspopup="true"
											         onClick={()=> freelancerObj.goToProfile(hit) }
											      >
											        <PersonIcon />
											    </IconButton>
										    </Tooltip>
										    <Tooltip title='Invite to Job'>
							                    <IconButton
											        aria-label="Invite to Job"
											        aria-controls="long-menu"
											        aria-haspopup="true"
											         onClick={()=> { 
											         	hit.id = hit.objectID
											         	freelancerObj.setState({jobsDialog:true, selectedFreelancer: hit }) } }
											      
											      >
											        <CheckIcon />
											    </IconButton>
										    </Tooltip>
								      </CardActions>
								    </Card>
								</ListItem>

   							 )

   					} )
   	)
});


const CustomRefinementList = connectRefinementList(RefinementList);
const VirtualRefinementList = connectRefinementList(() => null);


registerPlugin(FilePondPluginImagePreview);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



const invitationMessage="Hi, i would like to invite you to my job.";
const offerMessage="Hi, i am sending you a job offer.";

const styless = {
	bigAvatar:{
		margin: 10,
		width: 100,
		height: 100,
		alignSelf:'center',
		cursor: 'pointer'
	},
	title: {
		color: '#333333',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 24
	},
	subtitle: {
		color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 18
	},
	center:{
		textAlign:'center',
		color: '#3c4859'
	},

	small: {
	   	color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10
	   },

	   em: {
	   	 color: '#8e8e8e',
	   	 fontFamily: 'Gotham Rounded'
	   },

	   buttonPrimary: {
	   	padding: 12,
	   	fontFamily: 'Gotham Rounded',
	   	backgroundColor: '#00b16a',
	   	color: '#ffffff'
	   },

	   buttonDefault: {
	   	padding: 12,
	   	fontFamily: 'Gotham Rounded',
	   	borderColor: '#00b16a',
	   	color: '#00b16a',
	   }

}

const hourlyRateFilterMax = 500
const monthlyRateFilterMax = 5000

function scrollLeft(element, change, duration, that)
{
	
    var start = element.scrollLeft,
        currentTime = 0,
        increment = 20;
        
    var animateScroll = function(){        
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollLeft = val;


        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
            
        }else{
        	if(val>element.clientWidth)
        	{
        		that.setState({limit: that.state.limit+5}, ()=>{
        			that.getFreelancers()
        			
        		})
        	}
        	
        }
    };
    animateScroll();
}

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
	if (t < 1) return c/2*t*t + b;
	t--;
	return -c/2 * (t*(t-2) - 1) + b;
};

function formatCash (n) {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

class Freelancer extends Component{
	

	constructor(props){
		
		super(props);

		freelancerObj = this

		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		snackbar: false,
    		snackbarMessage: '',
    		applyDialog: false,
			job: [],
			isLoaded: false,
			jobs: [],
			jobsUnfiltered: [],
			jobCategories: [],
			jobCategoriesFilter:[],
			durations: [],
			durationsFilter:[],
			budgetMin:0,
			budgetMax: 100,
			jobTypes: [],
			jobTypesFilter:[],
			rowLimit: 10,
			rowIncrement: 10,
			profileStatus: '',
			hasSubmitProfile: 0,
			freelancers: [],
			favorites: [],
			allFavs: false,
			expertiseEntry: true,
			expertiseIntermediate: true,
			expertiseExpert: true,
			hourlyRateFilter: [0,100],
			monthlyRateFilter: [0,100],
			hourlyRateMin : 0,
			hourlyRateMax : 500,
			monthlyRateMin : 0,
			monthlyRateMax : 5000,
			limit: 50,
			searchString: '',
			searchFilterDialog:false,
			expertiseLevel: [{'name':'entry','checked':true},{'name':'intermediate','checked':true},{'name':'expert','checked':true}],
			jobsDialog:false,
			inviteTabIndex: 0,
			inviteMessage: "Hi, I would like to invite you to my job.",
			inviteJobId:'',
			inviteType: 'INVITATION',
			selectedFreelancer: [],
			inviteSuccessMessage: '',
			inviteIcon: '/verified.png',
			isLoaded: true,
			jobcategoriesRefinementList: [],
			expertiseRefinementList: []

		};
	
	}	

	componentDidMount() {
	
		this.getJobs()		
	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	async notifyClient(type,freelancer,job)
	{	

		let that = this

		const userRef = this.props.doc(this.props.db, "users", freelancer.id );
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
			let user = userSnap.data()
	
			let message = type === "invitation" ?  MESSAGES.JOB_APPLICATION_INVITE : MESSAGES.JOB_APPLICATION_OFFER
			message = message.replace("{jobPostTitle}", job.jobPostTitle )
			message = message.replace("{date}", new Date())
			message = message.replace("{reason}",that.state.inviteMessage)
			
			fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
			{
			    headers: {
			      'Accept': 'application/json',
			      'Content-Type': 'application/json'
			    },
			    method: "POST",
			    body: JSON.stringify({ title: type==="invitation" ? "New Job Invitation" : "New Job Offer", name: user.fname+" "+user.lname, to: user.email, message: message })
			})
			.then(function(res){ return res.json() })
			.then(function(data){
				//console.log('notifyClient',data)
			})
			.catch(function(res){ 
				console.log('error',res)
			})
		}

	}


	async createNotification(type,to){

		let message  =''
		let link = ''
		let iconname = ''
		if(type==="NEW_JOB_INVITE")
		{
			message = "You received a job invitation"
			link = "/my-applications/"
			iconname = "work"
		}else if(type==="NEW_JOB_OFFER")
		{
			message = "You received a job offer"
			link = "/my-applications/"
			iconname = "work"
		}

		const notificationsRef = this.props.doc(this.props.collection(this.props.db, "notifications"));

		await this.props.setDoc(notificationsRef, {
			to: to,
			icon: iconname,
			type: type,
			unread: true,
			link: link,
			message: message,
			created_at: new Date()
		}).then(function(){
						
		})
		.catch(function(error){
			console.log('error: ',error);
		});
	}

	async sendInvite(){

		this.props.setLoading(true)
		let that  = this

		const userRef = this.props.doc(this.props.db, "users", this.props.user.uid );
		const userSnap = await this.props.getDoc(userRef);
		let userdata

		if (userSnap.exists())
		{
			userdata = userSnap.data()
		}

		const freelancerRef = this.props.doc(this.props.db, "users",  that.state.selectedFreelancer.id );
		const freelancerSnap = await this.props.getDoc(freelancerRef);
		let freelancerdata

		if (freelancerSnap.exists())
		{
			freelancerdata = freelancerSnap.data()
		}
		
		let jpc = _.has(userdata,'jpc') ? userdata.jpc : 0

		if( !_.has(this.state.inviteJobId,'id') )
		{
			this.setState({snackbar:true, snackbarMessage:"Please select a job post"})
			return
		}

		if(this.state.inviteTabIndex===1)
		{
			
			
			const jaRef = this.props.collection(this.props.db, "jobApplications");
			const q = this.props.query(jaRef, this.props.where("jobID", "==", that.state.inviteJobId.id), this.props.where("uid", "==", that.state.selectedFreelancer.id ) );
			const querySnapshot = await this.props.getDocs(q);

			if(jpc < 1 )
			{

				this.setState({snackbarMessage: "Sorry you dont have enough credits to send offer or invite to this freelancer for this job.", snackbar: true})
				this.props.setLoading(false)
			}else if( querySnapshot.size>0 ){
				that.setState({inviteIcon: "/warning.png",  inviteTabIndex: that.state.inviteTabIndex+1, inviteSuccessMessage: "You already sent a job invitation or offer to the freelancer." })
				this.props.setLoading(false)
			}else{
				
				if( that.state.inviteType==="INVITATION" )
				{

					const docRef = await this.props.addDoc(this.props.collection(this.props.db, "jobApplications"), {
					 	uid: that.state.selectedFreelancer.id,
						jobID: that.state.inviteJobId.id,
						jobPostTitle: that.state.inviteJobId.jobPostTitle,
						coverLetter: '',
						proposedRate: '',
						attachments: '',
						otherLinks: '',
						invite_at : new Date(),
						applied_at: new Date(),
						status: that.state.inviteType,
						inviteMessage: that.state.inviteMessage,
						user: {
							avatar: _.has(freelancerdata,"profile") ? freelancerdata.profile.avatar : "" ,
							email: freelancerdata.email,
							fname: freelancerdata.fname,
							lname: freelancerdata.lname

						}
					}).then(function() {
					
						that.notifyClient('invitation', that.state.selectedFreelancer, that.state.inviteJobId )
						that.setState({ inviteIcon: '/verified.png', inviteTabIndex: that.state.inviteTabIndex+1, inviteSuccessMessage: 'Job invitation successful.' })
					   	
					   	that.createNotification("NEW_JOB_INVITE",that.state.selectedFreelancer.id)

					   	this.props.setLoading(false)
						
					}).catch(function(error) {
						console.log('error')
					    that.setState({snackbar:true,snackbarMessage:"Error"})
					    that.props.setLoading(false)
					});


				}else{

					const docRef = await this.props.addDoc(this.props.collection(this.props.db, "jobApplications"), {
					 	
					 	uid: that.state.selectedFreelancer.id,
						jobID: that.state.inviteJobId.id,
						jobPostTitle: that.state.inviteJobId.jobPostTitle,
						coverLetter: '',
						proposedRate: '',
						attachments: '',
						otherLinks: '',
						offer_at : new Date(),
						applied_at: new Date(),
						status: that.state.inviteType,
						inviteMessage: that.state.inviteMessage,
						user: {
							avatar: _.has(freelancerdata,"profile") ? freelancerdata.profile.avatar : "" ,
							email: freelancerdata.email,
							fname: freelancerdata.fname,
							lname: freelancerdata.lname

						}

					}).then(function() {
					
						that.notifyClient('offer',that.state.selectedFreelancer, that.state.inviteJobId)
						that.setState({ inviteIcon: '/verified.png', inviteTabIndex: that.state.inviteTabIndex+1, inviteSuccessMessage: 'Job invitation successful.' })
					    that.createNotification("NEW_JOB_OFFER",that.state.selectedFreelancer.id)
					    this.props.setLoading(false)
						
					}).catch(function(error) {
						console.log('error')
					    that.setState({snackbar:true,snackbarMessage:error})
					    this.props.setLoading(false)
					});

				}

				this.props.updateDoc(userRef, {
			        jpc: jpc-1
			    }).then( function(){
			      
			    });

			}
		}else{
			this.setState({inviteTabIndex: this.state.inviteTabIndex+1})	
			this.props.setLoading(false)
		}
	
	}


	async getJobs(){

		let jobs_ = []

		if( localStorage.getItem('jobs')  )
		{
			this.setState({ isLoaded: true, jobs : JSON.parse(localStorage.getItem('jobs')) })
		}else{

			const jobsRef = this.props.collection(this.props.db, "jobs");
		  	const q = this.props.query(jobsRef, this.props.where("user", "==", this.props.user.uid ), this.props.where("status", "!=", "EXPIRED"), this.props.orderBy('status',"desc"),  this.props.orderBy('created_at',"desc") );

		  	const querySnapshot = await this.props.getDocs(q);
			querySnapshot.forEach((doc) => {
			  
			    let data = doc.data()
				data.id = doc.id
				jobs_.push(data)
			});

			localStorage.setItem('jobs', JSON.stringify(jobs_) );
			this.setState({ isLoaded: true, jobs : jobs_ })
		}

	}


	goToProfile(freelancer){
		window.open('/freelancer/'+freelancer.objectID, '_blank');
	}

	render(){
	
		let that = this
		let freelancersList
		let jobCategories
		let jobExpertise
		let jobsList

	
		jobsList = this.state.jobs.map(function(item,i){
				let job = item

				let expires_at = job.expires_at;
				let now = moment()
				let expires = moment( new Date(expires_at.seconds * 1000) )
				let expires_in = expires.diff(now,'days')
			
				if(job.status==="ACTIVE" && expires_in > 0)
				{
						return (
							<ListItem key={i} button onClick={()=>{ that.setState({inviteJobId: job, inviteTabIndex: that.state.inviteTabIndex+1}) }}>
			                  <ListItemText secondary={_.has(job,'jobCategory') ? job.jobCategory : ''}>
			                  	<b>{_.has(job,'jobPostTitle') ? job.jobPostTitle : ''}</b>
			                  </ListItemText>
			                </ListItem>
						)
						
				}
			})

	
		return (

			<div>
			
			<header>
			  { this.state.isLoaded === true &&
		          <div style={{marginTop: 100}}>
			          
			          	<Grid container spacing={2}>
			          		<Grid item xs={1} md={2}>
			          		</Grid>
			          		<Grid item xs={10} md={8}>


			          		 <h1 className="App-title">
						          Find Freelancers
						     </h1>

						    
						    <div>		

						    	<InstantSearch  indexName="freelancers" searchClient={searchClient}

						    	 onSearchStateChange={(state) => {
							            if (that.state.searchFilterDialog && state.refinementList.jobcategories) {
							          
							              that.setState({jobcategoriesRefinementList: state.refinementList.jobcategories})
							            }

							            if (that.state.searchFilterDialog && state.refinementList.expertise) {
							              that.setState({expertiseRefinementList: state.refinementList.expertise })
							            }
							          }}

						    	>
						    		<CustomSearchBox />	
									
									<Configure
									  filters="status:APPROVED"
									/>

									<Configure
									  filters="preference:jobs"
									/>
											

						    		<Results  style={{position:'relative'}}>
						    		 		<div style={{overflowX:'auto'}} id="listContainer">
						    		 	 		<List style={{display:'flex', flexDirection: 'row', width:'100%'}}>
								    				<CustomHits />
								    			 </List>
								    		</div>
								    		<div class="pagination">
												<Pagination showFirst={false} showPrevious={false} showNext={false} showLast={false}  totalPages={10} />
											</div>

											<br/><br/><br/><br/>
											<center>
						          				<img alt="resume" src="/search-by-algolia-light-background.svg" />
			 								</center>

											
								     </Results>

								     {/* <div style={{position:'absolute',left:-60, top:120,height: '100%'}}>
								     						          	 	<IconButton
								     									        aria-label="Profile"
								     									        aria-controls="long-menu"
								     									        aria-haspopup="true"
								     									        onClick={()=> { scrollLeft(document.getElementById('listContainer'), -600, 1000, that); } }
								     									      >
								     									        <ArrowBackIcon />
								     									    </IconButton>
								     						          	 </div>
								     
								     						          	 <div style={{position:'absolute', right: -60, top:120}}>
								     						          	 	<IconButton
								     									        aria-label="Profile"
								     									        aria-controls="long-menu"
								     									        aria-haspopup="true"
								     									        onClick={()=> { scrollLeft(document.getElementById('listContainer'), 600, 1000, that);    } }
								     									      >
								     									        <ArrowForwardIcon />
								     									    </IconButton>
								     						          	 </div>*/}

								     	<VirtualRefinementList
								            defaultRefinement={this.state.jobcategoriesRefinementList}
								            attribute="jobcategories"
								          />

								        <VirtualRefinementList
								            defaultRefinement={this.state.expertiseRefinementList}
								            attribute="expertise"
								          />

								      <Dialog 
							        	maxWidth={'sm'}
							        	fullWidth={true}
							        	open={this.state.searchFilterDialog}
							        	onClose={ () => {this.setState({searchFilterDialog:false})} } >
							 			<DialogTitle><Typography style={styless.title}>Advance Search Filter</Typography></DialogTitle>   
							 			<DialogContent>
							 				Job Categories
							 				<hr/>
							 				<CustomRefinementList  
						 						defaultRefinement={this.state.jobcategoriesRefinementList} 
						 						attribute="jobcategories"
						 					/>
							 				<br/>
							 				Expertise
							 				<hr/>
							 				<CustomRefinementList defaultRefinement={this.state.expertiseRefinementList} attribute="expertise" />
							 				<br/>
							 				
							 			</DialogContent>    	
							        	<DialogActions style={{marginTop: 20}}>
						    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({searchFilterDialog:false}) } autoFocus>
									              Close
									            </Button>
								        </DialogActions>
							        </Dialog>



						    	</InstantSearch> 
		          		 	 
							</div>
			          		 
				           
			          		</Grid>
			          		<Grid item xs={1} md={2}></Grid>
			          	</Grid>
			         
			      </div>
		  	  }

		      { this.state.isLoaded === false &&
                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                        <CircularProgress style={{color:'#212121', alignSelf:'center'}} />
                    </div>
              }
	        </header>

	       
	        <Dialog 
	        	maxWidth={'sm'}
	        	fullWidth={true}
	        	open={this.state.jobsDialog}
	        	onClose={ () => {this.setState({jobsDialog:false, inviteTabIndex: 0})} } >
	 			  
	 			<DialogContent>
	 					
	 					{ this.state.inviteTabIndex === 0 &&
	 					  <div>
		 					  <Typography style={styless.title}>Select from your job post</Typography>
					          <List>
				 					{this.state.jobs.length > 0 && jobsList}
				 					{this.state.jobs.length === 0 && <Typography>You dont have an active job post.</Typography>}
				 			  </List>
				 		  </div>
			 			}

			 			{ this.state.inviteTabIndex === 1 &&
			 			 	<div>
				           		<Typography style={styless.em}>Send a job offer or invitation. This will cost you 1 credit.</Typography>

  								 <br/>
  								
					         	 <FormControl variant="outlined" style={{width:'100%'}}>
								    <Select
			          				  	onChange={ (event) => this.setState({inviteType: event.target.value, inviteMessage: event.target.value ==="INVITATION" ? invitationMessage : offerMessage }) }
										value={this.state.inviteType}
									    inputProps={{
									    name: 'inviteType'
									    }} fullWidth={true}
									    input={<OutlinedInput />} >
									     >

									    <MenuItem value="INVITATION">
									      <em>Job Invitation</em>
									    </MenuItem>
									   { /*<MenuItem value="OFFER">
									    	<em>Job Offer</em>
									     </MenuItem>*/
										}
							
								  	</Select>
							  	 </FormControl>
								<br/><br/>
						        <Typography style={styless.em}>Message to the Freelancer</Typography>
						        <TextField
								    	multiline
								    	rows="5"
			          					name='invite'
								      	variant='outlined'
								      	margin="normal" value={this.state.inviteMessage} fullWidth={true} onChange={ (event) => this.setState({inviteMessage: event.target.value }) } /> 
				        	</div>
				        }

				        { this.state.inviteTabIndex === 2 &&
			 			 	 <div>
					          <center>
					          
						          <img alt="invite" src={this.state.inviteIcon} />
						          <Typography>{ this.state.inviteSuccessMessage }</Typography>
								  <br/>
							  </center>
					        </div>
				        }
	 				
	 			</DialogContent>    	
	        	<DialogActions style={{marginTop: 20}}>
	        			{ this.state.inviteTabIndex === 2 &&
    					<Button style={styless.buttonPrimary} variant='contained'  onClick={ () =>{ this.setState({inviteTabIndex:0, jobsDialog:false}) } } autoFocus>
			              Done
			            </Button>
			        	}
			            { this.state.inviteTabIndex < 2 && this.state.inviteTabIndex>0 &&
			            <Button style={styless.buttonDefault} variant='outlined'  onClick={ () => { this.setState({inviteTabIndex: this.state.inviteTabIndex-1}) } } >
			              Back
			            </Button>
			        	}

			        	{ this.state.inviteTabIndex < 2 && this.state.inviteTabIndex > 0 &&
			            <Button disabled={ this.props.isLoading===true ? true : false } style={styless.buttonPrimary} variant='contained'  onClick={ () => { this.sendInvite() } } >
			              { this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : 'Next' }
			            </Button>
			        	}
		        </DialogActions>
	        </Dialog>

	        <Snackbar
		          anchorOrigin={{
		            vertical: 'bottom',
		            horizontal: 'center',
		          }}
          		open={this.state.snackbar}
          		autoHideDuration={5000}
          		onClose={this.snackbarClose}
          		ContentProps={{
            		'aria-describedby': 'message-id',
          		}}
          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
		          action={[
		            <IconButton
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              onClick={this.snackbarClose}
		            >
		              <CloseIcon />
		            </IconButton>,
		          ]} />
	    </div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		favFreelancers: state.favFreelancers,
		collection: state.collection,
		db: state.db,
		where: state.where,
		orderBy: state.orderBy,
		query: state.query,
		getDocs: state.getDocs,
		doc: state.doc,
		getDoc: state.getDoc,
		setDoc: state.setDoc,
		addDoc: state.addDoc,
		updateDoc: state.updateDoc,
		isLoading: state.isLoading
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		setFavFreelancers: (favs) => dispatch({type: "SET_FAV_FREELANCERS", payload: {favFreelancers:favs}})
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(Freelancer);





