import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import ChatIcon from '@mui/icons-material/Chat';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { FilePond, registerPlugin } from 'react-filepond';
//import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
//import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import Divider from '@mui/material/Divider';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import OutlinedInput from '@mui/material/OutlinedInput';
import _ from 'lodash'

import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Alert from '@mui/lab/Alert';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {  CardActionArea } from '@mui/material';

registerPlugin(FilePondPluginImagePreview);

const styles = {
    bigAvatar:{
        margin: 10,
        width: 150,
        height: 150,
        alignSelf:'center',
        cursor: 'pointer'
    },
    title: {
        color: '#333333',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10,
        fontSize: 24
    },
    subtitle: {
        color: '#8e8e8e',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10,
        fontSize: 18
    },
    center:{
        textAlign:'center',
        color: '#3c4859'
    },

    small: {
        color: '#8e8e8e',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10
       },

       em: {
         color: '#8e8e8e',
         fontFamily: 'Gotham Rounded'
       },

       buttonPrimary: {
        padding: 10,
        fontFamily: 'Gotham Rounded',
        backgroundColor: '#00b16a'
       },

       buttonDefault: {
        padding: 10,
        fontFamily: 'Gotham Rounded'
       }

}

class Blog extends Component{
    
    constructor(props){
        
        super(props);

        this.state = {
            
            snackbar: false,
            snackbarMessage: '',
            blog: [],
            blogs: []
        };
    
    }   

     handleChange = (event, value) => {
        this.setState({ submenu: value });
      };

    componentWillMount() {
        this.props.setLoading(true)
        this.setState({isLoaded:true})
        this.getBlog();
        
    }

    truncateString(str, maxLength) {
      if (str.length > maxLength) {
        return str.slice(0, maxLength) + '...';
      }
      return str;
    }

    extractImageLink(inputString) {
        const urlRegex = /(https?:\/\/[^\s]+(?:\/[^\s]+)*\.(jpg|jpeg|png|gif|bmp|webp)(?:\?[^\s]*)?)/ig;
        const matches = inputString.match(urlRegex);

     
        return matches!=null&&matches.length > 0 ? matches[0] : '';
    }  

    convertTimestampToReadableDate(timestamp) {
      const { seconds, nanoseconds } = timestamp;
      const milliseconds = seconds * 1000 + nanoseconds / 1e6;
      const date = new Date(milliseconds);
      const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

      return formattedDate;
    } 

    async getBlog()
    {
        let blogs = []

        let blogRef = this.props.doc(this.props.db, "blogs", this.props.id);
        let blogSnap = await this.props.getDoc(blogRef);
        let querySnapshot

        if (blogSnap.exists())
        {
              let doc = blogSnap.data();
       
              let id = blogSnap.id
              let blog = doc;
              blog.id = id
              this.setState({blog:blog})
        }else{

            blogRef = this.props.collection(this.props.db, "blogs");
            const q = this.props.query(blogRef, this.props.where("permalink", "==", this.props.id), this.props.limit(1) );
            querySnapshot = await this.props.getDocs(q);
            
            querySnapshot.forEach((doc) => {
              
              let id = doc.id
              let blog = doc.data();
              blog.id = id
              this.setState({blog:blog})

            });

        }

        

        let  query = this.props.query(this.props.collection( this.props.db, "blogs"), this.props.orderBy('updated_at','desc'), this.props.limit(4) );
        querySnapshot = await this.props.getDocs(query);

        querySnapshot.forEach((doc) => {
            let d = doc.data() 
            d.id = doc.id
            blogs.push(d)  
        });

        this.setState({blogs:blogs})
        this.props.setLoading(false)

    }

    
  
    render(){
    
        let that = this
        let image = '';

        if( _.has(this.state.blog,'content') )
        {
          image = that.extractImageLink(that.state.blog.content)
        }
   
       return (

            <div>
            
            <header>
              { this.props.isLoading === false &&
                  <div style={{marginTop: 100}}>
                      
                      <Grid container>
                            
                            <Grid item xs={1} sm={1} md={3}></Grid>
                            <Grid item xs={10} sm={10} md={6}>
                             {
                               
                                this.props.isLoading===false && _.has(that.state.blog,'title') &&
                                    <div>
                                      <center>
                                          <h1 className="App-title">{ _.has(that.state.blog,'title') ? that.state.blog.title:"" }</h1>
                                          <Typography variant="body2" color="text.secondary">
                                            { that.convertTimestampToReadableDate( that.state.blog.updated_at) }
                                          </Typography>
                                      </center>
                                      <div id="blog-container" dangerouslySetInnerHTML={{ __html: this.state.blog.content }} />
                                    </div>
                             }

                             <hr/>

                             <b>More Blogs</b> <br/><br/>

                             <Grid container spacing={5}>

                              {
                                    this.state.blogs.map(function(item, i)
                                    {
                                         let image = that.extractImageLink(item.content)
                                          return (
                                              <Grid item key={item.id} xs={12} sm={6} md={6}>
                                                 <Tooltip title={item.metadescription} arrow>
                                                      <Card>
                                                          <CardActionArea component="a" href={"/blog/"+item.permalink} rel="noopener noreferrer">
                                                              <CardMedia
                                                              component="img"
                                                              alt={item.metadescription}
                                                              height="140"
                                                              image={image}
                                                              title={ item.title } />

                                                              <CardContent>
                                                                  <Typography gutterBottom variant="h5" component="div">
                                                                    { item.title }
                                                                  </Typography>
                                                                  <Typography variant="body2" color="text.secondary">
                                                                    { that.convertTimestampToReadableDate( item.updated_at) }
                                                                  </Typography>
                                                              </CardContent>
                                                          </CardActionArea>
                                                      </Card>
                                                  </Tooltip>
                                              </Grid>

                                          )
                                    })
                                }
                              </Grid>
                             
                            </Grid>
                            <Grid item xs={1} sm={1} md={3}></Grid>
                    
                        </Grid>
                  </div>
              }

              { this.props.isLoading === true &&
                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                        <CircularProgress style={{color:'#212121', alignSelf:'center'}} />
                    </div>
              }
            </header>

           
            <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                open={this.state.snackbar}
                autoHideDuration={5000}
                onClose={this.snackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{ this.state.snackbarMessage }</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={this.snackbarClose}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]} />
        </div>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        firebase: state.firebase,
        user: state.user,
        isLoggedIn: state.isLoggedIn,
        isLoading: state.isLoading,
        collection: state.collection,
        db: state.db,
        where: state.where,
        orderBy: state.orderBy,
        limit: state.limit,
        query: state.query,
        getDocs: state.getDocs,
        doc: state.doc,
        getDoc: state.getDoc
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);





