import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import _ from 'lodash'
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import TimerIcon from '@mui/icons-material/Timer'
import MessageIcon from '@mui/icons-material/Message'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';

const styless = {
	bigAvatar:{
		margin: 10,
		width: 150,
		height: 150,
		alignSelf:'center',
		cursor: 'pointer'
	},
	title: {
		color: '#333333',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 24
	},
	subtitle: {
		color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 18
	},
	center:{
		textAlign:'center',
		color: '#3c4859'
	},

	small: {
	   	color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10
	   },

	   em: {
	   	 color: '#8e8e8e',
	   	 fontFamily: 'Gotham Rounded'
	   },

	   buttonPrimary: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded',
	   	backgroundColor: '#00b16a'
	   },

	   buttonDefault: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded'
	   }

}

class MyJobs extends Component{
	
	constructor(props){
		
		super(props);

		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		user: [],
    		snackbar: false,
    		snackbarMessage: '',
    		isSaving: false,
			jobs: [],
			addDialog: false,
			jobPostTitle:'',
			jobPostTitleError: false,
			jobDescription: '',
			jobDescriptionError: false,
			jobCategory: '',
			jobCategoryError: false,
			jobType: '',
			jobTypeError: false,
			estProjectDuration:'',
			estProjectDurationError: false,
			budget:  0,
			budgetError: false,
			hoursPerWeek: 0,
			hoursPerWeekError: false,
			howToApply: '',
			howToApplyError: false,
			notes: '',
			notesError: false,
			confirmDeleteJobDialog:false,
			deleteId: '',
			isEdit: false,
			jobStatus: "PENDING",
			isLoaded:false,
			jobCategories: [],
			expertise: '',
			expertiseError:false,
			closeJobReason: '',
			newJobID: '',
			jobApplications: [],
			tabActive: 'ALL',
			rowLimit: 10,
			rowIncrement: 10,
			rowLimitDefault: 10,
			jobModal:false,
			jobOfferModal: false,
			jobDetails: [],
			declineJobOfferModal: false,
			contracts: []
		};
	
	}	

	 handleChange = (event, value) => {
	    this.setState({ submenu: value });
	  };

	componentDidMount() {		
		this.getContracts()
	}
	async getContracts(){
		const contracts = await this.props.firebase.firestore().collection('contract').where("freelancer.uid","==", this.props.user.uid).get();

		this.setState({ isLoaded: true, contracts : contracts.docs.map( doc => { 
			let d = doc.data() 
			d.id = doc.id
			return d
		} ) })
	}

	
	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	async acceptOffer(){
		let that = this
		this.props.setLoading(true);

		 this.props.firebase.firestore().collection('jobApplications').doc(this.state.jobApplicationID.id).update({"status":"ACCEPTED_OFFER","accepter_offer_at": moment().format('MM-DD-YYYY h:m:s a') })
		 .then( () => {
		 	this.props.setLoading(false);
		 	this.setState({  jobOfferModal: false, jobApplicationID:[],declineReason: '', snackbar: true, snackbarMessage: 'Job Offer Accepted'}, ()=>{ }) 
		 })

		 this.props.firebase.firestore().collection('contract').doc().set({
		 	'jobDetails': that.state.jobDetails,
		 	'freelancer': that.props.user,
		 	'created_at': moment().format('MM-DD-YYYY h:m:s a')
		 })

	}

	async declineOffer()
	{
		 	this.props.setLoading(true);

		 this.props.firebase.firestore().collection('jobApplications').doc(this.state.jobApplicationID.id).update({"status":"DECLINED_OFFER","declined_offer_reason": this.state.declineReason,"declined_offer_at": moment().format('MM-DD-YYYY h:m:s a') })
		 .then( () => {
		 	this.props.setLoading(false);
		 	this.setState({ declineJobOfferModal: false, jobApplicationID:[],declineReason: '', snackbar: true, snackbarMessage: 'Application declined'}, ()=>{ }) 
			
		 })
	}

	async showJobDetails(job){
		const user = await this.props.firebase.firestore().collection('users').doc(job.user).get()
		job.owner = _.startCase( user.data().fname ) + " " + _.startCase( user.data().lname )
		
		if(job.estProjectDuration === 1)
			job.estProjectDuration = "Less than a week"
		else if(job.estProjectDuration ===2)
			job.estProjectDuration = "Less than a month"
		else if(job.estProjectDuration === 3)
			job.estProjectDuration = "Less than 3 months"
		else if(job.estProjectDuration === 4)
			job.estProjectDuration = "Less than 6 months"
		else if(job.estProjectDuration === 5)
			job.estProjectDuration = "More than a year"

		this.setState({jobDetails:job,jobModal:true})
	}

	async reviewOffer(job){
		const user = await this.props.firebase.firestore().collection('users').doc(job.user).get()
		job.owner = _.startCase( user.data().fname ) + " " + _.startCase( user.data().lname )
		
		if(job.estProjectDuration === 1)
			job.estProjectDuration = "Less than a week"
		else if(job.estProjectDuration ===2)
			job.estProjectDuration = "Less than a month"
		else if(job.estProjectDuration === 3)
			job.estProjectDuration = "Less than 3 months"
		else if(job.estProjectDuration === 4)
			job.estProjectDuration = "Less than 6 months"
		else if(job.estProjectDuration === 5)
			job.estProjectDuration = "More than a year"

		this.setState({jobDetails:job,jobOfferModal:true})
	}

	
	
	render(){
		let contractList
		let that = this
		let displayCount = 0;
		let statusCount = 0;

		if(this.state.contracts.length > 0)
		{		

				contractList = this.state.contracts.map(function(item, i){
				
				let status = ''
				let statusTooltip
				let statusColor
				let actions
				let ago = ''

				if( item.status ==="Part Time" ){
					status="NEW"
					statusTooltip="You submitted a proposal"
					statusColor='#f1c40f'
					ago = moment(item.applied_at,'MM-DD-YYYY H:m:s').fromNow();
				}else if( item.status ==="Full Time"){
					status="FIXED"
					statusTooltip="Client sent a job offer"
					statusColor='rgb(0, 177, 106)'
					ago = moment(item.offer_at,'MM-DD-YYYY H:m:s').fromNow();

					
				}else if( item.status ==="Project Based" ){
					status="INTERVIEW"
					statusTooltip="Client set your application for interview"
					statusColor='#3498db'
					ago = moment(item.interview_at,'MM-DD-YYYY H:m:s').fromNow();
				}

				
				
				if(that.state.tabActive===status&&displayCount<that.state.rowLimit)
				{
					displayCount++

					return (
						<ListItem>
							<ListItemText secondary={ "Hired by: "+item.jobDetails.owner }>
								<b onClick={ ()=> that.showJobDetails(item.job) } style={{fontFamily:"Gotham Rounded", fontWeight:'bold',cursor:"pointer"}}>{item.jobDetails.jobPostTitle}</b>
								</ListItemText>
		                  	<ListItemSecondaryAction>
		                  		{ actions }
		                  </ListItemSecondaryAction>

						</ListItem>
					)
				}else if(that.state.tabActive==="ALL"){
					displayCount++

					if(displayCount>that.state.rowLimit){
						return (false)
					}

					return (
						<ListItem>
							<ListItemText secondary={ "Hired by: "+item.jobDetails.owner }>
								<b onClick={ ()=> that.showJobDetails(item.job) } style={{fontFamily:"Gotham Rounded", fontWeight:'bold', cursor:"pointer"}}>{item.jobDetails.jobPostTitle}</b>
								</ListItemText>
		                  	<ListItemSecondaryAction>
		 
							  	<Tooltip title={ "Message" }>
									<IconButton edge="end" aria-label="timer" onClick={ ()=> { } }><MessageIcon /></IconButton>
							  	</Tooltip>
							  	<Tooltip title={ "Work Logs" }>
									<IconButton edge="end" aria-label="timer" onClick={ ()=> { } }><TimerIcon /></IconButton>
							  	</Tooltip>
							  	<Tooltip title={ "Invoice" }>
									<IconButton edge="end" aria-label="timer" onClick={ ()=> { } }><ReceiptIcon /></IconButton>
							  	</Tooltip>
		                  	</ListItemSecondaryAction>

						</ListItem>
					)
				}else{ 
					return true
				}
			

			})
				
		}
		

		
		return (

			<div>
			
			<header>
			  { this.state.isLoaded === true && 
	          <div style={{marginTop: 100}}>
		          
		          <Grid>
		          	<Grid container>
		          		<Grid item xs={0} md={1}>
		          		</Grid>
		          		<Grid item xs={12} md={10}>

		          		 <h1 className="App-title">My Jobs</h1>
		          		 
		          		 <br/>

		          		 <Tabs TabIndicatorProps={{style:{background: '#00B16A'}}} value={this.state.tabActive} onChange={ (event, newValue) => { this.setState({tabActive:newValue, rowLimit: that.state.rowLimitDefault}) } }>
				          <Tab value="ALL" label="All" />
				          <Tab value="HOURLY" label="Hourly" />
				          <Tab value="FIXED" label="Fixed Project" />
				          <Tab value="COMPLETED" label="Completed" />
				        </Tabs>

				        <br/>
		          		 

				         <div>
		          		 	
		          		 	
		          		 	<List>
		          		 	{this.state.contracts.length > 0 &&
		          		 		contractList
		          		 	}

		          		 	{this.state.contracts.length === 0 &&
		          		 		<center>
		          		 			<img alt="Online job" src="/online-job.png" />
		          		 			<Typography>You dont have an active job.</Typography>
		          		 			<br/>
		          		 		</center>
		          		 	}

		          		 	{ this.state.rowLimit<statusCount.length&&
		          		 	 <div style={{flex:1,justifyContent:'center', aligItems:'center', textAlign:'center'}}>
			          		 	<IconButton onClick={()=>{this.setState({rowLimit: this.state.rowLimit+this.state.rowIncrement})}} style={{alignSelf:'center', color:"#00b16a"}} aria-label="delete" size="large" color="primary">
					           		<ArrowDownwardIcon fontSize="inherit" />
					         	</IconButton>
					         </div>
					        }

		          		 	</List>
		          		 </div>

		          		</Grid>
		          		<Grid item xs={0} md={1}></Grid>
		          	</Grid>
		          </Grid>
		      </div>
		      }

		      { this.state.isLoaded === false &&
                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                        <CircularProgress style={{color:'#212121', alignSelf:'center'}} />
                    </div>
              }
	        </header>

	        <Dialog
		        open={this.state.declineJobOfferModal}
		        onClose={()=>this.setState({declineJobOfferModal:false})}
		        fullWidth={true}
		        maxWidth="sm"
		        scroll="body"
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		      >
		        <DialogTitle id="alert-dialog-title">Decline Job Offer</DialogTitle>
		        <DialogContent>
	 				You can state your reason for declining this job offer.
	 				<br/><br/>
	 				<FormControl style={{width:'100%'}} variant="bordered">
					        <InputLabel htmlFor="declinereason">Select Reason</InputLabel>
					        <Select
					          labelId=""
					          id="declinereason"
					          value={this.state.declineReason}
					          onChange={(event)=>{ this.setState({declineReason: event.target.value, showDeclineReason: event.target.value==="Other" ? true : false }) }}
					        >
					          <MenuItem value={'Budget too low'}>Budget too low</MenuItem>
					          <MenuItem value={'My skills is not fit for the job'}>My skills is not fit for the job</MenuItem>
					          <MenuItem value={'Already busy with other projects'}>Already busy with other projects</MenuItem>
					          <MenuItem value={'Other'}>Other</MenuItem>
					        </Select>
				      </FormControl>
				    { this.state.showDeclineReason === true && 
	 				<TextField
      					multiline
      					row="5"
      					name='overview'
				      	variant="outlined"
				      	margin="normal" value={this.state.declineReason} fullWidth={true} onChange={ (event) => this.setState({declineReason: event.target.value }) } />
	 				}
	 			</DialogContent>
		        <DialogActions>
		          <Button style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=>this.declineOffer() } >
		            Decline Job Offer
		          </Button>
		         </DialogActions>
		      </Dialog>
      
	        <Dialog
		        open={this.state.jobOfferModal}
		        onClose={()=>this.setState({jobOfferModal:false})}
		        fullWidth={true}
		        maxWidth="sm"
		        scroll="body"
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		      >
		        <DialogTitle id="alert-dialog-title">Job Detail</DialogTitle>
		        <DialogContent>
		          <DialogContentText id="alert-dialog-description">
		            <b>Job Title:</b> <br/>
		            { _.has(this.state.jobDetails,"jobPostTitle") ? this.state.jobDetails.jobPostTitle: '' }
		            <br/><br/>
		            
		            <b>Job Description:</b> <br/>
		            { _.has(this.state.jobDetails,"jobDescription") ? this.state.jobDetails.jobDescription: '' }
		            <br/><br/>
		            <b>Category: </b> <br/>
		            { _.has(this.state.jobDetails,"jobCategory") ? this.state.jobDetails.jobCategory: '' }
		            <br/><br/>
		            <b>Budget: </b> <br/>
		            { _.has(this.state.jobDetails,"budget") ? "$"+this.state.jobDetails.budget: '' }
		            <br/><br/>
		            <b>Job Type: </b> <br/>
		            { _.has(this.state.jobDetails,"jobType") ? this.state.jobDetails.jobType: '' }
		            <br/><br/>
		            <b>Project Duration: </b> <br/>
		            { _.has(this.state.jobDetails,"estProjectDuration") ? this.state.jobDetails.estProjectDuration: '' }
		            <br/><br/>
		            <b>Needed Experience: </b> <br/>
		            { _.has(this.state.jobDetails,"expertise") ? _.startCase( this.state.jobDetails.expertise ): '' }
		            <br/><br/>
		            <b>Hours Per Week: </b> <br/>
		            { _.has(this.state.jobDetails,"hoursPerWeek") ? this.state.jobDetails.hoursPerWeek: '' }
		            <br/><br/>
		            <b>Additional Note: </b> <br/>
		            { _.has(this.state.jobDetails,"notes") ? this.state.jobDetails.notes: '' }
		            <br/><br/>
		            <b>Job Offer by: </b> <br/>
		            { _.has(this.state.jobDetails,"owner") ? this.state.jobDetails.owner: '' }
		          </DialogContentText>
		        </DialogContent>
		        <DialogActions>
		          <Button style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=>this.acceptOffer() } >
		            Accept Offer
		          </Button>
		         </DialogActions>
		      </Dialog>
      
	        <Dialog
		        open={this.state.jobModal}
		        onClose={()=>this.setState({jobModal:false})}
		        fullWidth={true}
		        maxWidth="sm"
		        scroll="body"
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		      >
		        <DialogTitle id="alert-dialog-title">Job Detail</DialogTitle>
		        <DialogContent>
		          <DialogContentText id="alert-dialog-description">
		            <b>Job Title:</b> <br/>
		            { _.has(this.state.jobDetails,"jobPostTitle") ? this.state.jobDetails.jobPostTitle: '' }
		            <br/><br/>
		            
		            <b>Job Description:</b> <br/>
		            { _.has(this.state.jobDetails,"jobDescription") ? this.state.jobDetails.jobDescription: '' }
		            <br/><br/>
		            <b>Category: </b> <br/>
		            { _.has(this.state.jobDetails,"jobCategory") ? this.state.jobDetails.jobCategory: '' }
		            <br/><br/>
		            <b>Budget: </b> <br/>
		            { _.has(this.state.jobDetails,"budget") ? "$"+this.state.jobDetails.budget: '' }
		            <br/><br/>
		            <b>Job Type: </b> <br/>
		            { _.has(this.state.jobDetails,"jobType") ? this.state.jobDetails.jobType: '' }
		            <br/><br/>
		            <b>Project Duration: </b> <br/>
		            { _.has(this.state.jobDetails,"estProjectDuration") ? this.state.jobDetails.estProjectDuration: '' }
		            <br/><br/>
		            <b>Needed Experience: </b> <br/>
		            { _.has(this.state.jobDetails,"expertise") ? _.startCase( this.state.jobDetails.expertise ): '' }
		            <br/><br/>
		            <b>Hours Per Week: </b> <br/>
		            { _.has(this.state.jobDetails,"hoursPerWeek") ? this.state.jobDetails.hoursPerWeek: '' }
		            <br/><br/>
		            <b>Additional Note: </b> <br/>
		            { _.has(this.state.jobDetails,"notes") ? this.state.jobDetails.notes: '' }
		            <br/><br/>
		            <b>Posted by: </b> <br/>
		            { _.has(this.state.jobDetails,"owner") ? this.state.jobDetails.owner: '' }
		          </DialogContentText>
		        </DialogContent>
		        <DialogActions>
		          <Button style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=>this.setState({jobModal:false})} >
		            Close
		          </Button>
		         </DialogActions>
		      </Dialog>
      
	        <Snackbar
		          anchorOrigin={{
		            vertical: 'bottom',
		            horizontal: 'right',
		          }}
          		open={this.state.snackbar}
          		autoHideDuration={3000}
          		onClose={this.snackbarClose}
          		ContentProps={{
            		'aria-describedby': 'message-id',
          		}}
          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
		          action={[
		            <IconButton
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              onClick={this.snackbarClose}
		            >
		              <CloseIcon />
		            </IconButton>,
		          ]} />
	    </div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MyJobs);





