import { initializeApp } from "firebase/app";
import {   collection, getDocs, getDoc, getFirestore, query, where, doc, updateDoc, addDoc, setDoc, orderBy, limit, arrayUnion, arrayRemove , deleteDoc, onSnapshot  } from "firebase/firestore"; 
import { getAuth, 
		 onAuthStateChanged,
		 createUserWithEmailAndPassword,
		 updateProfile,
		 signOut,
		 sendEmailVerification,
		 sendPasswordResetEmail,
		 applyActionCode,
		 verifyPasswordResetCode ,confirmPasswordReset,
		 signInWithEmailAndPassword, deleteUser     } from "firebase/auth";

import { getStorage, ref as storageRef, uploadBytes,getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getAnalytics, logEvent  } from "firebase/analytics";

import * as firebaseconfig from '../constants/firebase';  

const app = initializeApp(firebaseconfig.CREDENTIALS);
const db = getFirestore(app);
const auth = getAuth();
const storage = getStorage(app);

try{
	const analytics = getAnalytics(app);
	logEvent(analytics, 'notification_received');
}catch(e) { }

const initialState = {
	arrayRemove:arrayRemove,
	onSnapshot:onSnapshot,
	deleteDoc: deleteDoc,
	deleteUser : deleteUser ,
	uploadBytesResumable:uploadBytesResumable,
	arrayUnion: arrayUnion,
	limit:limit,
	getDownloadURL:getDownloadURL,
	storageRef:storageRef,
	uploadBytes:uploadBytes,
	storage: storage,
	orderBy:orderBy,
	signInWithEmailAndPassword:signInWithEmailAndPassword,
	sendPasswordResetEmail:sendPasswordResetEmail,
	sendEmailVerification:sendEmailVerification,
	verifyPasswordResetCode:verifyPasswordResetCode,
	confirmPasswordReset :confirmPasswordReset ,
	applyActionCode:applyActionCode,
	signOut: signOut ,
	adminName: 'support',
	adminEmail: 'support@freelancertogo.com',
	setDoc:setDoc,
	addDoc:addDoc,
	updateDoc:updateDoc,
	updateProfile:updateProfile,
	createUserWithEmailAndPassword: createUserWithEmailAndPassword,
	doc:doc,
	query:query,
	where:where,
	auth:auth,
	onAuthStateChanged:onAuthStateChanged,
	db:db,
	getDocs:getDocs,
	getDoc:getDoc,
	collection:collection,
	user: [],
	isLoading: false,
	isLoggedIn: false,
	newMessageCount: 0,
	messageSent: 0,
	skillList: [],
	jobCategories: [],
	jobCategoriesList: [],
	profile: [],
	jobspost: [],
	contacts: [],
	favFreelancers: [],
	countries: [],
	clientCount: 0,
	freelancerCount: 0,
	pendingCount: 0,
	activeCount: 0,
	inactiveCount: 0,
	notifications: [],
	maintenance: false,
	funding: false,
	templateUsed: 'socialTemplate',
	previewTemplate: ''
}

export const reducer = ( state = initialState, action ) => {

	switch(action.type)
	{	
		case 'SET_PREVIEW_TEMPLATE':
		  return { ...state, previewTemplate: action.payload.previewTemplate }
		case 'SET_TEMPLATE':
		  return { ...state, template: action.payload.template }
		case 'SET_FUNDING':
		  return { ...state, funding: action.payload.funding }
		case 'SET_MAINTENANCE':
		  return { ...state, maintenance: action.payload.maintenance }
		case 'SET_PENDING_COUNT':
		  return { ...state, pendingCount: action.payload.pendingCount }
		case 'SET_ACTIVE_COUNT':
		  return { ...state, activeCount: action.payload.activeCount }
		case 'SET_INACTIVE_COUNT':
		  return { ...state, inactiveCount: action.payload.inactiveCount }	
		case 'SET_FREELANCER_COUNT':
		  return { ...state, freelancerCount: action.payload.freelancerCount }	
		case 'SET_CLIENT_COUNT':
		  return { ...state, clientCount: action.payload.clientCount }	
		case 'SET_COUNTRIES':
		  return { ...state, countries: action.payload.countries }
		case 'SET_FAV_FREELANCERS':
		  return { ...state, favFreelancers: action.payload.favFreelancers }
		case 'SET_CONTACTS':
		  return { ...state, contacts: action.payload.contacts }		
		case 'SET_JOBS':
		  return { ...state, jobspost: action.payload.jobspost }	
		case 'SET_JOB_CATEGORIES_LIST':
		  return { ...state, jobCategoriesList: action.payload.jobCategories }
		case 'SET_JOB_CATEGORIES':
		  return { ...state, jobCategories: action.payload.jobCategories }
		case 'SET_PROFILE':
		  return { ...state, profile: action.payload.profile }
		case 'SET_SKILLS':
		  return { ...state, skillList: action.payload.skillList }
		case 'SET_LOGOUT':
		  return { firebase: action.payload.firebase, isLoggedIn:false, user: [], isLoading: false}
		case 'SET_AUTH':
	      return { ...state, user: action.payload.user, isLoggedIn: action.payload.isLoggedIn}
	    case 'SET_LOADING':
	      return { ...state, isLoading: action.payload.isLoading}
	    case 'SET_MESSAGE_COUNT':
	      return { ...state, newMessageCount: action.payload.newMessageCount}
	    case 'SET_MESSAGE_SENT':
	      return { ...state, messageSent: action.payload.messageSent}
	    case 'SET_NOTIFICATIONS':
	      return { ...state, notifications: action.payload.notifications}
	    default:
	     return { ...state }
	}

}
