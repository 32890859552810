import React, { useState, useEffect  } from 'react';

import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { IconButton } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import PeopleIcon from '@mui/icons-material/People';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { Helmet } from 'react-helmet';
import blogs from '../../constants/allblogs.json'
import Tooltip from '@mui/material/Tooltip';
import {  CardActionArea } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import _ from 'lodash';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import Search from '@mui/icons-material/Search';

import { withRouter  } from 'react-router-dom';

const styles = {

   buttonPrimary: {
    padding: 18,
    fontFamily: 'Gotham Rounded',
    backgroundColor: '#00b16a'
   },

   searchButton: {
    fontFamily: 'Gotham Rounded',
    backgroundColor: '#00b16a'
   }
}

function extractImageLink(inputString)
{
        const urlRegex = /(https?:\/\/[^\s]+(?:\/[^\s]+)*\.(jpg|jpeg|png|gif|bmp|webp)(?:\?[^\s]*)?)/ig;
        const matches = inputString.match(urlRegex);

        return matches.length > 0 ? matches[0] : "";
}  




const Landing = (props) => {

    
   
    let query = '';
    const [signUpModalOpen, setModalOpen] = useState(false);
    const [videoError, setVideoError] = useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [formData, setFormData] = useState({
      search: ''
    });



    const queryChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };

    const handleCloseModal = () => {
      setModalOpen(false);
    };

    const handleVideoError = () => {
      setVideoError(true);
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      
      const searchValue = formData.search;
      window.location.href = '/find-work?query='+searchValue;
  };

   
    blogs = _.orderBy(blogs, [obj => obj.updated_at._seconds, obj => obj.updated_at._nanoseconds], ['desc', 'desc']);
  
    if (typeof window !== 'undefined')
    {

       return(
        <div>
          <Helmet>
              <title>Freelancertogo - Connect with Freelancers and Find Online jobs on the GO</title>
              <meta name="description" content="Freelancertogo is a new online work market place for freelancers. We help freelancers to get online job posted by clients all over the world." />
              <meta name="robots" content="index,follow" />
              <meta property="og:title" content="Freelancertogo" />
              <meta property="og:description" content="Discover the perfect freelancer for your projects. Your vision, their expertise – a winning combination." />
              <meta property="og:image" content="https://freelancertogo.com/cropped.png" />
              <meta property="og:url" content="https://freelancertogo.com" />
          </Helmet>
          <div id="hero">

                {
                  videoError ? (  <img class="fullscreen" class="fullscreen" src={"/freelancing-video-fallback-min.jpg"} alt="Freelancertogo" /> ) :
                  (
                    <video onError={handleVideoError} preload="metadata" onloadedmetadata="this.muted = true" autoPlay muted loop class="fullscreen">
                        <source src="/freelancing.webm#t=0.1" type="video/webm" />
                        <img src="/freelancing-fallback-min.png" title="Your browser does not support the <video> tag" />
                    </video>

                  )
                }
                

                <div id="hero-content">

                  <Grid container spacing={2}>
                    <Grid item md={2} sm={12} xs={12}></Grid>
                    <Grid item md={8} sm={12} xs={12}>

                        <p><h1 id="hero-title"><center>Find Your Dream Gig on the Go!</center></h1></p>
                        <br/>
                        <div id="search-container">
                          <form onSubmit={handleSubmit}>
                            <FormControl fullWidth>
                              <TextField 
                                name="search" 
                                variant="filled" 
                                label="Search Jobs"
                                sx={{
                                  "& .MuiFilledInput-root": {
                                    backgroundColor: "white",
                                  },
                                }}
                                type="text"
                                value={formData.search}
                                onChange={queryChange}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Button style={styles.searchButton} variant='contained' color='primary' type="submit">
                                        <Search /> Search
                                      </Button>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          </form>
                        </div>

                        <br/><br/>
                        <p><center>Discover the perfect freelancer for your projects.<br/>Your vision, their expertise – a winning combination.</center></p>
                        <br/>
                        <center><Button onClick={ ()=>  window.open("https://freelancertogo.com/signup") } id="cta-btn" style={styles.buttonPrimary} variant='contained' color='primary'>Post a Job</Button></center>
                      
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}></Grid>
                  </Grid>

                  

                  {/*<p>Connect with<h1>Freelancers</h1> </p>
                                    <br/>
                                    <p>Land Your Dream <h1>Gig on the <span>GO!</span></h1> </p>
                  
                                    <br/><br/>*/}
                 
                </div>
                
          </div>
          <div className="App" style={{backgroundColor: 'rgb(0, 177, 106,0.05)'}}>
              <Grid container spacing={2}>
               <Grid item md={9}><h1 style={{textAlign:"left"}}>Browse Through our Range of Skills</h1><br/><br/></Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3} sm={12} xs={12}>
                  <a target="_blank" href="/find-work?query=web development">
                    <Card>
                        <CardMedia
                          component="img"
                          height="140"
                          image="/web-development-min.jpg"
                          alt="web development"
                        />
                        <CardContent>
                        <Typography gutterBottom style={{fontSize:'15px'}} component="div">
                          Web Design & Development
                        </Typography>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <a target="_blank" href="/find-work?query=mobile development">
                    <Card>
                        <CardMedia
                          component="img"
                          height="140"
                          image="/mobile-development-min.jpg"
                          alt="mobile development"
                        />
                        <CardContent>
                        <Typography gutterBottom style={{fontSize:'15px'}} component="div">
                          Mobile App Development
                        </Typography>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <a target="_blank" href="/find-work?query=graphics design">
                    <Card>
                        <CardMedia
                          component="img"
                          height="140"
                          image="/graphic-design-min.jpg"
                          alt="graphic design"
                        />
                        <CardContent>
                        <Typography gutterBottom style={{fontSize:'15px'}} component="div">
                          Graphic & Design
                        </Typography>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <a target="_blank" href="/find-work?query=virtual assistant">
                     <Card>
                        <CardMedia
                          component="img"
                          height="140"
                          image="/virtual-assistant-min.jpg"
                          alt="Virtual Assistant"
                        />
                        <CardContent>
                        <Typography gutterBottom style={{fontSize:'15px'}} component="div">
                          Virtual Assistant
                        </Typography>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              </Grid>
              <br/>
              <Grid container spacing={2}>
                <Grid item md={3} sm={12} xs={12}>
                  <a target="_blank" href="/find-work?query=content writing">
                    <Card>
                        <CardMedia
                          component="img"
                          height="140"
                          image="/content-writing-min.jpg"
                          alt="Content Writing"
                        />
                        <CardContent>
                        <Typography gutterBottom style={{fontSize:'15px'}} component="div">
                          Content Writing
                        </Typography>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <a target="_blank" href="/find-work?query=seo">
                    <Card>
                        <CardMedia
                          component="img"
                          height="140"
                          image="/search-engine-optimization-min.jpg"
                          alt="Content Writing"
                        />
                        <CardContent>
                        <Typography gutterBottom style={{fontSize:'15px'}} component="div">
                          Search Engine Optimization
                        </Typography>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <a target="_blank" href="/find-work?query=photography">
                    <Card>
                        <CardMedia
                          component="img"
                          height="140"
                          image="/photography-min.jpg"
                          alt="Photography"
                        />
                        <CardContent>
                        <Typography gutterBottom style={{fontSize:'15px'}} component="div">
                          Photography
                        </Typography>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <a target="_blank" href="/find-work?query=illustration">
                    <Card>
                        <CardMedia
                          component="img"
                          height="140"
                          image="/illustration-min.jpg"
                          alt="illustration"
                        />
                        <CardContent>
                        <Typography gutterBottom style={{fontSize:'15px'}} component="div">
                          Illustration
                        </Typography>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              </Grid>
          </div>

          <div className="App">
            
            
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <h1>Why You Should Post Your Jobs to Us?</h1>

                    <List>
                        <ListItem>
                            <ListItemIcon>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={ <React.Fragment><Typography style={{fontSize:'28px',color:'#212121'}}>Global Talent Network</Typography></React.Fragment> }
                              secondary={ <React.Fragment><Typography style={{fontSize:'15px'}}>Access a diverse pool of talented freelancers
from around the world.</Typography></React.Fragment> }
                           
                            >
                            </ListItemText>
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={ <React.Fragment><Typography style={{fontSize:'28px',color:'#212121'}}>Tailored Expertise</Typography></React.Fragment> }
                              secondary={ <React.Fragment><Typography style={{fontSize:'15px'}}>Find freelancers with the specific skills you
need for your projects.</Typography></React.Fragment> }
                            >
                            </ListItemText>
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={ <React.Fragment><Typography style={{fontSize:'28px',color:'#212121'}}>Quality Assurance</Typography></React.Fragment> }
                              secondary={ <React.Fragment><Typography style={{fontSize:'15px'}}>Choose from pre-screened professionals who
deliver exceptional work</Typography></React.Fragment> }
                            >
                            </ListItemText>
                        </ListItem>
                         <ListItem>
                            <ListItemIcon>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={ <React.Fragment><Typography style={{fontSize:'28px',color:'#212121'}}>Pay when you are satisfied</Typography></React.Fragment> }
                              secondary={ <React.Fragment><Typography style={{fontSize:'15px'}}>Make Payment Upon Your Approval.</Typography></React.Fragment> }
                            >
                            </ListItemText>
                        </ListItem>
                    </List>
                  </Grid>
                  <Grid item md={6}>
                     
                     <img style={{marginTop:'30%',borderRadius:'20px',width:'100%'}} alt="freelancers" src="/freelancers-min.jpg" />
                    
                  </Grid>
                </Grid>
          
          </div>

          <div id="cta">
              <div id="cta-overlay">
                <div id="cta-content">
                  <Grid container spacing={2} >
                      <Grid item md={1} sm={1} xs={1}></Grid>
                      <Grid item md={10} sm={10} xs={10}>
                          <h1><center>We welcome you on board!</center></h1>
                      </Grid>
                      <Grid item md={1} sm={1} xs={1}></Grid>
                   </Grid>

                   <Grid container spacing={2} >
                      <Grid item md={1} sm={1} xs={1}></Grid>
                      <Grid item md={10} sm={10} xs={10} id="cta-btn-container">
                          <center><Button onClick={ ()=>  window.open("https://freelancertogo.com/signup") } id="cta-btn" style={styles.buttonPrimary} variant='contained' color='primary'>Sign Up</Button></center>
                      </Grid>
                      <Grid item md={1} sm={1} xs={1}></Grid>
                   </Grid>
                </div>
              </div>
          </div>

          <div className="App">
            <Box>
                <Grid container spacing={10} >
                    <Grid item md={1}></Grid>
                    <Grid item md={5}>
                         <br/><br/>
                        <img style={{borderRadius:'10px',width:'100%', transform: "rotate(10deg)"}} alt="Funded" src="/funded-handshake-min.jpg" />
                      
                    </Grid>
                    <Grid item md={5}>
                        <br/><br/>
                        <h1 id="funded-text">Freelancertogo's <b style={{marginLeft:5,color:"rgb(0, 177, 106)"}}> FUNDED</b></h1>
                      
                      <p style={{textAlign:'right'}}>Enhance your payment security by allowing clients to add funds to their job postings. This streamlined process guarantees that your hard work translates into swift payment. Once your work is approved, the funds are instantly transferred to you, ensuring a seamless and reliable payment experience.</p>
                    </Grid>
                    <Grid item md={1}></Grid>
                   
                  </Grid>
            </Box>
          </div>

          <div className="App" style={{backgroundColor: 'rgb(0, 177, 106,0.05)'}}>
              <Grid container spacing={2}>
               <Grid item md={9}><h1 style={{textAlign:"left"}}>Read the Latest Articles to Gain Insights</h1><br/><br/></Grid>
              </Grid>
              <Grid container spacing={2}>
                  
                  {
                     blogs.map(function(item, i)
                      {     
                           if(i<6)
                           {
                               let image = extractImageLink(item.content)
                          
                               return (
                                    <Grid item key={item.id} xs={12} sm={6} md={4}>
                                       <Tooltip title={item.metadescription} arrow>
                                            <Card>
                                                <CardActionArea component="a" href={"/blog/"+item.permalink} target="_blank" rel="noopener noreferrer">
                                                    <CardMedia
                                                    component="img"
                                                    alt={item.metadescription}
                                                    height="140"
                                                    image={image}
                                                    title={ item.title } />

                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="div">
                                                          { item.title }
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                          { item.readableDate }
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Tooltip>
                                    </Grid>

                                )
                           }
                          
                      })
                  }

              </Grid>
              <br/>
              <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                      <center><Button onClick={ ()=>  window.open("https://freelancertogo.com/blogs") } id="cta-btn" style={styles.buttonPrimary} variant='contained' color='primary'>See More</Button></center>
                  </Grid>
              </Grid>
          </div>

          <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={signUpModalOpen}  onClose={handleCloseModal} >
          <DialogContent>
            <DialogContentText>
              <h1><center>Start freelancing or hire a freelancer</center></h1>
            </DialogContentText>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
          </Box>
        </DialogContent>
      </Dialog>
           
        <div id="cookieChoiceInfo"><span>By continuing to browse the site you are agreeing to our use of cookies. For more details about cookies see our cookie policy.</span> <a target="_blank" href="https://knowledgebase.freelancertogo.com/freelancertogo-knowledgebase/get-started/cookie-policy">See details</a></div>
        </div>


    )

    }else
    {
       return (<div>
                  <Helmet>
                      <title>Freelancertogo - Connect with Freelancers and Find Online jobs on the GO</title>
                      <meta name="description" content="Freelancertogo is a new online work market place for freelancers. We help freelancers to get online job posted by clients all over the world." />
                      <meta name="robots" content="index,follow" />
                      <meta property="og:title" content="Freelancertogo" />
                      <meta property="og:description" content="Discover the perfect freelancer for your projects. Your vision, their expertise – a winning combination." />
                      <meta property="og:image" content="https://freelancertogo.com/cropped.png" />
                      <meta property="og:url" content="https://freelancertogo.com" />
                  </Helmet>
               </div>)
    }
  
}

export default Landing;  