import React, { Component } from 'react'
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux'
import _ from 'lodash'
import * as ROUTES from '../../constants/routes';
import moment from 'moment'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Rating from '@mui/lab/Rating';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/lab/Skeleton';

import { UseNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';

const styles = {
   small: {
   	color: '#8e8e8e',
   	fontFamily: 'Gotham Rounded'
   },

   em: {
   	 color: '#8e8e8e',
   	 fontFamily: 'Gotham Rounded'
   },

   buttonPrimary: {
   	padding: 18,
   	fontFamily: 'Gotham Rounded',
   	backgroundColor: '#00b16a'
   },

   bigAvatar: {
   	margin: 10,
   	width: 150,
   	height: 150,
   	alignSelf: 'center'
   }
}

class User extends Component{
	
	constructor(props){
		super(props);

		this.state = {
			id: '',
			user: [],
			profile: [],
			skills: [],
			projects: [],
			employment: [],
			educations: [],
			portfolioDialog: false,
			selectedPortfolio:[],
			scroll: 'body',
			feedbacks : [],
			identityVerified: '',
			usePremiumTemplate: '',
			redirect: ''
		};
	
	}	

	async componentDidMount() {
		
		console.log("usermain")
		//const db = this.props.firebase.firestore()
		let that = this

		const userRef = this.props.doc(this.props.db, "users", this.props.id );
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
			let data = userSnap.data()
					
			that.setState({
				id: that.props.id,
				user: data,
				profile: data.profile,
				skills: data.skills,
				projects: data.projects,
				employment: data.employment,
				educations: data.educations,
				profilecompleteness: null,
				identityVerified: _.has(data,'verification_review') ? data.verification_review : '',
				usePremiumTemplate: _.has(data,'usePremiumTemplate') ? data.usePremiumTemplate : ''
			}, ()=>{
				

				if(that.state.usePremiumTemplate==='')
					that.setState({redirect:'/fd/'})
				else if(that.state.usePremiumTemplate==='socialTemplate')
					that.setState({redirect:'/fsocial/'})
				
			})
		}else{

			const usersRef = this.props.collection(this.props.db, "users");
	  		const q = this.props.query(usersRef, this.props.where("mypermalink", "==", that.props.id) );
	  		const querySnapshot = await this.props.getDocs(q);

	  		querySnapshot.forEach((doc) => {
			  
			  	let data = doc.data();


				that.setState({
					id: that.props.id,
					user: data,
					profile: data.profile,
					skills: data.skills,
					projects: data.projects,
					employment: data.employment,
					educations: data.educations,
					profilecompleteness: null,
					identityVerified: _.has(data,'verification_review') ? data.verification_review : '',
					usePremiumTemplate: _.has(data,'usePremiumTemplate') ? data.usePremiumTemplate : ''
				}, ()=>{
					

					if(that.state.usePremiumTemplate==='')
						that.setState({redirect:'/fd/'})
					else if(that.state.usePremiumTemplate==='socialTemplate')
						that.setState({redirect:'/fsocial/'})
					
				})
				
			  	
			});

		}
	
		
	}




	render(){

		/*const navigate = useNavigate();

		if( this.state.redirect !== '' )
		{
			navigate(this.state.redirect+this.props.match.params.id);
		}*/
		return (
				<div>
			        <header>      
				       <div className="public-section">
				       	
				       		
				       </div>
			        </header>

			      
		    	</div>
			)
		
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		templateUsed: state.templateUsed,
		doc: state.doc,
		db: state.db,
		collection: state.collection,
		getDoc: state.getDoc,
		getDocs: state.getDocs
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAuth: (user, state) => dispatch({type: "SET_AUTH", payload: { user: user, route: state }}),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		setTemplate: ( template ) => dispatch({type: "SET_TEMPLATE", payload: {template:template}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(User);





