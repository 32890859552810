export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_OUT = '/signout';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const PROFILE = '/profile';
export const USER = '/freelancer/';
export const USER_SOCIAL = '/fsocial/';
export const USER_DEFAULT = '/fd/';

export const JOBS = '/jobs/';
export const FINDWORK = '/find-work/';
export const FINDFREELANCER = '/find-freelancers'
export const MYAPPLICATIONS = '/my-applications/';
export const MYJOBS = '/my-jobs/';
export const MESSAGE = '/message/';
export const AVATAR = 'https://firebasestorage.googleapis.com/v0/b/freelancertogo-e4de6.appspot.com/o/assets%2Fuser.png?alt=media&token=7af170e8-57a0-4505-b169-d6a0d2b3a0a8';
export const CHANU_GONZALES = '/chanugonzales';
export const USERS_LIST = '/admin/users';
export const JOBS_LIST = '/admin/jobs';
export const PAYOUT_LIST = '/admin/payouts';
export const ADMIN_SETTINGS = '/admin/settings';
export const COOKIE = '/cookie-policy';
export const PRIVACY = '/privacy-policy';
export const TERMSOFSERVICE = '/terms-of-service';
export const COMINGSOON = '/coming-soon';
export const UNDERCONSTRUCTION = '/under-construction';
export const ABOUT = '/about';
export const SUPPORT = '/support';
export const ENDCONTRACT = '/endcontract/';
export const BUYCREDITS = '/buycredits';
export const REPORTPROBLEM = '/report-a-problem';
export const HOWITWORKS = '/how-it-works';
export const TRANSACTIONS = '/transactions';
export const MAINTENANCE = '/maintenance';
export const PREMIUM = '/premium-profile';
export const BLANK = '/blank';

export const PREVIEW = '/preview/';
export const BLOG_ADMIN = '/blogadmin/';
export const BLOGS = '/blogs/';
export const BLOG = '/blog/';