import React, { Component } from 'react'
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux'
import _ from 'lodash'
import * as ROUTES from '../../../constants/routes';
import moment from 'moment'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Rating from '@mui/lab/Rating';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/lab/Skeleton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import { FilePond, registerPlugin } from 'react-filepond';
import Slider from '@mui/material/Slider';
//import { createMuiTheme } from '@mui/material/styles';
//import { ThemeProvider } from '@mui/styles';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

import LinearProgress from '@mui/material/LinearProgress';
//import { makeStyles, withStyles } from '@mui/material/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Grid from '@mui/material/Grid';

const styles = {
   small: {
   	color: '#8e8e8e',
   	fontFamily: 'Gotham Rounded'
   },

   em: {
   	 color: '#8e8e8e',
   	 fontFamily: 'Gotham Rounded'
   },

   buttonPrimary: {
   	padding: 14,
   	fontFamily: 'Gotham Rounded',
   	backgroundColor: '#00b16a',
   	color: '#fff'
   },

   bigAvatar: {
   	margin: 10,
   	width: 150,
   	height: 150,
   	alignSelf: 'center',
   	border: '8px solid #fff',

   }
}


/*const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: 'rgb(0, 177, 106)',
  },
}))(LinearProgress);*/


/*const muiTheme = createMuiTheme({
  overrides:{
    MuiSlider: {
      thumb:{
      color: "rgb(0, 177, 106)",
      },
      track: {
        color: 'rgb(0, 177, 106)'
      },
      rail: {
        color: 'rgb(0, 177, 106)'
      }
    }
}
});
*/
const templateStyle = {
	container: {
		borderRadius: 10
	},

	cover: {
		height: "500px",
		minHeight: "500px"
	},

	profilePic: {
		position: "absolute",
		marginTop: "-70px",
		marginLeft: "30px"
	},

	aboutContainer:{
		marginTop: "80px"
	}
}

class Template1 extends Component{
	
	constructor(props){
		super(props);

		this.state = {
			id: '',
			user: [],
			profile: [],
			skills: [],
			projects: [],
			employment: [],
			educations: [],
			settingsDialog: false,
			selectedPortfolio:[],
			scroll: 'body',
			feedbacks : [],
			identityVerified: '',
			submenu: 0,
			coverPhoto: '',
			isCoverUpload: false,
			skillProfiencies: {},
			socialTemplate: {},
			snackbar:false,
			snackbarMessage:'',
			usePremiumTemplate:''
		};
	
	}	

	componentDidMount() {
		this.getUser()
		this.getRating()
	}

	getUser()
	{
		this.props.setLoading(true)
		const db = this.props.firebase.firestore()
		let that = this
	
		db.collection('users').doc(this.props.match.params.id)
			.get()
			.then(function(doc){

				if(doc.exists){
					let data = doc.data()
					
					that.setState({
						id: that.props.match.params.id,
						user: data,
						profile: data.profile,
						skills: data.skills,
						projects: data.projects,
						employment: data.employment,
						educations: data.educations,
						profilecompleteness: null,
						identityVerified: _.has(data,'verification_review') ? data.verification_review : '',
						socialTemplate: _.has(data,'socialTemplate') ? data.socialTemplate : {},
						usePremiumTemplate:  _.has(data,'usePremiumTemplate') ? data.usePremiumTemplate : '',
					},()=>{
						that.getTemplate()
					})


					that.profileCompleteness(data)
					that.props.setLoading(false)
					if( !_.has(data,'socialTemplate') )
						that.getSkillProfiencies()

				}
			})

		

	}

	getTemplate(){
		let cover = _.has(this.state.socialTemplate,'cover') ? this.state.socialTemplate.cover : '/cover-default.jpg'
		let skillProfiencies =  _.has(this.state.socialTemplate,'skillProfiencies') ? this.state.socialTemplate.skillProfiencies : []

		this.setState({coverPhoto: cover, skillProfiencies:skillProfiencies})
	}

	async getRating(){
		const that = this

		let rating = await this.props.firebase.firestore().collection('jobApplications').where("uid","==",this.props.match.params.id).where("end_contract_client_at","!=","").get()
		//let rating = await this.props.firebase.firestore().collection('jobApplications').where("uid","==",this.props.match.params.id).where("end_contract_client_at","!=","").get()
		
		let ja = []
		
		rating.docs.map( doc => {
			console.log(doc.data())
			ja.push(doc.data())
		} )

		that.setState({ feedbacks:ja })
	}

	profileCompleteness(data){

		let percentage=0;
		//profile
		if( _.has(data.profile,'avatar') && data.profile.avatar!=='')
			percentage+=4
		if( _.has(data.profile,'title') && data.profile.title!=='')
			percentage+=4
		if( _.has(data.profile,'overview') && data.profile.overview!=='')
			percentage+=4
		if( _.has(data.profile,'hourlyRate') && data.profile.hourlyRate!=='')
			percentage+=4
		if( _.has(data.profile,'fullTimeRate') && data.profile.fullTimeRate!=='')
			percentage+=4

		//skills
		if( !_.isEmpty(data.skills) && data.skills.length>0)
			percentage+=20

		//educations
		if(!_.isEmpty(data.educations) && data.educations.length>0)
			percentage+=20

		//employment
		if(!_.isEmpty(data.employment) && data.employment.length>0)
			percentage+=20

		//projects
		if(!_.isEmpty(data.projects) && data.projects.length>0)
			percentage+=20

		

		this.setState({profilecompleteness:percentage})


	}

	async saveCustom()
	{	
		this.props.setLoading(true)
		let coverUpload = []
		let that = this

	
		if( this.state.isCoverUpload  )
		{
			let files = this.pond.getFiles();
			
			console.log('files',files)
			if(files.length>0)
			{
				
				files.map(file => coverUpload.push(file.file) )

				this.props.firebase.storage().ref().constructor.prototype.putFiles = await function(files)
				{ 
				  var ref = this;
				  const filesArray = [...coverUpload]
				  return Promise.all(filesArray.map(function(file) {
				  	let file_name = file.name
					let extension = file_name.split(".")
				    return ref.child("uassets/"+that.props.user.uid+"/cover."+extension[extension.length-1]  ).put(file);
				  }));
				}

				this.props.firebase.storage().ref().putFiles(files).then( await function(metadatas) {
		
					metadatas.map( data => {
						data.ref.getDownloadURL().then(function(downloadURL) {
							that.saveTemplate(downloadURL)
					  })
							return true

					} )
					
				}).catch(function(error) {
				  	console.log('error',error)
				  	that.setState({ isSaving: false })
				});

			}else{
				this.setState({snackbar:true,snackbarMessage:"Cover photo is not set.",})
				this.props.setLoading(false)
			}


		}else{
			this.saveTemplate('')
		}
					
		
	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}


	saveTemplate(downloadURL){
	
		let socialTemplate = this.state.socialTemplate

		if(downloadURL!=="")
			socialTemplate.cover = downloadURL

		socialTemplate.skillProfiencies = this.state.skillProfiencies;

		this.props.firebase.firestore().collection('users').doc( this.props.user.uid ).update({ 'socialTemplate': socialTemplate })
		this.props.setLoading(false)
		this.setState({snackbar:true, snackbarMessage:"Social Template saved", settingsDialog:false})
		this.getUser()
	}

	
	showSettingsDialog(){
		let that = this
		that.setState({settingsDialog:true})
	}

	getSkillProfiencies(){
		let sp = {}
		let that = this

		if(  _.has(this.state.skills,'length') && this.state.skills.length >0 )
		{
			this.state.skills.map(function(item,i){
						sp[item] = 0
						return
			})
			
			this.setState({skillProfiencies:sp},()=>{
				
			})
		}
	}


	
	render(){

		let that = this
		let feedbacks
		let educations
		let employments
		let projects
		let skills
		let skillProficiency
		

		if(  _.has(this.state.skills,'length') && this.state.skills.length >0 &&  !_.has(this.state.socialTemplate,'cover') )
		{
			skills = this.state.skills.map(function(item,i){
      		 			//return <Chip color="success" style={{margin: 5}} label={<span style={{fontSize:14}}>{ item }</span>} variant="outlined" />
      		 			return ( <ListItem>
      		 				<Grid container>
      		 					<Grid item md={4}>{item}</Grid>
      		 					<Grid item md={8}>   </Grid>
      		 				</Grid>
      		 				
      		 			</ListItem> )
					})
		
		} else if(  _.has(this.state.skills,'length') && this.state.skills.length >0 ){

			skills = this.state.skills.map(function(item,i){
      		 			//return <Chip color="success" style={{margin: 5}} label={<span style={{fontSize:14}}>{ item }</span>} variant="outlined" />
      		 			return ( <ListItem>
      		 				<Grid container>
      		 					<Grid item md={4}>{item}</Grid>
      		 					<Grid item md={8}>   </Grid>
      		 				</Grid>
      		 			</ListItem> )

      		 		})

		}

		if(  _.has(this.state.skills,'length') && this.state.skills.length >0 )
		{
			skillProficiency = this.state.skills.map(function(item,i){

      		 			return ( <ListItem>
      		 						
	      		 						<Grid container>
	      		 							<Grid item md={4}>
	      		 								<Typography>{item}</Typography>
	      		 							</Grid>
	      		 							<Grid item  md={6}>
	      		 								{/*<ThemeProvider theme={muiTheme}>
	      		 								<Slider value={( that.state.skillProfiencies[item] > 0 ? that.state.skillProfiencies[item] : 0 )} onChange={(event,value)=>{
	      		 										that.state.skillProfiencies[item] = value
	      		 										that.setState({skillProfiencies : that.state.skillProfiencies})
	      		 								 }} aria-labelledby="continuous-slider" />
	      		 								 </ThemeProvider>*/}
	      		 							</Grid>
	      		 							<Grid item md={2}>
	      		 								<Typography>{ that.state.skillProfiencies[item] > 0 ? that.state.skillProfiencies[item] : 0 }%</Typography>
	      		 							</Grid>
	      		 						</Grid>
      		 						
      		 					</ListItem> 
      		 				   )
					})
		}

		if ( _.has(this.state.projects,'length') && this.state.projects.length > 0)
		{

			console.log(this.state.projects)
			projects = this.state.projects.map(function(item,i){
          		 			return <Grid item xs={12} sm={12} md={6}>
          		 						
          		 						<Card style={{cursor:'pointer'}} onClick={ ()=>{ console.log('item',item); that.setState({selectedPortfolio: item, portfolioDialog: true}) } }>
									      <CardMedia
									        component="img"
									        height="140"
									        image={ item.projectfiles.length > 0 ? item.projectfiles[0] : "/cropped.png"}
									        alt=""
									      />
									      <CardContent>

									      	<Typography gutterBottom variant="h5" component="div">
									          {item.title} <br/>
									          { moment( item.started ).isValid() &&  moment( item.ended ).isValid()  &&
		          		 							<small style={{fontSize:14}}>{moment( item.started ).format('ll') } to {moment(item.ended).format('ll') }</small>
		          		 						}
									        </Typography>
									        <Typography variant="body2" color="text.secondary">
									          {  item.description.replace(/(?:\r\n|\r|\n)/g, '<br/>') }
									        </Typography>
									      </CardContent>
									     
									    </Card>

		          		 				<br/>
          		 				</Grid>
						})
		}

						          		 		

		if( _.has( this.state.employment,'length') && this.state.employment.length > 0 ){

			employments = this.state.employment.map(function(item,i)
						{
							          		 			
          		 			return ( <div>
	          		 				   <h3 style={{fontSize:16}}>{item.jobTitle} | {item.company}</h3>
	          		 				   { moment( item.jobStarted ).isValid() &&  moment( item.jobEnded ).isValid()  &&
	          		 				   		<small style={{fontSize:14}}>{moment(item.jobStarted).format('ll') } - { _.has(item,'jobEndedPresent') && item.jobEndedPresent===true ? "Present" : moment( item.jobEnded).format('ll') }</small>
	          		 				   }
	          		 				   <p style={{fontSize:14}} dangerouslySetInnerHTML={{ __html: item.jobDescription.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
	          		 				   <hr/>
          		 				   </div> )
          		 				  
						})

		}


		if(  this.state.feedbacks.length > 0 ){

			feedbacks = this.state.feedbacks.map(function(item,i){
			
				return (
						<ListItem >
				         	<ListItemText primary={item.jobPostTitle}
				         	secondary={

				         		<React.Fragment>
				         			<Typography style={{fontSize:16}}>
				         				<Rating
						                	  disabled={ true }
						                	  name="totalScore"
						                	  value={item.end_contract_totalscore}
									        /> <small> { Number( !isNaN(item.end_contract_totalscore) ? item.end_contract_totalscore : 0 ).toLocaleString(undefined, {minimumFractionDigits: 2}) } {  moment(item.hire_at.toDate()).format('MMM DD, YYYY') } -  { moment(item.end_contract_at.toDate()).format('MMM DD, YYYY') } </small>
				         			</Typography>
				         			<br/>
				         			<Typography style={{fontSize: 14}}>
				         				"{item.end_contract_feedback}"
				         			</Typography>
				         		</React.Fragment>

				         	}
				         	 />
				         	
				        </ListItem>
					)
			})
		}

		if( _.has(this.state.educations,'length') && this.state.educations.length > 0)
		{
			educations = this.state.educations.map(function(item,i){
							          		 			
          		 		return (
	          		 				<div>
		  		 						<h3 style={{fontSize:16}}>{item.schoolCourse} | {item.school}</h3>
		  		 						{ moment( item.schoolStarted ).isValid() &&  moment( item.schoolEnded ).isValid()  &&
		  		 							<small style={{fontSize:14}}>{ moment(item.schoolStarted).format('ll') } - { _.has(item,'schoolEndedPresent') && item.schoolEndedPresent===true ? "Present" : moment(item.schoolEnded).format('ll') }</small>
		  		 						}
		  		 						<p style={{fontSize:14}}>{item.schoolDescription}</p>
		  		 						<hr/>
	  		 				  		</div>
  		 				  		)
  		 				  	
						})
		}
		
		let name = 	this.state.user.fname && this.state.user.lname ? <h1 style={{color:'#00B16A', margin:5}}>{ this.state.user.fname.charAt(0).toUpperCase() + this.state.user.fname.slice(1) } { this.state.user.lname.charAt(0).toUpperCase() +"." }</h1> : ''
		
		return (
				<div>
			        <header>      
				       <div className="public-section">
				       	

				       	  
				          	<Grid container>
				          		<Grid item md={1} lg={1}></Grid>
				          			
				          		<Grid item xs={12} md={10} lg={10}>

				          			{ _.has(this.state.socialTemplate, 'alias' ) && this.props.match.params.id === this.props.user.uid &&
				          			<Tooltip title="Settings">
					          			<IconButton aria-label="Settings" color="default" onClick={()=>{this.showSettingsDialog()}}>
										  <SettingsIcon />
										</IconButton>
									</Tooltip>
									}

									<br/>

				          			<div style={templateStyle.container} >
				          				<div style={{borderRadius:10,height:'400px', backgroundSize: 'cover',backgroundImage: 'url('+this.state.coverPhoto+')'}}>
				          					
				          				</div>
				          				<div>
				          					<Grid container>
				          						<Grid item xs={12} md={3} style={{textAlign:'right'}}>
					          						<div className="profilePic">
						          					{
									          			_.has(this.state.profile,'avatar') ? <Avatar alt={this.state.profile.fname} src={this.state.profile.avatar} style={styles.bigAvatar} /> : <Avatar alt={name} src={ROUTES.AVATAR} style={styles.bigAvatar} />
									          		}

									          		</div>

									          		{ this.state.identityVerified === "APPROVED" &&
									          			<div className="badge-container">
									          				<Tooltip title="Freelancer identity verified">
										          				<VerifiedUserIcon  fontSize="small" style={{color:'#00b16a'}} />
										          			</Tooltip>
									          			</div>
									          		}
								          		</Grid>
								          		<Grid item xs={12} md={6} className="user-info">
								          			{ name }	

								          			{
								          			 _.has(this.state.profile,'title')  ? <h2 style={{margin:0,marginLeft:8}}>{ this.state.profile.title } &bull; { this.state.user.country } </h2> : ''
								          			}
								          		</Grid>
								          		<Grid item xs={12} md={3}>
								          			<h1 style={{textAlign:'center', color:'#00B16A'}}>${ _.has(this.state.profile,'hourlyRate') ? this.state.profile.hourlyRate  : 0 }/hr</h1>
								          			<h2 style={{textAlign:'center', color:'rgb(142, 142, 142)', marginTop:"-20px"}}>${ _.has(this.state.profile,'fullTimeRate') ? this.state.profile.fullTimeRate  : 0 } /month</h2>
							          				
								          		</Grid>
							          		</Grid>
			          					</div>
			          					
				          			</div>
				          			<div style={templateStyle.aboutContainer}>
		          						<Tabs value={this.state.submenu} onChange={(event,value)=>{ this.setState({ submenu: value }); } } variant="scrollable" scrollButtons="on" indicatorColor="primary" textColor="primary">
					                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded', minWidth:'80px',textTransform:'none'}} label={ <span style={{fontSize:14}}>About</span> } />
					                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded', minWidth:'80px',textTransform:'none'}} label={ <span style={{fontSize:14}}>Skills</span> } />
					                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded', minWidth:'80px',textTransform:'none'}} label={ <span style={{fontSize:14}}>Portfolio</span> } />
					                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded', minWidth:'80px',textTransform:'none'}} label={ <span style={{fontSize:14}}>Experience</span> } />
					                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded', minWidth:'80px',textTransform:'none'}} label={ <span style={{fontSize:14}}>Education</span> } />
					                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded', minWidth:'80px',textTransform:'none'}} label={ <span style={{fontSize:14}}>Ratings & Feedback</span> } />
					                    </Tabs>
		          					</div>
		          					<div className="App-panel">
		          						{this.state.submenu === 0 &&

		          							<div>
							          		{
							          			_.has(this.state.profile,'overview')? <p style={{textAlign:'justify', fontSize:14}} dangerouslySetInnerHTML={{ __html: this.state.profile.overview.replace(/(?:\r\n|\r|\n)/g, '<br/>') }}  /> : ''
							          		}
							          		</div>

		          						}

		          						{this.state.submenu === 1 && 
		          							<div>
		          							{ 	 !_.isEmpty( this.state.skills ) &&
		          									<List>
							          					{ skills }
							          				</List>
													
						          		 	}
						          		 	</div>
		          						}

		          						{this.state.submenu === 2 && 
		          							<div>
		          								{ !_.isEmpty(this.state.projects) &&
							          		 		<div>
							          		 			
								          		 		<Grid container>
									          		 		{
									          		 			projects
									          		 		}
								          		 		</Grid>
									          		 	
							          		 		</div>
							          		 	}
						          		 	</div>
		          						}

		          						{this.state.submenu === 3 && 
		          							<div>
		          								{ !_.isEmpty(this.state.employment) &&
										         	<div>
										         		{ employments }
													</div>
						          		 		}
						          		 	</div>
		          						}

		          						{this.state.submenu === 4 && 
		          							<div>
		          								{ !_.isEmpty( this.state.educations ) &&
						          		 		<div>
						          		 			{ educations }
						          		 		</div>
						          		 		}
						          		 	</div>
		          						}

		          						{this.state.submenu === 5 && 
		          							<div>
		          								{ !_.isEmpty( this.state.feedbacks ) &&
						          		 		<div>
						          		 			<List>
						          		 			{ feedbacks }
						          		 			</List>
						          		 		</div>
						          		 		}
						          		 	</div>
		          						}
		          					</div>
				          		</Grid>
				          		
				          		<Grid item md={1} lg={1}></Grid>
				          	</Grid>
				          	
				          
				       </div>
			        </header>

			        <Dialog
				        open={this.state.settingsDialog}
				        onClose={ ()=>this.setState({settingsDialog:false}) }
				        aria-labelledby="scroll-dialog-title"
				        aria-describedby="scroll-dialog-description"
				        fullWidth={true}
				        maxWidth={'md'}
				        scroll={this.state.scroll}
				      >
				      <DialogTitle id="scroll-dialog-title">Customize</DialogTitle>
				      <DialogContent>
				      		<Typography>Cover Photo</Typography>
				      		<br/>
	        				{ this.state.isCoverUpload === false &&
		        				<div style={{alignItems:'center', justifyContent:'center', display:'flex'}} >
		        				{
				          			this.state.coverPhoto ? <img onClick={()=>this.setState({isCoverUpload:true}) } style={{width:'100%',height:'auto',cursor:'pointer'}}  src={this.state.coverPhoto}   /> : <img onClick={()=>this.setState({isCoverUpload:true})} src='/cover-default.jpg' style={{width:'100%',height:'auto',cursor:'pointer'}}  />
				          		}
				          		</div>
			          		}
			          		{ this.state.isCoverUpload &&
		        				<FilePond 
		        					allowMultiple={false}
		        					maxFiles={1}
		        					maxFileSize="1MB"
		        					acceptedFileTypes={"image/*"}
		        					ref = { ref => this.pond = ref } >
		        				</FilePond>
	        				}

	        				<br/>
	        				<Typography>Skill Proficiency</Typography>
	        				<br/>
	        				<List>
	        					{skillProficiency}
	        				</List>
				      </DialogContent>
				     <DialogActions>
			          <Button  style={styles.buttonPrimary} autoFocus onClick={()=>this.saveCustom()}>
			            Save changes
			          </Button>
			        	</DialogActions>
				    </Dialog>

				    <Dialog
				        open={this.state.portfolioDialog}
				        onClose={ ()=>this.setState({portfolioDialog:false}) }
				        aria-labelledby="scroll-dialog-title"
				        aria-describedby="scroll-dialog-description"
				        fullWidth={true}
				        maxWidth={'sm'}
				        scroll={this.state.scroll}
				      >
				      <DialogTitle id="scroll-dialog-title">{ _.has(this.state.selectedPortfolio, "title") ? this.state.selectedPortfolio.title : "Project Title" }</DialogTitle>
				      <DialogContent>
				      		{ _.has(this.state.selectedPortfolio, "description") ? this.state.selectedPortfolio.description : "Project Description" }
				      		<br/><br/>
				      	
				      		{ _.has(this.state.selectedPortfolio,"projectfiles") ?
				      		<ImageList rowHeight={'300'} cols={2} justifyContent="center">
						        {this.state.selectedPortfolio.projectfiles.map((file,i) => (
						          <ImageListItem key={file}>
						            <img src={file} style={{width:'auto',height:'100%'}} />
						          </ImageListItem>
						        ))}
						    </ImageList>
						    : ""
							}
					        

							{ _.has(this.state.selectedPortfolio, "projectfiles") && this.state.selectedPortfolio.projectfiles.length === 0 ? <img alt='avatar' src={"/cropped.png"} style={{width:'100%'}} /> : "" }

				      </DialogContent>
				    </Dialog>

				    <Snackbar
				          anchorOrigin={{
				            vertical: 'bottom',
				            horizontal: 'center',
				          }}
		          		open={this.state.snackbar}
		          		autoHideDuration={6000}
		          		onClose={this.snackbarClose}
		          		ContentProps={{
		            		'aria-describedby': 'message-id',
		          		}}
		          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
				          action={[
				            <IconButton
				              key="close"
				              aria-label="Close"
				              color="inherit"
				              onClick={this.snackbarClose}
				            >
				              <CloseIcon />
				            </IconButton>,
				          ]} />
				    	</div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAuth: (user, state) => dispatch({type: "SET_AUTH", payload: { user: user, route: state }}),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Template1);





