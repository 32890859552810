import React, { Component } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Paper from '@mui/material/Paper';
import { connect } from 'react-redux'
import _ from 'lodash'
import CircularProgress from '@mui/material/CircularProgress';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import Avatar from '@mui/material/Avatar';
//import MaterialTable from 'material-table'
import PeopleIcon from '@mui/icons-material/People'
import FaceIcon from '@mui/icons-material/Face'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import { forwardRef } from 'react';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import Warning from '@mui/icons-material/Warning';
import ViewColumn from '@mui/icons-material/ViewColumn';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Alert from '@mui/lab/Alert';
import AttachMoneyIcon from '@mui/icons-material/MonetizationOn';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';

const rlimit = 1
var unsubscribe = null

class PayoutList extends Component{

	
	constructor(props){
		
		super(props);

		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		snackbar: false,
    		snackbarMessage: '',
    		isLoaded: true,
			users: [],
			openActionMenu: false,
			clientsCount: 0,
			freelancerCount: 0,
			dialogReason: false,
			identityVerifiedDialog: false,
			actionStatus:'',
			actionID: [],
			reasonText: '',
			notificationTitle: '',
			pendings: [],
			selectedStat : "PENDING",
			clientCount: 0,
			searchstring: '',
			identityverificationstatus: 'PENDING',
			selectedType: "",
			payouts: [],
			confirmPayoutDialog: false,
			selected_rowData: [],
			currentBalance: 0,
			transaction_input: ''
		};
	
	}	

	handleChange = (event, value) => {
	    this.setState({ submenu: value });
	};

	componentDidMount() {
		
		this.changeDisplay(this.state.selectedStat);
	}

	
	async changeDisplay(stat)
	{
		let that = this
		let requests = []
		let renderRequest = []
		let profile = []
		let withdrawal_data = []
		that.setState({ payouts: [], selectedStat: stat})	

		const wrRef = this.props.collection(this.props.db, "withdrawal_request");
	  	const q = this.props.query(wrRef, this.props.where("status", "==", stat), this.props.orderBy('created_at',"desc"), this.props.limit(1) );

	  	if(unsubscribe!=null)
			unsubscribe()

		unsubscribe = this.props.onSnapshot(q,(querySnapshot) => {
			  
			  renderRequest = []

			  querySnapshot.forEach((doc) => {
			    	
			    let id = doc.id
				let request = doc.data()
				let status = request.status
				let user = request.user
				let userid = user.uid

				withdrawal_data = {
					'withdrawAmount': request.withdrawAmount,
					'withdraw_fee': request.withdraw_fee,
					'withdraw_selection': request.withdraw_selection,
					'withdraw_selection_fee': request.withdraw_selection_fee, 
					'email':user.email,
					'status':  status ,
					'id': id,
					'fname': user.fname,
					'lname': user.lname, 
					'avatar': _.has(user.profile, 'avatar') ? user.profile.avatar : '',
					'name' : user.fname+" "+user.lname ,
					'userid': userid,
					'created_at': request.created_at,
					'user': user,
					'transaction_id': request.transaction_id,
					'processed_by': request.processed_by
				};
				
				renderRequest.push(withdrawal_data)

			 });

			 that.setState({ isLoaded: true, payouts : renderRequest, selectedStat: stat })

		}, (error) => {
		  console.error("Error getting documents: ", error);
		});

	  
		
	}

	async getBalance(uid)
	{

    	let that = this

    	const transactionsRef = this.props.collection(this.props.db, "transactions");
    	const q = this.props.query(transactionsRef, this.props.where("type", "==", "FUNDING_TRANSFER"), this.props.where("freelancer_id", "==", uid ), this.props.orderBy('created_at',"desc"), this.props.limit(1) );

    	const querySnapshot = await this.props.getDocs(q);
		querySnapshot.forEach((doc) => {
		  
		  	let last_transaction_id = doc.id
			let transaction = doc.data();
			let balance=Number(transaction.amount + transaction.last_balance)

			that.setState({currentBalance: balance})


		});
    }

	async searchID(){


		let that = this
		let requests = []
		let renderRequest = []

		const q = this.props.query(this.props.collection( this.props.db, "withdrawal_request"), this.props.where("user.email", "==", this.state.searchstring ), this.props.orderBy('created_at','desc'), this.props.limit(rlimit));

		const querySnapshot = await this.props.getDocs(q);
		querySnapshot.forEach((doc) => {
		  
		  	let id = doc.id
			let request = doc.data()
			let status = request.status
			let user = request.user
			let userid = user.uid
			
			renderRequest.push({
				'withdrawAmount': request.withdrawAmount,
				'withdraw_fee': request.withdraw_fee,
				'withdraw_selection': request.withdraw_selection,
				'withdraw_selection_fee': request.withdraw_selection_fee, 
				'email':user.email,
				'status':  status ,
				'id': id,
				'fname': user.fname,
				'lname': user.lname, 
				'avatar': _.has(user.profile, 'avatar') ? user.profile.avatar : '',
				'name' : user.fname+" "+user.lname ,
				'userid': userid,
				'created_at': request.created_at,
				'user': user
			})
		  	
		});

		that.setState({isLoaded: true, payouts: renderRequest})
	}

	/*

	//create notification add this later

	createNotification = (type,to) => {

		let message  =''
		let link = ''
		let iconname = ''
		if(type==="IDENTITYVERIFY_APPROVED")
		{
			message = "Identity verification was approved"
			link = "/profile"
			iconname = "security"
		}else if(type==="IDENTITYVERIFY_DECLINED")
		{
			message = "Identity verification was declined"
			link = "/profile"
			iconname = "security"
		}else if(type==="PROFILE_APPROVE")
		{
			message = "Your profile was approved"
			link = "/profile"
			iconname = "face"
		}else if(type==="PROFILE_DISAPPROVE")
		{
			message = "Your profile was declined"
			link = "/profile"
			iconname = "face"
		}else if(type==="PROFILE_SUSPEND")
		{
			message = "Your profile was suspended"
			link = "/profile"
			iconname = "security"
		}

		this.props.firebase.firestore().collection("notifications").doc().set({
						to: to,
						icon: iconname,
						type: type,
						unread: true,
						link: link,
						message: message,
						created_at: new Date()
					})
					.then(function(){
						
					})
					.catch(function(error){
						console.log('error: ',error);
					});
				

	} */

	
	async confirmPayout(rowData){

		this.getBalance(rowData.userid)
		this.setState({ selected_rowData: rowData, confirmPayoutDialog: true, actionID:rowData})
	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	jsUcfirst = (string) =>
	{
	    let str = string.charAt(0).toUpperCase() + string.slice(1);
	    return str
	}

	handleChangePage = (event, newPage) => {
    }

    handleChangeRowsPerPage = event => {

    }

    async markAsPaid()
    {
    	let that = this
    	let transaction_input = new String(this.state.transaction_input)
   
    	if(transaction_input.length==17)
    	{
    		const wrRef = this.props.doc(this.props.db, "withdrawal_request", this.state.selected_rowData.id );
			const wrSnap = await this.props.getDoc(wrRef);

			if (wrSnap.exists())
			{
				that.props.updateDoc(wrRef, {
			        transaction_id: that.state.transaction_input,
			        processed_by: that.props.user.uid,
			        status:"PAID"
			    }).then( async function(){
			       	
			       		that.setState({confirmPayoutDialog:false})
    					that.changeDisplay(that.state.selectedStat);

    					const q = that.props.query(that.props.collection( that.props.db, "transactions"), that.props.where("type", "==","FUNDING_TRANSFER"), that.props.where('freelancer_id','==',that.state.selected_rowData.userid),that.props.orderBy('created_at','desc'), that.props.limit(1));
    					const querySnapshot = await that.props.getDocs(q);

    					let last_transaction_id = ''
						let transaction = []
						let balance= 0

    					querySnapshot.forEach((doc) => {
		  
						  	last_transaction_id = doc.id
							transaction = doc.data();
							balance=Number(transaction.amount + transaction.last_balance)
						  	
						});

    					const transactionRef = that.props.doc( that.props.collection( that.props.db, "transactions"))
    					await that.props.setDoc(transactionRef, {
    						 freelancer: that.state.selected_rowData.user,
    						 freelancer_id: that.state.selected_rowData.userid,
    						 last_transaction_id: last_transaction_id, 
    						 last_balance:balance, 
    						 type: 'FUNDING_TRANSFER', 
    						 amount : Number(that.state.selected_rowData.withdrawAmount)*-1, 
    						 created_at : new Date()
    					}).then(()=>{
    							that.props.setLoading(false);

    							let message = MESSAGES.MONEY_SENT_PAYPAL
								message = message.replace("{receivable}", Number(that.state.selected_rowData.withdrawAmount) - Number(that.state.selected_rowData.withdraw_selection_fee)  )
								message = message.replace("{method}", that.state.selected_rowData.withdraw_selection )
								message = message.replace("{transaction_id}", that.state.transaction_input )
								message = message.replace("{address}", that.state.selected_rowData.user.profile.paypalme )
								
								fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
								{
								    headers: {
								      'Accept': 'application/json',
								      'Content-Type': 'application/json'
								    },
								    method: "POST",
								    body: JSON.stringify({ title: "You Got Your Earnings", name: that.state.selected_rowData.user.fname+" "+that.state.selected_rowData.user.lname, to: that.state.selected_rowData.user.email, message: message })
								})
								.then(function(res){ return res.json() })
								.then(function(data){
									that.changeDisplay(that.state.selectedStat);

								})
								.catch(function(res){ 
									console.log('error message',res)
								})
    					});
			    });

			}

    	}else{
    		this.setState({snackbarMessage: "Please enter the correct transaction id to continue.", snackbar: true})
    	}
    }
	

	render(){
	
		
		let that = this
		
		return (

			<div>
			
				<header>
				  { this.state.isLoaded === true &&
			          <div style={{marginTop: 100}}>
				          <Grid container spacing={2}>
				          		<Grid item xs={12} md={2}></Grid>
				          		<Grid item xs={12} md={8}>
				          		 	<h1 className="App-title">Payouts Request</h1>
				          		 	<br/>
						         	<div>

						         	<Grid container>
				         				<Grid item xs={4}>
				         					<Typography>Status</Typography>
				         					<br/>
				         					<Select
									          value={this.state.selectedStat}
									          onChange={(event)=> this.changeDisplay(event.target.value) }
									          style={{width: '80%'}}
									        >
									          <MenuItem value="PENDING">PENDING</MenuItem>
									          <MenuItem value="PAID">PAID</MenuItem>
									        </Select>
				         				</Grid>
				         				<Grid item xs={4}>
				         					<Typography>Search By Payout Request Email</Typography>
				         				
				         					<FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
          										<OutlinedInput
           											style={{width:'100%',  marginTop:'14px'}}
										            type={'text'}
										            value={this.state.searchstring}
										            onChange={ (event)=> this.setState({searchstring:event.target.value}) }
										            endAdornment={
										              <InputAdornment position="end">
										                <IconButton
										                  aria-label="toggle password visibility"
										                  onClick={ ()=> this.searchID() }
										                  edge="end"
										                >
										                  <SearchIcon />
										                </IconButton>
										              </InputAdornment>
										            
									            	}	/>
								            </FormControl>

				         				</Grid>
				         				<Grid item xs={4}>
				         					
				         				</Grid>
				         			</Grid>

				         			<br/>
						         	
						         	<TableContainer component={Paper}>
						         		<Table>
						         			<TableHead>
						         					<TableRow>
											            <TableCell align="center">Name</TableCell>
											            <TableCell align="center">Payment Method</TableCell>
											            <TableCell align="center">Email</TableCell>
											            <TableCell align="center">Amount</TableCell>
											            <TableCell align="center">Transaction Fee</TableCell>
											            <TableCell align="center">Date</TableCell>
											            <TableCell align="center">Actions</TableCell>
											         </TableRow>
						         			</TableHead>
						         			<TableBody>
						         				{
						         					this.state.payouts.map((row)=>(
						         						<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
													             <TableCell component="th" scope="row" >{row.name}</TableCell>
													             <TableCell align="center">{ row.withdraw_selection }</TableCell>
													             <TableCell align="center">{row.email}</TableCell>
													             <TableCell align="center">${row.withdrawAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits:2 } )}</TableCell>
													             <TableCell align="center">${row.withdraw_selection_fee.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits:2 } )}</TableCell>
													             <TableCell align="center">{  row.created_at.toDate().toDateString() }</TableCell>
													             <TableCell align="center">
														             <Tooltip title="Confirm Payout">
															             <IconButton onClick={()=>{
															            
															            	that.confirmPayout(row) 	
															         	
															             }}>
																			  <AttachMoneyIcon />
																		 </IconButton>
																	 </Tooltip>

														             <Tooltip title="View Profile">
															             <IconButton onClick={()=>{
															             	
															             	window.open("/freelancer/"+row.userid, "_blank")

															             }}>
																			  <ChevronRight />
																		 </IconButton>
																	 </Tooltip>
													             </TableCell>
													    </TableRow>
						         					))
						         				}
						         			</TableBody>
						         		</Table>
						         	</TableContainer>	
						         
				          		 </div>
				          		
				          		
				          		</Grid>
				          		<Grid item xs={12} md={2}></Grid>
				          		
				          	</Grid>
				      </div>
			  	  }

			      { this.state.isLoaded === false &&
	                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
	                        <CircularProgress style={{color:'#212121', alignSelf:'center'}} />
	                    </div>
	              }
		        </header>

		        <Snackbar
			        anchorOrigin={{
			          vertical: 'bottom',
			          horizontal: 'center',
			        }}
			        open={this.state.snackbar}
			        autoHideDuration={5000}
			        onClose={ ()=>this.setState({snackbar:false}) }
			        message={this.state.snackbarMessage} />


			    <Dialog
			        open={this.state.confirmPayoutDialog}
			        onClose={ ()=>this.setState({confirmPayoutDialog:false})}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			        fullWidth={true}
			        maxWidth="lg"
			      >
			        <DialogTitle id="alert-dialog-title">Confirm Payout</DialogTitle>
			        <DialogContent>
			        	
					    <TableContainer component={Paper}>
					    	<Table aria-label="simple table">
					    		<TableHead>
						          <TableRow>
						            <TableCell align="center">Withdraw ID</TableCell>
						            <TableCell>Name</TableCell>
						            <TableCell align="center">Withdraw Amount</TableCell>
						            <TableCell align="center">Receivable Amount</TableCell>
						            <TableCell align="center">Available Balance</TableCell>
						            <TableCell align="center">Balance After Withdrawal</TableCell>
						            <TableCell align="center">Paypal.me</TableCell>
						            <TableCell align="center">Payout Transaction ID</TableCell>
						            <TableCell align="center">Action</TableCell>
						          </TableRow>
						        </TableHead>
						        <TableBody>
						           <TableRow>
						            <TableCell>{ _.has(this.state.selected_rowData,'id') ? this.state.selected_rowData.id : "" }</TableCell>
						            <TableCell>{ _.has(this.state.selected_rowData,'name') ? this.state.selected_rowData.name : "" }</TableCell>
						            <TableCell align="center">$ { _.has(this.state.selected_rowData,'withdrawAmount') ? Number(this.state.selected_rowData.withdrawAmount).toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2}) : "0" } </TableCell>
						            <TableCell align="center" style={{color:'red'}}>$ { _.has(this.state.selected_rowData,'withdrawAmount') ? Number(this.state.selected_rowData.withdrawAmount) - (  Number(this.state.selected_rowData.withdraw_selection_fee) ) : "0" } </TableCell>
						           
						            { _.has(this.state.selected_rowData,'status') && this.state.selected_rowData.status ==="PENDING" &&

						            <TableCell align="center">${ Number(this.state.currentBalance).toLocaleString(undefined, {maximumFractionDigits:2 , minimumFractionDigits:2}) }</TableCell>
						            
						            }

						            { _.has(this.state.selected_rowData,'status') && this.state.selected_rowData.status ==="PENDING" &&
						            
						            <TableCell align="center">${ Number(this.state.currentBalance) > 0 ? Number( Number(this.state.currentBalance)-Number(this.state.selected_rowData.withdrawAmount) ).toLocaleString(undefined, {maximumFractionDigits:2 , minimumFractionDigits:2}) : 0 }</TableCell>
						            
						            }

						            { _.has(this.state.selected_rowData,'status') && this.state.selected_rowData.status ==="PAID" &&

						            	<TableCell align="center"></TableCell>
						            	
						        	}

						        	{ _.has(this.state.selected_rowData,'status') && this.state.selected_rowData.status ==="PAID" &&

						            	<TableCell align="center"></TableCell>
						            
						        	}

						            <TableCell align="center">{ _.has(this.state.selected_rowData,'user') ? this.state.selected_rowData.user.profile.paypalme : '' }</TableCell>
						            { _.has(this.state.selected_rowData,'status') && this.state.selected_rowData.status ==="PENDING" &&
						            <TableCell align="center">
						            	<TextField
					          					error={this.state.transaction_input_error}
					          					name='title'
										      	variant="outlined"
										      	margin="normal" onChange={(event)=>this.setState({transaction_input:event.target.value})} value={this.state.transaction_input} fullWidth={true} />
						            </TableCell>
						        	}

						        	{ _.has(this.state.selected_rowData,'status') && this.state.selected_rowData.status ==="PAID" &&
						            	<TableCell align="center"> 
						            		{ this.state.selected_rowData.transaction_id }
						            	</TableCell>
						        	}

						            <TableCell align="center">
						            	{ _.has(this.state.selected_rowData,'status') && this.state.selected_rowData.status ==="PENDING" &&
						            	<Tooltip title="Mark as Paid">
							            	<IconButton variant="outlined" onClick={ ()=>{ this.markAsPaid() } }>
							            		  <CheckIcon />
							            	</IconButton>
							            </Tooltip>
							        	}

						            </TableCell>
						          </TableRow>
						        </TableBody>
					    	</Table>
					    </TableContainer>
					    <br/><br/>
			        </DialogContent>
			       
			      </Dialog>
   
	    	</div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		clientCount: state.clientCount,
		freelancerCount: state.freelancerCount,
		collection: state.collection,
		db: state.db,
		where: state.where,
		orderBy: state.orderBy,
		limit: state.limit,
		query: state.query,
		getDoc: state.getDoc,
		getDocs: state.getDocs,
		doc: state.doc,
		onSnapshot : state.onSnapshot,
		updateDoc : state.updateDoc,
		setDoc: state.setDoc 
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setClientCount: (count) => dispatch({type: "SET_CLIENT_COUNT", payload: {clientCount: count}}),
		setFreelancerCount: (count) => dispatch({type: "SET_FREELANCER_COUNT", payload: {freelancerCount: count}}),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(PayoutList);





