import React, { Component } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import {Helmet} from 'react-helmet'

class GoogleAdsense extends Component {

	constructor(props){
		
		super(props);

		this.state = {
			snackbar: false,
    		snackbarMessage: '',
    		adBlockDetected: false
		};
	
	}	

	componentWillMount(){
		
	

	}

	componentDidMount()
	{
		 (window.adsbygoogle = window.adsbygoogle || []).push({});
	}

	componentWillUnmount()
	{
		
	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	render(){

		let that = this

		return (
			<div>
			{
				this.props.location==="feed" &&
					<ins style={{display:'block',textDecoration: 'none'}} class="adsbygoogle" data-ad-format="fluid" data-ad-layout-key="-fb+5w+4e-db+86" data-ad-client="ca-pub-8870698099238743" data-ad-slot="7295764064"></ins>
					
			}

			{
				this.props.location==="side" &&
					<ins class="adsbygoogle" style={{textDecoration: 'none'}} data-ad-client="ca-pub-8870698099238743" data-ad-slot="7252366183" data-ad-format="auto" data-full-width-responsive="true"></ins>
			}

			{

				this.props.location==="horizontal" &&
					<ins class="adsbygoogle" style={{textDecoration: 'none'}} data-ad-client="ca-pub-8870698099238743" data-ad-slot="7447529652" data-ad-format="auto" data-full-width-responsive="true"></ins>
			}


			 <Snackbar
		          anchorOrigin={{
		            vertical: 'bottom',
		            horizontal: 'right',
		          }}
          		open={this.state.snackbar}
          		autoHideDuration={3000}
          		onClose={this.snackbarClose}
          		ContentProps={{
            		'aria-describedby': 'message-id',
          		}}
          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
		          action={[
		            <IconButton
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              onClick={this.snackbarClose}
		            >
		              <CloseIcon />
		            </IconButton>,
		          ]} />

			</div>


			)
	}

}

const mapStateToProps = (state) => {
	return {
		user: state.user
		
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAdsense);