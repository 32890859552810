import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import ChatIcon from '@mui/icons-material/Chat';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import Divider from '@mui/material/Divider';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import OutlinedInput from '@mui/material/OutlinedInput';
import _ from 'lodash'

import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Alert from '@mui/lab/Alert';

import Grid from '@mui/material/Grid';

registerPlugin(FilePondPluginImagePreview);

const styles = {
    bigAvatar:{
        margin: 10,
        width: 150,
        height: 150,
        alignSelf:'center',
        cursor: 'pointer'
    },
    title: {
        color: '#333333',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10,
        fontSize: 24
    },
    subtitle: {
        color: '#8e8e8e',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10,
        fontSize: 18
    },
    center:{
        textAlign:'center',
        color: '#3c4859'
    },

    small: {
        color: '#8e8e8e',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10
       },

       em: {
         color: '#8e8e8e',
         fontFamily: 'Gotham Rounded'
       },

       buttonPrimary: {
        padding: 10,
        fontFamily: 'Gotham Rounded',
        backgroundColor: '#00b16a'
       },

       buttonDefault: {
        padding: 10,
        fontFamily: 'Gotham Rounded'
       }

}

class BuyCredits extends Component{
    
    constructor(props){
        
        super(props);

        this.state = {
            fullWidth: true,
            maxWidth: 'sm',
            db: [],
            snackbar: false,
            snackbarMessage: '',
            applyDialog: false,
            job: [],
            proposedRate: 0,
            proposedRateError:false,
            coverLetter: '',
            coverLetterError: false,
            otherLinks: '',
            otherLinksError: false,
            hourlyRate: 0,
            fullTimeRate: 0,
            jobApplication: [],
            allApplications:[],
            preference: '',
            confirmDeleteJobDialog: false,
            declineReason: '',
            jobApplicationID: '',
            declineApplicationDialog: false,
            interviewApplicationDialog: false,
            showDeclineReason: false,
            tabActive: "ALL",
            interviewMessage: '',
            showApplicationDialog: false,
            offerApplicationDialog: false,
            offerMessage: '',
            offerJobType: '',
            offerDuration: '',
            offerRate: '',
            offerHoursPerWeek: '',
            offerNotes: '',
            interviewDate: new Date(),
            interviewTime: new Date(),
            profileStatus: '',
            showContractDialog:false,
            planSelected: 5,
            planSelectedError:false
        };
    
    }   

     handleChange = (event, value) => {
        this.setState({ submenu: value });
      };

    componentDidMount() {

        this.setState({isLoaded:true})
        
    }
    
  
    render(){
    
        let that = this

       return (

            <div>
            
            <header>
              { this.state.isLoaded === true &&
                  <div style={{marginTop: 100}}>
                      
                        <Grid container>
                            
                            <Grid item xs={2} md={2}></Grid>
                            <Grid item xs={8} md={8}>

                             <h1 className="App-title">Buy Credits</h1>
                             
                             <br/>
                             
                            </Grid>
                            <Grid item xs={2} md={2}></Grid>
                            
                        </Grid>

                        <Grid container>
                            <Grid item xs={2} md={2}></Grid>
                            <Grid item xs={8} md={8}>
                                <Typography style={styles.em}>Select Credit Plans</Typography> <br/>

                                <FormControl variant="outlined" style={{width:'100%'}}>
                                <Select
                                        error={this.state.planSelectedError}
                                        onChange={ (event) => this.setState({planSelected: event.target.value }) }
                                        value={this.state.planSelected}
                                        fullWidth={true}
                                        input={<OutlinedInput />} >
                                

                                       <MenuItem value={5}>
                                                <em style={styles.em}>25 Credits - $5</em>
                                       </MenuItem>
                                       <MenuItem value={9}>
                                                <em style={styles.em}>50 Credits - $9</em>
                                       </MenuItem>
                                       <MenuItem value={15}>
                                                <em style={styles.em}>100 Credits - $15</em>
                                       </MenuItem>
                                            
                                    </Select>
                                </FormControl>

                                <br/><br/>

                                <Typography style={styles.em}>Select Payment Method</Typography> <br/>

                                
                                <Grid container>
                                    <Grid item xs={6}>
                                        <div onClick={ ()=> this.setState({setupPaypalDialog:true}) }
                                          style={{
                                            width: "100%",
                                            textAlign:'center'
                                          }}
                                          className="buttonbase"
                                        >
                                            <img alt="paypalme" src="../paypalme.png" />
                                            <h2>PayPal</h2>
                                        </div>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <div onClick={ ()=> this.setState({setupPaypalDialog:true}) }
                                          style={{
                                            width: "100%",
                                            textAlign:'center'
                                          }}
                                          className="buttonbase"
                                        >
                                            <img alt="paypalme" src="../gcash.png" />
                                            <h2>GCash</h2>
                                        </div>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={2} md={2}></Grid>
                        </Grid>
                  </div>
              }

              { this.state.isLoaded === false &&
                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                        <CircularProgress style={{color:'#212121', alignSelf:'center'}} />
                    </div>
              }
            </header>

           
            <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                open={this.state.snackbar}
                autoHideDuration={5000}
                onClose={this.snackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{ this.state.snackbarMessage }</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={this.snackbarClose}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]} />
        </div>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        firebase: state.firebase,
        user: state.user,
        isLoggedIn: state.isLoggedIn
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyCredits);





