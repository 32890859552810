import React, {Component} from 'react'
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';

const styles = {
   header:{
    fontSize: 64
   },
   buttonPrimary: {
    padding: 18,
    fontFamily: 'Gotham Rounded',
    backgroundColor: '#37c96a'
   }
}


class ComingSoon extends Component {

    constructor(props){
        super(props);

        this.state = {
            emailError: false,
            email: '',
            message: 'hello',
            open: false
        }
    }

    componentDidMount(){
        
    }

    handleSubmit = () =>{
        if( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( this.state.email ) ){
            this.setState({open:true, message: "Please enter a valid email address."})
        }else{

            const db = this.props.firebase.firestore()
            let that = this
                
            db.collection("emailList").doc().set({
                email: that.state.email,
                created_at: new Date()
            })
            .then(function(){
                that.setState({open:true, message: "Thank you for your interest. We promise to keep you updated with our progress. Please also like our facebook page."})
            })
            .catch(function(error){
                console.log(error);
            });

        }
    }

    onClose = ()=>{
        this.setState({open:false})
    }

    render(){
        return (
              <div className="comingsoon">
                 <Grid container>
                        <Grid item sm={12} md={2}></Grid>
                        <Grid item sm={12} md={8}>
                            <div className="panel">
                                <center><img alt="logo" className="logo-standard" src="/invert.png" /></center>
                                <center style={styles.header}>Coming Soon</center>
                                <center>Freelancertogo is just another freelancing website created by freelancer for freelancers. We will get the website running as soon as we can but for the meantime enter your email below to get the latest update from us.</center>

                                <TextField
                                    error={this.state.emailError}
                                    name='title'
                                    variant="outlined"
                                    placeholder="Enter your email"
                                    margin="normal" value={this.state.email} fullWidth={true} onChange={ (event) => this.setState({email: event.target.value }) } />

                                <br/>
                                <Button onClick={ ()=> this.handleSubmit() } style={styles.buttonPrimary} type='submit' variant='contained' color='primary' fullWidth={true}>Submit</Button>
                                        
                                <br/><br/>
                                <center>Like our page <a style={{color:'#f1c40f'}} href="https://facebook.com/freelancertogo" target="_blank" rel="noopener noreferrer">fb/freelancertogo</a></center>
                            </div>

                        </Grid>
                        <Grid sm={12} md={2}></Grid>
                    </Grid>

               <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                      }}
                    open={this.state.open}
                    autoHideDuration={10000}
                    onClose={this.onClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{ this.state.message }</span>}
                      action={[
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={this.onClose}
                        >
                          <CloseIcon />
                        </IconButton>,
                      ]} />
              </div>
        )
    }
}

const mapStateToProps = (state) => { 
    return {
        firebase: state.firebase
    }
}

const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(mapStateToProps,mapDispatchToProps)(ComingSoon);  

