import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { connect } from 'react-redux'
import _ from 'lodash'
import { registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import moment from 'moment'
import Rating from '@mui/lab/Rating';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Alert from '@mui/lab/Alert';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

registerPlugin(FilePondPluginImagePreview);


const styless = {
	bigAvatar:{
		margin: 10,
		width: 100,
		height: 100,
		alignSelf:'center',
		cursor: 'pointer'
	},
	title: {
		color: '#333333',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 24
	},
	subtitle: {
		color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 18
	},
	center:{
		textAlign:'center',
		color: '#3c4859'
	},

	small: {
	   	color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10
	   },

	   em: {
	   	 color: '#8e8e8e',
	   	 fontFamily: 'Gotham Rounded'
	   },

	   buttonPrimary: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded',
	   	backgroundColor: '#00b16a'
	   },

	   buttonDefault: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded'
	   }

}

class EndContract extends Component{
	

	constructor(props){
		
		super(props);

		this.state = {
			isLoaded:true,
			options: ['completed','failed'],
			selectedOption: 'completed',
			note: '',
			noteError:false,
			feedback: '',
			freelancerFeedback: '',
			feedbackError: false,
			expertise: 0,
			communication: 0,
			quality: 0,
			timeline: 0,
			id: '',
			isEndContract:false,
			totalScore: 0,
			snackbar: false,
			snackbarMessage: '',
			contract: [],
			endContractBy: '',
			endContractAt: '',
			isSubmitted: false,
			jobTitle: ''
		};
	
	}	


	componentDidMount() {
		
		let id =  this.props.id
		
		this.setState({id:id}, function(){
			this.getContract()
		})

		
		
	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	setTotalScore(){
		let that = this
	
		this.setState({totalScore: (that.state.expertise + that.state.communication + that.state.quality + that.state.timeline) / 4 }) 
	}

	localStorageMultiRemove()
    {
		let keys  = ['APPLIED','INVITATION','OFFER','DECLINED_OFFER','HIRE','DECLINED']

		for(let i = 0 ; i <keys.length ; i++)
		{
			localStorage.removeItem( 'jobApplications_'+keys[i]+'_'+this.props.id);
		}	
   }

	async getContract(){
		let that = this

		const jaRef = this.props.doc(this.props.db, "jobApplications", this.props.id );
		const jaSnap = await this.props.getDoc(jaRef);

		if (jaSnap.exists())
		{
			let contract = jaSnap.data()
			if(_.has(contract,'end_contract_at'))
			{
			
				that.setState({ jobTitle: contract.jobPostTitle, endContractFreelancer:  _.has(contract, 'end_contract_freelancer_at') ? moment(contract.end_contract_freelancer_at.toDate()).format('DD/MM/YYYY hh:mm a') : "", endContractClient:  _.has(contract, 'end_contract_client_at') ? moment(contract.end_contract_client_at.toDate()).format('DD/MM/YYYY hh:mm a') : "", endContractAt: _.has(contract, 'end_contract_at') ? moment(contract.end_contract_at.toDate()).format('DD/MM/YYYY hh:mm a') : "",
							    endContractBy: _.has(contract,'end_contract_initiated_by') ? contract.end_contract_initiated_by : '',
							    isEndContract:true,
							    totalScore: _.has(contract,'end_contract_totalscore') ? contract.end_contract_totalscore : 0 ,
							    timeline: _.has(contract,'end_contract_timeline') ? contract.end_contract_timeline : 0 ,
							    quality: _.has(contract,'end_contract_quality') ? contract.end_contract_quality : 0 ,
							    communication: _.has(contract,'end_contract_communication') ? contract.end_contract_communication : 0 ,
							    expertise: _.has(contract,'end_contract_expertise') ? contract.end_contract_expertise : 0 ,
							    feedback: _.has(contract,'end_contract_feedback') ? contract.end_contract_feedback : '' ,
							    selectedOption: _.has(contract,'end_contract_reason') ? contract.end_contract_reason : 0,
							    freelancerFeedback: _.has(contract,'end_contract_freelancer_feedback') ? contract.end_contract_freelancer_feedback : 0,
							    contract: contract })
			}
		}

	}

	async endContract(){
		let that = this
		let data

		if(this.state.isEndContract === true)
		{

			if(this.state.feedback===''){
				this.setState({feedbackError:true})
				return
			}else{
				this.setState({feedbackError:false})
			}

			data = {
				'end_contract_feedback': that.state.feedback,
				'end_contract_expertise': that.state.expertise,
				'end_contract_communication': that.state.communication,
				'end_contract_quality': that.state.quality,
				'end_contract_timeline': that.state.timeline,
				'end_contract_totalscore': that.state.totalScore,
				'end_contract_client_at' : new Date(),
			}

		}else{

			data = {
				'end_contract_at': new Date() ,
				'end_contract_client_at' : new Date(),
				'end_contract_reason': that.state.selectedOption,
				'end_contract_feedback': that.state.feedback,
				'end_contract_expertise': that.state.expertise,
				'end_contract_communication': that.state.communication,
				'end_contract_quality': that.state.quality,
				'end_contract_timeline': that.state.timeline,
				'end_contract_totalscore': that.state.totalScore,
				'end_contract_initiated_by' : 'client'
			}
		}


		const jaRef = that.props.doc(that.props.db, "jobApplications", this.state.id );

		that.props.updateDoc(jaRef, data ).then( ()=>{
	     	that.setState({isSubmitted:true, isEndContract:true, snackbar:true, snackbarMessage: 'Contract ended successfully'})
			that.localStorageMultiRemove()
	     } );

	}


	render(){

		
		let that = this

		return (

			<div>
				
				{ this.state.isLoaded === true &&
		          <div style={{marginTop: 100}}>

			          	<Grid container>
			          		
			          		<Grid item xs={0} md={2}>
			          		</Grid>
			          		<Grid item xs={12} md={8}>

			          			 <Tooltip title="Back">
					          		 	<Link to="/my-applications">
						          		 	<IconButton style={{alignSelf:'center', color:"#00b16a"}} aria-label="delete"  color="primary">
								           		<ArrowBackIcon />
								         	</IconButton>
							         	</Link>
							     </Tooltip>

			          			 <h1 className="App-title">
				          		 	 Contract<br/>
				          		 	 <b>{this.state.jobTitle}</b>
				          		 </h1>
				          		 
				          		
				          		 { this.state.isSubmitted === true &&
					          		 <center>
					        			<img alt="verified" src="/verified.png" />
					        			<Typography>Thank you for submitting your feedback</Typography>
					        			<br/>
					        			<a href="/jobs"><Button style={styless.buttonPrimary} type='submit' variant='contained' color='primary' fullWidth={false}>Back to Jobs</Button></a>
					        		 </center>
				        		 }
				          		 
				          		  { this.state.isEndContract === true && this.state.isSubmitted === false && 
				          		  	<div>
					          		 	{ this.state.endContractClient !== "" &&
				          		 			<Alert severity="info">Client ended contract on { this.state.endContractClient }</Alert>
				          		 		}

				          		 		<br/>

				          		 		{ this.state.endContractFreelancer !== "" &&
				          		 			<Alert severity="success">Freelancer ended contract on { this.state.endContractFreelancer }</Alert>
				          		 		}
					          		 	<br/>
					          		  	<Card variant="outlined">
					          		  		<CardContent>
					          		  			{ !_.has(this.state.contract,'end_contract_feedback') && 
					          		  				<Typography style={{color:'rgba(0, 0, 0, 0.87)'}}>Freelancer's feedback will be available once you submit your feedback.</Typography>
					          		  			}

					          		  			

					          		  			{ _.has(this.state.contract,'end_contract_freelancer_feedback') && _.has(this.state.contract,'end_contract_feedback') &&
					          		  				<div>
					          		  				<Typography>Freelancer's Feedback</Typography>
						          		  				<List>
						          		  					<ListItem>
						          		  						<ListItemAvatar>
						          		  							{ _.has(this.state.contract, 'user') && _.has(this.state.contract.user,'avatar') &&
								          		  						<Avatar alt="Remy Sharp" src={ that.state.contract.user.avatar } />
								          		  					}
						          		  						</ListItemAvatar>
						          		  						<ListItemText secondary={'"'+this.state.contract.end_contract_freelancer_feedback+'"'}  primary={ _.has(this.state.contract, 'user') && _.has(this.state.contract.user,'fname') && this.state.contract.user.fname +" "+this.state.contract.user.lname } />	
						          		  					</ListItem>
						          		  					
						          		  				</List>
					          		  				</div>
					          		  			}

					          		  			{ !_.has(this.state.contract,'end_contract_freelancer_feedback') && 
					          		  				<Typography style={{color:'rgba(0, 0, 0, 0.87)'}}><i>"Freelancer didnt submit a feedback yet."</i></Typography>
					          		  			}
					          		  		</CardContent>
					          		  	</Card>
				          		  	</div>
				          		  }

				          		 <br/>

				          		 { this.state.isSubmitted === false &&
					          		 <Card variant="outlined">
					          		 	<CardContent>
							          		 { this.state.isEndContract === false &&
							          		 	<div>
							          		 		<Typography style={styless.small}>Reason for ending the contract</Typography>

							          		 		<Select
							          				  	onChange={ (event) => this.setState({selectedOption: event.target.value }) }
														value={this.state.selectedOption}
														disabled = {this.state.isEndContract}
													    inputProps={{
													    name: 'preference',
													    }} fullWidth={true} >

													    <MenuItem value="completed">
													      <em>Project Completed</em>
													    </MenuItem>
													    <MenuItem value="failed">
													      <em>Project did not end well</em>
													    </MenuItem>
													    <MenuItem value="floating">
													      <em>Project did not start</em>
													    </MenuItem>
													  </Select>

													  <br/><br/>
							          		 	</div>


							          		 }

							          		

						          			 { _.has(this.state.contract, 'end_contract_initiated_by') && 
							          		 	<div>
									          		 <Typography style={styless.small}>Contract Ended Initiated By: </Typography>
					          		  				 <Typography style={{color: 'rgba(0, 0, 0, 0.87)'}}>{ this.state.contract.end_contract_initiated_by.charAt(0).toUpperCase() + this.state.contract.end_contract_initiated_by.slice(1) }</Typography>
									          		
									          		 <hr/>
							          			</div>
						          			 }

						          			 { _.has(this.state.contract, 'end_contract_reason') && 
							          		 	<div>
									          		 <Typography style={styless.small}>Reason for Ending the Contract: </Typography>
									          		 <Typography style={{color: 'rgba(0, 0, 0, 0.87)'}}>Job { this.state.contract.end_contract_reason }</Typography>
									          			
									          		 <hr/>
							          			</div>
						          			 }

							          		 <Typography style={styless.small}>Feedback to Freelancer</Typography>

							          		 { !_.has(this.state.contract, 'end_contract_feedback') && 
								          		 <TextField
												  	multiline
												  	rows={3}
												  	error={this.state.feedbackError}
						          					name='feedback'
											      	variant="outlined"
											      	margin="normal" value={this.state.feedback} fullWidth={true} onChange={ (event) => this.setState({feedback: event.target.value }) } />
						          			 }

						          			 { _.has(this.state.contract, 'end_contract_feedback') && 
						          			 	<Typography><i style={{color: 'rgba(0, 0, 0, 0.87)'}}>"{this.state.contract.end_contract_feedback}"</i></Typography>
						          			 }
					          				  <hr/>

					          				  <Typography style={styless.small}>Rating to Freelancer : <span style={{color:'#ffb400'}}>{ Number(this.state.totalScore).toFixed(2) }</span></Typography>
								     
					          				  <List>
										      	<ListItem>
										      		<ListItemText
									                    primary="Expertise"
									                  />
									                <ListItemSecondaryAction>
									                	<Rating
									                	  disabled={ !_.has(this.state.contract, 'end_contract_expertise') ? false : true }
									                	  name="expertise"
												          value={this.state.expertise}
												          onChange={(event, newValue) => {
												          	
												            this.setState({expertise:newValue}, function(){
												            	this.setTotalScore()
												            })
												            
												          }}
												        />
									                </ListItemSecondaryAction>
										      	</ListItem>

										      	<ListItem>
										      		<ListItemText
									                    primary="Communication"
									                  />
									                <ListItemSecondaryAction>
									                	<Rating
									                	  disabled={ !_.has(this.state.contract, 'end_contract_communication') ? false : true }
									                	  name="communication"
												          value={this.state.communication}
												          onChange={(event, newValue) => {
												          	
												            this.setState({communication:newValue}, function(){
												            	 this.setTotalScore()
												            })
												           
												          }}
												        />
									                </ListItemSecondaryAction>
										      	</ListItem>

										      	<ListItem>
										      		<ListItemText
									                    primary="Quality"
									                  />
									                <ListItemSecondaryAction>
									                	<Rating
									                	  disabled={ !_.has(this.state.contract, 'end_contract_quality') ? false : true }
									                	  name="quality"
												          value={this.state.quality}
												          onChange={(event, newValue) => {
												          	
												            this.setState({quality:newValue}, function(){
												            	this.setTotalScore()
												            })
												            
												          }}
												        />
									                </ListItemSecondaryAction>
										      	</ListItem>

										      	<ListItem>
										      		<ListItemText
									                    primary="Compliance  with the Timeline"
									                  />
									                <ListItemSecondaryAction>
									                	<Rating
									                	  disabled={ !_.has(this.state.contract, 'end_contract_timeline') ? false : true }
												          name="timeline"
												          value={this.state.timeline}
												          onChange={(event, newValue) => {
												          	
												            this.setState({timeline:newValue}, function(){
												            	this.setTotalScore()
												            })

												          }}
												        />
									                </ListItemSecondaryAction>
										      	</ListItem>
										      </List>

					          		 	</CardContent>
					          		 </Card>
				          		 }
				          		 	
				          		 <br/>
				          		 
				          		 { !_.has(this.state.contract, 'end_contract_feedback' ) && this.state.isSubmitted === false &&
								     <Button style={styless.buttonPrimary} onClick={ () => this.endContract() } variant='contained' color='primary' fullWidth={true}>
				          					Submit Feedback
				          			 </Button>
			          			 }

			          		</Grid>
			          		<Grid item xs={0} md={2}>

			          		</Grid>
			          		
			          	</Grid>
			         
			      </div>
		  	  }

		     
		     <Snackbar
		          anchorOrigin={{
		            vertical: 'bottom',
		            horizontal: 'center',
		          }}
          		open={this.state.snackbar}
          		autoHideDuration={5000}
          		onClose={this.snackbarClose}
          		ContentProps={{
            		'aria-describedby': 'message-id',
          		}}
          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
		          action={[
		            <IconButton
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              onClick={this.snackbarClose}
		            >
		              <CloseIcon />
		            </IconButton>,
		          ]} />
		
	    	</div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		favFreelancers: state.favFreelancers,
		doc: state.doc,
		collection: state.collection,
		db: state.db,
		getDoc: state.getDoc,
		updateDoc: state.updateDoc
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		setFavFreelancers: (favs) => dispatch({type: "SET_FAV_FREELANCERS", payload: {favFreelancers:favs}})
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(EndContract);





