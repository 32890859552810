import React from "react";
import ReactDOM from "react-dom";
//import scriptLoader from "react-async-script-loader";
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';


import * as firebaseconfig from './firebase'; 


const CLIENT_ID =firebaseconfig.PAYPAL.funding
//create button here
let PayPalFunding = null;

 const paypalOptions = {
    'client-id': CLIENT_ID, // Replace with your PayPal Client ID
    currency: 'USD', // Specify the currency
  };

// next create the class and Bind React and ReactDom to window
//as we will be needing them later

class PaypalFunding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpaypalFunding:0,
      checkoutAmountError:false,
      fee: 0.05,
      paypal_fixed: 0.49,
      paypal_percentage: 0.0349,
      fundAmount: 0,
      showButtons: false,
      loading: true,
      paid: false,
      processing:false,
      selectedPackage: 0,
      processing: false,
      min_funding:0,
      max_funding:0,
      creditPackages: [
      {value: 0, amount: 5, credits: 15},
      {value: 1, amount: 10, credits: 35},
      {value: 2, amount: 15, credits: 60}
      ],
      jpc: 0,
      creditPackagesFreelancer: [
      {value: 0, amount: 5, credits: 50},
      {value: 1, amount: 10, credits: 110},
      {value: 2, amount: 15, credits: 170}
      ]
  
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }
  
  componentDidMount()
  {
    this.getStat()
    this.setState({ loading: false, showButtons: true, jpc: this.props.user.jpc, user: this.props.user.uid });   
  }

  async getStat()
  {

      let that = this
      const querySnapshot = await this.props.getDocs(this.props.collection(this.props.db, "stats"));
        
      querySnapshot.forEach((doc) => {
          
         let data = doc.data()
         data.id = doc.id

        this.setState({min_funding: data.min_funding, max_funding: data.max_funding}) 

      });

  }

  createOrder = (data, actions) => 
  {
      let that = this
      let checkoutAmount = this.computeCheckout()

      
      if( Number(checkoutAmount)<=0 )
      {

        that.setState({checkoutAmountError:true})
        return;
      }else{
         that.setState({checkoutAmountError:false});

          return actions.order.create(
          {
            purchase_units: [
              {
                description:'Freelancertogo Funding: $'+Number( checkoutAmount ).toLocaleString(),
                amount: {
                  currency_code: "USD",
                  value: checkoutAmount
                }
              }
            ]
          });
      }

     
  };

   onApprove =  (data, actions) =>
   {
       let that = this
       this.setState({processing: true})

       actions.order.capture().then(details => {
        
          const paymentData = {
            payerID: data.payerID,
            orderID: data.orderID
         };

        let user = this.props.user.uid
        let job = this.props.job

        this.props.addDoc(this.props.collection(this.props.db, "transactions"),{
          payment_platform: that.props.payment_platform,
          user: user,
          last_transaction_id: this.props.last_transaction_id,
          last_balance: this.props.last_balance, 
          job: job.id, 
          jobdetails:job, 
          type: 'FUNDING_ADD',
          payerID: data.payerID, 
          orderID: data.orderID,
          amount : Number(this.state.fundAmount), 
          fee:  Number(this.state.fundAmount) * Number(this.state.fee),
          created_at : new Date()
        });

      this.setState({ processing:false, showButtons: false, paid: true });

    });

   };

  

 computeCheckout(){

    let base = Number(this.state.fundAmount) * this.state.fee
    base = base + Number(this.state.fundAmount)
    return Number( Number( this.computePaypalfee(this.state.fundAmount) + base ).toFixed(2) )
 }
 
 computePaypalfee( amount ){
  
   let fixed = amount > 0 ? 0.49 : 0
   let newAmount = Number(this.state.fundAmount)*Number(this.state.fee)
   newAmount = Number(newAmount)+Number(this.state.fundAmount)
   newAmount = newAmount*this.state.paypal_percentage
   
   if(newAmount>0)
    newAmount = newAmount+0.49

   return newAmount

 }

 computeFreelancertogofee( amount ){
    return Number( Number(amount * this.state.fee).toFixed(2) );
 }

 render() {
    let that = this
    const { showButtons, loading, paid, processing } = this.state;
   
    return (
      <div className="main">
        {loading && <center><CircularProgress style={{color:"#00b16a"}} /></center>}
        {processing && <center>
                          <CircularProgress style={{color:"#00b16a"}} />
                          <Typography>Please wait while we are processing your order.</Typography>
                          <br/><br/>
                       </center>}
        {showButtons && (
          <div>
            
            <Typography variant="subtitle2">Funded amount are not refundable. Please double check the amount before proceeding.</Typography>
            <br/><br/>

            <TextField fullWidth helperText={"Fund amount must be greater than $"+this.state.min_funding+" and less than $"+this.state.max_funding+". Limit will be altered in the future."} error={this.state.checkoutAmountError} label="Enter Amount" onKeyPress={(event)=>{ if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        } }} onChange={ (e)=>
            {

              if(Number(e.target.value)>Number(that.state.min_funding) && Number(e.target.value)<=Number(that.state.max_funding) )
                this.setState({showpaypalFunding:1})
              else
                this.setState({showpaypalFunding:0})



              this.setState({fundAmount:e.target.value } )
            }
          } />

            <br/><br/>

             <Typography align='center' variant='h3'>${ Number( this.computeCheckout()  ).toLocaleString(undefined, {minimumFractionDigits: 2}) }</Typography>
             <Typography align='center' variant='subtitle1'>Total Checkout Amount</Typography>
             <br/>


             <Typography align='center' variant='subtitle2'>Fund amount: ${ Number(this.state.fundAmount).toFixed(2) }</Typography>
             <Typography align='center' variant='subtitle2'>Transaction Fee : ${ Number(  this.computeCheckout() - Number(this.state.fundAmount)   ).toLocaleString( undefined, {minimumFractionDigits:2,maximumFractionDigits:2} ) }
                <Tooltip title={ <div style={{whiteSpace:'pre-line'}}>Fee breakdown: <br/> Paypal fee: ${ Number( this.computePaypalfee(this.state.fundAmount) ).toFixed(2) } <br/> Freelancertogo Fee: ${ Number(this.state.fundAmount * this.state.fee) }  </div> } >
                    <HelpIcon style={{marginLeft:'2px',fontSize:'0.875rem'}} />
                </Tooltip>
             </Typography>

        
            <br/><br/>
            { this.state.showpaypalFunding == 1 &&
          

            <PayPalScriptProvider options={paypalOptions}>
                <PayPalButtons
                  style={{ layout: 'horizontal' }} // Adjust the button layout as needed
                  createOrder={ this.createOrder }
                  onApprove={ this.onApprove }
                  onClose={ ()=>{} }
                   />
              </PayPalScriptProvider>
            }
          </div>
        )}

        {paid && (
          <div className="main">
            <center>
              <p>
                <img alt="verified" src="/verified.png" /> <br/>
                Success! Fund is added to your job post.     
              </p>
            </center>
          </div>
        )}
      </div>
    );
  }

 }

 //export default scriptLoader(`https://www.paypal.com/sdk/js?disable-funding=credit&client-id=${CLIENT_ID}`)(PaypalFunding);

 export default PaypalFunding;