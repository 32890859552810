import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';	
import { connect } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import ErrorIcon from '@mui/icons-material/Error'
import CheckIcon from '@mui/icons-material/Check'
import _ from 'lodash'
import Alert from '@mui/lab/Alert';
import Box from '@mui/material/Box';
import PaypalFunding from '../../constants/paypalFunding';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';

registerPlugin(FilePondPluginImagePreview);


const styless = {
	bigAvatar:{
		margin: 10,
		width: 150,
		height: 150,
		alignSelf:'center',
		cursor: 'pointer'
	},
	title: {
		color: '#333333',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 24
	},
	subtitle: {
		color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 18
	},
	center:{
		textAlign:'center',
		color: '#3c4859'
	},

	small: {
	   	color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10
	   },

	   em: {
	   	 color: '#8e8e8e',
	   	 fontFamily: 'Gotham Rounded'
	   },

	   buttonPrimary: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded',
	   	backgroundColor: '#00b16a'
	   },

	   buttonDefault: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded'
	   }

}

const styles = theme => ({
   snackbarClose: {

   },
   chip: {
   		margin: theme.spacing.unit
   },
   root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
   },

   
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class Transactions extends Component{
	
	constructor(props){
		
		super(props);

		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		user: [],
    		snackbar: false,
    		snackbarMessage: '',
    		isSaving: false,
    		transactions: [],
    		timePeriod: 1,
    		init: 0
		};
	
	}	

	 handleChange = (event, value) => {
	    this.setState({ submenu: value });
	  };

	componentWillMount() {

		this.getTransactions(this.state.timePeriod)
		
	}

	async getTransactions(timePeriod){

		let query
		let start
		let end
		let transactions = []
		let that = this

		this.setState({transactions: []})

		
		if(timePeriod===1)
		{
			start = new Date();
			start.setDate(start.getDate() - 7);
			end = new Date();

		}else if(timePeriod==2)
		{
			start = new Date();
			start.setDate(start.getDate() - 15);
			end = new Date();
		}else if(timePeriod==3)
		{
			start = new Date();
			start.setDate(start.getDate() - 30);
			end = new Date();
		}

		const transactionRef = this.props.collection(this.props.db, "transactions");
	
		if(this.props.user.preference==='jobs')
			query = this.props.query(transactionRef, this.props.where("freelancer_id", "==",  this.props.user.uid), this.props.where('created_at','>=',start), this.props.where('created_at','<=',end), this.props.orderBy('created_at','desc'), this.props.limit(20) );
		else
			query = this.props.query(transactionRef, this.props.where("user", "==",  this.props.user.uid), this.props.where('created_at','>=',start), this.props.where('created_at','<=',end), this.props.orderBy('created_at','desc'), this.props.limit(20) );

		const querySnapshot = await this.props.getDocs(query);

		querySnapshot.forEach((doc) => {
		  
		  	let data = doc.data();
		  	data.id = doc.id

		  	transactions.push(data)
		  	
		})

		that.setState({timePeriod:timePeriod, isLoaded: true, transactions:transactions})
	}

	

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	
	render(){
		let transactionsList
		let that = this

		if(this.state.transactions.length > 0){
		
			transactionsList = this.state.transactions.map( function(item,i){
		
				return ( 
						<div>
						   { item.type==="FUNDING_TRANSFER" && that.props.user.preference === "freelancers" &&
						   	<ListItem>
								<ListItemText
									primary={ "$"+ Number(item.amount).toLocaleString({undefined, minimumFractionDigits: 2}) + " Fund Release to "+ item.freelancer.fname + " " + item.freelancer.lname + " for the job '" + item.job.jobPostTitle +"'" }
									secondary={ item.created_at.toDate().toDateString() }
								></ListItemText>
							</ListItem>
						   }

						   { item.type==="FUNDING_ADD" && item.amount > 0 && that.props.user.preference === "freelancers" && 
						   	<ListItem>
								<ListItemText
									primary={ "$"+ Number(item.amount).toLocaleString({undefined, minimumFractionDigits: 2}) + " Fund Added to '" + item.jobdetails.jobPostTitle + "'" }
									secondary={ item.created_at.toDate().toDateString() }
								></ListItemText>
							</ListItem>
						   }

						   { item.type==="FUNDING_TRANSFER" && that.props.user.preference === "jobs" && Number(item.amount) < 0 &&
						   	<ListItem>
								<ListItemText
									primary={ "We paid $"+ Number(Math.abs(item.amount) ).toLocaleString({undefined, minimumFractionDigits: 2}) + " through your selected payment method" }
									secondary={ item.created_at.toDate().toDateString() }
								></ListItemText>
							</ListItem>
						   }

						   { item.type==="FUNDING_TRANSFER" && that.props.user.preference === "jobs" && Number(item.amount) > 0 &&
						   	<ListItem>
								<ListItemText
									primary={ "You received $"+ Number(Math.abs(item.amount) ).toLocaleString({undefined, minimumFractionDigits: 2}) + " from your job '"+ item.job.jobPostTitle + "'" }
									secondary={ item.created_at.toDate().toDateString() }
								></ListItemText>
							</ListItem>
						   }

						</div>
						
					)
			} )
		}
		
		
		return (

			<div>
			
			<header>
			  { this.state.isLoaded === true && 
	          <div style={{marginTop: 100}}>
		          
		          <Grid container spacing={2} >
		          		<Grid item  xs={0} md={2}>
		          		</Grid >
		          		<Grid item  xs={12} md={8}>

		          		 <h1 className="App-title">Transactions</h1>
		          		 
		          		 <br/>
		          		 <FormControl fullWidth>
							  <InputLabel>Time Period</InputLabel>
							  <Select
							    value={this.state.timePeriod}
							    label="Time Period"
							    onChange={(e)=>{ this.getTransactions(e.target.value) } }
							  >
							    <MenuItem value={1}>Last 7 Days</MenuItem>
							    <MenuItem value={2}>Last 15 Days</MenuItem>
							    <MenuItem value={3}>Last 30 Days</MenuItem>
							  </Select>
						</FormControl>
		          		 <br/><br/>

				         <div>
		          		 	<List>
		          		 		{ this.state.transactions.length > 0 && transactionsList}
		          		 		{ this.state.transactions.length === 0 && 
		          		 			<Typography align='center'>No transaction found on the selected time period.</Typography>
		          		 		}
		          		 	</List>
		          		 </div>

		          		</Grid >
		          		<Grid item  xs={0} md={2}></Grid >
		          	</Grid >
		      </div>
		      }

		      { this.state.isLoaded === false &&
                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                        <CircularProgress style={{color:'#212121', alignSelf:'center'}} />
                    </div>
              }
	        </header>

	        
	        <Snackbar
		          anchorOrigin={{
		            vertical: 'bottom',
		            horizontal: 'center',
		          }}
          		open={this.state.snackbar}
          		autoHideDuration={3000}
          		onClose={this.snackbarClose}
          		ContentProps={{
            		'aria-describedby': 'message-id',
          		}}
          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
		          action={[
		            <IconButton
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              onClick={this.snackbarClose}
		            >
		              <CloseIcon />
		            </IconButton>,
		          ]} />
	    </div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		collection: state.collection,
		db: state.db,
		where: state.where,
		orderBy: state.orderBy,
		limit: state.limit,
		query: state.query,
		getDocs: state.getDocs
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);





