import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux'
import ChatIcon from '@mui/icons-material/Chat';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import Divider from '@mui/material/Divider';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import InputLabel from '@mui/material/InputLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import OutlinedInput from '@mui/material/OutlinedInput';
import _ from 'lodash'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Alert from '@mui/lab/Alert';
import Grid from '@mui/material/Grid';

registerPlugin(FilePondPluginImagePreview);

const styles = {
    bigAvatar:{
        margin: 10,
        width: 150,
        height: 150,
        alignSelf:'center',
        cursor: 'pointer'
    },
    title: {
        color: '#333333',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10,
        fontSize: 24
    },
    subtitle: {
        color: '#8e8e8e',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10,
        fontSize: 18
    },
    center:{
        textAlign:'center',
        color: '#3c4859'
    },

    small: {
        color: '#8e8e8e',
        fontFamily: 'Gotham Rounded',
        marginBottom: 10
       },

       em: {
         color: '#8e8e8e',
         fontFamily: 'Gotham Rounded'
       },

       buttonPrimary: {
        padding: 10,
        fontFamily: 'Gotham Rounded',
        backgroundColor: '#00b16a'
       },

       buttonDefault: {
        padding: 10,
        fontFamily: 'Gotham Rounded'
       }

}

class ReportProblem extends Component{
    
    constructor(props){
        
        super(props);

        this.state = {
            fullWidth: true,
            maxWidth: 'sm',
            db: [],
            snackbar: false,
            snackbarMessage: '',
            browser: 'firefox',
            url: '',
            urlerror: false,
            description: '',
            descriptionError: false
        };
    
    }   

    async getAdmin(){
      this.props.setLoading(true)
      let admin
      let that = this
      admin = await this.props.firebase.firestore().collection('users').where("preference","==","admin").get()
      
      admin.docs.map( doc => {

        let user = doc.data();
        let message = "Problem Location: "+that.state.url
        message += "<br/><br/>"
        message += "Browser: "+that.state.browser
        message += "<br/><br/>"
        message += "Detailed Description: "+that.state.description
        
        fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
        {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({ title: "Submit a Problem", name: user.fname+" "+user.lname, to: user.email, message: message })
        })
        .then(function(res){ return res.json() })
        .then(function(data){
          that.props.setLoading(false)
          that.setState({ url: '',description: '', snackbar: true, snackbarMessage: "Thank you! We received your concern and our team will fix the issue as soon as possible."})
        })
        .catch(function(res){ 
          console.log('error',res)
        })

        return true

      } )

    }

     submitProblem = (event, value) => {

        let error = 0
        if(this.state.description===""){
          this.setState({descriptionError:true})
          error++;
        }else{
          this.setState({descriptionError:false})
        }

        if(this.state.url===""){
          this.setState({urlerror:true})
          error++;
        }else{
          this.setState({urlerror:false})
        }

        if(error>0){
          this.setState({snackbar:true, snackbarMessage:'Please correct error then submit again.'})
        }
        else
        {
          this.getAdmin()
        }
      };

    componentDidMount() {

        this.setState({isLoaded:true})
        
    }

    snackbarClose = () => {
    this.setState({snackbar: false})
  }
    
  
    render(){
    
        let that = this

       return (

            <div>
            
            <header>
              { this.state.isLoaded === true &&
                  <div style={{marginTop: 100}}>
                      
                      <Grid>
                        <Grid container >
                            
                            <Grid item  xs={2} md={2}></Grid  >
                            <Grid item  xs={8} md={8}>

                             <h1 className="App-title">Report a Problem</h1>
                             
                             <br/>
                             
                            </Grid  >
                            <Grid item  xs={2} md={2}></Grid  >
                            
                        </Grid  >

                        <Grid container >
                            <Grid item  xs={2} md={2}></Grid  >
                            <Grid item  xs={8} md={8}>
                                <Typography>Please use this form to report a problem you are experiencing with freelancertogo. Please give us as much information on the problem you experience so we can easily reproduce and fix the issue.</Typography> <br/>
                                <br/>

                                <Typography>Which page did you experience the issue? You can tell us the name of the page or enter the website exact URL</Typography>
                                <TextField
                                      error={this.state.urlerror}
                                      name='description'
                                      variant='outlined'
                                      multiline
                                      row="10"
                                      size="medium"
                                    margin="normal" value={this.state.url} fullWidth={true} onChange={ (event) => this.setState({url: event.target.value }) } />
                                 <br/><br/>
                                <Typography>What browser did you used when you experience the issue?</Typography>
                                <br/>
                                <Select
                                    onChange={ (event) => this.setState({preference: event.target.value }) }
                                    value={this.state.browser}
                                      inputProps={{
                                      name: 'browser',
                                      }} fullWidth={true} >

                                      <MenuItem value="firefox">
                                        <em>Firefox</em>
                                      </MenuItem>
                                      <MenuItem value="chrome">
                                        <em>Google Chrome</em>
                                      </MenuItem>
                                      <MenuItem value="safari">
                                        <em>Safari</em>
                                      </MenuItem>
                                      <MenuItem value="edge">
                                        <em>Edge</em>
                                      </MenuItem>

                                </Select>

                                 <br/><br/>

                                <Typography>Detailed Description</Typography>
                               
                                <TextField
                                  error={this.state.descriptionError}
                                      name='description'
                                      variant='outlined'
                                      multiline
                                      row="10"
                                      size="medium"
                                    margin="normal" value={this.state.description} fullWidth={true} onChange={ (event) => this.setState({description: event.target.value }) } />

                                <br/><br/>
                                <Button style={styles.buttonPrimary} onClick={ ()=>this.submitProblem() } type='submit' variant='contained' color='primary' fullWidth={true}>Submit</Button>
                            
                            </Grid  >
                            <Grid item  xs={2} md={2}></Grid  >
                        </Grid  >
                      </Grid>
                  </div>
              }

              { this.state.isLoaded === false &&
                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                        <CircularProgress style={{color:'#212121', alignSelf:'center'}} />
                    </div>
              }
            </header>

           
            <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                open={this.state.snackbar}
                autoHideDuration={5000}
                onClose={this.snackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{ this.state.snackbarMessage }</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={this.snackbarClose}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]} />
        </div>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        firebase: state.firebase,
        user: state.user,
        isLoggedIn: state.isLoggedIn
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportProblem);





