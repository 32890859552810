import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import AppBar from '@mui/material/AppBar'
import SwipeableDrawer  from '@mui/material/SwipeableDrawer'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import MessageIcon from '@mui/icons-material/Message';
import WorkIcon from '@mui/icons-material/Work';
import SearchIcon from '@mui/icons-material/Search';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import _ from 'lodash'
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux'
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import ls from 'local-storage'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import moment from 'moment'
import FaceIcon from '@mui/icons-material/Face';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AttachMoneyIcon from '@mui/icons-material/MonetizationOn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BookIcon from '@mui/icons-material/Book';

const drawerWidth = 240

const styles = {
    root: {
        flexGrow: 1
    },
    toolbar: {
        backgroundColor: 'red'
    },
    tab: {
        backgroundColor: '#fff'
    },
    menu:{
        color: '#8e8e8e',
        fontFamily: 'Gotham Rounded'
    },
    brand:{
        color: '#00b16a',
        fontFamily: 'Gotham Rounded',
        fontSize: 18,
        fontWeight: 'bold',
        marginLeft: 36,
        marginTop: 5
    }

}

class Navigation extends Component {



    constructor(props){

        super(props);

        this.state = {
            preference: null,
            snackbar: false,
            snackbarMessage: '',
            isDrawer:false,
            notificationMenu: false,
            notification_start: 0,
            notification_limit:5,
            notifications: [],
            checkNotification:false
        }

    }

    doSignOut = () => {
        let that = this
        this.props.setLoading(true);

        //localStorage.removeItem('jobs')
        localStorage.clear()

        this.props.signOut(this.props.auth).then(() => {
            
            that.props.logout(null);
             window.location = '/'

        }).catch((error) => {
          // An error happened.
        });

     
    }

    async getChatRooms(){
        
        const that = this

        let query
        let newmessagescount = 0
        let unread
        
        if(this.props.user.preference==="freelancers")
            query = this.props.firebase.firestore().collection('rooms').where( "from","==", that.props.user.uid ).get()
        else
            query = this.props.firebase.firestore().collection('rooms').where( "to","==", that.props.user.uid ).get()

        query.docs.map ( async function(item,i){

                let user
                let id = item.id
                let room = item.data()
                room.id = id
                let history = room.history
                let iunread = _.filter(history, function(o){
                    return o.read===false && o.user!==that.props.user.uid
                })

                room.unread = iunread
                newmessagescount += unread.length
                that.props.setMessageCount(newmessagescount)
        })

    }

    changeIcon(icon){

        if(icon==='face')
            return <FaceIcon />
        else if(icon==='security')
            return <VerifiedUserIcon />
        else if(icon==='work')
            return <WorkIcon />
        else
            return <HelpIcon />
    }
    componentDidMount(){
        //this.getChatRooms()

        let that = this
        setTimeout(function(){   that.setState({checkNotification:true}) }, 3000);
        
    }

    handleNotification(item){
        
        let that = this
        this.setState({notificationMenu:false})
        this.props.setLoading(true);

        if(item.unread===true)
        {
             this.props.firebase.firestore().collection('notifications').doc(item.id).update({"unread":false,"read_at": new Date()})
             .then( () => {
                this.props.setLoading(false);
                
             })

        }

        this.props.setLoading(false);
 
     
    }



    render(){

        if (typeof window !== 'undefined')
        {
             let that = this

             const drawer = (
                <div style={{width:'200px'}} onClick={()=>this.setState({isDrawer:false})}>
                    <List>

                        { this.props.maintenance === false && this.props.isLoggedIn === false &&
                                                      
                            <div>

                                <Link to={ROUTES.LANDING}>
                                <ListItem button>
                                        <ListItemIcon>
                                           <Avatar alt="logo" src="/cropped.png" style={{position:'initial'}} />
                                        </ListItemIcon>
                                    </ListItem>

                                </Link>

                                 <Divider />


                                <Link to={ROUTES.SIGN_IN}>
                                    <ListItem button>
                                        <ListItemText primary="Sign In" />
                                    </ListItem>
                                </Link>
                                <Link to={ROUTES.SIGN_UP}>
                                    <ListItem button>
                                        <ListItemText primary="Sign Up" />
                                    </ListItem>
                                </Link>
                            </div>
                            
                        }
                        { this.props.maintenance === false && this.props.isLoggedIn === true &&
                            <div>

                            <div style={{flex:1}}>
                                <Link to={ROUTES.LANDING}>
                                <ListItem button>
                                        <ListItemIcon>
                                            <Avatar alt="logo" src="/cropped.png" style={{position:'initial'}} />
                                        </ListItemIcon>
                                    </ListItem>

                                </Link>
                            </div>

                            <Divider />


                            {
                                this.props.user.preference!=="admin" &&
                                <Link to={ROUTES.PROFILE}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Avatar style={{ backgroundColor: '#00b16a', color:'#fff'}} alt={ this.props.user.fname+" "+this.props.user.lname } src={ !_.isEmpty( this.props.user.profile ) ?this.props.user.profile.avatar : '' } > { this.props.user.fname.charAt(0).toUpperCase()+""+this.props.user.lname.charAt(0).toUpperCase() } </Avatar>      
                                        </ListItemIcon>
                                        <ListItemText primary="Profile" />
                                    </ListItem>
                                </Link>
                            }

                            {
                                this.props.user.preference!=="admin" &&
                                <Link to={ROUTES.MESSAGE}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <MessageIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Messages" />
                                    </ListItem>
                                </Link>
                            }

                            {
                                this.props.user.preference==="jobs" &&
                                <Link to={ROUTES.FINDWORK}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <SearchIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Find Work" />
                                    </ListItem>
                                </Link>
                            }

                            {
                                this.props.user.preference==="jobs" &&
                                <Link to={ROUTES.MYAPPLICATIONS}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <FindInPageIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Applications" />
                                    </ListItem>
                                </Link>
                            }


                            {
                                this.props.user.preference==="freelancers" &&
                                <Link to={ROUTES.JOBS}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <WorkIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Jobs" />
                                    </ListItem>
                                </Link>
                            }


                            {
                                this.props.user.preference==="freelancers" &&
                                <Link to={ROUTES.FINDFREELANCER}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <SearchIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Find Freelancer" />
                                    </ListItem>
                                </Link>
                            }


                            {
                                this.props.user.preference==="admin" &&
                                <Link to={ROUTES.USERS_LIST}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <PeopleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Users List" />
                                    </ListItem>
                                </Link>
                            }

                            {
                                this.props.user.preference==="admin" &&
                                <Link to={ROUTES.JOBS_LIST}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <WorkIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Jobs List" />
                                    </ListItem>
                                </Link>
                            }

                            {
                                this.props.user.preference==="admin" &&
                                <Link to={ROUTES.PAYOUT_LIST}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <AttachMoneyIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Payouts" />
                                    </ListItem>
                                </Link>
                            }

                            {
                                this.props.user.preference!=="admin" &&
                                <Link to={ROUTES.TRANSACTIONS}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <ReceiptIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Transactions" />
                                    </ListItem>
                                </Link>
                            }


                            <a target="_blank" href="https://knowledgebase.freelancertogo.com">
                                    <ListItem button>
                                        <ListItemIcon>
                                            <HelpIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="How It Works" />
                                    </ListItem>
                            </a>


                            <Link to={ROUTES.BLOGS}><Tooltip title="Blogs"><IconButton style={styles.menu}><BookIcon /></IconButton></Tooltip></Link>
                              

                        
                            <ListItem button onClick={()=>this.doSignOut()}>
                                <ListItemIcon>
                                    <PowerSettingsNewRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItem>
                    
                            


                            </div>
                        }
                    </List>
                </div>
                )

            return (

                <div className={styles.root}>
                    { this.props.isLoading === true && <LinearProgress sx={{ backgroundColor: 'white', '& .MuiLinearProgress-bar': { backgroundColor: '#00b16a' } }} style={{position: 'fixed', width:'100%', top: 0, left: 0, color:'#212121'}}/> }
                    
                    <AppBar id="mobilenav" position="fixed" color="inherit" style={{ background: 'rgba(255,255,255,1)', boxShadow: '0 1px 1px rgba(0,0,0,0.05)' , top: this.props.isLoading === true ? 10 : 0 }}>
                        <Toolbar onClose={ ()=>this.setState({isDrawer:false}) }>
                            <div style={{flex:1}}>
                                <Link to={ROUTES.LANDING}>
                                <img alt="logo" src="/cropped.png" className="logo" style={{position:'initial'}} />
                                </Link>
                            </div> 
                            <div style={{flex: 1}}></div>
                           
                                <Tooltip title="Account">
                                    <IconButton style={styles.menu} onClick={()=>this.setState({isDrawer:true})}>
                                          <MenuIcon />
                                    </IconButton>
                                </Tooltip>
                                
                           
                        </Toolbar>
                    </AppBar>

                    <AppBar id="webnav" position="fixed" color="inherit" style={{ background: 'rgba(255,255,255,1)', boxShadow: '0 1px 1px rgba(0,0,0,0.05)' , top: this.props.isLoading === true ? 10 : 0 }}>
                        <Toolbar>
                            <div style={{flex: 1}}>
                                <Link to={ROUTES.LANDING}>
                                <img alt="logo" src="/cropped.png" className="logo" />
                                <Typography style={styles.brand}><span style={{color: '#666666',fontSize:'18px'}}>freelancerto</span>go</Typography></Link>
                            </div> 
                            <div style={{flex: 1}}></div>
                            { this.props.maintenance !== true && this.props.isLoggedIn === false &&
                                                      
                                <div>
                                    <Link to={ROUTES.SIGN_IN}><Button style={styles.menu}>Login</Button></Link>
                                    <Link to={ROUTES.SIGN_UP}><Button style={styles.menu}>Signup</Button></Link>
                                </div>
                                
                            }

                            {  this.props.isLoggedIn === true &&
                                <div>

                                { this.props.maintenance !== true && this.props.user.preference!=="admin" &&
                                    <Link to={ROUTES.PROFILE}>
                                        <Tooltip title="Account">
                                            <IconButton style={styles.menu}>
                                                <Avatar style={{ backgroundColor: '#00b16a', color:'#fff'}} alt={ this.props.user.fname+" "+this.props.user.lname } src={ !_.isEmpty( this.props.user.profile ) ?this.props.user.profile.avatar : '' } > { this.props.user.fname.charAt(0).toUpperCase()+""+this.props.user.lname.charAt(0).toUpperCase() } </Avatar>      
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                }

                                { this.props.maintenance !== true && this.props.user.preference!=="admin" &&
                                    <Link to={ROUTES.MESSAGE}>
                                        <Tooltip title="Message">
                                            <IconButton style={styles.menu}>
                                                <Badge badgeContent={this.props.newMessageCount} color="primary">
                                                    <MessageIcon />
                                                </Badge>
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                }


                                { this.props.maintenance !== true && this.props.user.preference==="freelancers" &&
                                    <Link to={ROUTES.JOBS}><Tooltip title="Jobs"><IconButton style={styles.menu}><WorkIcon /></IconButton></Tooltip></Link>
                                }

                                { this.props.maintenance !== true && this.props.user.preference==="jobs" &&
                                    <Link to={ROUTES.FINDWORK}><Tooltip title="Find Work"><IconButton style={styles.menu}><SearchIcon /></IconButton></Tooltip></Link>
                                }

                                { this.props.maintenance !== true && this.props.user.preference==="freelancers" &&
                                    <Link to={ROUTES.FINDFREELANCER}><Tooltip title="Find Freelancers"><IconButton style={styles.menu}><SearchIcon /></IconButton></Tooltip></Link>
                                }

                                { this.props.maintenance !== true && this.props.user.preference==="jobs" &&
                                    <Link to={ROUTES.MYAPPLICATIONS}><Tooltip title="My Applications"><IconButton style={styles.menu}><FindInPageIcon /></IconButton></Tooltip></Link>
                                }

                                { /*this.props.user.preference=="jobs" &&
                                    <Link to={ROUTES.MYJOBS}><Tooltip title="My Jobs"><IconButton style={styles.menu}><WorkIcon /></IconButton></Tooltip></Link>*/
                                }

                                { this.props.user.preference==="admin" &&
                                    <Link to={ROUTES.USERS_LIST}><Tooltip title="Users"><IconButton style={styles.menu}><PeopleIcon /></IconButton></Tooltip></Link>
                                }

                                { this.props.user.preference==="admin" &&
                                    <Link to={ROUTES.JOBS_LIST}><Tooltip title="Jobs"><IconButton style={styles.menu}><WorkIcon /></IconButton></Tooltip></Link>
                                }  

                                { this.props.user.preference==="admin" &&
                                    <Link to={ROUTES.PAYOUT_LIST}><Tooltip title="Payout"><IconButton style={styles.menu}><AttachMoneyIcon /></IconButton></Tooltip></Link>
                                }

                                { this.props.user.preference==="admin" &&
                                    <Link to={ROUTES.ADMIN_SETTINGS}><Tooltip title="Utility"><IconButton style={styles.menu}><SettingsIcon /></IconButton></Tooltip></Link>
                                } 

                                 { this.props.user.preference==="admin" &&
                                    <Link to={ROUTES.BLOG_ADMIN}><Tooltip title="Blogs Admin"><IconButton style={styles.menu}><BookIcon /></IconButton></Tooltip></Link>
                                }   


                               {/* <Tooltip title="Notifications"><IconButton onClick={ () => 
                                    
                                        {
                                    
                                            this.setState({notificationMenu: this.state.notificationMenu === false ? true : false })
                                        }
                                     } style={styles.menu}>
                                    { _.has(this.props.notifications,'length') && this.state.checkNotification === true && this.props.notifications.length > 0 &&
                                        <Badge badgeContent={ _.filter(this.props.notifications, function(o){
                                                return o.unread === true
                                            }).length } color="primary"><NotificationsNoneIcon />
                                        </Badge>
                                    }

                                    { (this.state.checkNotification === false || (this.state.checkNotification === true && this.props.notifications.length ===0)) &&
                                        <NotificationsNoneIcon />
                                    }

                                    </IconButton></Tooltip>

                                { this.state.notificationMenu === true &&
                                    <ClickAwayListener onClickAway={()=>{ this.setState({notificationMenu:false}) }}>
                                    <div id="notification-panel">
                                        <List>

                                            { 
                                                this.props.notifications.map(function(item,i){

                                                    return (
                                                           <Link to={item.link}>
                                                                <ListItem selected={item.unread===true ? true : false } button style={{cursor:'pointer'}} onClick={()=>{ that.handleNotification(item) }}>
                                                                    <ListItemIcon>
                                                                       { that.changeIcon(item.icon) }
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={item.message}
                                                                        secondary= { _.has( item,'created_at' )  ?  moment( new Date( item.created_at.seconds * 1000).toUTCString() ).fromNow() : '' }
                                                                      />
                                                                </ListItem>
                                                           </Link>
                                                            
                                                        )
                                                })
                                            }
                                            
                                            
                                        </List>
                                    </div> 
                                    </ClickAwayListener>
                                }*/}

                                { this.props.maintenance !== true && this.props.user.preference!=="admin" &&
                                    <Link to={ROUTES.TRANSACTIONS}><Tooltip title="Transactions"><IconButton style={styles.menu}><ReceiptIcon /></IconButton></Tooltip></Link>
                                }
                                { this.props.maintenance !== true &&
                                     <a target="_blank" href="https://knowledgebase.freelancertogo.com"><Tooltip title="How it Works"><IconButton style={styles.menu}><HelpIcon /></IconButton></Tooltip></a>
                                }

                                <Link to={ROUTES.BLOGS}><Tooltip title="Blogs"><IconButton style={styles.menu}><BookIcon /></IconButton></Tooltip></Link>
                              
                              
                                <Tooltip title="Logout"><IconButton onClick={ () => this.doSignOut() } style={styles.menu}><PowerSettingsNewRoundedIcon /></IconButton></Tooltip>
                              

                                </div>
                                
                            }
                        </Toolbar>

                    
                    </AppBar>


                    <Snackbar
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    open={this.state.snackbar}
                    autoHideDuration={3000}
                    onClose={ () => this.setState({snackbar:false}) }
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{ this.state.snackbarMessage }</span>}
                      action={[
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={ () => this.setState({snackbar:false}) }
                        >
                          <CloseIcon />
                        </IconButton>,
                      ]} />


                      { this.state.isDrawer === true &&
                          <SwipeableDrawer variant="temporary"  anchor="left" open={true} onOpen={()=>{ this.setState({isDrawer:true}) }}  onClose={ ()=>{this.setState({isDrawer:false})} } >
                            { drawer }
                          </SwipeableDrawer >
                       }
                    
                    
                    
                </div>
                

                
            )

        }else{
            return (<div></div>)
        }
       
    }
}

const mapStateToProps = (state) => { 
    return {
        firebase: state.firebase,
        isLoading: state.isLoading,
        user: state.user,
        isLoggedIn: state.isLoggedIn,
        newMessageCount: state.newMessageCount,
        notifications: state.notifications,
        maintenance: state.maintenance,
        signOut: state.signOut,
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (firebase) => dispatch({type: "SET_LOGOUT", payload: {firebase:firebase}}),
        setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
        setMessageCount: (newMessageCount) => dispatch({type: "SET_MESSAGE_COUNT", payload: {newMessageCount:newMessageCount}})
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Navigation);  

