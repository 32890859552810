import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';	
import { connect } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import * as MESSAGES from '../../constants/messages';
import * as FIREBASECONFIG from '../../constants/firebase';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import ErrorIcon from '@mui/icons-material/Error'
import CheckIcon from '@mui/icons-material/Check'
import _ from 'lodash'
import Alert from '@mui/lab/Alert';
import Box from '@mui/material/Box';
import PaypalFunding from '../../constants/paypalFunding';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
//import SwipeableViews from 'react-swipeable-views';
import jobCategories from '../../constants/jobCategories.json'
import InputLabel from '@mui/material/InputLabel';
//import { withStyles } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

registerPlugin(FilePondPluginImagePreview);

let htmlToDraft, htmlToDraft2, htmlToDraft3;
let draftToHtml, draftToHtml2, draftToHtml3;
let EditorState, EditorState2, EditorState3;
let convertToRaw, convertToRaw2, convertToRaw3;
let ContentState, ContentState2, ContentState3;

const styless = {
	bigAvatar:{
		margin: 10,
		width: 150,
		height: 150,
		alignSelf:'center',
		cursor: 'pointer'
	},
	title: {
		color: '#333333',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 24
	},
	subtitle: {
		color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 18
	},
	center:{
		textAlign:'center',
		color: '#3c4859'
	},

	small: {
	   	color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10
	   },

	   em: {
	   	 color: '#8e8e8e',
	   	 fontFamily: 'Gotham Rounded'
	   },

	   buttonPrimary: {
	   	padding: 10,
	   	fontFamily: 'Gotham Rounded',
	   	backgroundColor: '#00b16a'
	   },

	   buttonDefault: {
	   	padding: 12,
	   	fontFamily: 'Gotham Rounded',
	   	borderColor: '#00b16a',
	   	color: '#00b16a',
	   },

}

const styles = theme => ({
   snackbarClose: {

   },
   chip: {
   		margin: theme.spacing.unit
   },
   root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
   },

   
})



let jobRender = 0
let rlimit = 10
let lastjobscount = 0
let newjobscount = 0

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class Jobs extends Component{
	
	constructor(props){
		
		super(props);

		this.state = {
			payment_platform: '',
			last_transaction_id: '',
			last_balance: 0,
			balanceDisplay: 0,
			itemFundBalance: 0,
			fundingItem: [],
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		user: [],
    		snackbar: false,
    		snackbarMessage: '',
    		isSaving: false,
			jobs: [],
			addDialog: false,
			jobPostTitle:'',
			jobPostTitleError: false,
			jobDescription: '',
			jobDescriptionError: false,
			jobCategory: '',
			jobCategoryError: false,
			jobType: 'Project Based',
			jobTypeError: false,
			estProjectDuration:'',
			estProjectDurationError: false,
			budget:  0,
			budgetError: false,
			hoursPerWeek: 0,
			hoursPerWeekError: false,
			howToApply: '',
			howToApplyError: false,
			notes: '',
			notesError: false,
			confirmDeleteJobDialog:false,
			deleteId: '',
			isEdit: false,
			jobStatus: "PENDING",
			isLoaded:false,
			jobCategories: [],
			expertise: '',
			expertiseError:false,
			closeJobReason: '',
			newJobID: '',
			jpc: 0,
			fundpanel:0,
			jobTitleDisable: false,
			selectJobStatus: "ACTIVE",
			RefreshJobDialog:false,
			reactivateId: '',
			allowgindex:false,
			showmore: true,
			editorState: null,
			editorState2: null,
			editorState3: null

		};
	
	}	

	 handleChange = (event, value) => {

	    this.setState({ submenu: value });
	  };

	componentWillMount() {

		if (typeof window !== 'undefined')
		{
			
			import('react-draft-wysiwyg').then(module => {
		      this.setState({ Editor: module.Editor, Editor2: module.Editor, Editor3: module.Editor });
		    }).catch(error => {
		      console.error('Error loading react-draft-wysiwyg:', error);
		    });

		    import('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');

		    import('html-to-draftjs').then(module => {
		        htmlToDraft = module.default;
		        htmlToDraft2 = module.default;
		        htmlToDraft3 = module.default;
		    }).catch(error => {
		        console.error('Error importing html-to-draftjs:', error);
		    });

		    import('draftjs-to-html').then(module => {
		        draftToHtml = module.default;
		        draftToHtml2 = module.default;
		        draftToHtml3 = module.default;
		    }).catch(error => {
		        console.error('Error importing html-to-draftjs:', error);
		    });

		    import('draft-js').then(module => {
		        EditorState = module.EditorState;
		        convertToRaw = module.convertToRaw;
		        ContentState = module.ContentState;
		        this.setState({editorState: EditorState.createEmpty() });

		        EditorState2 = module.EditorState;
		        convertToRaw2 = module.convertToRaw;
		        ContentState2 = module.ContentState;
		        this.setState({editorState2: EditorState.createEmpty() });

		        EditorState3 = module.EditorState;
		        convertToRaw3 = module.convertToRaw;
		        ContentState3 = module.ContentState;
		        this.setState({editorState3: EditorState.createEmpty() });
		    }).catch(error => {
		        console.error('Error importing draft-js:', error);
		    });


		}

		this.getJobs()
		this.getJobCategories();

	}

	moreJobs(){

		lastjobscount = newjobscount
		rlimit+=10
		this.getJobs()
	}

	async getJobs(){

		let jobs_ = []
		const jobsRef = this.props.collection(this.props.db, "jobs");
		let q = [];
		if(this.state.selectJobStatus==="ACTIVE")
		{
			q = this.props.query(jobsRef, this.props.where("user", "==", this.props.user.uid),  this.props.where("status", "!=", "EXPIRED"), this.props.orderBy('status',"desc") ,this.props.orderBy('created_at',"desc"), this.props.limit(rlimit) );
		}else{
			q = this.props.query(jobsRef, this.props.where("user", "==", this.props.user.uid), this.props.where("status", "==", "EXPIRED"), this.props.orderBy('status',"desc") ,this.props.orderBy('created_at',"desc"), this.props.limit(rlimit) );
		}
		
	    const querySnapshot = await this.props.getDocs(q);

	   	querySnapshot.forEach((doc) =>
	    {
		  	let data = doc.data()
			data.id = doc.id
			jobs_.push(data)
	    });

	    newjobscount = jobs_.length

	
	    if(lastjobscount===newjobscount)
	    {
	    	this.setState({showmore:false})
	    }

	    this.setState({ isLoaded: true, jobs : jobs_ })		
	
	}

	async getJobCategories(){	
		this.setState({ jobCategories  : jobCategories  })
	}

	
	confirmDeleteJob(){
		let that = this

		const jobsRef = this.props.doc(this.props.db, "jobs", this.state.deleteId );

  		this.props.updateDoc(jobsRef, {
	        status: 'INACTIVE',
	        reasonClosed: that.state.closeJobReason
	      }).then( function(){
	       		 that.getJobs()
		    	 that.setState({confirmDeleteJobDialog:false, snackbar:true, snackbarMessage:"You just closed the job post."})
	      } );

	}

	confirmReactivateJob(){
		this.props.setLoading(true)
		let that = this

		let created_at = new Date()
		let expires_at = new Date( new Date().setMonth( new Date().getMonth() + 3 ) )
		
		const jobsRef = this.props.doc(this.props.db, "jobs", this.state.reactivateId );

		this.props.updateDoc(jobsRef, {
	        status: 'PENDING',
	        created_at: created_at,
	        expires_at: expires_at
	      }).then( function(){
	       	that.getJobs()
		    that.setState({RefreshJobDialog:false, snackbar:true, snackbarMessage:"Your job post is now being reviewed."})
		    that.props.setLoading(false)
	      } );
	}

	toSnakeCase = (str) => {
	  // Replace spaces with underscores
	  str = str.replace(/\s+/g, '-');

	  // Convert to lowercase
	  return str.toLowerCase();
	}

	async saveProfile(){

		const rawContentState = convertToRaw(this.state.editorState.getCurrentContent());
		let html = draftToHtml(rawContentState);

		const rawContentState2 = convertToRaw2(this.state.editorState2.getCurrentContent());
		let html2 = draftToHtml2(rawContentState2);

		const rawContentState3 = convertToRaw3(this.state.editorState3.getCurrentContent());
		let html3 = draftToHtml3(rawContentState3);

		this.setState({jobDescription: html, howToApply: html2, notes: html3});

		if(this.props.user.jpc < 3)
		{
			this.setState({snackbar:true, snackbarMessage:"You dont have enough credits to create new Job post."})
			this.props.setLoading(false)
			return
		}

		let that = this;
		this.props.setLoading(true)

		const timestamp = Date.now();
		const randomNumber = Math.floor(Math.random() * 1000);

		var job = { jobPostTitle: this.state.jobPostTitle, 
			jobDescription: html, 
			jobCategory: this.state.jobCategory, 
			jobType: this.state.jobType,
			estProjectDuration: this.state.estProjectDuration,
			budget: this.state.budget,
			hoursPerWeek: this.state.hoursPerWeek,
			howToApply: this.state.howToApply,
			notes: this.state.notes,
			user: this.props.user.uid,
			status:this.state.jobStatus ==="ACTIVE" ? "UPDATE" : "PENDING",
			expertise: this.state.expertise,
			allowgindex: this.state.allowgindex,
			permalink: this.toSnakeCase(this.state.jobType)+"-"+this.toSnakeCase(this.state.jobCategory)+"-"+this.toSnakeCase(this.state.jobPostTitle.replace(/[^\w\s-]/g, '-')+"-"+randomNumber ) }
		
		if( this.state.isEdit === false )
		{
			job.created_at = new Date()
			job.expires_at = new Date( new Date().setMonth( new Date().getMonth() + 3 ) )
		}
		
		that.props.setLoading(true);

		if(that.state.isEdit===true)
		{

			that.getAdmin(); 

			const jobRef = this.props.doc(this.props.db, "jobs",that.state.deleteId);
			const jobSnap = await this.props.getDoc(jobRef);
			const data = jobSnap.data()

			job.expires_at = data.expires_at
			job.status = "UPDATE"

			if (jobSnap.exists())
			{

				this.props.updateDoc(jobRef, job ).then( function(){
			        
			        that.props.setLoading(false);
					that.setState({ addDialog:false, snackbarMessage:"Your job post was saved and will to be reviewed.", snackbar: true})
					that.setState({ jobPostTitle: '', 
						jobDescription: '', 
						jobCategory: '', 
						jobType: '',
						estProjectDuration: '',
						budget: '',
						hoursPerWeek: '',
						howToApply: '',
						notes: ''  })

					that.getJobs()

			    });
			}
			
		}else
		{	
			that.getAdmin();
			
			job.status = "PENDING"

			const jobRef = this.props.doc(this.props.collection(this.props.db, "jobs"));

			await this.props.setDoc(jobRef, job)
			.then( ()=>{

				that.props.setLoading(false);
				that.setState({ newJobID: jobRef.id, addDialog:false, snackbarMessage:"Your job post was saved and will be reviewed.", snackbar: true})
				that.setState({ jobPostTitle: '', 
					jobDescription: '', 
					jobCategory: '', 
					jobType: '',
					estProjectDuration: '',
					budget: '',
					hoursPerWeek: '',
					howToApply: '',
					notes: ''  })

				that.getJobs()						

			} )

		}
		

		
	}

	async showBalance(){
		let that = this

		let balance = 0;
		let last_transaction_id = '';

		const transactionsRef = this.props.collection(this.props.db, "transactions");
	  	const q = this.props.query(transactionsRef, this.props.where("job", "==", this.state.fundingItem.id ), this.props.orderBy('created_at',"desc"), this.props.limit(1) );

	  	const querySnapshot = await this.props.getDocs(q);
		querySnapshot.forEach((doc) => {
		  
		  	let transaction = doc.data();	
			last_transaction_id = doc.id
			
			balance=Number(transaction.amount ) + Number(transaction.last_balance)
		  	
		});

		this.setState({ last_transaction_id: last_transaction_id, last_balance: balance, balanceDisplay: balance })
	
	}

	async addFundingShow(item){

		if(item.status === "PENDING")
			this.setState({snackbar:true, snackbarMessage: "We are still checking your job post. You can add funds when your job post is approved. "})
		else
			this.setState({fundpanel: 0, fundingDialog:true, fundingItem: item},()=>{
				this.showBalance();
			});
	}

	handleEditorChange = (newEditorState) => {

		const rawContentState = convertToRaw(this.state.editorState.getCurrentContent());
		const html = draftToHtml(rawContentState);

	    this.setState({
	        editorState: newEditorState,
	        jobDescription: html
	    });
	};

	handleEditorChange2 = (newEditorState) => {

		const rawContentState2 = convertToRaw2(this.state.editorState2.getCurrentContent());
		const html2 = draftToHtml2(rawContentState2);

	    this.setState({
	        editorState2: newEditorState,
	        howToApply: html2
	    });
	};

	handleEditorChange3 = (newEditorState) => {

		const rawContentState3 = convertToRaw3(this.state.editorState3.getCurrentContent());
		const html3 = draftToHtml3(rawContentState3);

	    this.setState({
	        editorState3: newEditorState,
	        notes: html3
	    });
	};

	async createJob(){

		const contentBlock = htmlToDraft("");
		const contentBlock2 = htmlToDraft2("");
		const contentBlock3 = htmlToDraft3("");
			                    			
		if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({jobDescription: "", editorState:editorState})
        }

        if (contentBlock2) {
            const contentState2 = ContentState2.createFromBlockArray(contentBlock2.contentBlocks);
            const editorState2 = EditorState2.createWithContent(contentState2);
            this.setState({howToApply: "", editorState2:editorState2})
        }

        if (contentBlock3) {
            const contentState3 = ContentState3.createFromBlockArray(contentBlock3.contentBlocks);
            const editorState3 = EditorState3.createWithContent(contentState3);
            this.setState({notes: "", editorState3:editorState3})
        }

		this.setState({jobTitleDisable:false})
		this.props.setLoading(true)

		const userRef = this.props.doc(this.props.db, "users", this.props.user.uid );
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
			var userdata = userSnap.data();
			let jpc = _.has(userdata,'jpc') ? userdata.jpc : 0

			if(jpc>3)
				this.setState({jpc: jpc, addDialog:true, isEdit:false, jobPostTitle: '',jobDescription:'',hoursPerWeek:'',budget:'',howToApply:'',notes:'' })
			else
				this.setState({ jpc: jpc, snackbar: true, snackbarMessage: 'Sorry but you have used up all of your Job Post Credits.'})
			
			this.props.setLoading(false)
		}
	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	editDialog(item){


		const contentBlock = htmlToDraft(item.jobDescription);
		const contentBlock2 = htmlToDraft2(item.howToApply);
		const contentBlock3 = htmlToDraft3(item.notes);
			                    			
		if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({editorState:editorState})
        }

        if (contentBlock2) {
            const contentState2 = ContentState2.createFromBlockArray(contentBlock2.contentBlocks);
            const editorState2 = EditorState2.createWithContent(contentState2);
            this.setState({editorState2:editorState2})
        }

        if (contentBlock3) {
            const contentState3 = ContentState3.createFromBlockArray(contentBlock3.contentBlocks);
            const editorState3 = EditorState3.createWithContent(contentState3);
            this.setState({editorState3:editorState3})
        }



		this.setState({jobTitleDisable: true, addDialog:true, 
			jobPostTitle: _.has(item,'jobPostTitle') ? item.jobPostTitle : '',
			jobDescription: _.has(item,'jobDescription') ? item.jobDescription : '',
			jobCategory: _.has(item,'jobCategory') ? item.jobCategory : '',
			jobType: _.has(item,'jobType') ? item.jobType : '',
			estProjectDuration: _.has(item,'estProjectDuration') ? item.estProjectDuration : '',
			budget: _.has(item,'budget') ? item.budget : '',
			hoursPerWeek: _.has(item,'hoursPerWeek') ? item.hoursPerWeek : '',
			howToApply: _.has(item,'howToApply') ? item.howToApply : '',
			notes: _.has(item,'notes') ? item.notes : '',
			isEdit:true,
			deleteId: _.has(item,'id') ? item.id : '',
			jobStatus: _.has(item,'status') ? item.status : '',
			expertise: _.has(item,'expertise') ? item.expertise : '',
			allowgindex: _.has(item,"allowgindex") ? item.allowgindex: false
		})
	}


	async getAdmin(){
		let admin
		let that = this
		let message = MESSAGES.NEW_JOB
		message = message.replace("{jobPostTitle}", that.state.jobPostTitle)
		message = message.replace("{jobPostID}", that.state.newJobID)
		message = message.replace("{date}", new Date())
		message = message.replace("{user}", that.props.user.uid)
		
		fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
		{
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    method: "POST",
		    body: JSON.stringify({ title: "New Job Post", name: that.props.adminName , to: that.props.adminEmail, message: message })
		})
		.then(function(res){
			 return res.json() })
		.then(function(data)
		{
		
		})
		.catch(function(res)
		{ 
			
		})
		
		

	}

	submitJobPost(){
		let error = 0;

		if(this.state.jobPostTitle==="")
		{
			error++;
			this.setState({jobTitleError:true})
		}else{
			this.setState({jobTitleError:false})
		}

		if(this.state.expertise==="")
		{
			error++;
			this.setState({expertiseError:true})
		}else{
			this.setState({expertiseError:false})
		}


		if(this.state.jobDescription==="")
		{
			error++;
			this.setState({jobDescriptionError:true})
		}else{
			this.setState({jobDescriptionError:false})
		}

		if(this.state.jobCategory==="")
		{
			error++;
			this.setState({jobCategoryError:true})
		}else{
			this.setState({jobCategoryError:false})
		}

		if(this.state.jobType==="")
		{
			error++;
			this.setState({jobTypeError:true})
		}else{
			this.setState({jobTypeError:false})
		}

		if(this.state.estProjectDuration==="")
		{
			error++;
			this.setState({estProjectDurationError:true})
		}else{
			this.setState({estProjectDurationError:false})
		}

		if(this.state.budget==="")
		{
			error++;
			this.setState({budgetError:true})
		}else{
			this.setState({budgetError:false})
		}

		if(this.state.jobType !=='Project Based' && this.state.hoursPerWeek==="")
		{
			error++;
			this.setState({hoursPerWeekError:true})
		}else{
			this.setState({hoursPerWeekError:false})
		}

		if(this.state.budget ==="" || this.state.budget<1)
		{
			error++;
			this.setState({budgetError:true})
		}else{
			this.setState({budgetError:false})
		}


		if(error>0){
			this.setState({snackbarMessage:"Please make sure to input required fields", snackbar:true})
		}else{
			this.saveProfile()
		}
		

	}
	
	render(){

		const { Editor } = this.state;

		let jobsList
		let jobCategoriesList
		let that = this


		if(this.state.jobCategories.length > 0){
		
			jobCategoriesList = this.state.jobCategories.map( function(item,i){
		
				return ( 
						<MenuItem value={item} key={i}>
								<em style={styles.em}>{item}</em>
						</MenuItem>
					)
			} )
		}
		
		if(this.state.jobs.length > 0 && this.state.isLoaded)
		{
		
			jobsList = _.orderBy( this.state.jobs, 'created_at', 'desc' ).map(function(item, i){
			
			let id = item.id
			let status = ''
			let joblink = '/jobs/'
			
			if(item.status==="PENDING" || item.status === "UPDATE"){
				status ='#FFCD34'
				joblink = '/jobs/'+item.id
			}else if(item.status==="ACTIVE"){
				status = '#00b16a'
				joblink = '/jobs/'+item.id
			}else if(item.status==="INACTIVE"){
				status = '#e74c3c'
				joblink = '/jobs/'
			}

			
			
			let expires_at = item.expires_at;
			let now = moment()
			let expires = moment( new Date(expires_at.seconds * 1000) )
			let expires_in = expires.diff(now,'days')
			

			if(expires_in < 1){
				item.status = "EXPIRED"
				status = '#e74c3c'
			}

			return (
				<ListItem key={i}>
					<ListItemAvatar>
						<Tooltip title={item.status}>
			          		<Avatar style={{backgroundColor: `${status}` }}>
			          			{ ( (item.status ==="PENDING") || (item.status === "UPDATE") ) && <ErrorIcon /> }
			          			{ (item.status ==="ACTIVE") && <CheckIcon /> }
			          			{ (item.status ==="INACTIVE" || item.status === "EXPIRED") && <CloseIcon /> }
			          		</Avatar>
			          	</Tooltip>
			        </ListItemAvatar>

			        { ( item.status === "PENDING" || item.status === "UPDATE" ) && 
						<ListItemText
	                    	secondary={ expires_in > 0 ? "Expires in "+expires_in+" days" : ""}
	                  	>
	                  		<Tooltip title={'Your job post is still pending and cannot be viewed.'}>
	                  			<span style={{fontFamily:"Gotham Rounded"}}>{item.jobPostTitle}</span>
	                  		</Tooltip>
	                  	</ListItemText>
                  	}

                  	 { item.status === "ACTIVE" && 
						<ListItemText
	                    	secondary= {`Expires in ${expires_in} days \u2022 ${_.isUndefined(item.applicants) ? '0 Proposal' : `${item.applicants} ${item.applicants === 1 ? 'Proposal' : 'Proposals'}`}`}
	                  	>
	                  		<Link to={joblink} style={{fontFamily:"Gotham Rounded"}}>{item.jobPostTitle}</Link>
	                  		
	                  	</ListItemText>
                  	}

                  	{ item.status === "EXPIRED" && 
						<ListItemText
	                    	secondary={ that.state.selectJobStatus==="ACTIVE"?"This job will be move to the archive":""}
	                  	>
	                  	<Link to={joblink} style={{fontFamily:"Gotham Rounded"}}>{item.jobPostTitle}</Link>
	                  	</ListItemText>
                  	}
                  	

                  	<ListItemSecondaryAction>
                  		
                  		{ that.props.funding === true && item.status==="ACTIVE" && 
                  		<Tooltip title='Add fund to this project'>
		                    <IconButton
						        aria-label="More"
						        aria-controls="long-menu"
						        aria-haspopup="true"
						        onClick={ () => that.addFundingShow(item) }
						      >
						        <AttachMoneyIcon />
						    </IconButton>
					    </Tooltip>
						}

						
                  		{ (item.status ==="ACTIVE")  && <Tooltip title='Edit Job'>
		                    <IconButton
						        aria-label="More"
						        aria-controls="long-menu"
						        aria-haspopup="true"
						        onClick={()=>that.editDialog(item) }
						      >
						        <EditIcon />
						    </IconButton>
					    </Tooltip>
						}
						{ item.status ==="ACTIVE" && item.status ==="PENDING" &&
					    <Tooltip title='Close Job'>
		                    <IconButton
						        aria-label="More"
						        aria-controls="long-menu"
						        aria-haspopup="true"
						        onClick={()=> that.setState({confirmDeleteJobDialog:true, deleteId: id}) }
						      >
						        <CloseIcon />
						    </IconButton>
					    </Tooltip>
						}

						{ item.status === "EXPIRED" && 
							<Tooltip title='Reactivate Job'>
			                    <IconButton
							        aria-label="More"
							        aria-controls="long-menu"
							        aria-haspopup="true"
							        onClick={()=> that.setState({RefreshJobDialog:true, reactivateId: id}) }
							      >
							        <RefreshIcon />
							    </IconButton>
						    </Tooltip>	
						}
					
                  </ListItemSecondaryAction>
				</ListItem>	
				)

			})
				
		}
		

		
		return (

			<div>
			
			<header>
			  { this.state.isLoaded === true && 
	          <div style={{marginTop: 100}}>
		          
		         <Grid container spacing={2}>
		          		<Grid item xs={12} md={2}>
		          		</Grid>
		          		<Grid item xs={12} md={8}>

		          		 <h1 className="App-title">My Jobs</h1>
		          		 
		          		 <br/>
		          		 

				         <div>
		          		 	
		          		 <Grid container>
          		 			<Grid item md={6}>
	          		 			<FormControl fullWidth>
	          		 			  <InputLabel>Job Status</InputLabel>

								  <Select
								    defaultValue={this.state.selectJobStatus}
								    value={this.state.selectJobStatus}
								    label="Job Status"
								    onChange={ (event)=>{
								    	that.setState({selectJobStatus: event.target.value }, ()=>{
								    		that.getJobs()
								    	})
								    } }
								  >
								    <MenuItem  value={"ACTIVE"}>New</MenuItem >
								    <MenuItem  value={"EXPIRED"}>Archived</MenuItem >
								  </Select>
								</FormControl>
          		 			</Grid>
          		 			<Grid item md={6}>
	          		 			<div className="text-right">
			          		 		<Button style={styless.buttonPrimary} onClick={ () => this.createJob() } variant='contained' color='primary'>
					          			New Job
					          		</Button>
	          		 			</div>
          		 			</Grid>
          		 		</Grid>

		          		 	<br/>

		          		 	<List>
		          		 	{this.state.jobs.length > 0 &&
		          		 		jobsList
		          		 	}

		          		 	{this.state.jobs.length === 0 &&
		          		 		<center>
		          		 			<img alt="online-job" src="/online-job.png" />
		          		 			<Typography>You dont have an active job post.</Typography>
		          		 			
		          		 		</center>
		          		 	}


		          		 	{ this.state.showmore === true &&
			          		 	<center>
				          		 	<Tooltip title="Load More">
		  								<IconButton onClick={ ()=> { this.moreJobs() } }>
								              <MoreHorizIcon />
								        </IconButton>
							        </Tooltip>
						        </center>
					    	}
	          		 	

		          		 	</List>

		          		 </div>

		          		</Grid>
		          		<Grid item xs={12} md={2}></Grid>
		          	</Grid>
		      </div>
		      }

		      { this.state.isLoaded === false &&
                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
                        <CircularProgress style={{color:'#00b16a', alignSelf:'center'}} />
                    </div>
              }
	        </header>

	        <Dialog
	            maxWidth={'lg'}
	            fullWidth={true}
	        	open={this.state.addDialog}
	        	onClose={ () => this.setState({addDialog:false}) } >
	        		<DialogTitle><Typography style={styless.title}>Job Details</Typography> </DialogTitle>
	        		<DialogContent>

	        			
	        			<Typography style={styless.em}>Job Post Title</Typography>
	        				<TextField
	        					error={this.state.jobTitleError}
	          					name='jobTitle'
						      	variant='outlined'
						      	disabled={this.state.jobTitleDisable}
						      	margin="normal" value={this.state.jobPostTitle} fullWidth={true} onChange={ (event) => this.setState({jobPostTitle: event.target.value }) } />

						 	<Typography style={styless.em}>Job Description</Typography>
						    
					    	{ Editor  &&

								<Editor
					                editorState={this.state.editorState}
					                onEditorStateChange={this.handleEditorChange}
					            />
					        }
						    <br/>
						    <Typography style={styless.em}>Job Category</Typography>
						    <br/>
						    <FormControl variant="outlined" style={{width:'100%'}}>
							    <Select
							    	error={this.state.jobCategoryError}
		          				  	onChange={ (event) => this.setState({jobCategory: event.target.value }) }
									value={this.state.jobCategory}
								    inputProps={{
								    name: 'preference',
								    }} fullWidth={true}
								    input={<OutlinedInput />} >
								     >

								    { jobCategoriesList }
								    
							  </Select>
						  	</FormControl>
						  	<br/>
						  	<br/>
						  	<Typography style={styless.em}>Expertise</Typography>
						    <br/>
						    <FormControl variant="outlined" style={{width:'100%'}}>
							    <Select
		          				  	onChange={ (event) => this.setState({expertise: event.target.value }) }
									error = { this.state.expertiseError }
									value={this.state.expertise}
								    inputProps={{
								    name: 'expertise'
								    }} fullWidth={true}
								    input={<OutlinedInput />} >
								     >

								    <MenuItem value="entry">
								      <em style={styles.em}>Entry</em>
								    </MenuItem>
								    <MenuItem value="intermediate">
								    	<em style={styles.em}>Intermediate</em>
								    </MenuItem>
								    <MenuItem value="expert">
								    	<em style={styles.em}>Expert</em>
								    </MenuItem>

							  </Select>
						  	</FormControl>
						  	<br/>
						    <br/>
						    <Typography style={styless.em}>Job Type</Typography>
						    <br/>
						    <FormControl variant="outlined" style={{width:'100%'}}>
							    <Select
		          				  	onChange={ (event) => this.setState({jobType: event.target.value }) }
									error = { this.state.jobTypeError }
									value={this.state.jobType}
								    inputProps={{
								    name: 'preference'
								    }} fullWidth={true}
								    input={<OutlinedInput />} >
								     >

								    <MenuItem value="Full Time">
								      <em style={styles.em}>Full Time</em>
								    </MenuItem>
								    <MenuItem value="Part Time">
								    	<em style={styles.em}>Part Time</em>
								    </MenuItem>
								    <MenuItem value="Project Based">
								    	<em style={styles.em}>Project Based</em>
								    </MenuItem>

							  </Select>
						  	</FormControl>
						  	<br/>
						  	<br/>

						    <Typography style={styless.em}>Estimated Project Duration</Typography>
					    	<br/>
						    <FormControl variant="outlined" style={{width:'100%'}}>
							    <Select
		          				  	onChange={ (event) => this.setState({estProjectDuration: event.target.value }) }
									value={this.state.estProjectDuration}
									error={this.state.estProjectDurationError}
								    inputProps={{
								    name: 'preference',
								    }} fullWidth={true}
								    input={<OutlinedInput />} >
								     >

								    <MenuItem value="1">
								      <em style={styles.em}>Less than a week</em>
								    </MenuItem>
								    <MenuItem value="2">
								    	<em style={styles.em}>Less than a month</em>
								    </MenuItem>
								    <MenuItem value="3">
								    	<em style={styles.em}>Less than 3 months </em>
								    </MenuItem>
								    <MenuItem value="4">
								    	<em style={styles.em}>Less than 6 months </em>
								    </MenuItem>
								    <MenuItem value="5">
								    	<em style={styles.em}>Less than a year </em>
								    </MenuItem>
								    <MenuItem value="6">
								    	<em style={styles.em}>More than a year</em>
								    </MenuItem>


							  </Select>
						  	</FormControl>
						  	<br/>
						  	<br/>

						 	<Typography style={styless.em}>{ this.state.jobType==="Project Based" ? 'How much will you pay for this project?' : 'Rate Per Hour' }</Typography>
		     				<TextField
	          					error={ this.state.budgetError }
	          					name='hourly-rate'
						      	variant="outlined"
						      	InputProps={{
						          startAdornment: <InputAdornment position="start">$</InputAdornment>
						        }}
						      	margin="normal" value={this.state.budget} fullWidth={true} onChange={ (event) => { 
						      		const re = /^[0-9\b]+$/

						      		if (event.target.value === '' || re.test(event.target.value)) {
								       this.setState({budget: event.target.value })
								    }
						      		
						      	 } } />

						    { this.state.jobType !== 'Project Based' &&
						    <div>
						    <Typography style={styless.em}>Hours per week</Typography>
		     				<TextField
	          					error={ this.state.hoursPerWeekError }
	          					name='hourly-rate'
						      	variant="outlined"
						      	
						      	margin="normal" value={this.state.hoursPerWeek} fullWidth={true} onChange={ (event) => { 
						      		const re = /^[0-9\b]+$/

						      		if (event.target.value === '' || re.test(event.target.value)) {
								       this.setState({hoursPerWeek: event.target.value })
								    }
						      		
						      	 } } />

						     </div>
						     }
						     <br/><br/>
						     <Typography style={styless.em}>Instructions to Apply (optional)</Typography>

						     { Editor  &&

								<Editor
					                editorState={this.state.editorState2}
					                onEditorStateChange={this.handleEditorChange2}
					            />
					         }

					         <br/>
							 <Typography style={styless.em}>Additional Note (optional)</Typography>

							 { Editor  &&

								<Editor
					                editorState={this.state.editorState3}
					                onEditorStateChange={this.handleEditorChange3}
					            />
					         }

					         <br/>
							
						     <Tooltip title="Allow Google to include your job link in its search results, making your job post more visible on Google's search engine.">
			          		 	<FormGroup>
									  <FormControlLabel control={

									  	<Switch
								        checked={that.state.allowgindex}
								        onChange={ ()=>{
								        	that.setState({allowgindex: that.state.allowgindex==true?false:true })
								        } }
								        style={{ color: '#00b16a' }} // Set the color to green
								        name="mySwitch"
								        inputProps={{ 'aria-label': 'toggle switch' }}
								      />

									  } label="Allow Google to Index your job post" />
								</FormGroup>
			          		 </Tooltip>

							 <br/>
						     <Alert severity="error">Your job posting will be marked as pending. Freelancers will not be able to see it until we review it.</Alert>
						    
	        			</DialogContent>
	        			<DialogActions style={{marginTop: 20}}>
	        					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({addDialog:false}) }  autoFocus>
					              Close
					            </Button>
					            <Button style={styless.buttonPrimary} variant='contained'  onClick={ () => this.submitJobPost(this) }>
					              { this.props.isLoading === true ?  <CircularProgress style={{ 'color': '#fff'}} size={28} />  : ( this.state.isEdit === true ? "Update" : 'Post Job' ) }
					            </Button>
				        </DialogActions>
	        		
	        </Dialog>

	        <Dialog
	            maxWidth={'sm'}
	            fullWidth={true}
	        	open={this.state.fundingDialog}
	        	onClose={ () => this.setState({fundingDialog:false}) } >
	        		<DialogContent>

	        			
	        			{ 
	        				this.state.fundpanel==0 && 
		        				<div>
							         <Typography align='center' variant='h3'>${ Number( this.state.balanceDisplay ).toLocaleString(undefined, {minimumFractionDigits: 2}) }</Typography>
				        			 <Typography align='center' variant='subtitle1'>Available Fund</Typography>

				        			 <br/><br/>
				        			 <Box textAlign='center'>
					        			<Button  style={{ borderColor: '#00b16a', color: '#00b16a' }} variant="outlined" onClick={ ()=>this.setState({fundpanel:1}) } startIcon={<AttachMoneyIcon />}>
										  Add Fund
										</Button>
									 </Box>

									 <br/><br/>

									  <Typography align='center' variant='subtitle2'>Freelancers are most likely to apply to projects that are funded. This funds will be use to pay the freelancer after completing the job upon your approval . Funded status will be shown in your job post.</Typography>
									  <br/>
					        	</div>
					    }

					    { 
					    	this.state.fundpanel==1 && 
						        <div>
						        	<Box>
						        		<Tooltip title="Back">
						          		 	<IconButton onClick={ ()=>this.setState({fundpanel: 0}) } style={{alignSelf:'center', color:"#00b16a"}} aria-label="delete"  color="primary">
								           		<ArrowBackIcon />
								         	</IconButton>
								        </Tooltip>

						        		<br/>
			          		 			<Typography align='center'>Select your payment platform</Typography>
			          		 			<br/>

		          		 				<div onClick={ ()=>this.setState({fundpanel:2, payment_platform:'paypal'}) }
								          style={{
								            width: "97%",
								            textAlign:'center'
								          }}
								          className="buttonbase"
								        >

								        	<img alt="paypalme" src="../paypalme.png" />
								        	<h2>PayPal</h2>
								        </div>
			          		 		</Box>
						        </div>
					    }
					    
					    {
					    	this.state.fundpanel== 2 && 
						    	<div>
								  <br/>
								  { this.state.payment_platform==='paypal' &&
							          <Box textAlign='center'>
							          	<PaypalFunding  payment_platform={this.state.payment_platform} last_transaction_id={this.state.last_transaction_id} last_balance={this.state.last_balance} job={this.state.fundingItem} addDoc={this.props.addDoc}  getDocs={this.props.getDocs} user={this.props.user} collection={this.props.collection} db={this.props.db} doc={this.props.doc} updateDoc={this.props.updateDoc} />
							          	<br/>
							          </Box>
						      	  }
						        </div>
						}
					     
					  
	        			
	        		</DialogContent>
        			
	        </Dialog>

	        <Dialog 
	        	maxWidth={'sm'}
	        	fullWidth={true}
	        	open={this.state.confirmDeleteJobDialog}
	        	onClose={ () => this.setState({confirmDeleteJobDialog:false}) } >
	 			<DialogTitle><Typography style={styless.title}>Close Job</Typography></DialogTitle>   
	 			<DialogContent>
	 				Reason for closing the job

	 				<TextField
						    multiline
						    rows="5"
						    name='jobDescription'
						      variant='outlined'
						     margin="normal" value={this.state.closeJobReason} fullWidth={true} onChange={ (event) => this.setState({closeJobReason: event.target.value }) } />

	 			</DialogContent>    	
	        	<DialogActions style={{marginTop: 20}}>
    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({confirmDeleteJobDialog:false}) } color="default" autoFocus>
			              Cancel
			            </Button>
			            <Button style={styless.buttonPrimary} variant='contained'  onClick={ () => this.confirmDeleteJob() } color="primary">
			              Close Job
			            </Button>
		        </DialogActions>
	        </Dialog>
	        
	          <Dialog 
	        	maxWidth={'sm'}
	        	fullWidth={true}
	        	open={this.state.RefreshJobDialog}
	        	onClose={ () => this.setState({RefreshJobDialog:false}) } >
	 			<DialogTitle><Typography style={styless.title}>Reactivate Job</Typography></DialogTitle>   
	 			<DialogContent>

	 				Reactivation of this job will cost 3 credits. Credits will be deducted after the review process. Would you like to continue?

	 			</DialogContent>    	
	        	<DialogActions style={{marginTop: 20}}>
    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({RefreshJobDialog:false}) } autoFocus>
			              Cancel
			            </Button>
			            <Button disabled={this.props.isLoading===true?true:false} style={styless.buttonPrimary} variant='contained'  onClick={ () => this.confirmReactivateJob() }>
			              
			               { this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : 'Confirm' }
			            </Button>
		        </DialogActions>
	        </Dialog>
	        
	        <Snackbar
		          anchorOrigin={{
		            vertical: 'bottom',
		            horizontal: 'center',
		          }}
          		open={this.state.snackbar}
          		autoHideDuration={3000}
          		onClose={this.snackbarClose}
          		ContentProps={{
            		'aria-describedby': 'message-id',
          		}}
          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
		          action={[
		            <IconButton
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              onClick={this.snackbarClose}
		            >
		              <CloseIcon />
		            </IconButton>,
		          ]} />
	    </div>
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		funding: state.funding,
		collection: state.collection,
		db: state.db,
		where: state.where,
		orderBy: state.orderBy,
		query: state.query,
		getDocs: state.getDocs,
		doc: state.doc,
		getDoc: state.getDoc,
		setDoc: state.setDoc,
		adminName: state.adminName,
		adminEmail: state.adminEmail,
		limit: state.limit,
		orderBy: state.orderBy,
		updateDoc : state.updateDoc,
		isLoading: state.isLoading,
		addDoc: state.addDoc
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);





