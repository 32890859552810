import React, {Component} from 'react'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid';

const styles = {
   header:{
    fontSize: 64
   },
   buttonPrimary: {
    padding: 18,
    fontFamily: 'Gotham Rounded',
    backgroundColor: '#37c96a'
   }
}


class ComingSoon extends Component {

    constructor(props){
        super(props);

        this.state = {
            emailError: false,
            email: '',
            message: 'hello',
            open: false
        }
    }

    componentDidMount(){
        
    }

    handleSubmit = () =>{
        if( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( this.state.email ) ){
            this.setState({open:true, message: "Please enter a valid email address."})
        }else{

            const db = this.props.firebase.firestore()
            let that = this
                
            db.collection("emailList").doc().set({
                email: that.state.email,
                created_at: new Date()
            })
            .then(function(){
                that.setState({open:true, message: "Thank you for your interest. We promise to keep you updated with our progress. Please also like our facebook page."})
            })
            .catch(function(error){
                console.log(error);
            });

        }
    }

    onClose = ()=>{
        this.setState({open:false})
    }

    render(){
        return (
              <div className="comingsoon">
                <Grid>
                    <Grid container>
                        <Grid item  sm={12} md={2}></Grid >
                        <Grid item  sm={12} md={8}>
                            <div className="panel">
                                <center><img alt="logo" className="logo-lg" src="/invert.png" /></center>
                                <center style={styles.header}>Under Construction</center>
                                <center>This page is under development. Please check back later.</center>
                                <center>Like our page <a style={{color:'#f1c40f'}} href="https://facebook.com/freelancertogo" target="_blank" rel="noopener noreferrer">fb/freelancertogo</a></center>
                            </div>

                        </Grid >
                        <Grid item  sm={12} md={2}></Grid >
                    </Grid >
                </Grid>
              </div>
        )
    }
}

const mapStateToProps = (state) => { 
    return {
        firebase: state.firebase
    }
}

const mapDispatchToProps = (dispatch) => {
    return {};
}
export default connect(mapStateToProps,mapDispatchToProps)(ComingSoon);  

