import React, { Component, useRef  } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import _ from "lodash";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import jobCategories from '../../constants/jobCategories.json'
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';

import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';

//import { EditorState, convertToRaw, ContentState  } from 'draft-js';
//import { Editor } from 'react-draft-wysiwyg';
//import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import draftToHtml from 'draftjs-to-html';
//import htmlToDraft from 'html-to-draftjs';


let htmlToDraft;
let draftToHtml;
let EditorState;
let convertToRaw;
let ContentState;

const styles = {
   small: {
   	color: '#8e8e8e',
   	fontFamily: 'Gotham Rounded'
   },

   em: {
   	 color: '#8e8e8e',
   	 fontFamily: 'Gotham Rounded'
   },

   buttonPrimary: {
   	padding: 18,
   	fontFamily: 'Gotham Rounded',
   	backgroundColor: '#00b16a',
   	color: '#fff'
   }
}


//const editorRef = React.createRef();

class BlogAdmin extends Component{


	
	constructor(props){
		
		

		super(props);

		this.state = {
			fullWidth: true,
    		maxWidth: 'sm',
    		db: [],
    		snackbar: false,
    		snackbarMessage: '',
    		isLoaded: false,
			users: [],
			openActionMenu: false,
			clientsCount: 0,
			freelancerCount: 0,
			dialogReason: false,
			actionStatus:'',
			actionID: [],
			reasonText: '',
			notificationTitle: '',
			pendings: [],
			selectedStat : "PENDING",
			clientCount: 0,
			searchstring: '',
			newJobCategory: '',
			addJobCategoryDialog:false,
			categoryName: '',
			categoryNameError: false,
			categoryDescription: '',
			categoryReference: '',
			skillName: '',
			skillNameError: false,
			skillDescription: '',
			skillReference: '',
			searchJobCategory: '',
			renderJobCategories: [],
			renderSkills: [],
			searchJobCategoryDialog: false,
			searchSkillsDialog: false,
			addSkillDialog: false,
			maintenance: false,
			enableAds: false,
			funding: false,
			stat: [],
			stat_id: '',
			isLoading: false,
			min_funding: 0,
			max_funding: 0,
			min_withdrawal: 0,
			max_withdrawal: 0,
			jobCategory: '',
			numberOfJobs: 5,
			numberOfFreelancers: 50,
			alljobs: [],
			allfreelancers: [],
			addAffiliateLink:'',
			addAffiliateName: '',
			addAffiliateDescription: '',
			affiliateLinks: [],
			newblog:false,
			publish:false,
			blogid:null,
			title: '',
			blogs: [],
			removeBlog:false,
			blogtoremove:null,
			blogtoedit:null,
			blogContent:'',
			permalink: '',
			editorState: null
		};
		

	}	

	handleChange = (event, value) => {
	    this.setState({ submenu: value });
	};

	componentDidMount() {
		
		 if (typeof window !== 'undefined') {
			
			import('react-draft-wysiwyg').then(module => {
		      this.setState({ Editor: module.Editor });
		    }).catch(error => {
		      console.error('Error loading react-draft-wysiwyg:', error);
		    });

		    import('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');

		    import('html-to-draftjs').then(module => {
		        htmlToDraft = module.default;
		    }).catch(error => {
		        console.error('Error importing html-to-draftjs:', error);
		    });

		    import('draftjs-to-html').then(module => {
		        draftToHtml = module.default;
		    }).catch(error => {
		        console.error('Error importing html-to-draftjs:', error);
		    });

		    import('draft-js').then(module => {
		        EditorState = module.EditorState;
		        convertToRaw = module.convertToRaw;
		        ContentState = module.ContentState;
		        this.setState({editorState: EditorState.createEmpty() });
		    }).catch(error => {
		        console.error('Error importing draft-js:', error);
		    });


		}

		this.getStat()
		this.getBlogs()

	}

	async getBlogs(){ 

		let that = this
		let blogs = []

		const querySnapshot = await this.props.getDocs(this.props.collection(this.props.db, "blogs"));
        
        querySnapshot.forEach((doc) => {  
        	let blog = doc.data()
			blog.id  = doc.id
			blogs.push(blog)

        });

        this.setState({blogs:blogs})
  	}

	async getStat(){

		let that = this

		const querySnapshot = await this.props.getDocs(this.props.collection(this.props.db, "stats"));
        
        querySnapshot.forEach((doc) => {  
        	let stat = doc.data()
			stat.id  = doc.id
			that.setState({ min_funding:stat.min_funding, max_funding: stat.max_funding, min_withdrawal: stat.min_withdrawal, max_withdrawal: stat.max_withdrawal , funding: stat.funding, maintenance:stat.maintenance,stat_id: stat.id, enableAds:stat.enable_ads})
        	
        	that.props.setLoading(false)
			that.setState({isLoaded: true, stat: stat})
        });

	}

	
	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	jsUcfirst = (string) =>
	{
	    let str = string.charAt(0).toUpperCase() + string.slice(1);
	    return str
	}

	handleChangePage = (event, newPage) => {
    }

    handleChangeRowsPerPage = event => {

    }

    handleEditorChange = (newEditorState) => {
	    this.setState({
	        editorState: newEditorState,
	    });
	};

    createSeoFriendlyLink(title) {
		  // Convert title to a URL-friendly format
		  const urlFriendlyTitle = title
		    .toLowerCase()
		    .replace(/[^\w\s]/g, '') // Remove special characters
		    .replace(/\s+/g, '-') // Replace spaces with hyphens

		  // Get the current timestamp
		  const timestamp = Date.now();

		  // Combine the URL-friendly title and timestamp
		  const seoFriendlyLink = `${urlFriendlyTitle}-${timestamp}`;

		  return seoFriendlyLink;
	}
	
	async removeBlog(blog){
	
		let that = this
		this.props.setLoading(true)
		await this.props.deleteDoc(this.props.doc(this.props.db, "blogs", blog.id)).then(function(){
			that.props.setLoading(false)
			that.getBlogs()
			that.setState({removeBlog:false})
		});
	}
	async save()
	{	

		const rawContentState = convertToRaw(this.state.editorState.getCurrentContent());
		const html = draftToHtml(rawContentState);

		
		let that = this
		this.props.setLoading(true)

		if(this.state.blogid===null)
		{
			const docRef = await this.props.addDoc(this.props.collection(this.props.db, "blogs"), {
				  title: that.state.title,
				  metadescription: that.state.metadescription,
				  //content: that.state.blogContent,
				  content: html,
				  publish : that.state.publish,
				  created_at : new Date(),
				  updated_at : new Date(),
				  permalink: that.createSeoFriendlyLink(that.state.title)
				}).then(function(){
					that.props.setLoading(false)
					that.setState({blogContent:'', snackbar:true,snackbarMessage:"Saved!",title:'',metadescription:'',newblog:false, blogid:null})
					that.getBlogs()
					
				});
		}else{


			const blogRef = this.props.doc(this.props.db, "blogs", this.state.blogid);

	  		this.props.updateDoc(blogRef, {
		        title: that.state.title,
			    metadescription: that.state.metadescription,
			    content:  html,
			    publish : that.state.publish,
			    updated_at : new Date(),
			    permalink: that.state.permalink === "" ? that.createSeoFriendlyLink(that.state.title) : that.state.permalink
		    }).then( function(){
		       		that.props.setLoading(false);
			   		that.setState({snackbar:true,snackbarMessage:"Saved!",title:'',metadescription:'',newblog:false, blogid:null})
			   		that.getBlogs()
		    });

		}

		

	}

	render(){
	
		const { Editor } = this.state;
		
		let that = this

		let renderBlogs = this.state.blogs.map(function(item,i){
          			return (
          				<div>
          				<ListItem key={i}>
			                  <ListItemText
			                    primary={item.title}
			                    secondary={item.metadescription}
			                  />
			                  <ListItemSecondaryAction>
			                    <IconButton onClick={ ()=>{
			                    	that.setState({removeBlog:true,blogtoremove:item})
			                    } }>
			                      <DeleteIcon />
			                    </IconButton>
			                    <IconButton onClick={ ()=>{
			                    	that.setState({permalink: _.has(item,'permalink') ?  item.permalink : '', blogtoedit:item,newblog:true,title:item.title, metadescription:item.metadescription, blogid:item.id, publish:item.publish},()=>{
			                    		

			                    		setTimeout(() => {

			                    			const contentBlock = htmlToDraft(item.content);
			                    			
			                    			if (contentBlock) {
									            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
									            const editorState = EditorState.createWithContent(contentState);
									            that.setState({blogContent: item.content, editorState:editorState})
									        }
										
										}, 1000);


			                    	})
			                    	
			                    
			                    } }>
			                      <EditIcon />
			                    </IconButton>
			                  </ListItemSecondaryAction>
			             </ListItem>
			             <Divider variant="inset" component="li" />
			             </div>
          				)
          		});

		return (

			<div>
			
				<header>
				  { this.state.isLoaded === true &&
			          <div style={{marginTop: 100}}>
				          
				          <Grid container spacing={2}>
				          		<Grid item xs={12} md={2}></Grid>
				          		<Grid item xs={12} md={8}>

					          		 <h1 className="App-title">Blogs</h1>
					          		 {
					          		 	this.state.isLoading===true &&
						          		 <Box sx={{ width: '100%' }}>
									      	<LinearProgress />
									   	 </Box>
								   	 }

					          		 <hr/>

							         <div>
						         		<Grid container spacing={2}>
					         				<Grid item>
					         					<Button style={styles.buttonPrimary} variant="contained" onClick={ ()=> this.setState({newblog:true, title: '', metadescription:'', blogid:null, blogContent:'', permalink: '' }) }>New</Button>
					         				</Grid>
						         		</Grid>

						         		<br/>

						         		<Grid container spacing={2} >
					         				<Grid sm={12} md={12} item>
					         					<List>
					         						{renderBlogs}
					         					</List>
					         				</Grid>
						         		</Grid>

						         		
					          		 </div>
					          		 
				          		</Grid>
				          		<Grid item xs={12} md={2}></Grid>
				          
				          </Grid>
				      </div>
			  	  }

			      { this.state.isLoaded === false &&
	                    <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
	                        <CircularProgress style={{color:'#00b16a', alignSelf:'center'}} />
	                    </div>
	              }
		        </header>

		        <Snackbar
			        anchorOrigin={{
			          vertical: 'bottom',
			          horizontal: 'right',
			        }}
			        open={this.state.snackbar}
			        autoHideDuration={1000}
			        onClose={ ()=>this.setState({snackbar:false}) }
			        message={this.state.snackbarMessage} />

			    <Dialog maxWidth="lg" fullWidth={true} open={this.state.newblog} onClose={()=>this.setState({newblog:false})}>
	    			<DialogTitle>New Blog</DialogTitle>
	    			<DialogContent>
	    				<TextField
				            autoFocus
				            margin="dense"
				            id="title"
				            label="Title"
				            type="text"
				            fullWidth
				            variant="standard"
				            value={this.state.title}
				            onChange={ (event)=>this.setState({title:event.target.value}) }
				          />

				        <TextField
				            margin="dense"
				            id="metadescription"
				            label="Meta Description"
				            type="text"
				            fullWidth
				            variant="standard"
				            value={this.state.metadescription}
				            onChange={ (event)=>this.setState({metadescription:event.target.value}) }
				          />

				         <br/><br/>

				       	{ Editor  &&


							<Editor
				                editorState={this.state.editorState}
				                onEditorStateChange={this.handleEditorChange}
				            />
				        }
			           

						<br/><br/>

						<TextField
				            autoFocus
				            margin="dense"
				            id="permalink"
				            label="Permalink"
				            type="text"
				            fullWidth
				            variant="standard"
				            value={this.state.permalink}
				            onChange={(event)=>this.setState({permalink:event.target.value})}
				           
				          />
				         
					    <br/><br/>

					    <FormControlLabel
				        control={<Switch sx={{ "&.MuiSwitch-root .Mui-checked": {
													color: "#00b16a"
							  },
							  ".MuiSwitch-track": {
							    backgroundColor: "rgba(0, 177, 106, 0.5) !important"
							  } 
							}} checked={this.state.publish} onChange={ ()=>this.setState({publish: this.state.publish===true?false:true})} />}
				        label="Publish Blog" />

				        <input
					        type="hidden"
					        value={this.state.blogid}
					    />
					    
	    		</DialogContent>
	    		<DialogActions>
	    			<Button style={styles.buttonPrimary} variant="contained" onClick={ ()=> this.save() }>Save</Button>
	    		</DialogActions>
	    	</Dialog>

	    	<Dialog
		        open={this.state.removeBlog}
		        onClose={()=>this.setState({removeBlog:false})} >
		        <DialogTitle>
		          Remove Blog
		        </DialogTitle>
		        <DialogContent>
		          <Typography>
		           Are you sure you want to remove this blog?
		          </Typography>
		        </DialogContent>
		        <DialogActions>
		          <Button onClick={ ()=>this.removeBlog(this.state.blogtoremove) }>Yes</Button>
		        </DialogActions>
		      </Dialog>

	    	</div>

	    	
			)
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		clientCount: state.clientCount,
		freelancerCount: state.freelancerCount,
		collection: state.collection,
		db: state.db,
		getDocs: state.getDocs,
		docs: state.docs,
		doc: state.doc,
		getDoc: state.getDoc,
		updateDoc: state.updateDoc,
		isLoading: state.isLoading,
		addDoc: state.addDoc,
		deleteDoc : state.deleteDoc 
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setClientCount: (count) => dispatch({type: "SET_CLIENT_COUNT", payload: {clientCount: count}}),
		setFreelancerCount: (count) => dispatch({type: "SET_FREELANCER_COUNT", payload: {freelancerCount: count}}),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}})
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(BlogAdmin);





