import React, { Component,useState  } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Downshift from 'downshift'
import Paper from '@mui/material/Paper';
import { connect } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import _ from 'lodash'
import DeleteIcon from '@mui/icons-material/Delete';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Moment from 'react-moment';
import * as ROUTES from '../../constants/routes';
import * as FIREBASECONFIG from '../../constants/firebase';
import * as MESSAGES from '../../constants/messages';
import Avatar from '@mui/material/Avatar';
import LinearProgress from '@mui/material/LinearProgress';
import { lighten } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Autocomplete from '@mui/lab/Autocomplete';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/lab/Alert';
import PaypalButton from '../../constants/paypalButton';
import ls from 'local-storage'
import moment from 'moment'
import skillsList from '../../constants/skillsList.json'
import jobCategories from '../../constants/jobCategories.json'
import countries from '../../constants/countries.json'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpIcon from '@mui/icons-material/Help';
import StarIcon from '@mui/icons-material/Star';
import InboxIcon from '@mui/icons-material/Inbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  
} from "react-share";



import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import CreateIcon from '@mui/icons-material/Create';
import SaveIcon from '@mui/icons-material/Save';
import Grid from '@mui/material/Grid';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import '../../constants/filepond.min.css';
import '../../constants/filepond-plugin-image-preview.min.css';

const greenTheme  = createTheme({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
      	root: {
          backgroundColor: 'rgba(0, 177, 106, 0.5)',
        },
        barColorPrimary: {
          backgroundColor: '#00b16a',
        },
      },
    },
  },
});


registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);

const styless = {
	bigAvatar:{
		margin: 10,
		width: 150,
		height: 150,
		alignSelf:'center',
		cursor: 'pointer'
	},
	title: {
		color: '#333333',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 24
	},
	subtitle: {
		color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10,
	   	fontSize: 18
	},
	center:{
		textAlign:'center',
		color: '#3c4859'
	},

	small: {
	   	color: '#8e8e8e',
	   	fontFamily: 'Gotham Rounded',
	   	marginBottom: 10
	   },

	   em: {
	   	 color: '#8e8e8e',
	   	 fontFamily: 'Gotham Rounded'
	   },

	   buttonPrimary: {
	   	padding: 12,
	   	fontFamily: 'Gotham Rounded',
	   	backgroundColor: '#00b16a',
	   	color: '#ffffff'
	   },

	   buttonDefault: {
	   	padding: 12,
	   	fontFamily: 'Gotham Rounded',
	   	borderColor: '#00b16a',
	   	color: '#00b16a',
	   }
}
const skillsCount = 10;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class Profile extends Component{
	
	constructor(props){

		super(props);

		this.state = {
			deletemyaccount:'',
			submenu: 0,
			title: '',
			overview: '',
			hourlyRate: 0,
			fullTimeRate: 0,
			isSkill: false,
			skill: '',
			fullWidth: true,
    		maxWidth: 'sm',
    		profile: [],
    		db: [],
    		user: [],
    		snackbar: false,
    		snackbarMessage: '',
    		currentTab: 'Profile',
    		skillsList: [],
    		skillsData: [],
    		projectsData: [],
    		portfolioData: [],
    		skillInput: 'hello',
    		dialogTitle: '',
    		dialogContent: '',
    		skillsDialog: false,
    		educationDialog: false,
    		school: '',
    		schoolCourse: '',
    		schoolStarted: new Date(Date.now()),
    		schoolEnded: '',
    		schoolDescription: '',
    		educationData: [],
    		employmentData: [],
    		anchorEl: null,
    		isEditEducation: false,
			isEditEmployment:false,
    		isEditJob: false,
    		isEditEducationIndex: null,
			isEditProjectIndex:null,
    		confirmDeleteDialog:false,
    		confirmDeleteEducationIndex: null,
    		employmentDialog:false,
    		company: '',
    		jobTitle: '',
    		jobDescription: '',
    		jobStarted: '',
			jobEnded: '',
			confirmDeleteEmploymentDialog: false,
			projectTitle: '',
			projectDescription: '',
			projectLink: '',
			projectStarted: '',
			projectEnded: '',
			isEditProject:false,
			projectScreenshots: [],
			projectScreenshotsURL: [],
			projectfiles: [],
			isSaving: false,
			profileTitleError: false,
			profileOverviewError:false,
			profileHourlyRateError: false,
			profileFullTimeRateError: false,
			selectedSkill: '',
			schoolError: false,
			schoolCourseError: false,
			schoolStartedError: false,
			schoolEndedError: false,
			jobTitleError: false,
			jobDescriptionError: false,
			jobStartedError: false,
			jobEndedError: false,
			companyError: false,
			projectTitleError: false,
			projectDescriptionError: false,
			projectStartedError: false,
			projectEndedError: false,
			avatar: '',
			isAvatarUpload: false,
			projectAvatar: '',
			isProjectAvatar: false,
			skillval: '',
			profilecompleteness: 0,
			isLoaded: false,
			preference: '',
			profileStatus: '',
			hasSubmitProfile: 0,
			setupPaypalDialog:false,
			paypalme:'',
			expertise: "",
			jobCategories: [],
			jobCategoriesSelected: [],
			fname: '',
			lname: '',
			confirmDeleteProjectDialog: false,
			jpc: 0,
			schoolEndedPresent:false,
			schoolEndedDisabled: false,
			jobEndedPresent:false,
			jobEndedDisabled:false,
			portfolioDialog:false,
			showPaypal: false,
			activeAccount: false,
			identityVerified: false,
			identityVerifiedDialog:false,
			currentBalance:0,
			withdrawPanel:false,
			withdrawAmount:0,
			withdrawAmountError:false,
			withdrawAmountErrorMessage: '',
			withdraw_next: 0,
			withdraw_selection : '',
			fee: 0.05,
			releaseConfirmationNumber: 0,
			c1: 0,
			c2: 0,
			c3: 0, 
			c4: 0,
			c5: 0,
			confirmationMatch: true,
			pendingWithdrawal: 0,
			pendingWithdrawalDate: new Date(),
			min_withdrawal: 0,
			max_withdrawal: 0,
			addProject_once: 0,
			saveWarning:false,
			profileUpdated:false,
			paypal_percentage: 0.0349,
			allowgindex: false,
			allowpublic: false,
			mypermalink:'',
			uploadComplete:false,
			isUploadingFiles:0
		};
	
	}	

	 handleChange = (event, value) => {
	    this.setState({ submenu: value });
	  };

	componentWillMount(){
		this.getSkillsList()
		this.getProfile()
		this.getJobCategories()
		this.getBalance()
		this.getStat()
		this.getCountries() 
	}

	
	async savePermalink()
	{	
		let that = this
		this.props.setLoading(true)
		
		const usersRef = this.props.collection(this.props.db, "users");
	  	const q = this.props.query(usersRef, this.props.where("mypermalink", "==", that.state.mypermalink) );

	  	const querySnapshot = await this.props.getDocs(q);

	  	if( querySnapshot.size == 0 )
	  	{
	  		const userRef = this.props.doc(this.props.db, "users", this.props.user.uid);

	  		 this.props.updateDoc(userRef, {
		        mypermalink: that.state.mypermalink
		      }).then( function(){
		       		that.props.setLoading(false);
			   		that.setState({snackbar:true,snackbarMessage:"Saved!"})
		      } );

	  	}else{
	  		that.setState({snackbar:true, snackbarMessage:"Permalink is already in used. Try other permalink."})
	  		that.props.setLoading(false);
	  	}
	}


	updateallowpublic = (event, value) => {

	   let that = this;
	   this.props.setLoading(true);
	   this.setState({ allowpublic: value },()=>{

	   	 const userRef = that.props.doc(that.props.db, "users", that.props.user.uid);

	   	 that.props.updateDoc(userRef, {
	        allowpublic: value
	     }).then( ()=>{
	     	that.props.setLoading(false);
	   		that.setState({snackbar:true,snackbarMessage:"Saved!"})
	     } );

	   
	  
	   });
	   
		
	};

	updateallowgindex = (event, value) => {

	   let that = this;
	   this.props.setLoading(true);
	   this.setState({ allowgindex: value },()=>{

	   	 const userRef = that.props.doc(that.props.db, "users", that.props.user.uid);

	   	 that.props.updateDoc(userRef, {
	        allowgindex: value
	     }).then( ()=>{
	     	that.props.setLoading(false);
	   		that.setState({snackbar:true,snackbarMessage:"Saved!"})
	     } );

	   
	  
	   });
	   
		
	};

	async getStat()
	{
		let that = this
		const querySnapshot = await this.props.getDocs(this.props.collection(this.props.db, "stats"));
        
        querySnapshot.forEach((doc) => {
            
           let data = doc.data()
           data.id = doc.id

           that.setState({min_withdrawal: data.min_withdrawal, max_withdrawal: data.max_withdrawal}) 

        });
    }

	async useMax()
	{

		let that = this
		const transactionsRef = this.props.collection(this.props.db, "transactions");

	  	const q = this.props.query(transactionsRef, this.props.where("type", "==", "FUNDING_TRANSFER"), this.props.where("freelancer_id", "==", this.props.user.uid), this.props.orderBy('created_at',"desc"), this.props.limit(1) );

	  	const querySnapshot = await this.props.getDocs(q);
		querySnapshot.forEach((doc) => {
		  
		  	let last_transaction_id = doc.id
			let transaction = doc.data();
			let balance=Number(transaction.amount + transaction.last_balance)

			that.setState({withdrawAmount: balance})
		});
  }

	getCountries(){
		this.props.setCountries(countries)	    
	}

	async getJobCategories(){	

		this.setState({ jobCategories  : jobCategories  })
	}

    async getBalance(){
    	let that = this
    	let balance = 0
    	const q = this.props.query(this.props.collection( this.props.db, "transactions"), this.props.where("type", "==", "FUNDING_TRANSFER"), this.props.where('freelancer_id','==',that.props.user.uid),this.props.orderBy('created_at','desc'), this.props.limit(1));

		const querySnapshot = await this.props.getDocs(q);
		querySnapshot.forEach((doc) => {
		  

		  	let last_transaction_id = doc.id
			let transaction = doc.data();

			balance=Number(transaction.amount + transaction.last_balance)

			that.setState({currentBalance: balance})
		});

		this.checkExistingWithdrawalRequest()
    	
    }

	async getProfile()
	{
		
		console.log("getProfile", this.props.user)
		const state = this

		let data = this.props.user

		if(_.has(data,'profile'))
			state.props.setProfile(data.profile)

		state.setState({
				jobCategoriesSelected: _.has(data.profile,'jobcategories') ? data.profile.jobcategories : [],
				profileStatus: _.has(data, 'status') ? data.status: '',
				paypalme: _.has(data.profile, 'paypalme') ? data.profile.paypalme: '',
				avatar: _.has(data,'profile') ? data.profile.avatar :'',
				title: _.has(data,'profile') ? data.profile.title : '', 
				overview: _.has(data,'profile') ? data.profile.overview : '', 
				hourlyRate: _.has(data,'profile') ? data.profile.hourlyRate : '',
				fullTimeRate: _.has(data,'profile') ? data.profile.fullTimeRate : '',
				expertise: _.has(data.profile, 'expertise') ? data.profile.expertise : '',
				preference: _.has(data,'profile') ? data.profile.preference : '',
				fname: data.fname,
				lname: data.lname,
				jpc: _.has(data,'jpc') ? data.jpc : 0,
				country: _.has(data,'country') ? data.country : '',
				activeAccount: _.has(data,'status') ? data.status : '',
				identityVerified: _.has(data, 'verification_review') ? data.verification_review : '',
				mypermalink: _.has(data, 'mypermalink') ? data.mypermalink : ''
		});

		if(data.country){
			state.setState({
				country: data.country
			})
		}

		if(data.skills){
			state.setState({
				skillsData: data.skills
			})
		}

		if(data.educations){
			state.setState({
				educationData: data.educations
			})
		}

		if(data.employment){
			state.setState({
				employmentData: data.employment
			})
		}

		if(data.projects){
			state.setState({
				projectsData: data.projects
			})
		}

		state.profileCompleteness(data)
		state.setState({isLoaded:true,fname:data.fname, lname:data.lname, jpc: data.jpc, allowgindex: _.has(data,"allowgindex") ? data.allowgindex : false, allowpublic: _.has(data,"allowpublic") ? data.allowpublic : false })



	}

	async getSkillsList(){	
		
		this.setState({ skillsList :  _.orderBy(skillsList, 'name', 'asc') })
	}

	updateJPC=(jpc)=>{

		this.setState({jpc:jpc})
	}

	profileCompleteness(data){
	
		let percentage=0;
	
		if(!_.isEmpty( data.profile ) && !_.isEmpty( data.profile.avatar ) )
			percentage+=5
		if( !_.isEmpty( data.profile ) &&  !_.isEmpty( data.profile.title ) )
			percentage+=5
		if( !_.isEmpty( data.profile ) &&  !_.isEmpty( data.profile.overview ) )
			percentage+=5
		if( this.state.hourlyRate > 0 )
			percentage+=5
		if( this.state.hourlyRate > 0 )
			percentage+=5

		//skills

		if( data.preference === 'jobs' && !_.isEmpty( data.skills ) && data.skills.length>0)
			percentage+=25

		//educations
		if( data.preference === 'jobs' && !_.isEmpty( data.educations ) && data.educations.length>0)
			percentage+=25

		//employment
	
		//projects
		if( data.preference === 'jobs' && !_.isEmpty( data.projects ) && data.projects.length>0)
			percentage+=25

		this.setState({profilecompleteness:percentage})


	}

	async saveProfile() {

		var state = this;
		var profile = { isSaving:true, jobcategories: this.state.jobCategoriesSelected, expertise: this.state.expertise, paypalme: this.state.paypalme, avatar: this.state.avatar, title: this.state.title, overview: this.state.overview, hourlyRate: Number(this.state.hourlyRate), fullTimeRate: Number(this.state.fullTimeRate) };

		const user = this.props.user

		state.props.setLoading(true);

		const userRef = this.props.doc(this.props.db, "users", user.uid);
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
		 
		  	var data = userSnap.data();
			data.profile = profile;
			data.skills = state.state.skillsData

			if(state.state.projectTitle!=="")
			{
					if(_.has(state.state.projectsData))
						state.setState({projectsData: data.projects})

					let project = {
						title: state.state.projectTitle,
						description: state.state.projectDescription,
						link: state.state.projectLink,
						started: state.state.projectStarted,
						ended: state.state.projectEnded,
						projectfiles: state.state.projectfiles
					}

					if(state.state.isEditProject === false){
						state.state.projectsData.push(project)
					}else{
						state.state.projectsData[state.state.isEditProjectIndex] = project
					}


			}

			if(state.state.school!==""&&state.state.confirmDeleteEducationIndex===null){

				
				let school = {
					school: state.state.school,
					schoolCourse: state.state.schoolCourse,
					schoolDescription: state.state.schoolDescription,
					schoolStarted: state.state.schoolStarted,
					schoolEnded: state.state.schoolEnded,
					schoolEndedPresent: state.state.schoolEndedPresent
				}

				if(state.state.isEditEducation === false){
					state.state.educationData.push(school)
				}else{
					state.state.educationData[state.state.isEditEducationIndex] = school
				}

			}

			if(state.state.company!=='')
			{

				let job = {
					company: state.state.company,
					jobTitle: state.state.jobTitle,
					jobDescription: state.state.jobDescription,
					jobStarted: state.state.jobStarted,
					jobEnded: state.state.jobEnded,
					jobEndedPresent: state.state.jobEndedPresent
				}

				if(state.state.isEditEmployment === false){
					state.state.employmentData.push(job)
				}else{
					state.state.employmentData[state.state.isEditEmploymentIndex] = job
				}
			}

			data.educations = state.state.educationData
			data.employment = state.state.employmentData
			data.projects = state.state.projectsData
			data.fname = state.state.fname
			data.lname = state.state.lname
			data.country = !_.isEmpty(state.state.country) ? state.state.country : ''

			if(state.state.profileUpdated === true)
				data.status = "PENDING"

			state.profileCompleteness(data)
			
			state.props.setDoc(userRef, data )
			.then( () => {

				state.setState({
					school: '',
					schoolCourse: '',
					schoolDescription: '',
					schoolStarted: '',
					schoolEnded: '',
					educationDialog: false,
					employmentDialog: false,
					snackbar: true,
					snackbarMessage: 'Your '+state.state.currentTab+' was updated',
					confirmDeleteDialog:false,
					confirmDeleteEmploymentDialog:false,
					company: '',
					jobTitle: '',
					jobDescription: '',
					jobStarted: '',
					jobEnded: '',
					projectTitle: '',
					projectDescription: '',
					projectLink: '',
					projectStarted: '',
					projectEnded: '',
					projectfiles: [],
					portfolioDialog: false,
					confirmDeleteEducationIndex: null,
					profileTitleError: false,
					profileOverviewError: false,
					profileHourlyRateError: false,
					profileFullTimeRateError: false,
					schoolError: false,
					schoolCourseError: false,
					schoolStartedError: false,
					schoolEndedError: false,
					isAvatarUpload: false,
					isSaving: false,
					isProjectAvatar: false
				})

				state.props.setLoading(false)

			});
		} 
		
		
	}

	portfolioDialogClose = () => {
		this.setState({portfolioDialog: false})
	}

	employmentDialogClose = () => {
		this.setState({employmentDialog: false})
	}

	educationDialogClose = () => {
		this.setState({educationDialog: false})
	}
	skillsDialogClose =() => {
		this.setState({skillsDialog: false});
	}

	saveSkill(){

	}

	
	handleAddSkill = (skill) => {

		let array = this.state.skillsData
	
		if(array.length<skillsCount){
			
			if( _.includes( array, skill ) ){
				this.setState({snackbar:true, snackbarMessage: skill+" is already selected on your skills set."})
			}else{
				array.push(skill);
				this.setState({skillsData:array});
			}
		}else{
			this.setState({skillsDialog:true})
		}

			
	}

	snackbarClose = () => {
		this.setState({snackbar: false})
	}

	handleDelete = (data) => {
	  var array = this.state.skillsData;
	  var index = array.indexOf(data); // Let's say it's Bob.

	  if (index !== -1) {   
	    array.splice(index, 1);
	    this.setState({skillsData:array});
	
	  }
	}

	putStorageItem(item) {
	  // the return value will be a Promise
	  let that = this
	  return this.props.firebase.storage().ref("/uassets/"+this.props.user.uid+"/"+item.name).put(item)
	  .then((snapshot) => {
		
		snapshot.ref.getDownloadURL().then(function(downloadURL) {
			let projectScreenshotsURL = that.state.projectScreenshotsURL
			projectScreenshotsURL.push(downloadURL);
			that.setState({projectScreenshotsURL: projectScreenshotsURL})
		  });
	  
	  }).catch((error) => {
	    console.log('error:', error.message)
	  });
	}

	uploadFile(files){
		let that = this
	    this.setState({projectScreenshots:files})

	    Promise.all(

	    	files.map(item => that.putStorageItem(item) 	)

	    	)
	    	.then( (url) => {
	    	} )
	    	.catch( (error) => {
	    		console.log('Error: ', error.message )
	    	} )
	}

	moreEducationMenu = event => {
		this.setState({ anchorEl: event.currentTarget })
	}

	editProject(item, i){

		this.setState({
			projectTitle: item.title,
			projectTitleError: false,
			projectDescription: item.description,
			projectLink: item.link,
			projectDescriptionError: false,
			projectStarted: item.started,
			projectStartedError: false,
			projectEnded: item.ended,
			projectEndedError: false,
			projectfiles: item.projectfiles,
			isEditProject: true,
			portfolioDialog: true,
			isEditProjectIndex: i,
			projectAvatar: item.projectfiles[0] ? item.projectfiles[0] : ''
		})
	}

	editEmployment(item, i){
		this.setState({
			company: item.company,
			jobTitle: item.jobTitle,
			jobDescription: item.jobDescription,
			jobStarted: item.jobStarted,
			jobEnded: item.jobEnded,
			isEditEmployment: true,
			employmentDialog: true,
			isEditEmploymentIndex: i,
			jobEndedPresent: _.has(item,'jobEndedPresent') ? item.jobEndedPresent : false,
			jobEndedDisabled: _.has(item,'jobEndedPresent') ? item.jobEndedPresent : false
		})
	}
	
	editEducation( item, i){

		this.setState({
			school: item.school,
			schoolCourse: item.schoolCourse,
			schoolDescription: item.schoolDescription,
			schoolStarted: item.schoolStarted,
			schoolEnded: item.schoolEnded,
			educationDialog: true,
			isEditEducation: true,
			isEditEducationIndex: i,
			schoolEndedPresent: _.has(item,'schoolEndedPresent') ? item.schoolEndedPresent : false,
			schoolEndedDisabled: _.has(item,'schoolEndedPresent') ? item.schoolEndedPresent : false
		})
	}

	deleteProject(item,i){
		this.setState({ confirmDeleteProjectDialog: true, confirmDeleteProjectIndex: i });
	
	}

	deleteEmployment(item,i){

		this.setState({ confirmDeleteEmploymentDialog: true, confirmDeleteEmploymentIndex: i });
	}

	deleteEducation(item,i){

		this.setState({
			school: item.school,
			schoolCourse: item.schoolCourse,
			schoolDescription: item.schoolDescription,
			schoolStarted: item.schoolStarted,
			schoolEnded: item.schoolEnded,
			confirmDeleteEducationIndex: i,
			confirmDeleteDialog: true
		})

	}

	confirmDeleteProject(){
		let projects = this.state.projectsData
		projects.splice(this.state.confirmDeleteProjectIndex, 1)

		let that = this

		this.setState({projectsData:projects, confirmDeleteProjectDialog: false}, function(){
			that.saveProfile()	
		})

	}

	confirmDeleteEmployment()
	{
		let employments = this.state.employmentData
		employments.splice(this.state.confirmDeleteEmploymentIndex,1)
		
		let that = this
		this.setState({employmentData:employments}, function(){
			that.saveProfile()	
		})
	}

	confirmDeleteEducation(){
		let educations = this.state.educationData
		educations.splice(this.state.confirmDeleteEducationIndex,1)
		
		let that = this
		this.setState({educationData:educations, confirmDeleteDialog : false}, function(){
			that.saveProfile()	
		})
		

	}

	profileEducation(){
		let error = 0
		//validate school
		if(this.state.school===''){
			this.setState({schoolError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({schoolError:false})
		}

		if(this.state.schoolCourse===''){
			this.setState({schoolCourseError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({schoolCourseError:false})
		}

		if(this.state.schoolStarted===''){
			this.setState({schoolStartedError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({schoolStartedError:false})
		}

		if( this.state.schoolEndedPresent === false && this.state.schoolEnded===''){
			this.setState({schoolEndedError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({schoolEndedError:false})
		}

		if(error===0){
			this.saveProfile()
		}
	}

	profileEmployment(){

		let error = 0

		// validate employment

		if(this.state.company===''){
			this.setState({companyError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({companyError:false})
		}

		if(this.state.jobTitle===''){
			this.setState({jobTitleError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({jobTitleError:false})
		}

		
		if(this.state.jobStarted===''){
			this.setState({jobStartedError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({jobStartedError:false})
		}

		if(this.state.jobEndedPresent === false && this.state.jobEnded===''){
			this.setState({jobEndedError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({jobEndedError:false})
		}

		if(error===0)
			this.saveProfile()

	}
	async profileBasic(){
		
		
		let error = 0;

		if(this.state.title===''){
			this.setState({profileTitleError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({profileTitleError:false})
		}

		if(this.state.expertise===''){
			this.setState({expertiseError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({expertiseError:false})
		}

		if(this.state.overview===''){
			this.setState({profileOverviewError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({profileOverviewError:false})
		}

		if(this.state.hourlyRate===''){
			this.setState({profileHourlyRateError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({profileHourlyRateError:false})
		}

		if(this.state.fullTimeRate===''){
			this.setState({profileFullTimeRateError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({profileFullTimeRateError:false})
		}


		if(error>0)
		{
			this.setState({snackbar:true, snackbarMessage:"Check error before saving."})
			return
		}

		if(this.state.isAvatarUpload===false)
		{
			this.setState({saveWarning:false})
			this.saveProfile();
			return 0
		}

		let files = this.pond2.getFiles();
		let that = this

		if(files.length===0&&error===0)
		{
			this.setState({ isSaving: false, saveWarning:false })
			this.saveProfile();
			return 0
		}


		
		const storageRef = this.props.storageRef(this.props.storage, 'uassets/'+that.props.user.uid+"/"); 

		let avatarUpload = []
		 files.map( async file => {
			
			const fileRef = that.props.storageRef(storageRef, Math.random().toString(36).substring(7)+file.file.name);

			try {
		      await that.props.uploadBytes(fileRef, file.file);
		      const downloadURL = await that.props.getDownloadURL(fileRef);

		      that.setState({ isSaving: false, avatar : downloadURL, saveWarning:false }, ()=>{
		      	that.saveProfile();
		      } )
			  
		    } catch (error) {
		      console.error(`Error uploading "${file.file.name}":`, error);
		    }

		 } )

	}

	
	async addProject()
	{
		this.props.setLoading(true)
		let error = 0
		
		if(this.state.projectTitle===''){
			this.setState({projectTitleError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({projectTitleError:false})
		}

		if(this.state.projectDescription===''){
			this.setState({projectDescriptionError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({projectDescriptionError:false})
		}

		if(this.state.projectStarted===''){
			this.setState({projectStartedError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({projectStartedError:false})
		}

		if(this.state.projectEnded===''){
			this.setState({projectEndedError:true})
			this.props.setLoading(false);
			error++
		}else{
			this.setState({projectEndedError:false})
		}

		if(error>0)
			return
		

		let that = this
		let uploadFiles = []

		this.setState({
			isSaving: true
		})

		if( this.state.isSaving === true )
			return

		let files = []

		if(this.pond)
			files= this.pond.getFiles();

		if(files.length===0)
		{
			that.setState({ isSaving: false })
			that.saveProfile();
			return 0
		}

		
		let fileRefs = []
		const uploadPromises = [];
		const storageRef = this.props.storageRef(this.props.storage, 'uassets/'+that.props.user.uid+"/"); 

		files.map( async file => {
			
			const fileRef = that.props.storageRef(storageRef, Math.random().toString(36).substring(7)+file.file.name);

			try {
		      const uploadTask = that.props.uploadBytes(fileRef, file.file);
		      fileRefs.push(fileRef)
		      uploadPromises.push(uploadTask);
			  
		    } catch (error) {
		      console.error(`Error uploading "${file.file.name}":`, error);
		    }

		} )


		Promise.all(uploadPromises).then( ()=>{
			 
			 let files_ = []
			 let fileCount = 0
			 fileRefs.map(async fr=>{
			 	 const downloadURL = await that.props.getDownloadURL(fr);
			 	 files_.push(downloadURL);

			 	 fileCount++;

			 	 if(fileCount==fileRefs.length)
			 	 {
			 	 	  that.setState({projectfiles:files_},()=>{
							that.saveProfile();
					  })
			 	 }

			 })
		} )

		
	}

	async generateConfirmationCode(){

  		let that = this
  		var min = 10000;
		var max = 99999;
		var num = Math.floor(Math.random() * (max - min + 1)) + min;

		if(this.state.releaseConfirmationNumber===0){
			this.setState({releaseConfirmationNumber: num, withdraw_next: 3})
		}

		let message = MESSAGES.SEND_CONFIRMATION_CODE_WITHDRAWAL
		message = message.replace("{code}", num )
		message = message.replace("{amount}", that.state.withdrawAmount )
		
	
		fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
		{
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    method: "POST",
		    body: JSON.stringify({ title: "Release Withdrawal Confirmation Code", name: that.props.user.fname+" "+that.props.user.lname, to: that.props.user.email, message: message })
		})
		.then(function(res){ return res.json() })
		.then(function(data){
		})
		.catch(function(res){ 
			console.log('error message',res)
		})

    }

    computePaypalfee( amount )
    {

	   let fixed = amount > 0 ? 0.49 : 0
	   let newAmount = Number(amount)*Number(this.state.fee)

	   newAmount = Number(newAmount)+Number(amount)
	   newAmount = newAmount*this.state.paypal_percentage
	   
	   if(newAmount>0)
	    	newAmount = newAmount+fixed

	  

	   return newAmount
 
 	}

	

	computeFreelancertogofee( amount ){
   		 return Number( Number(amount * this.state.fee).toFixed(2) );
	}

	async submitProfile() {

		let that = this
		this.props.setLoading(true)

		const userRef = this.props.doc(this.props.db, "users", this.props.user.uid);
		const userSnap = await this.props.getDoc(userRef);

		if (userSnap.exists())
		{
		  let profile = userSnap.data()

		  if( _.has(profile,'profile') && _.has(profile,'skills') && profile.skills.length > 0 && profile.profile.avatar !== '' ){

		  	this.props.updateDoc(userRef, {
		        status: "PENDING"
		    }).then( function(){
		        that.setState({ hasSubmitProfile:1, snackbar: true, snackbarMessage:"We received your request to review your profile. We will let you know the status very soon."})
				that.getAdmin(profile)
				that.props.setLoading(false)
		    });

			
		  }else if( !_.has(profile,'profile') ){
				this.setState({snackbar: true, snackbarMessage:"Please complete your profile before submitting for review"})
				that.props.setLoading(false)
		  }else if( !_.has(profile,'skills') || (_.has(profile,'skills') && profile.skills.length == 0 ) ){
				this.setState({snackbar: true, snackbarMessage:"Please add your skills before submitting for review"})
				that.props.setLoading(false)
		  }else if( _.has(profile,'profile') && profile.profile.avatar === '' ){
				this.setState({snackbar: true, snackbarMessage:"Please upload a profile picture before submitting for review"})
				that.props.setLoading(false)
		  }

		}else{
			this.props.setLoading(false)
		}

		

	}

	async deleteMyAccount()
	{
		
		let that = this
		let uid = this.props.user.uid;
		that.props.setLoading(true);

		if(that.state.deletemyaccount=="delete")
		{
			
			this.props.deleteUser(this.props.auth.currentUser).then( async () =>
			{
				await that.props.deleteDoc( that.props.doc( that.props.db, "users", uid));
				localStorage.clear();
				that.props.setLoading(false);
				window.location = '/';
			}).catch((error) => {
			  console.log("error delete account",error)
			});

		}else{
			that.props.setLoading(false);
			this.setState({snackbar:true, snackbarMessage:"To proceed with the deletion, simply type 'delete' in the text field and press the 'Delete My Account' button."})
		
		}
		
	}
	
	submitVerification(){

		this.props.setLoading(true);
		let id = this.pond3.getFiles();
		let selfie = this.pond4.getFiles();
		let uploads = [];
		let verificationimages = []
		let that = this
		 let i = 0;

		id.map(file => { if(file.fileSize<1000000){uploads.push(file.file)} } )
		selfie.map(file => { if(file.fileSize<1000000){uploads.push(file.file)} } )

		if(uploads.length<2)
		{
			this.setState({snackbar:true, snackbarMessage:'Please upload required images.'})
			this.props.setLoading(false);
			return
		}

	
		let fileRefs = []
		const uploadPromises = [];
		const storageRef = this.props.storageRef(this.props.storage, 'uassets/'+that.props.user.uid+"/");

		uploads.map( async file => {
      
	      const fileRef = that.props.storageRef(storageRef, Math.random().toString(36).substring(7)+file.name);

	      try {
	      	 
	          const uploadTask = that.props.uploadBytes(fileRef, file );
	          fileRefs.push(fileRef)
	          uploadPromises.push(uploadTask);
	        
	        } catch (error) {
	          console.error(`Error uploading:`, error);
	        }

	    } )

	     Promise.all(uploadPromises).then( ()=>{
       
		       let files_ = []
		       let fileCount = 0

		       const userRef = this.props.doc(this.props.db, "users", this.props.user.uid);

		       fileRefs.map(async fr=>{
		         const downloadURL = await that.props.getDownloadURL(fr);
		         files_.push(downloadURL);

		         fileCount++;

		         if(fileCount==fileRefs.length)
		         {
		             

		             that.props.updateDoc(userRef, {
					        verification_1: files_[0],
					        verification_2: files_[1],
					        verification_review: "PENDING"
					 });

		             that.props.setLoading(false);
					 that.setState({snackbar:true,snackbarMessage:'Thank you! We received your request to verify your account. We will send an email when the verification is complete.',identityVerifiedDialog:false})
					
					 let profile = that.props.user
					 that.getAdminNotifyVerification(profile)

		         }

		       })
	    } )

		
	}

	async getAdminNotifyVerification(profile){

		let that = this;

		let message = MESSAGES.NEW_IDENTITY_VERIFICATION
		message = message.replace("{name}", profile.fname+" "+profile.lname )
		message = message.replace("{email}", profile.email)
		message = message.replace("{date}", new Date())
		
		fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
		{
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    method: "POST",
		    body: JSON.stringify({ title: "Identity Verification Request", name: that.props.adminName , to: that.props.adminEmail, message: message })
		})
		.then(function(res){
			 return res.json() })
		.then(function(data)
		{
		
		})
		.catch(function(res)
		{ 
			
		})

	}

	async checkExistingWithdrawalRequest(){

		let that = this
	
    	const q = this.props.query(this.props.collection( this.props.db, "withdrawal_request"), this.props.where("user.uid", "==", this.props.user.uid), this.props.where('status','==','PENDING'),this.props.orderBy('created_at','desc'));

		const querySnapshot = await this.props.getDocs(q);
		querySnapshot.forEach((doc) => {
		  
		  	let data = doc.data();
			this.setState({pendingWithdrawal: data.withdrawAmount, pendingWithdrawalDate: data.created_at})
		  	
		});
	}

	async verifyConfirmation()
	{

	  	this.props.setLoading(true)
	  	let balance = 0;
		let last_transaction_id = ''
		let that = this

	  	let inputConfirmation = this.state.c1+""+this.state.c2+""+this.state.c3+""+this.state.c4+""+this.state.c5
	 
	  	if( Number(inputConfirmation) === this.state.releaseConfirmationNumber )
	  	{
	  		
	  		const docRef = await this.props.addDoc(this.props.collection( this.props.db, "withdrawal_request"),
	  			{
	  				status: "PENDING",
	  				user: that.props.user,
	  				withdrawAmount:that.state.withdrawAmount,
	  				withdraw_selection: that.state.withdraw_selection,
	  				withdraw_selection_fee : Number( that.computePaypalfee(that.state.withdrawAmount) ) + Number(that.computeFreelancertogofee(that.state.withdrawAmount)),
	  				created_at : new Date()
	  			}
	  		).then(function(){
	  			that.props.setLoading(false)
				that.setState({withdraw_next: 4})
				that.checkExistingWithdrawalRequest()
	  		});

	  	}else
	  	{
	  		that.props.setLoading(false)
	  		this.setState({confirmationMatch: false})
	  	}
    }


	async getAdmin(profile)
	{

		let that = this;

		let message = MESSAGES.NEW_PROFILE_REVIEW
		message = message.replace("{name}", profile.fname+" "+profile.lname )
		message = message.replace("{email}", profile.email)
		message = message.replace("{date}", new Date())
		
		fetch(FIREBASECONFIG.clfunc+"/sendStatusChangeMessage",
		{
		    headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json'
		    },
		    method: "POST",
		    body: JSON.stringify({ title: "Profile Approval Request", name: that.props.adminName, to: that.props.adminEmail, message: message })
		})
		.then(function(res){
			 return res.json() })
		.then(function(data)
		{
		})
		.catch(function(res)
		{ 
		})

	}
	
	onTagsChange = (event, values) => {
    this.setState({
      country: values
    })
    	}

	
	render()
	{

		if (typeof window !== 'undefined')
		{

			let selectSkills = [{value: ''}];
			const publicURL = '/freelancer/'+this.props.user.uid

			let curr_date = new Date(); // current date
		    let day_info = 8.64e+7; // milliseconds per day
		    let days_to_monday = 8 - curr_date.getDay(); // days left to closest Monday
		    let monday_in_sec = curr_date.getTime() + days_to_monday * day_info; // aleary Monday in seconds from 1970 
		    let next_monday = new Date(monday_in_sec); // Monday in date object
		    next_monday.setHours(0,0,0);
	  
			if( this.state.skillsList[0] ){
				
				for(var i = 0 ; i<this.state.skillsList.length; i++){
					if( _.has(this.state.skillsList[i],'name') )
					selectSkills.push({value: this.state.skillsList[i].name })
				}	

				selectSkills = _.uniqWith(selectSkills, _.isEqual);
			
			}

			let renderEducation
			let renderEmployment
			let renderProjects
			let that = this

			if( this.state.projectsData ){
				renderProjects = this.state.projectsData.map(function(item, i){

					return (

						<div key={i}>
		              		<Typography style={styless.title}>{item.title}</Typography>
		              		
		              		
		              		<Typography>
			              			{item.description }
			              	</Typography>

			              	<Typography>
						        <Moment style={{fontFamily:'Gotham Rounded'}} format="MM/DD/YYYY">{ item.started }</Moment> - <Moment style={{fontFamily:'Gotham Rounded'}} format="MM/DD/YYYY">{ item.ended }</Moment>
						    </Typography>

						    <br/>
						    <Grid>
						    	<Grid container >

						    { item.projectfiles.map(function(item, j){
						    	return ( <Grid item key={j} md={12} sm={12} xs={12} ><div className="portfolio-thumbnail" style={{backgroundImage: `url(${item})`}}></div> <br/></Grid>
								)
						    		
						    })

							}
								</Grid >
							</Grid>
						    
						    <br/>

						    <IconButton aria-label="Edit" onClick={()=>that.editProject(item,i)} size="small">
					          <EditIcon fontSize="inherit" />
					        </IconButton>
					        <IconButton onClick={()=>that.deleteProject(item,i)} size="small">
					          <DeleteIcon fontSize="inherit" />
					        </IconButton>

					        <br/><br/>
					        <hr />

		              		
		              	</div>

					)

				})
			}

			if( this.state.employmentData ){

				 renderEmployment = this.state.employmentData.map(function(item, i){

	              return ( 

	              	<div key={i}>
	              		<Typography style={styless.title}>{item.jobTitle}</Typography>
	              		<Typography style={styless.subtitle}>{item.company}</Typography>
	              	
	              		
	              		<Typography>
		              			{item.jobDescription }
		              	</Typography>

		              	<Typography style={styless.em} color="textSecondary">
					        <Moment style={{fontFamily:'Gotham Rounded'}} format="MM/DD/YYYY">{ item.jobStarted }</Moment> - { _.has(item,'jobEndedPresent') && item.jobEndedPresent === true ? "Present" : <Moment format="MM/DD/YYYY">{ item.jobEnded }</Moment>}	
					    </Typography>

					    <br/>

					    <IconButton aria-label="Edit" onClick={()=>that.editEmployment(item,i)}>
				          <EditIcon fontSize="inherit" />
				        </IconButton>
				        <IconButton onClick={()=>that.deleteEmployment(item,i)}>
				          <DeleteIcon />
				        </IconButton>

				        <br/><br/>
				        <hr />

	              		
	              	</div>
	              	
	              	)
	            })

			}

			if( this.state.educationData ){

				 renderEducation = this.state.educationData.map(function(item, i){

	              return ( 
	              	<div key={i}>
	              		<Typography style={styless.title}>{item.school}</Typography>
	              		<Typography style={styless.subtitle}>{item.schoolCourse}</Typography>
	              	
	              		
	              		<Typography style={styless.em}>
		              			{item.schoolDescription }
		              	</Typography>

		              	<Typography style={styless.em} color="textSecondary">
					          <Moment style={{fontFamily:'Gotham Rounded'}} format="MM/DD/YYYY">{ item.schoolStarted }</Moment> - { _.has(item,'schoolEndedPresent') && item.schoolEndedPresent === true ? "Present" : <Moment format="MM/DD/YYYY">{ item.schoolEnded }</Moment>}
					    </Typography>

					    <br/>

					    <IconButton aria-label="Edit" onClick={()=>that.editEducation(item,i)} >
				          <EditIcon fontSize="inherit"/>
				        </IconButton>
				        <IconButton onClick={()=>that.deleteEducation(item,i)} >
				          <DeleteIcon />
				        </IconButton>

				        <br/><br/>
				        <hr />

	              		
	              	</div>
	              	)
	            })

			}

		
			return (

				<div>
				
				<header>
		          { this.state.isLoaded === true &&
			          <div style={{marginTop: 70}}>
				          <Grid container spacing={2} >
				          		<Grid item xs={0} md={3}>
				          		</Grid>
				          		<Grid item xs={12} md={6}>

				          		 <h1 className="App-title">My Profile</h1>
				          		 <br/>
				          		 <ThemeProvider theme={greenTheme}>
				          		 	<LinearProgress variant="determinate" value={this.state.profilecompleteness} />
				          		 </ThemeProvider>
				          		 <br/>
				          		 <Grid>
				          		 	<Grid container >
				          		 		<Grid item xs={12} md={6}>
				          		 			<Typography>Your Profile is {this.state.profilecompleteness}% Complete</Typography>
				          		 			
				          		 		</Grid>
				          		 		<Grid item xs={12} md={6}>
				          		 			
				          		 			<TextField
						          				name='view-profile'
											    variant="outlined"
										      	InputProps={{
										          endAdornment: <InputAdornment position="end">
										          	<Tooltip title="Copy">
											          	<IconButton
											          		edge="end"
											          		aria-label="Copy Public Profile Url"
											          		onClick={ () => {

											          			
											          			navigator.clipboard.writeText(window.location.hostname+publicURL)
											          			that.setState({snackbar:true, snackbarMessage:'Your public link was copied to clipboard'})
											          		} }
											          	>
											          		<FileCopyIcon />
											          	</IconButton>
										          	</Tooltip>
										          	<Tooltip title="Public link">
											          	<IconButton
											          		edge="end"
											          		aria-label="Go to Public Profile"
											          		onClick={ () => { 
											          			
											          			window.open(publicURL, "_blank") 
											          		} }
											          	>
											          		<ArrowForwardIcon />
											          	</IconButton>
										          	</Tooltip>
										          	{/*<Tooltip title="Premium profile">
											          	<IconButton
											          		edge="end"
											          		onClick={ () => { 
											          			
											          			window.open("/premium-profile", "_blank") 
											          		} }
											          	>
											          		<StarIcon />
											          	</IconButton>
										          	</Tooltip>*/}
										          </InputAdornment>,
										        }}
										      	margin="normal" disabled={ that.state.profilecompleteness < 100 ? true : false  } value={window.location.hostname+publicURL} fullWidth={true}  />

				          		 		</Grid>
				          		 	</Grid >
				          		 	{ this.state.isLoaded == true && this.state.profileStatus !== "APPROVED" &&  this.state.hasSubmitProfile === 0 &&
				          		 			
					          		 	<Grid container >
					          		 		<Grid item xs={12} md={12}>
					          		 			<br/>
					          		 			<Alert severity="warning">
					          		 				<Grid container >
					          		 					<Grid item xs={8} md={8}>
					          		 						Your profile is not yet active. Complete your profile and submit it for review. If your profile is accepted, you'll receive 50 credits. 
					          		 					</Grid>
					          		 					<Grid item xs={4} md={4}>
					          		 						<Button style={styless.buttonDefault} variant="outlined" onClick={ ()=>{ this.submitProfile() } }>Submit Profile</Button>
					          		 					</Grid>
					          		 				</Grid >
					          		 			</Alert>
						          		  	
					          		 		</Grid>
					          		 	</Grid >

				          		 	}
				          		 </Grid>
				          		 
				          		 <br/><br/><br/><br/>

				          		 { this.props.isLoggedIn === true &&
						                    <Tabs TabIndicatorProps={{ style: { backgroundColor: "#00b16a"  }}} value={this.state.submenu} onChange={this.handleChange} variant="scrollable" indicatorColor="primary" textColor="primary">
						                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Profile" />
						                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Skills" />
						                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Education" />
						                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Employment" />
						                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Portfolio" />
						                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Get Paid" />
						                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Earnings" />
						                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Credits" />
						                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Perks" />
						                        <Tab style={{color:'#8e8e8e',fontFamily: 'Gotham Rounded'}} label="Settings" />
						                        
						                    </Tabs>
						         }

						         <div className="App-panel">
				          		 { this.state.submenu === 0 &&
				          			<form onSubmit={ this.saveProfile }>
				          		
						          		<Grid container >
						          			<Grid item xs={12} md={12}>

						          				<Typography style={styless.em}>Your Avatar</Typography>
						        				<br/>
						        				{ this.state.isAvatarUpload === false &&
							        				<div style={{alignItems:'center', justifyContent:'center', display:'flex'}} >
							        				{
									          			this.state.avatar ? <Avatar onClick={()=>this.setState({isAvatarUpload:true})} alt={this.state.title} src={this.state.avatar} style={styless.bigAvatar} /> : <Avatar onClick={()=>this.setState({isAvatarUpload:true})} alt={this.state.title} src={ROUTES.AVATAR} style={styless.bigAvatar} />
									          		}
									          		</div>
								          		}
								          		{ this.state.isAvatarUpload &&
							        				<FilePond 
							        					allowMultiple={false}
							        					maxFiles={1}
							        					maxFileSize="1MB"
							        					acceptedFileTypes={"image/*"}
							        					ref = { ref2 => this.pond2 = ref2 } >
							        				</FilePond>
						        				}
						        				<br/>

						        				<div style={{textAlign:'center'}}>

						        					 <Tooltip title={this.state.activeAccount ==="APPROVED" ? "Profile is Active" : "Your profile is not active. Earn this badge when you complete your profile and submit for us to review." }>
												        <IconButton style={{cursor:'initial'}} onClick={()=>{} } size="medium" color="primary">
												          { this.state.activeAccount !== "APPROVED" &&
												          		<AssignmentIndIcon fontSize="small" style={{color:'#333333'}} />
												          }
												          { this.state.activeAccount === "APPROVED" &&
												          		<AssignmentIndIcon fontSize="small" style={{color:'#00b16a'}} />
												          }
												        </IconButton>
											        </Tooltip>

						        					
								        				
								        				{ this.state.identityVerified !=="APPROVED" && 
								        					<Tooltip title="Your identity is not verified. Earn this badge when you verify your identity with us.">
										        				<IconButton onClick={()=>{ this.setState({identityVerifiedDialog:true}) } } color="primary">
														         <VerifiedUserIcon fontSize="small" style={{color:'#333333'}} />
														        </IconButton>
														     </Tooltip>
												    	}

												    	{ this.state.identityVerified ==="APPROVED" && 
												    		<Tooltip title="Identity Verified">
										        				<IconButton color="primary" style={{cursor:'initial'}}>
														          <VerifiedUserIcon fontSize="small" style={{color:'#00b16a'}} />
														        </IconButton>
													         </Tooltip>
												    	}
												   
												   
										        </div>

						        				<br/>

						        				

						          				<Typography style={styless.small}>First Name</Typography>
						          				<TextField
						          					error={this.state.fnameError}
						          					name='title'
											      	variant="outlined"
											      	margin="normal" value={this.state.fname} fullWidth={true} onChange={ (event) => this.setState({fname: event.target.value }) } />
						          				<br/><br/>

						          				<Typography style={styless.small}>Last Name</Typography>
						          				<TextField
						          					error={this.state.lnameError}
						          					name='title'
											      	variant="outlined"
											      	margin="normal" value={this.state.lname} fullWidth={true} onChange={ (event) => this.setState({lname: event.target.value }) } />
						          				<br/><br/>

						          				 <Typography style={styless.small}>Country</Typography>
								          				
						          				  <Autocomplete
						          				  		defaultValue={this.state.country}
						          				  	    onChange={this.onTagsChange}
						          				  	    options={this.props.countries.map((option) => _.has(option,'name') ? option.name : '')}
												        renderInput={(params) => (
												          <TextField style={{width:'100%'}} {...params} value={this.state.country} label={this.state.country} margin="normal" variant="outlined" />
												        )}
												      />
								          		<br/>

						          				<Typography style={styless.small}>Your Job Title</Typography>
						          				<TextField
						          					error={this.state.profileTitleError}
						          					name='title'
											      	variant="outlined"
											      	margin="normal" value={this.state.title} fullWidth={true} onChange={ (event) => this.setState({title: event.target.value }) } />
						          				<br/><br/>
						          			</Grid>
						          		</Grid >


						          		<Grid container >
						          			<Grid item xs={12} md={12}>
						          					<Typography style={styless.small}>Overview</Typography>
						          					<TextField
						          					multiline
						          					row="5"
						          					error={this.state.profileOverviewError}
						          					name='overview'
											      	variant="outlined"
											      	margin="normal" value={this.state.overview} fullWidth={true} onChange={ (event) => this.setState({overview: event.target.value }) } />
						          					<br/><br/>
						          			</Grid>
						          		</Grid >
						          		<Grid container >
						          			<Grid item xs={12} md={12}>
					          					<Typography style={styless.small}>Job Category</Typography>
					      
												<Autocomplete
				          						  multiple
				          						  value={this.state.jobCategoriesSelected}
												  id="combo-box-demo"
												  onChange={(event,value)=>{

												  	if(value.length<=3)
												  		that.setState({jobCategoriesSelected: value})
												  	else
												  		that.setState({snackbar:true, snackbarMessage:"You can only select 3 job categories."})
												  }}
												  options={this.state.jobCategories}
												  disableCloseOnSelect
												  getOptionLabel={option => option}  
												  renderInput={params=>(
												  	<TextField {...params} style={{width:'100%'}} variant="outlined"  />
												  	)}
												  />
					          					<br/><br/>
						          			</Grid>

						          		</Grid >
						          		<Grid container >
						          			<Grid item xs={12} md={12}>
						          					<Typography style={styless.small}>Expertise</Typography>
						          					<FormControl variant="outlined" style={{width:'100%'}}>
													    <Select
								          				  	onChange={ (event) => this.setState({expertise: event.target.value }) }
															error = { this.state.expertiseError }
															value={this.state.expertise}
														    inputProps={{
														    name: 'expertise'
														    }} fullWidth={true}
														    input={<OutlinedInput />} >
														     >

														    <MenuItem value="entry">
														      <em>Entry</em>
														    </MenuItem>
														    <MenuItem value="intermediate">
														    	<em>Intermediate</em>
														    </MenuItem>
														    <MenuItem value="expert">
														    	<em>Expert</em>
														    </MenuItem>

													  </Select>
												  	</FormControl>
						          					<br/><br/>
						          			</Grid>
						          		</Grid >
						          		<Grid container >
						          			<Grid item xs={12} md={12}>
						          					<Typography style={styless.small}>Hourly Rate</Typography>
						          					<TextField
						          					error={ this.state.profileHourlyRateError }
						          					name='hourly-rate'
											      	variant="outlined"
											      	InputProps={{
											          startAdornment: <InputAdornment position="start">$</InputAdornment>,
											        }}
											      	margin="normal" value={this.state.hourlyRate} fullWidth={true} onChange={ (event) => { 
											      		const re = /^[0-9\b]+$/

											      		if (event.target.value === '' || re.test(event.target.value)) {
													       this.setState({hourlyRate: event.target.value, fullTimeRate: event.target.value * (40 * 4) })
													    }
											      		
											      	 } } />
						          					<br/><br/>
						          			</Grid>
						          		</Grid >
						          		<Grid container >
						          			<Grid item xs={12} md={12}>
						          					<Typography style={styless.small}>Full Time Rate</Typography>
						          					<TextField
						          					error={ this.state.profileFullTimeRateError }
						          					name='fulltime-rate'
											      	variant="outlined"
											      	InputProps={{
											          startAdornment: <InputAdornment position="start">$</InputAdornment>,
											        }}
											      	margin="normal" value={this.state.fullTimeRate} fullWidth={true} onChange={ (event) => { 
											      			
											      		const re = /^[0-9\b]+$/

											      		if (event.target.value === '' || re.test(event.target.value)) {
													       this.setState({fullTimeRate: event.target.value })
													    }

											      		} } />
						          					<br/><br/>
						          			</Grid>
						          		</Grid >
						          		<br/>
						         
						          		<Grid container >
						          			<Grid item xs={12} md={12}>
						          				<Button disabled={ this.state.isSaving === true ? true : false } style={styless.buttonPrimary} onClick={ () =>  {
						          					if(this.state.profileStatus==="APPROVED"){
						          						 this.setState({saveWarning:true, profileUpdated:true})
						          						}else{
						          							this.profileBasic()
						          						}
						          				} } variant='contained' color='primary' fullWidth={true}>
						          					 { this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : 'Save' }
						          				</Button>
						          			</Grid>
						          		</Grid >
						          		<br/>
				          		
				          			</form>
				          		 }

				          		 { this.state.submenu === 1 &&
				          		 	<div>
				          		 		
				          		 		<div style={{marginBottom: 20, display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>	
				          		 		{ this.state.skillsData.length > 0 &&
				          		 			this.state.skillsData.map( (data,i) => {
									          return (
									            <Chip
									              style={{margin: 5, fontFamily:'Gotham Rounded'}}
									              key={i}
									              label={data}
									              onDelete={ () => this.handleDelete(data)} /> );
									        	})
				          		 		}
				          		 		{
				          		 			this.state.skillsData.length===0 &&
				          		 			<center>
				          		 			 <img alt="skills" src="/skills.png" />		
				          		 			 <Typography>You haven`t set any skills yet.</Typography>
				          		 			</center>
				          		 		}
										</div>
										
				          		 		<Downshift
				          		 			onChange={selection => this.handleAddSkill(selection.value) }
		    								itemToString={item => (item ? '' : '')} >
										    {({
										      getInputProps,
										      getItemProps,
										      getLabelProps,
										      getMenuProps,
										      isOpen,
										      inputValue,
										      highlightedIndex,
										      selectedItem,
										    }) => (
										      <div>

										      	<Typography style={styless.small}>Search and select your skills</Typography>
										        <TextField name='title' variant="outlined" fullWidth={true} {...getInputProps()} />
										        <Paper {...getMenuProps()}>
										          {isOpen
										            ? selectSkills
										                .filter(item => inputValue && inputValue !== '' && item.value.toLowerCase().includes(inputValue.toLowerCase()))
										                .map((item, index) => (
										                  <MenuItem
										                    {...getItemProps({
										                      key: item.value,
										                      index,
										                      item,
										                      style: {
										                        backgroundColor:highlightedIndex === index ? 'lightgray' : 'white',
										                        fontWeight: selectedItem === item ? 'bold' : 'normal',
										                        fontFamily: 'Gotham Rounded'
										                      },
										                    })}
										                  >
										                    {item.value}
										                  </MenuItem>
										                ))
										            : null}
										        </Paper>
										      </div>
										    )}
										  </Downshift>

				          		 		<br/>
				          		 		<Typography style={styless.em}>You can add up to 10 Skills</Typography>
				          		 	    <br/>

				          		 	    <Button disabled={ this.props.isLoading === true ? true :false } style={styless.buttonPrimary} onClick={ () => this.saveProfile() } variant='contained' color='primary' fullWidth={true}> { this.props.isLoading === true ? <CircularProgress style={{color:"#fff"}} size={28} /> : 'Save' }</Button>
				          		  	
				          		  	</div>
				          		 }

				          		 { this.state.submenu === 2 &&
				          		 	<div>
				          		 		{ this.state.educationData && this.state.educationData.length === 0 &&
						          		 	<center>
						          		 		<img alt="education" src="/education.png" />
						          		 		<Typography>You haven`t set any education yet.</Typography>

						          		 	</center>
					          		    }

					          		    { this.state.educationData && renderEducation }

					          		    <br/>
					          		 	<center>
					          		 		<Button onClick={ () => this.setState({educationDialog:true, school: '', schoolCourse: '', schoolDescription: '', schoolStarted: '', schoolEnded: '', isEditEducation: false}) } variant='contained' color='primary' style={{backgroundColor:'#00b16a', fontFamily:'Gotham Rounded', padding: 10}}>Add Education</Button>
					          		 	</center>
					          		 	<br/><br/>
				          		 	</div>
				          		 }

				          		 { this.state.submenu === 3 &&
				          		 	<div>
				          		 		{ this.state.employmentData.length === 0 &&
				          		 			<center>
				          		 			 <img alt="employment" src="/employment.png" />		
				          		 			 <Typography>You haven`t set any employment yet.</Typography>
				          		 			</center>
						          		 
					          		    }

					          		    { this.state.employmentData.length > 0 && renderEmployment }

					          		    <br/>
					          		 	<center>
					          		 		<Button onClick={ () => this.setState({isEditEmployment:false, employmentDialog:true, company: '', jobTitle: '', jobDescription: '', schoolStarted: '', schoolEnded: ''}) } variant='contained' color='primary' style={{backgroundColor:'#00b16a', fontFamily:'Gotham Rounded', padding: 10}}>Add Employment</Button>
					          		 	</center>
					          		 	<br/><br/>
				          		 	</div>
				          		 }

				          		 { this.state.submenu === 4 &&
				          		 	<div>
				          		 		{ this.state.projectsData.length === 0 &&
				          		 			<center>
				          		 			 <img alt="projects" src="/projects.png" />		
				          		 			 <Typography>You haven`t added any project yet.</Typography>
						          		 	
				          		 			</center>
						          		 	
					          		    }

					          		    { this.state.projectsData.length > 0 && renderProjects }

					          		    <br/>
					          		    <center>
					          		 		<Button onClick={ () => this.setState({addProject_once:0, isEditProject: false, portfolioDialog:true, company: '', jobTitle: '', jobDescription: '', schoolStarted: '', schoolEnded: '', projectTitle: '', projectDescription: '', projectLink: '',projectStarted: '', projectEnded: '', projectfiles: []}) } variant='contained' color='primary' style={{backgroundColor:'#00b16a', fontFamily:'Gotham Rounded', padding: 10}}>Add Project</Button>
					          		 	</center>
					          		 	<br/><br/>

				          		 	</div>

				          		 }

				          		 { this.state.submenu === 5 &&
				          		 	<div>
				          		 		<Typography>Setup on how you will get paid on freelancertogo with our available payment methods.</Typography>
				          		 		<br/><br/>
				          		 		<Grid>
				          		 			<Grid container >
				          		 				<Grid item xs={12} md={12}>

				          		 				<div onClick={ ()=> this.setState({setupPaypalDialog:true}) }
										          style={{
										            width: "100%",
										            textAlign:'center'
										          }}
										          className="buttonbase"
										        >
										        	<img alt="paypalme" src="../paypalme.png" />
										        	<h2>PayPal.Me</h2>
										        </div>


				          		 				</Grid>
				          		 			</Grid >
				          		 		</Grid>
				          		 	</div>

				          		 }

				          		 { this.state.submenu === 6 &&
				          		 	<div>
				          		 		<center>
				          		 			<h1>${ Number( Number(that.state.currentBalance - that.state.pendingWithdrawal ).toFixed(2) ).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }</h1>
				          		 			<Typography>Available Balance</Typography>
				          		 		</center>
				          		 		<br/>

				          		 		{ this.state.pendingWithdrawal > 0 &&
				          		 		<Alert severity="success">
								          		You have a pending withdrawal of ${Number(this.state.pendingWithdrawal).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})} requested on {this.state.pendingWithdrawalDate.toDate().toDateString()}
								        </Alert>
								    	}

								        <br/>

								        { this.state.pendingWithdrawal === 0 &&
					          		 		<Button onClick={ () => this.setState({withdrawPanel: true, withdraw_next:0, withdrawAmount:0,releaseConfirmationNumber:0}) } style={styless.buttonPrimary} variant='contained' color='primary' fullWidth={true}>
						          					Withdraw
						          			</Button>
					          			}
				          		 	</div>

				          		 }

				          		 { this.state.submenu === 7 &&
				          		 	<div>
				          		 		<center>
				          		 			<h1>You have { this.state.jpc > 0 ? this.state.jpc : 0 } Credits</h1>
				          		 			
				          		 		</center>
				          		 		<br/>
				          		 		<Button onClick={ () => this.setState({showPaypal:true}) } style={styless.buttonPrimary} variant='contained' color='primary' fullWidth={true}>
					          					 Buy Credits
					          			</Button>
				          		 	</div>

				          		 }

				          		 { this.state.submenu === 8 &&
				          		 	<div>
				          		 		<center>
				          		 			<h1>Signup Link</h1>
				          		 		</center>
				          		 		<Typography>
				          		 			If a freelancer signs up using your signup link, you'll get 50 credits. Once the freelancer has completed the profile approval, free credits will be credited to your account. 
				          		 		</Typography>
				          		 		<TextField
						          				name='view-profile'
											    variant="outlined"
										      	InputProps={{
										          endAdornment: <InputAdornment position="end">
										          	<Tooltip title="Copy">
											          	<IconButton
											          		edge="end"
											          		aria-label="Copy Sign Up Link"
											          		onClick={ () => {
											          			
											          			navigator.clipboard.writeText(window.location.hostname+"/signup?ref="+that.props.user.uid)
											          			that.setState({snackbar:true, snackbarMessage:'Your signup link was copied to clipboard'})
											          		} }
											          	>
											          		<FileCopyIcon />
											          	</IconButton>
										          	</Tooltip>
										          </InputAdornment>,
										        }}
										      	margin="normal" disabled={ that.state.profilecompleteness < 100 ? true : false  } value={window.location.hostname+"/signup?ref="+that.props.user.uid} fullWidth={true}  />

				          		 	</div>

				          		 }

				          		 { this.state.submenu === 9 &&
				          		 	<div>
				          		 		<center>
				          		 			<h1>Settings</h1>
				          		 		</center>

				          		 		<Typography>
				          		 			<b>Custom Permalink</b><br/>
				          		 			
				          		 			<TextField
						          				name='view-profile'
											    variant="outlined"
										      	InputProps={{
										          endAdornment: <InputAdornment position="end">
										          	<Tooltip title="Generate">
											          	<IconButton
											          		edge="end"
											          		aria-label="Generate Permalink"
											          		onClick={ () => {
											          			
											          			let mypermalink = this.state.fname.toLowerCase()+"-"+this.state.lname.toLowerCase()
											          			
											          			that.setState({mypermalink:mypermalink})
											          		} }
											          	>
											          		<CreateIcon />
											          	</IconButton>
										          	</Tooltip>
										          	<Tooltip title="Save">
											          	<IconButton
											          		edge="end"
											          		aria-label="View Profile"
											          		onClick={ () => { 
											          			

											          			if(that.state.mypermalink!="")
											          			{	
											          				this.savePermalink()

											          				
											          			}else{
											          				that.setState({snackbar:true, snackbarMessage:"Generate or input a custom permalink."})
											          			}
															   
															  
															  
											          		} }
											          	>
											          		<SaveIcon />
											          	</IconButton>
										          	</Tooltip>

										          	<Tooltip title="View">
											          	<IconButton
											          		edge="end"
											          		aria-label="Save Permalink"
											          		onClick={ () => { 
											          			window.open("/freelancer/"+that.state.mypermalink, "_blank") 
											          		} }
											          	>
											          		<ArrowForwardIcon />
											          	</IconButton>
										          	</Tooltip>
										          	
										          </InputAdornment>,
										        }}
										      	margin="normal" value={this.state.mypermalink} fullWidth={true} onChange={ (event) =>  { const pattern = /^[a-zA-Z0-9-]*$/; if (pattern.test(event.target.value)) { this.setState({mypermalink: event.target.value }) } } }  />

				          		 			

				          		 		</Typography>

				          		 		<hr/>

				          		 		<Typography>
				          		 			<b>Public Profile</b><br/>
				          		 			
				          		 			<FormGroup row>
				          		 				<Grid item xs={12} md={6}>
				          		 					<Tooltip title="Allow Google to include your public link in its search results, making your profile more visible on Google's search engine.">
				          		 						<FormControlLabel
												        control={<Switch sx={{ "&.MuiSwitch-root .Mui-checked": {
   																				color: "#00b16a"
															  },
															  ".MuiSwitch-track": {
															    backgroundColor: "rgba(0, 177, 106, 0.5) !important"
															  } 
															}} checked={this.state.allowgindex} onChange={this.updateallowgindex} />}
												        label="Allow Google to Index your profile." />
											        </Tooltip>
				          		 				</Grid>
				          		 				<Grid item xs={12} md={6}>
				          		 					 <Tooltip title="We will display your profile using your public link, even if users are not logged into FreelancerToGo.">
				          		 					<FormControlLabel
											        control={<Switch sx={{ "&.MuiSwitch-root .Mui-checked": {
   																				color: "#00b16a"
															  },
															  ".MuiSwitch-track": {
															    backgroundColor: "rgba(0, 177, 106, 0.5) !important"
															  } 
															}} checked={this.state.allowpublic} onChange={this.updateallowpublic} />}
											        label="Make my profile public." />
											        </Tooltip>
				          		 				</Grid>
											</FormGroup>

				          		 		</Typography>
				          		 		<hr/>
				          		 		<Typography>
				          		 			<b>Account Deletion</b><br/>
				          		 			Deleting your account will result in the permanent removal of all your data from our database, and this action cannot be undone. To proceed with the deletion, simply type 'delete' in the text field and press the "Delete My Account" button.
				          		 		</Typography>
				          		 		<br/>
				          		 		<div style={{justifyContent:'center', textAlign:'center'}}>
				          		 			 <TextField variant="outlined" margin="normal" value={this.state.deletemyaccount} fullWidth={true} onChange={ (event) => this.setState({deletemyaccount: event.target.value }) } />
						          			 <br/>
				          		 			 <Button style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=>this.deleteMyAccount() } >
									           Delete My Account
									         </Button>
				          		 		</div>
				          		 	</div>

				          		 }
				          		 </div>

				          		</Grid>
				          		<Grid item xs={0} md={3}></Grid>
				          	</Grid >
				      </div>
			  	  }

			  	  { this.state.isLoaded === false &&
	                        <div style={{marginTop: 150, justifyContent:'center', textAlign:'center'}}>
	                        <CircularProgress  style={{color:'#00b16a', alignSelf:'center'}} />
	                        </div>
	              }
		        </header>

		        <Dialog
			        open={this.state.identityVerifiedDialog}
			        onClose={()=>{ 
			        	this.setState({identityVerifiedDialog:false})
			        	this.props.setLoading(false)
			        	 } }
			        fullWidth={true}
			        maxWidth="md"
			        scroll="body"
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			      >
			        <DialogContent>
		 				
		 				<h1 style={{textAlign:'center', fontSize: '28px'}}>
		 					Identity Verification
		 				</h1>

		 				<Typography>
		 					We would like to verify freelancers identity to give clients a better feeling that they are not communicating with fake accounts. This identity verification process will ask you to upload 
		 					a scanned copy or a taken picture of any of your government issued ID and a selfie of you holding the same ID.

		 					<br/><br/>

		 					For your privacy, you can blur out any sensitive information from your id like id number, contact numbers and address. We will only looking at your full name and your id picture.

		 					<br/><br/>

		 					We will also delete the uploaded id and picture from our system after the verification process.
		 				</Typography>

		 				<br/><br/>
		 				<Grid container >
		 					<Grid item md={6} sm={12}>

		 						<div className="App-panel">
			 						<Typography style={{textAlign:'center',fontSize: '16px'}}><i>Upload scanned or picture of your government issued id.</i></Typography>
			 						
			 						<FilePond 
			        					allowMultiple={false}
			        					maxFiles={1}
			        					maxFileSize="1MB"
			        					acceptedFileTypes={"image/*"}
			        					ref = { ref3 => this.pond3 = ref3 } >
			        				</FilePond>
		        				</div>
		 					</Grid>
		 					<Grid item md={6} sm={12}>

		 						<div className="App-panel">
			 						<Typography style={{textAlign:'center',fontSize: '16px'}}><i>Upload a selfie of you holding the id. Make sure that your id picture and name is clear.</i></Typography>

			 						<FilePond 
			        					allowMultiple={false}
			        					maxFiles={1}
			        					maxFileSize="1MB"
			        					acceptedFileTypes={"image/*"}
			        					ref = { ref4 => this.pond4 = ref4 } >
			        				</FilePond>
		        				</div>
		 					</Grid>
		 				</Grid >
						
		 			</DialogContent>
			        <DialogActions>
			          <Button disabled={this.props.isLoading===true?true:false} style={styless.buttonPrimary} variant='contained' color='primary' onClick={()=>this.submitVerification() } >
			           
			           	{ this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : 'Submit' }
			          </Button>
			          <Button style={styless.buttonDefault} variant='outlined' color='primary' onClick={()=>this.setState({identityVerifiedDialog:false}) } >
			           Close
			          </Button>
			         </DialogActions>
			      </Dialog>

		        <Dialog
			        open={this.state.showPaypal}
			        onClose={()=>this.setState({showPaypal:false})}
			        fullWidth={true}
			        maxWidth="sm"
			        scroll="body"
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			      >
			        <DialogTitle id="alert-dialog-title">Buy Credits</DialogTitle>
			        <DialogContent>
		 				
					  <PaypalButton updateJPC = { this.updateJPC } jpc={this.state.jpc}  user={this.props.user} db={this.props.db} doc={this.props.doc} updateDoc={this.props.updateDoc} />
		 				
		 			</DialogContent>
			        <DialogActions>
			          <Button style={styless.buttonDefault} variant='outlined' color='primary' onClick={()=>this.setState({showPaypal:false}) } >
			           Close
			          </Button>
			         </DialogActions>
			      </Dialog>

			    <Dialog
			        open={this.state.withdrawPanel}
			        onClose={()=>this.setState({withdrawPanel:false})}
			        fullWidth={true}
			        maxWidth="sm"
			        scroll="body"
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			      >
			        <DialogTitle id="alert-dialog-title">Request Payment</DialogTitle>
			        <DialogContent>
		 				
		 				 { this.state.withdraw_next === 0 &&
					       <TabPanel value={0} index={0}>
					       		<Typography align='center' variant='h5'>${ Number( this.state.currentBalance ).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2}) }</Typography>
					            <Typography align='center' variant='subtitle2'>Available Balance</Typography>
					            <br/>
					            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
			        				<TextField value={this.state.withdrawAmount} helperText={ this.state.withdrawAmountErrorMessage } error={that.state.withdrawAmountError} id="amount" label="Enter amount" variant="outlined" fullWidth onKeyPress={(event)=>{ if (!/[0-9.]/.test(event.key)) {
						          event.preventDefault();
						        } }} onChange={ (e)=>
						            {
						              
						              this.setState({withdrawAmount:e.target.value } )
						            }
						          }

						          InputProps={{
						          	endAdornment: <InputAdornment position="end" onClick={ ()=> this.useMax()  } style={{cursor:'pointer'}}>Use Max</InputAdornment>
						          }}

						        />
						   		
				            	</Box>

					       		


				       		  
			        			<br/>
				        		<center>
					        		<Button style={styless.buttonPrimary} variant='contained' color='primary' onClick={ ()=>{

					        			if(Number(this.state.withdrawAmount)<that.state.min_withdrawal)
					        				that.setState({withdrawAmountError:true, withdrawAmountErrorMessage: "Minimum withdrawal amount is $"+that.state.min_withdrawal})
					        			else if(Number(this.state.withdrawAmount)>that.state.max_withdrawal){
					        				that.setState({withdrawAmountError:true, withdrawAmountErrorMessage: "Maximum withdrawal amount is $"+that.state.max_withdrawal})
					        			}else if(Number(this.state.withdrawAmount)<=0)
					        				that.setState({withdrawAmountError:true, withdrawAmountErrorMessage: "Amount show be greater than 0"})
					        			else if(Number(this.state.withdrawAmount)>Number(this.state.currentBalance) )
					        				that.setState({withdrawAmountError:true, withdrawAmountErrorMessage: "You dont have enough balance."})
					        			else if( isNaN(this.state.withdrawAmount) )
					        				that.setState({withdrawAmountError:true, withdrawAmountErrorMessage: "Your input is invalid"})
					        			else
					        				that.setState({withdrawAmountError:false, withdrawAmountErrorMessage: "",withdraw_next: 1})
					        		}}>
					 					 Next
									</Button>
								</center>
								<br/>
					       </TabPanel>
					     }

					     { this.state.withdraw_next === 1 &&
					       <TabPanel value={1} index={1}>
					      	 <Tooltip title="Back">
			          		 	<IconButton onClick={ ()=>this.setState({withdraw_next: 0}) } style={{alignSelf:'center', color:"#00b16a"}} aria-label="delete"  color="primary">
					           		<ArrowBackIcon />
					         	</IconButton>
					         </Tooltip>

			        		 <br/>
					       	 
					       	 <center>
					       	 		<Typography>Select where we will send your earnings</Typography>
					       	 		<br/>
					       	 		{
					       	 			this.state.paypalme !== "" &&
					       	 			
					       	 			<div fullWidth onClick={ ()=> this.setState({withdraw_selection: 'paypalme', withdraw_next: 2}) }
								          style={{
								            textAlign:'center'
								          }}
								          className="buttonbase"
								        >
								        	<img alt="paypalme" src="../paypalme.png" />
								        	<h2>PayPal.Me</h2>
								        </div>
					       	 		}

					       	 		{
					       	 			this.state.paypalme === "" &&
					       	 			<Alert severity="error">
								          		You havent setup your withdrawal method. Please go to 'GET PAID' tab to setup how you will get your earnings.
								        </Alert>
					       	 		}

					       	 		<br/><br/>
					       	 		
							 </center>
					       </TabPanel>
					     }

					     { this.state.withdraw_next === 2 &&
					       <TabPanel value={2} index={2}>
					         <Tooltip title="Back">
			          		 	<IconButton onClick={ ()=>this.setState({withdraw_next:1}) } style={{alignSelf:'center', color:"#00b16a"}} aria-label="delete"  color="primary">
					           		<ArrowBackIcon />
					         	</IconButton>
					         </Tooltip>

			        		 <br/>
					       	 <Typography align='center' variant='h3'>${ Number( Number( this.state.withdrawAmount ) -  Number( this.computeFreelancertogofee( this.state.withdrawAmount ) ) - Number( this.computePaypalfee(this.state.withdrawAmount) ) ).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2}) }</Typography>
				             <Typography align='center' variant='subtitle1'>You will receive this amount</Typography>
				             <br/>
				             <Typography align='center' variant='subtitle2'>Withdraw amount: ${ Number( this.state.withdrawAmount ).toFixed(2) }</Typography>
				             <Typography align='center' variant='subtitle2'>Transaction Fee : ${ Number(  Number( this.computePaypalfee(this.state.withdrawAmount) ) + Number( this.computeFreelancertogofee(this.state.withdrawAmount) )   ).toLocaleString( undefined, {minimumFractionDigits:2,maximumFractionDigits:2} ) }
				                <Tooltip title={ <div style={{whiteSpace:'pre-line'}}>Freelancertogo Commission: ${ Number( Number(this.computeFreelancertogofee(this.state.withdrawAmount)) ).toLocaleString({minimumFractionDigits:2, maximumFractionDigits:2}) } <br/> Paypal Fee: ${ Number( Number( this.computePaypalfee(this.state.withdrawAmount) ) ).toLocaleString({minimumFractionDigits:2, maximumFractionDigits:2})  }  </div> } >
				                    <HelpIcon style={{marginLeft:'2px',fontSize:'0.875rem'}} />
				                </Tooltip>
				             </Typography>
				             <br/><br/>
					       	 <center>
					         <Button disabled={this.props.isLoading===true?true:false} style={styless.buttonPrimary} variant='contained' color='primary' onClick={ ()=>that.generateConfirmationCode()  }>
					 			{ this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : 'Next' }
							 </Button>
							 </center>
					       </TabPanel>
					     }

					     { this.state.withdraw_next === 3 &&
					       <TabPanel value={3} index={3}>
					         <Tooltip title="Back">
			          		 	<IconButton onClick={ ()=>this.setState({withdraw_next: 2}) } style={{alignSelf:'center', color:"#00b16a"}} aria-label="delete"  color="primary">
					           		<ArrowBackIcon />
					         	</IconButton>
					         </Tooltip>

			        		 <br/>
					       		<Box textAlign='center'>
					       			<Typography>Enter the 5 digit code that was sent to your email.</Typography>
					       
						      		<div style={{flexDirection: 'row'}}>

						      			<br/>
						      			<TextField onKeyPress={(event)=>{ if (!/[0-9.]/.test(event.key)) {
						          event.preventDefault();
						        } }} onChange={(e)=>{ 
						      				this.setState({c1: e.target.value })
						      				
						      				const nextfield = document.querySelector(
									          `input[name=c2]`
									        );

									        if (nextfield !== null) {
									          nextfield.focus();
									          nextfield.select()
									        }
						      				

						      			}
						      			} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ selectTextOnFocus: true, maxLength: 1, min: 0, style: { textAlign: 'center' }}} />
						      			<TextField onKeyPress={(event)=>{ if (!/[0-9.]/.test(event.key)) {
						          event.preventDefault();
						        } }} name="c2" selectTextOnFocus={true} onChange={(e)=>{
						      				this.setState({c2: e.target.value })

						      				const nextfield = document.querySelector(
									          `input[name=c3]`
									        );

									        if (nextfield !== null) {
									          nextfield.focus();
									          nextfield.select()
									        }

						      			}
						      			}
						      				 variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
						      			<TextField onKeyPress={(event)=>{ if (!/[0-9.]/.test(event.key)) {
						          event.preventDefault();
						        } }} name="c3" onChange={(e)=>{
						      				this.setState({c3: e.target.value })

						      				const nextfield = document.querySelector(
									          `input[name=c4]`
									        );

									        if (nextfield !== null) {
									          nextfield.focus();
									          nextfield.select()
									        }
						      			}
						      			} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
						      			<TextField onKeyPress={(event)=>{ if (!/[0-9.]/.test(event.key)) {
						          event.preventDefault();
						        } }} name="c4" onChange={(e)=>{
						      				this.setState({c4: e.target.value })

						      				const nextfield = document.querySelector(
									          `input[name=c5]`
									        );

									        if (nextfield !== null) {
									          nextfield.focus();
									          nextfield.select()
									        }
						      			}
						      			} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
						      			<TextField onKeyPress={(event)=>{ if (!/[0-9.]/.test(event.key)) {
						          event.preventDefault();
						        } }} name="c5" onChange={(e)=>this.setState({c5: e.target.value })} variant="outlined" style={{width:'50px',margin:'10px'}} inputProps={{ maxLength: 1,min: 0, style: { textAlign: 'center' }}} />
						      			<br/><br/>
						      		</div>

						      		{ this.state.confirmationMatch === false && 
						      			<div>
							      			<Alert severity="error">Confirmation code is incorrect.</Alert>
							      			<br/>
							      		</div>
						      		}

						      		<br/><br/>
						      		<Button disabled={this.props.isLoading===true?true:false} style={styless.buttonPrimary} variant='contained' color='primary' onClick={ ()=>that.verifyConfirmation() }>
						 				{ this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : 'Next' }
								 	</Button>
					      		</Box>
						   </TabPanel>
					     }

					     { this.state.withdraw_next === 4 &&
					       <TabPanel value={4} index={4}>
					        
					       	 <center>
				                <img alt="verified" src="/verified.png" /> <br/>
				               	<div>
			        				<Typography>We received your request. Please wait at least 3-5 business days for the fund to arrive on your paypal account.</Typography>
		        					<br/><br/>
	        					</div>
				             </center>
					       	 <center>
					        		<Button style={styless.buttonPrimary} variant='contained' color='primary' onClick={ ()=>that.setState({withdrawPanel: false}) }>
					 					 Close
									</Button>
							 </center>
					       </TabPanel>
					     }
				
					  
		 			</DialogContent>
			       
			      </Dialog>

		        <Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.setupPaypalDialog}
		        	onClose={ ()=>this.setState({setupPaypalDialog:false}) } >
		 			<DialogTitle><Typography style={styless.title}>Paypal.Me</Typography></DialogTitle>   
		 			<DialogContent>
		 				<div style={{alignItems:'center', justifyContent:'center', display:'flex'}} >
							  <Avatar src="../paypalme.png" style={styless.bigAvatar} />
		 				</div>

		 				<Typography style={styless.small}>Your Paypal.me link <Tooltip title="Go to Paypal.me"><a rel="noopener noreferrer" href={"https://www.paypal.me/"+this.state.paypalme} target="_blank" style={{float:'right'}}><ArrowForwardIcon/></a></Tooltip></Typography> 
		 				<Typography>https://paypal.me/{ this.state.paypalme }</Typography>
		 				<TextField
	  						name='title'
				      		variant="outlined"
				      		margin="normal" value={this.state.paypalme} fullWidth={true} onChange={ (event) => this.setState({paypalme: event.target.value }) } />
	  				  	<br/><br/>
		 			</DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
	    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({setupPaypalDialog:false}) } autoFocus>
				              Close
				            </Button>
				            <Button disabled={this.props.isLoading===true?true:false} style={styless.buttonPrimary}  onClick={ () => this.saveProfile() }>
				               { this.props.isLoading === true ? <CircularProgress style={{color:"#fff"}} size={28} /> : 'Save' }
				            </Button>
			        </DialogActions>
		        </Dialog>

				<Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.confirmDeleteProjectDialog}
		        	onClose={this.state.confirmDeleteProjectDialogClose} >
		 			<DialogTitle><Typography style={styless.title}>Delete</Typography></DialogTitle>   
		 			<DialogContent><Typography>Are you sure you want to remove Project?</Typography></DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
	    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({confirmDeleteProjectDialog:false}) } autoFocus>
				              No
				            </Button>
				            <Button style={styless.buttonPrimary}  onClick={ () => this.confirmDeleteProject() }>
				              Yes
				            </Button>
			        </DialogActions>
		        </Dialog>

				<Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.confirmDeleteEmploymentDialog}
		        	onClose={this.state.confirmDeleteEmploymentDialogClose} >
		 			<DialogTitle><Typography style={styless.title}>Delete</Typography></DialogTitle>   
		 			<DialogContent><Typography>Are you sure you want to remove Employment?</Typography></DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
	    					<Button style={styless.buttonDefault} variant='outlined'  onClick={ () => this.setState({confirmDeleteEmploymentDialog:false}) } autoFocus>
				              No
				            </Button>
				            <Button style={styless.buttonPrimary}  onClick={ () => this.confirmDeleteEmployment() }>
				              Yes
				            </Button>
			        </DialogActions>
		        </Dialog>
		    
		        <Dialog 
		        	maxWidth={'sm'}
		        	fullWidth={true}
		        	open={this.state.confirmDeleteDialog}
		        	onClose={this.state.confirmDeleteDialogClose}
		        >
		 			<DialogTitle><Typography style={styless.title}>Delete</Typography></DialogTitle>   
		 			<DialogContent><Typography>Are you sure you want to remove Education?</Typography></DialogContent>    	
		        	<DialogActions style={{marginTop: 20}}>
	    					<Button style={styless.buttonDefault} variant="outlined"  onClick={ () => this.setState({confirmDeleteDialog:false}) } autoFocus>
				              No
				            </Button>
				            <Button style={styless.buttonPrimary}  onClick={ () => this.confirmDeleteEducation() }>
				              Yes
				            </Button>
			        </DialogActions>
		        </Dialog>
		        <Dialog
		            maxWidth={'sm'}
		            fullWidth={true}
		        	open={this.state.educationDialog}
		        	onClose={this.educationDialogClose} >
		        		<DialogTitle>
		        			<Typography style={styless.title}>Add Education
		        			</Typography>
		        		</DialogTitle>
		        		<DialogContent>
		        			<Typography style={styless.em}>Your School</Typography>
		        				<TextField
		        					error={this.state.schoolError}
		          					name='school'
							      	variant='outlined'
							      	margin="normal" value={this.state.school} fullWidth={true} onChange={ (event) => this.setState({school: event.target.value }) } />

							    <br/><br/>
							 	<Typography style={styless.em}>Course</Typography>
							    <TextField
							    	error={this.state.schoolCourseError}
		          					name='course'
		          					variant='outlined'
							      	margin="normal" value={this.state.schoolCourse} fullWidth={true} onChange={ (event) => this.setState({schoolCourse: event.target.value }) } />

							    <br/><br/>
							    <Typography style={styless.em}>Overview (Optional)</Typography>
							    <TextField
							    	error={this.state.schoolDescriptionError}
		          					name='description'
		          					variant='outlined'
		          					multiline
						          	row="5"
							      	margin="normal" value={this.state.schoolDescription} fullWidth={true} onChange={ (event) => this.setState({schoolDescription: event.target.value }) } />

							    <br/><br/>

							    <Typography style={styless.em}>Year Started</Typography>
						    	<TextField
						    		error={this.state.schoolStartedError}
				          			name='started'
				          			variant='outlined'
				          			type='date'
				          			format="MM-DD-YYYY"
				          			margin="normal" value={this.state.schoolStarted} fullWidth={true} onChange={ (event) => this.setState({schoolStarted: event.target.value }) } />
							 	
							 	<br/><br/>

							 	<Typography style={styless.em}>Year Ended</Typography>

						         <FormControlLabel control={

						         	<Checkbox
							            checked={this.state.schoolEndedPresent}
							            onChange={()=>{ this.setState({schoolEndedPresent: this.state.schoolEndedPresent === true ? false : true, schoolEndedDisabled: this.state.schoolEndedPresent === true ? false : true }) }}
							            name="schoolEndedPresent"
							            color="success"
							          />

						         } label="Present" />
						    	
			     				<TextField
			     					error={this.state.schoolEndedError}
		          					name='ended'
							      	variant='outlined'
							      	type='date'
							      	disabled={this.state.schoolEndedDisabled}
							      	defaultValue={new Date()}
							      	InputProps = {{inputProps: {min: this.state.schoolStarted } }}
							      	margin="normal" value={this.state.schoolEnded} fullWidth={true} onChange={ (event) => this.setState({schoolEnded: event.target.value }) } />
								
		        		</DialogContent>
		        			<DialogActions style={{marginTop: 20}}>
		        					<Button  style={styless.buttonDefault} variant="outlined" onClick={this.educationDialogClose} autoFocus>
						              Close
						            </Button>
						            <Button style={styless.buttonPrimary} disabled={ this.props.isLoading === true ? true :false }  onClick={ () => this.profileEducation() }>
						               { this.props.isLoading === true ? <CircularProgress style={{color:"#fff"}} size={28} /> : 'Save' }
						            </Button>
					        </DialogActions>
		        		
		        </Dialog>

		        <Dialog
		            maxWidth={'sm'}
		            fullWidth={true}
		        	open={this.state.portfolioDialog}
		        	onClose={this.portfolioDialogClose} >
		        		<DialogTitle><Typography style={styless.title}>Add Project</Typography> </DialogTitle>
		        		<DialogContent>

		        			<DialogContentText>

		        				<Typography style={styless.em}>Project Title</Typography>
		        				<TextField
		        					error={this.state.projectTitleError}
		          					name='projectTitle'
							      	variant='outlined'
							      	margin="normal" value={this.state.projectTitle} fullWidth={true} onChange={ (event) => this.setState({projectTitle: event.target.value }) } />

							    <br/><br/>
							 	<Typography style={styless.em}>Project Description</Typography>
							    <TextField
							    	multiline
							    	rows="5"
							    	error={this.state.projectDescriptionError}
		          					name='projectDescription'
							      	variant='outlined'
							      	margin="normal" value={this.state.projectDescription} fullWidth={true} onChange={ (event) => this.setState({projectDescription: event.target.value }) } />
							    
							    <br/><br/>
							    <Typography style={styless.em}>Project Link (Optional)</Typography>
							    <TextField
							    	name='projectLink'
							      	variant='outlined'
							      	margin="normal" value={this.state.projectLink} fullWidth={true} onChange={ (event) => this.setState({projectLink: event.target.value }) } />

							    <br/><br/>
							    <Typography style={styless.em}>Date Started</Typography>
						    	<TextField
						    		error={this.state.projectStartedError}
						    		type='date'
				          			name='started'
									variant='outlined'
									margin="normal" value={this.state.projectStarted} fullWidth={true} onChange={ (event) => this.setState({projectStarted: event.target.value }) } />
							 	
							 	<br/><br/>
							 	<Typography style={styless.em}>Date Ended</Typography>
			     				<TextField
			     					error={this.state.projectEndedError}
			     					type='date'
		          					name='ended'
							      	variant='outlined'
							      	InputProps = {{inputProps: {min: this.state.projectStarted } }}
							      	
							      	margin="normal" value={this.state.projectEnded} fullWidth={true} onChange={ (event) => this.setState({projectEnded: event.target.value }) } />
							
							    <Typography style={styless.em}>Project Screenshot (Max 3 image files) </Typography>
		        				<br/>

		        				<FilePond 
			        					allowMultiple={true}
			        					maxFiles={3}
			        					maxFileSize="1MB"
							        	acceptedFileTypes={"image/*"}
			        					ref = { ref => this.pond = ref } 
			        					>
			        					
			        			</FilePond>

		        				<br/>
		        				<br/>
		        				

		        			</DialogContentText>
		        			</DialogContent>
		        			<DialogActions style={{marginTop: 20}}>
		        					<Button style={styless.buttonDefault} variant='outlined'  onClick={this.portfolioDialogClose} autoFocus>
						              Close
						            </Button>
						            <Button disabled={this.props.isLoading===true?true:false} style={styless.buttonPrimary}  onClick={ () => this.addProject(this) }>
						              { this.props.isLoading === true ? <CircularProgress style={{color:"#fff"}} size={28} /> : 'Save' }
						            </Button>
					        </DialogActions>
		        		
		        </Dialog>

		        <Dialog
		            maxWidth={'sm'}
		            fullWidth={true}
		        	open={this.state.employmentDialog}
		        	onClose={this.employmentDialogClose} >
		        		<DialogTitle><Typography style={styless.title}>Add Employment</Typography></DialogTitle>
		        		<DialogContent>
		        			<DialogContentText>
		        				<Typography style={styless.em}>Company</Typography>
		        				<TextField
		        					error={this.state.companyError}
		          					name='company'
							      	variant='outlined'
							      	margin="normal" value={this.state.company} fullWidth={true} onChange={ (event) => this.setState({company: event.target.value }) } />

							 	<br/><br/>
							 	<Typography style={styless.em}>Job Title</Typography>
							    <TextField
							    	error={this.state.jobTitleError}
		          					name='job'
							      	variant='outlined'
							      	margin="normal" value={this.state.jobTitle} fullWidth={true} onChange={ (event) => this.setState({jobTitle: event.target.value }) } />

							    <br/><br/>
							    <Typography style={styless.em}>Overview (Optional)</Typography>
							    <TextField
							    	error={ this.state.jobDescriptionError }
		          					name='description'
							      	variant='outlined'
							      	multiline
						          	row="5"
							      	margin="normal" value={this.state.jobDescription} fullWidth={true} onChange={ (event) => this.setState({jobDescription: event.target.value }) } />

							    <br/><br/>
							    <Typography style={styless.em}>Job Started</Typography>
						    	<TextField
						    		error={ this.state.jobStartedError }
				          			name='started'
				          			type='date'
									variant='outlined'
									margin="normal" value={this.state.jobStarted} fullWidth={true} onChange={ (event) => this.setState({jobStarted: event.target.value }) } />
							 	
							 	<br/><br/>
							 	<Typography style={styless.em}>Job Ended</Typography>

							 	<FormControlLabel control={

							 		<Checkbox
							            checked={this.state.jobEndedPresent}
							            onChange={()=>{ this.setState({jobEndedPresent: this.state.jobEndedPresent === true ? false : true, jobEndedDisabled: this.state.jobEndedDisabled === true ? false : true }) }}
							            name="jobEndedPresent"
							            color="success"
							          />

							 	} label="Present" />

							 	

			     				<TextField
			     					error={ this.state.jobEndedError }
		          					name='ended'
		          					type='date'
							      	variant='outlined'
							      	disabled={this.state.jobEndedDisabled}
							      	InputProps = {{inputProps: {min: this.state.jobStarted } }}
							      	margin="normal" value={this.state.jobEnded} fullWidth={true} onChange={ (event) => this.setState({jobEnded: event.target.value }) } />
							
							     
		        			</DialogContentText>
		        			</DialogContent>
		        			<DialogActions style={{marginTop: 20}}>
		        					<Button style={styless.buttonDefault} variant='outlined'  onClick={this.employmentDialogClose} autoFocus>
						              Close
						            </Button>
						            <Button disabled={this.props.isLoading===true ? true : false } style={styless.buttonPrimary}  onClick={ () => this.profileEmployment() }>
						               { this.props.isLoading === true ? <CircularProgress style={{ 'color': '#fff'}} size={28} /> : 'Save' }
						            </Button>
					        </DialogActions>
		        		
		        </Dialog>

		        <Dialog
		        	open={this.state.skillsDialog}
		        	onClose={this.skillsDialogClose} >
		        		<DialogTitle>Opps!</DialogTitle>
		        		<DialogContent>
		        			<DialogContentText>Sorry but you can only add up to 10 skills</DialogContentText>
		        			<DialogActions>
		        				<Button onClick={this.skillsDialogClose} color="primary" autoFocus>
					              Close
					            </Button>
		        			</DialogActions>
		        		</DialogContent>
		        </Dialog>

		        <Dialog
			        open={this.state.saveWarning}
			        onClose={()=>{this.setState({saveWarning:false})}}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			      >
			        <DialogTitle id="alert-dialog-title">
			          {"Pending your profile."}
			        </DialogTitle>
			        <DialogContent>
			          <Typography>
			            You made changes to your profile and they will be set to pending. Your profile will not be visible in the freelancer search and you will  not be able to submit a proposal until we check it. Would you like to continue?
			          </Typography>
			        </DialogContent>
			        <DialogActions>
			          <Button style={styless.buttonDefault} onClick={()=>{ this.setState({saveWarning:false}) } }>No</Button>
			          <Button style={styless.buttonPrimary}  onClick={ ()=>this.profileBasic() } autoFocus color="primary" variant='contained'>
			            Yes
			          </Button>
			        </DialogActions>
			      </Dialog>

		        <Snackbar
			          anchorOrigin={{
			            vertical: 'bottom',
			            horizontal: 'center',
			          }}
	          		open={this.state.snackbar}
	          		autoHideDuration={6000}
	          		onClose={this.snackbarClose}
	          		ContentProps={{
	            		'aria-describedby': 'message-id',
	          		}}
	          		message={<span id="message-id">{ this.state.snackbarMessage }</span>}
			          action={[
			            <IconButton
			              key="close"
			              aria-label="Close"
			              color="inherit"
			              onClick={this.snackbarClose}
			            >
			              <CloseIcon />
			            </IconButton>,
			          ]} />
		    </div>
			)

		}else
		{
			return (

				<div></div>

			)
		}
		
	}
}

const mapStateToProps = (state) => {
	return {
		firebase: state.firebase,
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		skillList: state.skillList,
		jobCategories: state.jobCategories,
		profile: state.profile,
		jobCategoriesList: state.jobCategoriesList,
		countries: state.countries,
		doc: state.doc,
		getDoc: state.getDoc,
		db: state.db,
		collection: state.collection,
		where: state.where,
		query: state.query,
		orderBy: state.orderBy,
		getDocs: state.getDocs,
		setDoc: state.setDoc,
		storage:state.storage,
		storageRef: state.storageRef,
		uploadBytes: state.uploadBytes,
		getDownloadURL:state.getDownloadURL,
		limit: state.limit,
		updateDoc: state.updateDoc,
		adminEmail: state.adminEmail,
		adminName: state.adminName,
		isLoading:state.isLoading,
		deleteDoc: state.deleteDoc,
		deleteUser: state.deleteUser,
		auth: state.auth,
		addDoc: state.addDoc
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		logout: (firebase) => dispatch({type: "SET_LOGOUT", payload: {firebase:firebase}}),
		setCountries: (countries) => dispatch({type: "SET_COUNTRIES", payload: {countries:countries}}),
		setLoading: (isLoading) => dispatch({type: "SET_LOADING", payload: {isLoading:isLoading}}),
		setSkillList: (skills) => dispatch({ type: "SET_SKILLS", payload: { skillList: skills } }),
		setJobCategories: (jobCategories) => dispatch({ type: "SET_JOB_CATEGORIES_LIST", payload: { jobCategories: jobCategories } }),
		setProfile: (profile) => dispatch({ type: "SET_PROFILE", payload: { profile: profile } }),
		setJobPosts: (jobspost) => dispatch({type: "SET_JOBS", payload: {jobspost:jobspost }})
		
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);





